import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  avatar: string;
  requestRemaining: number;
  firstName: string;
  lastName: string;
  role: string;
  city: string;
  country: string;
  linkedinLink: string;
  bio: string;
  acquisitionClosed: string;
  annualAquisitions: string;
  accreditedBuyer: string;
  profile_percentage:number|string;
  token: string;
  errorMsg: string;
  loading: boolean;
  language: string;
  pageText: {
    "complete":string,
  "yourProfile":string,
  "yourDeals":string,
  "location":string,
  "yourActivities":string,
  "exploreListings":string,
  "availableThisWeek":string,
  "yourSubscription":string,
  "aboutYourself":string,
  "linkedInProfile":string,
  "viewInLinkedIn":string,
  "noOfAcq":string,
  "projectedAnnualAcq":string,
  "accreditedBuyer":string,
  "completeYourProfile":string,
  "viewFullProfile":string,
  "loremIpsum":string,
  "dealsSub": string,
  "fO": string,
  "vP": string
  }
    // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getApiBuyerDashboardCallId: string = "";
  apiTotalMonthCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      avatar: '',
      requestRemaining: 0,
      firstName: '',
      lastName: '',
      role: '',
      city: '',
      country: '',
      linkedinLink: '',
      bio: '',
      acquisitionClosed: '',
      annualAquisitions: '',
      accreditedBuyer: '',
      profile_percentage: 0,
      errorMsg: "",
      token: "",
      loading: false,
      language: '',
      pageText: {
        "dealsSub": "",
        "complete":"",
  "yourProfile":"",
  "yourDeals":"",
  "location":"",
  "yourActivities":"",
  "fO":"",
  "exploreListings":"",
  "availableThisWeek":"",
  "yourSubscription":"",
  "aboutYourself":"",
  "linkedInProfile":"",
  "viewInLinkedIn":"",
  "noOfAcq":"",
  "projectedAnnualAcq":"",
  "accreditedBuyer":"",
  "completeYourProfile":"",
  "viewFullProfile":"",
  "loremIpsum":"",
  "vP": ""
      }
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    let lang = await getStorageData("language");
    if(!lang) {
      lang = 'en';
    }
    this.setState({language: lang}, () => {
      this.translateText(lang);
      this.getDashboardData();
      this.getAccessRequestCount();
    })
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    if (!this.state.token) {
      toast.error("Please login!");
      return false;
    }
    if(!this.state.language) {
      return false;
    }

    const header = {
      token: this.state.token,
    };

    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiBuyerDashboardCallId  = reqMsg.messageId;

    // Set endpoint
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/start_ups/buyer_profile?locale=${this.state.language}`
    );

    // Set header
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Set HTTP method
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.setState({ loading: true });
    runEngine.sendMessage(reqMsg.id, reqMsg);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    const apiId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const respJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (respJson) {
      if (!respJson.errors) {
        this.handleSuccessfulResponse(apiId, respJson);
      } else {
        this.handleErrorResponse(respJson.errors[0]["token"]);
      } 
    }
    if (errReponse) {
      this.handleErrorResponse(errReponse);
    }
    // Customizable Area End
  }
  // Customizable Area Start

  getAccessRequestCount = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
       token: this.state.token,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTotalMonthCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getAccessCountEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  translateText = async (lang: string) => {
    this.setState({pageText: lang === 'en' ? webConfigJSON.staticTextEn : webConfigJSON.staticTextAr})
  }

  
  componentWillMount() {
    const userToken = localStorage.getItem("token") || "";
    if (userToken) {
      this.setState({ token: userToken });
  }
  this.receiveTokenValue(userToken)
}
  
    receiveTokenValue = (token: string) => {
      this.setState({ token: token }, () => {
        if (!token) {
          toast.error("Please login!");
        } else {
        }
      });
    }
  getFullName() {
    const { firstName, lastName } = this.state;
    return `${firstName} ${lastName}`;
  }

  handleSuccessfulResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getApiBuyerDashboardCallId) {
      this.handleGetApiBuyerDashboardResponse(responseJson);
    }
   
    if (apiRequestCallId == this.apiTotalMonthCallId && responseJson && responseJson.total_requests_remaining_this_week) {
      this.setState({requestRemaining: responseJson.total_requests_remaining_this_week});
    }
  }

 

  handleGetApiBuyerDashboardResponse(responseJson: any) {
    const {
      first_name, last_name, bio,
      social_media_link, number_of_acquisition_closed, projected_annual_acquisitions,
      accredited_buyer, country, city, image, user_type, profile_percent
    } = responseJson.data.attributes;
    this.setState({
      avatar: image?.url || '',
      firstName: first_name, lastName: last_name, 
      bio: bio, role: user_type,
      linkedinLink: social_media_link, acquisitionClosed: number_of_acquisition_closed,
      annualAquisitions: projected_annual_acquisitions, accreditedBuyer: accredited_buyer,
      country: country, city: city,
      loading: false,
      profile_percentage:(parseInt(profile_percent)).toFixed()|| 0
    });
  }

  handleErrorResponse(errorReponse: any) {
    this.setState({
      loading: false,
      errorMsg: errorReponse,
    });
    toast.error(errorReponse);
  }

 getNameRoute=(route:string)=>{
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), route);

  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
return message;
}
handleCompleteProfile=()=>{
  this.send(this.getNameRoute("EditBuyerProfile"))
  }
  handleProfileClick=()=>{
  this.send(this.getNameRoute("EditBuyerProfile"))

  }

  handleCatalogueNavigation = () => {
    this.send(this.getNameRoute("Catalogue"));
  };

  handleConditon = (condition: any, trueSta: any, falseSta: any) => {
    return condition ? trueSta : falseSta
  }
  
  // Customizable Area End
}
