export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const arrow2 = require("../assets/arrow2.svg");
export const back_arrow = require("../assets/back_arrow.svg");
export const backArrow = require("../assets/back.svg");
export const frontArrow = require("../assets/front.svg");
export const dot = require("../assets/dot.svg");
export const close = require("../assets/close.svg");
export const tick = require("../assets/purpletick.svg");