import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
// Customizable Area End

export const configJSON = require("./config");

export type Props =  WithStyles & {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  pagetitle: string,
  description: string,
  lang:string,
  descrip:any[],
  title:string,
  descripAr:any[],
  titleAr:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class TermsAndConditionsDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  TermsconditionsCallId: string = "";
  // Customizable Area End



  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      pagetitle: "",
      description: "",
      lang:"",
      descrip:[],
      title:"",
      descripAr:[],
      titleAr:""
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getTermsConditionsApi();
    this.getLang()
    // Customizable Area End
  }

  getLang = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return   this.setState({ lang: lang },)
    }
    this.setState({ lang: 'en' })
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.messageTermsResponseApi(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handlenavigation = () => {
    this.props.navigation.goBack();
  }

  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }

  messageTermsResponseApi = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.TermsconditionsCallId === apiRequestCallId){
      if(responseJson && responseJson.data){

        const attributesdes = responseJson.data.map((data : any) =>  data.attributes.description)
        const attributestit = responseJson.data.map((data : any) =>  data.attributes.title)
        const attributesdesAr = responseJson.data.map((data : any) =>  data.attributes.arabic_description)
        const attributestitAr = responseJson.data.map((data : any) =>  data.attributes.arabic_title)
        this.setState({
          descrip: attributesdes,
          title: attributestit[0],
          descripAr:attributesdesAr,
          titleAr:attributestitAr[0]
        })
      }
    }
  } 

  getTermsConditionsApi = async () => {

    const headers = {
      "content-type": "application/json"
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.getApiMethod}`
    );

    this.TermsconditionsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
