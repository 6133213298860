import React from "react";
// Customizable Area Start
import {
  Container,
  Grid,
  Typography,
  Button,
  FormControl,
  InputAdornment,
  TextField,
  OutlinedInput,
  IconButton,
  Checkbox,
  Box,
  Modal,
  Backdrop,
  Fade
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  createStyles,
  Theme as AugmentedTheme,
  withStyles
} from "@material-ui/core/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { backgroundArrow1} from '../../email-account-login/src/assets';
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import { conditionSort } from "../../../components/src/Helper";
// Customizable Area End

import EmailAccountRegistrationBlockController, {
  Props, S, configJSON
} from "./EmailAccountRegistrationBlockController.web";
import { Form, Formik } from "formik";
import { MailOutline, Visibility, VisibilityOff } from "@material-ui/icons";

const images = require("./assets");

export class EmailAccountRegBlock extends EmailAccountRegistrationBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start  
  handleErrorMessage = (field: keyof S['touched']) => {
    const { touched, errors } = this.state;
    const { classes } = this.props;
    if (touched[field] && errors[field]) {
      return (<Box className={classes.errorField}>{errors[field]}</Box>);
    }
    return null;
  }

  // Customizable Area End

  errorMessageHandler = (error: any, touched: any, fieldKey: any) => {
    const {classes} = this.props;
    if (error?.[fieldKey] && touched?.[fieldKey]) {
      return (<Box className={classes.errorField}>{error[fieldKey]}</Box>);
    }
  }

  render() {
    // Customizable Area Start
   
    const isError = this.areAllErrorsEmpty();
    const { classes } = this.props;
    const { initialData } = this.state;
    const pageText = this.state.signupPageText
    const handleProgressBarClass = (ProgressCheck:boolean) : string => {
      if(this.state.PasswordType === "Strong")
      {
         return ProgressCheck ? classes.PasswordStrong : classes.PasswordStrongBar;
      }else if(this.state.PasswordType === "Medium")
      {
        return ProgressCheck ? classes.PasswordMedium: classes.PasswordMediumBar;
      }else{
        return ProgressCheck ? classes.PasswordWeak:classes.PasswordWeakBar;
      }
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
       <div className={classes.mainDiv}>
    <HeaderBlock />
    <Container className={classes.innerContainer}>
      <Grid container >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.mainSignupContainer}
        >
          <Grid className={classes.form}>
          <Formik
            innerRef={this.formRef}
            initialValues={initialData}
            onSubmit={this.handleFormSubmit}
            enableReintialize={true}
          >
            {() => {
              
              return (
                <Form
                  className={classes.signupForm}
                  translate={undefined}
                >
                  {conditionSort(this.state.anyError, <Box className={classes.errorField} data-test-id="err">{this.state.anyError}</Box>, null)}
                  <div className={classes.formInnerCon}>
                  <Typography className={classes.signupHeading}>
                   {this.getSpecifiedLangString(this.state.language, pageText.signUpText, "اشتراك")}
                  </Typography>

                <div className={classes.firstLastNameCon}>
                  <FormControl className={classes.formInput}>
                    <label>{this.getSpecifiedLangString(this.state.language, pageText.firstNamelabel, "الاسم الأول")}</label>
                    <TextField
                      variant="outlined"
                      size="medium"
                      placeholder={this.getSpecifiedLangString(this.state.language, pageText.firstNamePlaceHolder, "محمد")}
                      type="text"
                      fullWidth
                      onChange={this.handleInputChange}
                      name="firstName"
                      value={this.state.firstName}
                      onBlur={this.handledBlurFirst("firstName")}
                      id="firstName"
                      InputProps={{style:{fontFamily:'Montserrat',fontWeight:500}}}
                    />
                    {this.handleErrorMessage('firstName')}
                  </FormControl>
                  <FormControl className={classes.formInput}>
                    <label>{this.getSpecifiedLangString(this.state.language, pageText.lastNameLabel, "الاسم العائلي")}</label>
                    <TextField
                      variant="outlined"
                      size="medium"
                      onBlur={this.handledBlurFirst("lastName")}
                      placeholder={this.getSpecifiedLangString(this.state.language, pageText.lastNamePlaceHolder, "أحمد")}
                      type="text"
                      name="lastName"
                      fullWidth
                      onChange={this.handleInputChange}
                      value={this.state.lastName}
                      id="lastName"
                      InputProps={{
                        style: { fontFamily:'Montserrat',fontWeight:500}
                      }}
                    />
                    {this.handleErrorMessage('lastName')}
                  </FormControl>
                  </div>
                  <FormControl className={classes.formInput}>
                    <label>{this.getSpecifiedLangString(this.state.language, pageText.emailLabel, "عنوان الإيميل")}</label>
                    <OutlinedInput
                      style={{fontFamily:'Montserrat',fontWeight:500}}
                      placeholder={configJSON.emailPlaceHolder}
                      name="email"
                      onChange={this.handleInputChange}
                      onFocus={() => this.setState({ emailFocused: true })}
                      onBlur={this.handledBlurFirst("email")}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <MailOutline
                            style={{
                              ...styles.greyColor,
                              opacity : (this.conditionOr(this.state.emailFocused, this.state.email,"1","0.5"))
                            }}
                          />
                        </InputAdornment>
                      }
                      value={this.state.email}
                      id="email"
                    />
                    {this.handleErrorMessage('email')}
                    {this.state.emailRegisteredAlready &&  (
                      <Box className={classes.errorField}>
                        {this.state.emailErrorRes}
                      </Box>
                    )}
                  </FormControl>
                  <FormControl className={classes.formInput}>
                    <Box
                      style={styles.phoneNumberWithCountryCode}
                      className={classes.phoneInput}
                    >
                      <Typography
                        style={{...styles.typoThirdRow, fontWeight : this.conditionAndCheck(this.state.language ==="en","500","550")}}
                        data-test-id="phoneNumber"
                      >
                        {this.getSpecifiedLangString(this.state.language, pageText.phoneNumberLabel, "رقم الهاتف")}
                      </Typography>
                      <Box sx={styles.countryCodeDownTriangleAndPhoneNumber}>
                        <Box
                          sx={{
                            ...styles.countryCodeWithDownTriangle,
                            width: "auto",
                          }}
                        >
                          <Box sx={styles.flagWithCode}>
                            {this.state.countryFlag === true && (
                              <img
                                src={this.state.countryItemObject.flags.png}
                                style={{
                                  width: "40px",
                                  maxWidth: "40px",
                                  height: "26px",
                                  borderRadius: "4px"
                                }}
                              />
                            )}
                            <p
                              className={this.state.countryItemObject.idd.root ? classes.activeCode : classes.notActive}
                            >
                              {this.state.countryItemObject.idd.root +
                                this.state.countryItemObject.idd.suffixes[0] ||
                                "+966"}
                            </p>
                          </Box>
                        </Box>

                        <Box
                          data-test-id="countryCodeOpenButton"
                          className="select-down-code"
                          onClick={this.handleOpen}
                        >
                          <img src={images.downArrow} alt="arrow" />
                        </Box>
                        <Box style={{ width: "75%" }} data-test-id="countryCode">
                          <input
                            type="text"
                            placeholder="551234578"
                            onChange={this.handleInputChange}
                            value={this.state.phoneNumber}
                            name="phoneNumber"
                            id="phoneNumber"
                            onBlur={this.handledBlurFirst("phoneNumber")}
                            data-test-id="phoneNumberP"
                            className={classes.thridRowInputElement}
                          />
                        </Box>
                      </Box>
                      {this.handleErrorMessage('phoneNumber')}
                      {this.state.phoneErrors && (
                        <Box className={classes.errorField}>
                          {this.state.phoneNumberRegisteredAlready ? pageText.phoneNumberAlreadyExitsText : pageText.inValidPhoneNumberText}
                        </Box>
                      )}
                    </Box>
                  </FormControl>
                  <FormControl className={classes.formInput}>
                    <Box className={classes.labelContaint}>
                      <label>{this.getSpecifiedLangString(this.state.language, pageText.passwordLabel, "كلمة المرور")}</label>
                      <Button className={classes.infoBtn} onClick={this.passwordInfoModal} data-testid="passwordInfo"><img src={images.registerInfo} /></Button>
                    </Box>
                    <OutlinedInput
                      style={{fontFamily:'Montserrat',fontWeight:500}}
                      onFocus={() => this.setState({ passwordFocused: true })}
                      onBlur={(event) => {this.setState({ passwordFocused: false }); this.handledBlurFirst("password")}}
                      placeholder={this.getSpecifiedLangString(this.state.language, pageText.passwordLabelPlaceHolder, "كلمة المرور")}
                      type = {this.conditionAndCheck(this.state.enablePasswordField,"text","password")}
                      name="password"
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <Visibility
                                style={{
                                  ...styles.greyColor,
                                  opacity : (this.conditionOr(this.state.passwordFocused, this.state.password,"1","0.5"))
                                }}
                              />
                            ) : (
                              <VisibilityOff
                                style={{
                                  ...styles.greyColor,
                                  opacity : (this.conditionOr(this.state.passwordFocused, this.state.password,"1","0.5"))
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      // @ts-ignore
                      onChange={this.handleInputChange}
                      value={this.state.password}
                      id="password"
                    />
                    <Box className={classes.PasswordErrorBlock}>
                     
                      {this.state.password.length ? <div className={classes.PasswordErrorContent}>
                       <span >{this.getSpecifiedLangString(this.state.language, pageText.passwordStrengthlabel, "قوة كلمة المرور")}
                        </span>  
                        <div className={`${handleProgressBarClass(true)} ${classes.PasswordCommon}`}>
                          <span data-test-id="PasswordType">{this.returnPasswordType()}</span> 
                          <div className={classes.PasswordBar}>
                            <div className={`${handleProgressBarClass(false)}`}/>
                          </div>
                        </div>
                      </div> : ""}
                             
                    </Box>
                  </FormControl>
                  
                  <FormControl className={classes.formInput}>
                    <Box className={classes.labelContaint}>
                      <label>{this.getSpecifiedLangString(this.state.language, pageText.confirmPasswordLabel, "تأكيد كلمة المرور")}</label>
                      <Button className={classes.infoBtn} onClick={this.passwordInfoModal} data-testid="passwordInfo"><img src={images.registerInfo} /></Button>
                    </Box>
                    <OutlinedInput
                      style={{fontFamily:'Montserrat',fontWeight:500}}
                      onFocus={() =>
                        this.setState({ confirmPasswordFocused: true })
                      }
                      onBlur={this.handledBlurFirst("confirmPassword")}
                      placeholder={this.getSpecifiedLangString(this.state.language, pageText.confirmPasswordLabelAr, "تأكيد كلمة المرور")}
                      type={
                        this.state.enableConfirmPasswordField
                          ? "text"
                          : "password"
                      }
                      name="confirmPassword"
                      // @ts-ignore
                      onChange={this.handleInputChange}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {this.state.enableConfirmPasswordField ? (
                              <Visibility
                                style={{
                                  ...styles.greyColor,
                                  opacity : (this.conditionOr(this.state.confirmPasswordFocused, this.state.confirmPassword,"1","0.5"))
                                }}
                              />
                            ) : (
                              <VisibilityOff
                                style={{
                                  ...styles.greyColor,
                                  opacity : (this.conditionOr(this.state.confirmPasswordFocused, this.state.confirmPassword,"1","0.5"))
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={this.state.confirmPassword}
                      id="confirmPassword"
                    />
                    {this.handleErrorMessage('confirmPassword')}
                  </FormControl>
                  <FormControl className={classes.formInput}>
                    <div style={{...styles.tnc,justifyContent:"",}} className={classes.rowInput}>
                      <Checkbox
                        checked={this.state.checkedFinal}
                        onChange={this.handleCheckBoxChangeBox}
                        className={classes.checkboxDesign}
                        name="checkedFinal"
                        id="tndc"
                        onBlur={this.handledBlurFirst("checkedFinal")}
                      />
                      <Typography style={{...styles.tncText,marginRight: this.conditionAndCheck(this.state.language ==="ar","10px", "0px")}} >
                       {this.getSpecifiedLangString(this.state.language, pageText.agreeToThe, "أوافق على")}{" "}
                      <Button onClick={this.handleRegisterData}  data-testid="terms-conditions" className={classes.tncLink}>{this.getSpecifiedLangString(this.state.language, pageText.termsCondtionsText, " الشروط و الأحكام")}</Button>
                      </Typography>
                    </div>
                    {this.handleErrorMessage('checkedFinal')}
                  </FormControl>
                  <Button
                    id="submitButton"
                    onClick={this.handleFormSubmit}
                    style={{...styles.submitButton,background:( !this.state.phoneNumber || this.state.phoneErrors || 
                      !this.state.firstName || !this.state.email || !this.state.password || !this.state.confirmPassword || !this.state.checkedFinal ||
                      this.state.emailRegisteredAlready || !isError ||  this.state.PasswordType !== "Strong")?'#8c8c8c':'#10BFB8'}}
                    variant="contained"
                    disabled={ !this.state.phoneNumber || this.state.phoneErrors || this.state.emailRegisteredAlready || this.state.PasswordType !== "Strong" ||
                    !this.state.firstName || !this.state.email || !this.state.password || !this.state.confirmPassword || !this.state.checkedFinal ||
                    !isError}
                  >
                    <span style={{color:'#fff'}}>
                    {this.getSpecifiedLangString(this.state.language, pageText.signUpText, "اشتراك")}
                    </span>
                  </Button>
                  </div>
                </Form>
              )}}
          </Formik>
          <div style={{textAlign: 'center', paddingTop: '4px'}}>
            <Typography>{this.getSpecifiedLangString(this.state.language, 'Already have an account?', " هل لديك حساب بالفعل؟")}
              <Button onClick={this.handleLoginNav}  data-test-id="login-reg-bt" className={classes.tncLink} style={{marginLeft: '0px'}}>
                {this.getSpecifiedLangString(this.state.language, 'Login', "تسجيل الدخول")}
                </Button>
              </Typography>
          </div>
        </Grid>
        <Modal
          data-test-id="passwordInfo"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={styles.modal}
          className={classes.infoModal}
          open={this.state.passwordInfo}
          onClose={this.passwordInfoModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.passwordInfo}>
            <div className={classes.infoModalPaper}>
              <div className={classes.headerAction}>
                <label className={classes.modalTitle}>{this.getSpecifiedLangString(this.state.language, pageText.passwordStrengthlabel, "قوة كلمة المرور")}</label>
                <Button className={classes.closeInfoBtn} onClick={this.passwordInfoModal}><img src={images.close} alt=""/></Button>
              </div>
              <ul className={classes.optionList}>
                <li>{this.getSpecifiedLangString(this.state.language, pageText.optionsListOne, "حرف صغير واحد على الأقل")}</li>
                <li>{this.getSpecifiedLangString(this.state.language, pageText.optionsListTwo, "حرف كبير واحد على الأقل")}</li>
                <li>{this.getSpecifiedLangString(this.state.language, pageText.optionsListThree, "رمز واحد على الأقل")}</li>
                <li>{this.getSpecifiedLangString(this.state.language, pageText.optionsListFour, "رقم واحد على الأقل")}</li>
              </ul>
            </div>
          </Fade>
        </Modal>


        <Modal
          data-test-id="CountryCodeModal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={styles.modal}
          className="Flag_Modal"
          open={this.state.countrySelectionModalOpen}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.countrySelectionModalOpen}>
            <div style={styles.paper} className={classes.paper}>
              <div className="input-wrapper" style={{display:'none'}}>
                <input
                  id="countryCode"
                  className="input_tag"
                  data-test-id="CountryCodeId"
                  type="text"
                  placeholder="Search"
                  value={this.state.inputValue}
                  onChange={e => this.handleChangeCountry(e.target.value)}
                />

                <img src={images.searchIcon} />
              </div>
              <div className="results-list" style={{overflow:'unset'}}>
                {this.state.resultval.map((result: any, id: number) => {
                  return (
                    <div
                      className="search-result"
                      key={id}
                      onClick={() => this.onClickParticularCoutnry(result)}
                    >
                      <div className="Flag_Country">
                        <div className="Flag_Margin_Righ">
                          <img
                            src={result.flags.png || ""}
                            alt="flag"
                            width={50}
                            height={30}
                          />
                        </div>
                        <div className="Country_Font_Family">
                          {this.state.language ==="en" ? result.name.common : result.name.nativeName.ara.common}
                        </div>
                      </div>
                      <div>
                        {result.idd.root}
                        {result.idd.suffixes[0]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Fade>
        </Modal>
        </Grid>
      </Grid>
    </Container>
    <FooterBlock />
  </div>
        
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  submitButton: {
    borderRadius: "20px",
    height: "48px",
    width: "fit-content",
    textTransform: "none", 
    fontWeight: 700,
    fontSize:'20px',
    fontFamily: 'Montserrat'
  },
  width30: { width: "30%" },
  width15: { width: "15%" },
  width55: { width: "55%" },
  tnc: { fontSize: "12px", justifyContent: "left", alignItem: "center",display: "flex" },
  tncText: { fontSize: "14px", marginLeft: "8px", marginBottom: "0px",  },
  greyColor: { color: "#262626" },
  phoneNumberWithCountryCode: {
    width: "100%"
  },
  typoThirdRow: {
    color: "#262626",
    fontFamily: "Montserrat,Sans serif",
    fontSize: "16px",
    fontStyle: "normal",
    marginBottom: "8px",
  },
  countryCodeDownTriangleAndPhoneNumber: {
    marginTop: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "10px",
    width: "100%"
  },
  countryCodeWithDownTriangle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "2%"
  },
  flagWithCode: {
    width: "100%",
    height: "25px",
    padding: "12px",
    borderRadius: "12px",
    border: "1px solid #8C8C8C",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "white",
    gap: "2px"
  },
  modal: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px"
  },
  paper: {
    backgroundColor: "white",
    height: "370px",
    width: "25%",
    borderRadius: "10px",
    padding: "30px 30px",
    margin: "auto"
  },
};
// Customizable Area End

// Customizable Area Start
const customStyle: {} = (theme: AugmentedTheme) =>
  createStyles({
    activeCode:{
      textAlign: "center",
      margin: "auto",
      fontSize: "18px",
      fontWeight: 500,
      color: "#000"
    },
    formInnerCon: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    notActive:{
      fontSize: "16px",
      textAlign: "center",
      margin: "auto",
      letterSpacing: "3.2px",
      color: "#8c8c8c",
      fontWeight: 300,
    },
    mainContainer: {
      width: "100%",
      fontFamily: "Montserrat,sans-serif"
    },
    closeInfoBtn:{
      width: "24px",
      height: "24px",
      minWidth: "24px",
      padding: "0px",
      marginBottom: "2px"
    },
    infoBtn:{
      "fontSize": "12px",
      "padding": "0px",
      "minWidth": "20px",
      "display": "inline-block",
      "marginLeft": "12px",
      "& img":{
        width: "18px"
      },
      "&:hover":{
        background: "#fff"
      },
      "&:active":{
        background: "#fff"
      }
    },
    checkboxDesign:{
      padding: "0px"
    },
    infoModalPaper: {
      backgroundColor: "white",
      height: "277px",
      width: "600px",
      borderRadius: "20px",
      boxSizing: "border-box",
      border: "1px solid #000",
      padding: "37px 45px",
      margin: "auto",
      "@media (max-width:767px)": {
        height: "auto",
        width: "90%",
      }
    },
    headerAction:{
      display: "flex",
      justifyContent: "space-between",
      alignItem: "center"
    },
    optionList:{
      paddingLeft: "39px",
      marginTop: "37px",
      "& li": {
        "color": "#000",
        "fontFamily": "Montserrat",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": 500,
        "lineHeight": "32px"
      }
    },
    modalTitle:{
      "color": "#000",
      "fontFamily": "Montserrat",
      "fontSize": "20px",
      "fontStyle": "normal",
      "fontWeight": 700,
      "lineHeight": "normal"
    },
    innerContainer: {
      width: "100%",
      maxWidth: "none",
      paddingLeft: "0",
      paddingRight: "0",
      fontFamily: "Montserrat,sans-serif"
    },
    tncLink: { color: "#5412FC", marginLeft: "4px",
      fontFamily: "Montserrat,sans-serif",
      "fontWeight": 500,
      "lineHeight": "24px", textDecoration: "underline", textTransform: "inherit", padding: "0px", verticalAlign: "baseline", "&:hover":{
        backgroundColor: "#fff"
      } },

    mainDiv: {
      width: "100%",
      height: "1200px",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      fontFamily: "Montserrat,sans-serif",
      [theme.breakpoints.down("sm")]: {
        height: "600px"
      }
    },
    horizontal: {
      backgroundColor: "#5412FC",
      height: "1px",
      fontFamily: "Montserrat,sans-serif"
    },
    hrContainer: {
      padding: "0px 16px !important",
      marginTop: "0.3vw",
      fontFamily: "Montserrat,sans-serif"
    },
    navbar: {
      flexShrink: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "19px 19px !important",
      fontFamily: "Montserrat,sans-serif",
      "& img": {
        maxWidth: "100%",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          height: "2.5vh"
        }
      }
    },
    mainSignupContainer: {
      backgroundImage: "url(" + backgroundArrow1 + ")",
      backgroundPositionX: 'center',
      backgroundRepeat: "no-repeat",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Montserrat,sans-serif",
      padding: "min(7%,2rem)",
    },
    form: {
      width: "min(100%,600px)",
      padding: "min(7%,2rem) min(5%,3rem)",
      border: "solid 1px #292929",
      backgroundColor: "#FFFFFF",
      borderRadius: "20px",
      fontFamily: "Montserrat,sans-serif",
      "@media (max-width:900px)": {
        width: "min(100%,450px)",
        padding: "min(7%,2rem) min(5%,1.75rem)",
      },
      "@media (max-width:600px)": {
        width: "min(100%,303px)",
        padding: "min(7%,1.3rem) min(5%,1rem)",
      },
      "& .PrivateSwitchBase-root-20": {
        "@media (max-width:900px)": {
          paddingRight: 0
        }
      }
    },
    labelContaint:{
      display: "flex",
      alignItem: "center",
      marginBottom: "8px",
      "& label":{
        marginBottom: "0px !important"
      }
    },
    paper:{
      "@media (max-width:600px)": {
        width:'100% !important'
      }
    },
    formInput: {
      width: "100%",
      marginBottom: "16px",
      fontFamily: "Montserrat,sans-serif",
      "& label": {
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "8px",
        fontWeight: "500",
        "@media (max-width:900px)": {
          fontSize: "12px",
        }
      },
      "& .MuiOutlinedInput-root": {
        height: '48px',
        borderRadius: "12px",
        marginLeft: "0px",
      },
      "@media (max-width:900px)": {
      }
    },
    thridRowInputElement: {
      width: "100%",
      padding: "12px",
      borderRadius: "12px",
      border: "1px solid #8C8C8C",
      fontSize: "18px",
      fontWeight: 500,
      fontFamily: "Montserrat, sans-serif",
      "color": "#262626",
      "fontStyle": "normal",
      "lineHeight": "24px",
      "letterSpacing": "3.6px",
      '&::placeholder': {
        color: "#8C8C8C",
        "fontWeight": 300,
        "fontSize": "16px",
        "lineHeight": "24px",
        "letterSpacing": "3.2px",
      },
      '&::-ms-input-placeholder': {
        color: "#8C8C8C",
        "fontWeight": 300,
        "fontSize": "16px",
        "lineHeight": "24px",
        "letterSpacing": "3.2px",
      }
    },
    phoneInput:{
      "@media (max-width:900px)": {
        "& .MuiTypography-body1":{
          fontSize: "12px !important",
        }
      }
    },
    rowInput: {
      width: "100%",
      display: "flex",
      fontFamily: "Montserrat,sans-serif"
    },
    hover21:{
      "&:hover": {
        cursor:'pointer'
      }
    },
    errorField: {
      "color": "#FF7575",
      "textAlign": "center",
      "fontFamily": "Montserrat",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": 500,
      "lineHeight": "24px",
      backgroundColor: "rgba(255, 117, 117, 0.30)",
      borderRadius: "12px",
      marginTop: "10px",
      marginBottom: "0px",
      padding: "4px 4px",
    },
    validSubmitBtn: {
      backgroundColor: "#10BFB8",
      color: "#FFF",
      fontWeight: "bold",
      fontFamily: "Montserrat,sans-serif"
    },
    invalidSubmitBtn: {
      background: "#8C8C8C",
      color: "#FFF",
      fontWeight: "bold",
      fontFamily: "Montserrat,sans-serif"
    },
    signupHeading: {
      fontSize: "30px",
      fontWeight: "bold",
      marginBottom: "16px",
      marginTop: "16px",
      color: "#5412FC",
      fontFamily: "Montserrat,sans-serif"
    },
    firstLastNameCon: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      gap: "1rem",
      "@media (max-width:600px)": {
        flexDirection: "column",
        gap: 0,
      },
    },
    signupForm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontFamily: "Montserrat,sans-serif",
      width: "100%",
      "@media (max-width:600px)": {
          width: "100%",
      }
    },
    PasswordErrorBlock: {
    width:"100%",
      color: "#262626",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      paddingTop:"6px",
      "@media (max-width:500px)": {
        fontSize:"11px"
      }
    },
    PasswordErrorContent: {
      display:"flex",
      whiteSpace:"nowrap",
      "@media (max-width:500px)": {
        whiteSpace:"unset"
      }
    },
    PasswordCommon:{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 700,
      gap:"15px",
      marginLeft:"12px",
      width:"50%",
      "@media (max-width:500px)": {
        gap:"5px",
      marginLeft:"5px",
      }
    },
    PasswordStrong: {
      color: "#10BFB8"

    }, PasswordMedium: {
      color: "#FFA800"
    }, PasswordWeak: {
      color: "#FF7575"
    }, PasswordBar: {
      width: "168px",
      height: "4px",
      borderRadius: "2px",
      backgroundColor: "#D9D9D9",
      "@media (max-width:500px)": {
        width: '130px'
      }
    },
    PasswordStrongBar: {
      background: "#10BFB8",
      width: "100%",
      height: "4px",
      borderRadius: "2px",
      
    },
    PasswordMediumBar: {
      background: "#FFA800",
      width:"66.66%",
      height: "4px",
      borderRadius: "2px",
    },

    PasswordWeakBar: {
      background: "#FF7575",
      width: "33.33%",
      height: "4px",
      borderRadius: "2px",
    }

  });


export default withStyles(customStyle)(EmailAccountRegBlock)
// Customizable Area End
