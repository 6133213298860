Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.signInApiEndPoint = "bx_block_login/logins";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Sign In";
exports.labelTitleArabic = "تسجيل الدخول";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.wrongEmailIdOrPassword = "Wrong Email or Password"
exports.wrongEmailIdOrPasswordArabic = "البريد الإلكتروني أو كلمة المرور خاطئة";
exports.emailId = " Email ID*"
exports.emailIdTextArabic = "*البريد الإلكتروني"
exports.passTextArabic = "كلمة المرور";
exports.forgotPasswordText = "Forgot Password?"
exports.forgetPasswordTextArabic = " هل نسيت كلمة السر؟";
exports.forgotEmailIdText = "Forgot Email ID?"
exports.forgotEmailIdTextArabic = "هل نسيت البريد الإلكتروني؟";
exports.signInText = "Sign in";
exports.signInTextArabic = "تسجيل الدخول";
exports.signUpText= "Sign Up";
exports.signUpTextArabic = "اشتراك";
exports.addNewUserText = " New User?";
exports.addNewUserTextArabic = "مستخدم جديد؟";

exports.otpEn = {
  verificationText: "Verification",
enterCodeSentText: "Enter code sent to your phone number",
invalidCodeText: "Invalid Code. Try Again",
timeRemainingText: "Time remaining:",
sText: "s",
resendText: " Resend Code",
verifyCodeText: "Verify Code",
successText: "Success!",
verificationSuccessFulText: " Your verification was successful.",
dashboardRedirectionText: "You will be redirected to the Dashboard",
}

exports.otpAr = {
  verificationText: "تحقق",
enterCodeSentText: "أدخل الرمز المرسل إلى رقم هاتفك",
invalidCodeText: "الرمز غير صحيح. حاول مرة أخرى",
timeRemainingText: "الوقت المتبقي",
sText: "ثانية",
resendText: "إعادة إرسال OTP",
verifyCodeText: "تحقق من الرمز ",
successText: "نجاح!",
verificationSuccessFulText: "تم التحقق بنجاح.",
dashboardRedirectionText: "ستتم إعادة توجيهك إلى لوحة التحكم",
}
// Customizable Area End