// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/styles";
import { Dialog } from "@material-ui/core";

import AcquisitionProcessController, {
  Props,
} from "./AcquisitionProcessController.web";
import {
  closeIcon,
  image,
  infoIcon,
  infoIconSelected,
  AssessBuyers,
  AssessBuyersSelected,
  Check,
  ConfirmPayment,
  Congratulations,
  DiscussTerms,
  FacilitatePaymentAssetHandover,
  FacilitatePaymentAssetHandoverSelected,
  FinalizeAcquisition,
  GatherProof,
  HandlingProposals,
  HandlingProposalsSelected,
  InitiateEscrow,
  InteractWithBuyers,
  MeasureInterest,
  ProvideInformation,
  Respond,
  SealTheDealWithAPA,
  SetTimelines,
  ShiftAssets,
  UndergoingDueDiligence,
  UndergoingDueDiligenceSelected,
  evaluate_company,
  handling_proposals,
  undergoing_diligence,
  facilitate_payment_asset_handover,
  finalize_acquisition,
  Step1Disable,
  Step2Disable,
  Step3Disable,
  Step4Enable,
  Step5Enable,
  rocketIcon,
  businessLogo
} from "./assets";
import { ReportProblemOutlined } from "@material-ui/icons";
import { AcquisitionProcessSteps } from "../../../components/src/AcquisitionProcessSteps.web";
import { getStorageData } from "framework/src/Utilities";
import { conditionSort } from "../../../components/src/Helper";
// Customizable Area End

export class AcquisitionProcessStepper extends AcquisitionProcessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userType = "";
  getUserType = async () => {
    let typeOfUser = await getStorageData("typeOfUser");
    this.userType = typeOfUser
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, activeStep, myAcquisitionType, learnmoreList } = this.props;
    let stepperListSeller = [
      {
        id: 1,
        img: AssessBuyers,
        selectedImg: AssessBuyersSelected,
        label: this.state.pageText.accessBuyers,
        learnMoreList: [
          {
            icon: MeasureInterest,
            title: "Measure Interest",
            description:
              "Engage with potential buyers and understand their objectives before sharing detailed information.",
              arabic_title: "قياس الفائدة",
      arabic_description: "تواصل مع المشترين المحتملين وافهم أهدافهم قبل مشاركة المعلومات التفصيلية.",
          },
          {
            icon: ProvideInformation,
            title: "Provide Information",
            arabic_title: "توفير المعلومات",
            description:
              "Once under a mutual NDA, confidently disclose relevant data and documents about your company (e.g, contracts, financials, codes, ..etc)",
              arabic_description: "بمجرد إبرام اتفاقية عدم إفشاء متبادل، أفصح بثقة عن البيانات والوثائق ذات الصلة بشركتك (مثل العقود والبيانات المالية والرموز وغيرها)"
          },
          {
            icon: SetTimelines,
            title: "Set Timelines:",
            arabic_title: "تعيين الجداول الزمنية:",
            description:
              "Ensure both parties agree on timelines for each phase until the LOI. This prevents unnecessary delays.",
              arabic_description: "تأكد من اتفاق الطرفين على الجداول الزمنية لكل مرحلة حتى خطاب النوايا. وهذا يمنع التأخيرات غير الضرورية."
          },
        ],
      },
      {
        id: 2,
        img: HandlingProposals,
        selectedImg: HandlingProposalsSelected,
        label: this.state.pageText.handlingProposals,
        learnMoreList: [
          {
            icon: Check,
            title: "Check",
            description:
              "Even though LOIs may have non-binding elements, some terms can be legally enforceable. Consult with a legal expert.",
          },
          {
            icon: DiscussTerms,
            title: "Discuss Terms",
            description:
              "Review the LOI promptly and address any areas of concern. All aspects of an LOI are open for discussion.",
          },
          {
            icon: Respond,
            title: "Respond",
            description:
              "Always respond to every LOI. Neglecting them might tarnish your standing in the market.",
          },
        ],
      },
      {
        id: 3,
        img: UndergoingDueDiligence,
        selectedImg: UndergoingDueDiligenceSelected,
        label: this.state.pageText.undergoingDueDiligence,
        learnMoreList: [
          {
            icon: GatherProof,
            title: "Gather Proof",
            description:
              "Due diligence involves engaging all functions of the business. Collect and provide evidence supporting your company’s operations and claims",
          },
          {
            icon: InteractWithBuyers,
            title: "Interact with Buyers",
            description:
              "Collaborate closely with the buyer's main decision-makers to ensure a smooth and expedited due diligence process.",
          },
          {
            icon: SealTheDealWithAPA,
            title: "Seal the Deal with APA",
            description:
              "The Asset Purchase Agreement (APA) officially details the acquisition's assets, terms, and conditions.",
          },
        ],
      },
      {
        id: 4,
        img: FacilitatePaymentAssetHandover,
        selectedImg: FacilitatePaymentAssetHandoverSelected,
        label: this.state.pageText.facilitatePayment,
        learnMoreList: [
          {
            icon: InitiateEscrow,
            title: "Initiate Escrow",
            description:
              "Begin the escrow process with the buyer using the dedicated escrow service.",
          },
          {
            icon: ShiftAssets,
            title: "Shift Assets",
            description: "Relocate the agreed-upon assets as per the APA.",
          },
          {
            icon: ConfirmPayment,
            title: "Confirm Payment",
            description: "Ensure the receipt of funds from the escrow service.",
          },
        ],
      },
      {
        id: 5,
        img: FinalizeAcquisition,
        selectedImg: Step5Enable,
        label: this.state.pageText.finalizeAcquisition,
        learnMoreList: [
          {
            icon: Congratulations,
            title: "Congratulations!",
            description: "",
          },
        ],
      },
    ];
    
    let stepperListBuyer = [
      {
        id: 1,
        img: Step1Disable,
        selectedImg: evaluate_company,
        label: this.state.pageText.evaluateTheCompany,
        learnMoreList: [],
      },
      {
        id: 2,
        img: Step2Disable,
        selectedImg: handling_proposals,
        label: this.state.pageText.makeAnOffer,
        learnMoreList: [],
      },
      {
        id: 3,
        img: Step3Disable,
        selectedImg: undergoing_diligence,
        label: this.state.pageText.dueDiligence,
        learnMoreList: [],
      },
      {
        id: 4,
        img: facilitate_payment_asset_handover,
        selectedImg: Step4Enable,
        label: this.state.pageText.transactionAndConclusion,
        learnMoreList: [],
      },
      {
        id: 5,
        img: finalize_acquisition,
        selectedImg: Step5Enable,
        label: this.state.pageText.finalizeAcquisition,
        learnMoreList: [],
      }
    ];
  
    let stepperList = conditionSort(this.userType == 'buyer', stepperListBuyer, stepperListSeller)
    const learnMoreActiveList =
      stepperList &&
      stepperList.find((step: {id: number}) => step.id === activeStep);

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div
          className={classes.mainDiv}
          data-test-id="main-acquisition-process-stepper"
        >
          <div className={classes.headingButtonDiv}>
            <div className={classes.headingDiv}>
              <img src={conditionSort(this.props.image, conditionSort(['Start Up', 'بدء', 'شركة ناشئة', 'Start up'].includes(this.props.image as string), rocketIcon, businessLogo),image)} 
              style={{ backgroundColor: conditionSort(['Start Up', 'بدء', 'شركة ناشئة', 'Start up'].includes(this.props.image as string), 'rgb(16, 191, 184)', 'rgb(84, 18, 252)') ,
  borderRadius: '50%',
  width: '64px',
  height: '64px',}}/>
              <div className={classes.headingSection}>
                <p className={classes.subHeadingText}>{this.state.pageText.listing}</p>
                <p className={classes.headingText}>{this.props.selectedName ? this.props.selectedName :this.state.listingName}</p>
              </div>
            </div>
            {!myAcquisitionType && <button disabled={this.getIfCondition(this.state.activeStep == 5, true, false)}
             className={classes.TerminateAccessButton} onClick={()=> this.setState({terminateAccess:true})}
              data-test-id="terminate-access" style={this.getIfCondition(this.state.activeStep == 5, {background: '#8C8C8C', pointerEvents: 'none'}, {})}>
            {this.getIfCondition(this.userType ==="buyer",this.state.pageText.retractDeal, this.state.pageText.terminateAccess)}
            </button>}
          </div>
          <div className={classes.stepperMenuDiv}>
            {stepperList && <AcquisitionProcessSteps stepperList={stepperList} activeStep={activeStep == 5 ? 6 : activeStep} infoIconSelected={infoIconSelected} infoIcon={infoIcon} lang={this.state.language}/>
              }
          </div>
          </div>
          {!myAcquisitionType &&
          <>
            <div className={classes.learnMoreDiv}>
              {learnMoreActiveList &&
                learnmoreList?.map((more,index) =>
                  activeStep === 5 ? (
                    <div key={more.title} className={classes.learnMoreLastSection}>
                      <img src={more.url} className={classes.learnMoreLastIcon} />
                      <p className={classes.learnMoreLastTitle}>{more.title}</p>
                    </div>
                  ) : (
                    <div key={more.title} className={classes.learnMoreSection}>
                      <img src={more.url} className={classes.learnMoreIcon} />
                      <p className={classes.learnMoreTitle}>{conditionSort(this.state.language == 'ar', more.arabic_title, more.title) }</p>
                      <button
                        data-test-id={`learn-more-button-${index}`}
                        className={classes.learnMoreButton}
                        onClick={() => this.handleLearnMoreOnClick(true, more)}
                      >
                        {this.state.pageText.learnMore}
                      </button>
                    </div>
                  )
                )}
            </div>
            <Dialog
              aria-labelledby="simple-dialog-title"
              open={this.state.terminateAccess}
              className={classes.terminateAccess}
              scroll="body"
            >
              <div className={classes.learnMoreDialogContent}>
                <ReportProblemOutlined style={{fontSize:'100px', color:'#FF7575'}}/>
                <p className={classes.learnMoreDialogTitle}>
                  {this.getIfCondition( this.userType ==="buyer", this.state.pageText.retractDeal,this.state.pageText.terminateAccess)}
                </p>
                <p className={classes.areYouSure}>
                  {this.state.pageText.areYouSure}
                </p>
                <p className={classes.learnMoreDialogDescription}>
                  {this.getIfCondition(this.userType ==="buyer", this.state.pageText.retBuyer,
                  this.state.pageText.terSeller)}
                </p>
                <div className={classes.buttonDiv}> 
                <button data-test-id="yes-button" className={classes.buttonStylesYesNo} onClick={()=> {this.onTerminateClick(this.userType)}}
                 style={{textDecoration:'none', color:'#10BFB8', borderRadius:'12px',border:'2px #10BFB8 solid',background:'white', }}>
                    {this.state.pageText.yias}
                  </button>
                  <button  onClick={()=> {this.setState({terminateAccess:false})}} data-test-id="no-button" className={classes.buttonStylesYesNo}
                  style={{textDecoration:'none', color:'#FF7575', borderRadius:'12px',border:'2px #FF7575 solid',background:'white', }}>
                    {this.state.pageText.ntmb}
                  </button>
                  </div>
                <button
                  data-test-id="close-button"
                  className={classes.learnMoreDialogCloseButton}
                  onClick={() =>
                    this.setState({terminateAccess:false})
                  }
                >
                  <img src={closeIcon} />
                </button>
              </div>
            </Dialog>
            <Dialog
              aria-labelledby="simple-dialog-title"
              open={this.state.openLearnMoreDialog}
              className={classes.learnMoreDialog}
              scroll="body"
            >
              <div className={classes.learnMoreDialogContent}>
                <img
                  src={this.state.selectedLearnMoreContent.url}
                  className={classes.learnMoreDialogIcon}
                />
                <p className={classes.learnMoreDialogTitle}>
                  {conditionSort(this.state.language == 'ar', this.state.selectedLearnMoreContent.arabic_title, this.state.selectedLearnMoreContent.title) }
                </p>
                <p className={classes.learnMoreDialogDescription}>
                  
                  {conditionSort(this.state.language == 'ar', this.state.selectedLearnMoreContent.arabic_description, this.state.selectedLearnMoreContent.description) }
                </p>
                <button
                  data-test-id="learn-more-close-button"
                  className={classes.learnMoreDialogCloseButton}
                  onClick={() =>
                    this.handleLearnMoreOnClick(false, {
                      url: "",
                      title: "",
                      description: "",
                      arabic_title: "",
                      arabic_description: ""
                    })
                  }
                >
                  <img src={closeIcon} />
                </button>
              </div>
            </Dialog>
          </>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  mainDiv: {
    padding: "25px",
    marginBottom: "15px",
    borderRadius: "20px",
    border: "2px transparent solid",
    background:
      "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
    "@media (max-width:768px)":{
      padding:"20px 20px 20px 24px"
    }
  },
  headingButtonDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
    gap: "20px",
    "@media (max-width:959px)": {
      display: "grid",
      justifyContent: "normal",
    },
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
    "@media (max-width:768px)":{
      gap:"16px"
    }
  },
  headingSection: {
    display: "grid",
    gap: "10px",
    "@media (max-width:768px)":{
      gap:"0px"
    }
  },
  buttonDiv :{
    display:"flex", 
    justifyContent:'space-between',
    width:500,
    "@media (max-width:768px)":{
      width:300,
    }
  },
  buttonStylesYesNo:{
    width:'200px',
     height:'40px',
     background:'white', 
     fontSize:'20px', 
     lineHeight:'24px', 
     fontWeight:500,
     fontFamily:"Montserrat",
     "@media (max-width:768px)":{
      width:'200px',
      fontSize:"16px",
      lineHeight:"20px",
      height:"40px"
    }
  },
  subHeadingText: {
    color: "black",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "0",
    "@media (max-width:768px)":{
      fontSize: "10px",
    }
  },
  headingText: {
    color: "black",
    fontSize: "30px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "0",
    "@media (max-width:768px)":{
      fontSize: "16px",
      lineHeight:'normal'
    }
  },
  TerminateAccessButton: {
    background: "#FF7575",
    borderRadius: "12px",
    padding: "18px 28px",
    color: "white",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "24px",
    border: "none",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "@media (max-width:768px)":{
      fontSize: "16px",
      lineHeight:'normal',
      justifyContent:'center',
      padding:'16px 30px'
    }
  },
  stepperMenuDiv: {
    gap: "20px",
    justifyContent: "space-between",
    overflow: "auto",
    "@media (max-width:768px)":{
      display: 'block'
    }
  },
  stepperMenuSection: {
    background: "white",
    position: "relative",
    "&:last-child div::after": {
      display: "none",
    },
  },
  stepperMenu: {
    borderRadius: "20px",
    border: "2px #262626 solid",
    padding: "40px 20px 0",
    minHeight: "180px",
    width: "120px",
    textAlign: "center",
    "&::after": {
      right: "-115px",
      bottom: "90px",
      content: "'---------------------'",
      position: "absolute",
    },
  },
  stepperMenuImg: {
    marginBottom: "16px",
    width: "100px",
    height: "100px"
  },
  stepperMenuText: {
    color: "#000000",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    margin: 0,
  },
  stepperInfoIcon: {
    position: "absolute",
    top: "12px",
    right: "12px",
  },
  learnMoreDiv: {
    borderRadius: "20px",
    border: "1px #8C8C8C solid",
    padding: "15px",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "15px",
    overflowX: 'auto',
    "@media (max-width:768px)":{
      display: 'grid',
  gridRow: '1',
  justifyItems: 'center',
  justifyContent: 'center',
  gridGap: '2rem'
    }
  },
  learnMoreSection: {
    textAlign: "center",
    "@media (max-width:768px)":{
      minWidth:'200px'
    }
  },
  learnMoreIcon: {
    width: "80px",
    height: "80px",
    "@media (max-width:768px)":{
      width:"60px",
      height:"60px"
    }
  },
  learnMoreTitle: {
    color: "black",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    margin: "10px 0 10px",
    "@media (max-width:768px)":{
      fontSize:"16px"
    }
  },
  learnMoreButton: {
    color: "black",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    textDecoration: "underline",
    border: "none",
    background: "transparent",
    "@media (max-width:768px)":{
      fontSize:'14px'
    }
  },
  learnMoreLastSection: {
    display: "flex",
    alignItems: "center",
    gap: "80px",
    "@media (max-width:768px)":{
      gap: "20px"
    }
  },
  learnMoreLastIcon: {
    width: "100px",
    height: "100px",
    margin: "10px 0",
    "@media (max-width:768px)":{
      width: "50px",
    height: "50px",
    }
  },
  learnMoreLastTitle: {
    color: "black",
    fontSize: "32px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    "@media (max-width:768px)":{
      fontSize: "16px"
    }
  },
  learnMoreDialog: {
    "& .MuiDialog-paper": {
      height: "auto",
      boxShadow: "none",
      borderRadius: "20px",
      padding: "25px",
      position: "relative",
      "@media (max-width:768px)":{
        padding: "10px"
      }
    },
  },
  terminateAccess:{
    "& .MuiDialog-paper": {
      height: "auto",
      boxShadow: "none",
      position: "relative",
      padding: "25px 120px",
      borderRadius: "20px",
      "@media (max-width:768px)":{
        width:'300px',
        padding:"0 40px"
      }
    },
  },
  learnMoreDialogContent: {
    display: "grid",
    justifyItems: "center",
    alignContent: "center",
  },
  learnMoreDialogIcon: {
    width: "120px",
    height: "120px",
  },
  learnMoreDialogTitle: {
    color: "black",
    fontSize: "28px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    margin: "10px",
    textAlign: "center",
  },
  areYouSure:{
    color:'black',
    margin:0,
    fontSize:'28px',
    textAlign:'center',
    fontWeight:700,
    fontFamily: "Montserrat",
  },
  learnMoreDialogDescription: {
    color: "black",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "35px",
    textAlign: "center",
    margin: "10px !important",
    "@media (max-width:768px)":{
      fontSize:'18px',
      lineHeight:'24px'
    }
  },
  learnMoreDialogCloseButton: {
    position: "absolute",
    top: 25,
    right: 30,
    background: "transparent",
    border: "none",
    padding: 0,
  },
  buttonArea:{
    display:'flex',
    justifyContent:'space-between',
    backgroundColor:'blue'
  },
  buttonStyles:{
    textDecoration:'none',
  }
};

export default withStyles(styles)(AcquisitionProcessStepper);
// Customizable Area End
