Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.viewMore = "View More"
exports.viewMoreAr = "عرض المزيد"
exports.sectionOneHeading = "The Go-To Destination for Mergers & Acquisitions in MENA";
exports.sectionOneHeadingAr = "الوجهة المفضلة لعمليات الاندماج والاستحواذ في منطقة الشرق الأوسط وشمال أفريقيا";
exports.sectionOneContent = "We bring together buyers and sellers of businesses and startups leading to successful acquisitions - Be the first to experience Nami!";
exports.sectionOneContentAr = "نحن نجمع بين المشترين والبائعين للشركات والشركات الناشئة مما يؤدي إلى عمليات استحواذ ناجحة - كن أول من يختبر نامي!";
exports.joinOurWaitList = "Join Our Waitlist Now!";
exports.becomeABuyer = "Become a Buyer";
exports.becomeABuyerAr = "كن مشترياً";
exports.becomeASeller = "Become a Seller";
exports.becomeASellerAr = "كن بائعاً";
exports.buyerTxt = "Buyer";
exports.sellerTxt = "Seller";
exports.buyerTxtAr = "المشتري";
exports.sellerTxtAr = "البائع";

exports.signUpNow = "Sign Up Now";
exports.signUpNowAr = "سجّل الآن";
exports.signIn = "Sign in";
exports.howNamiWorks = "How Nami Works";
exports.howNamiWorksAr = "كيف يعمل نامي";
exports.unlockTitle = "Unlock your potential value";
exports.unlockTitleAr = "أطلق العنان لقيمك المحتملة";
exports.unlockSubTitle = "List your business with ease and gain access to our exclusive network of buyers";
exports.unlockSubTitleAr = "أدرج نشاطك التجاري بسهولة واحصل على إمكانية الوصول إلى شبكتنا الحصرية من المشترين";
exports.targetTitle = "Target your dream opportunity";
exports.targetTitleAr = "استهدف فرصة أحلامك";
exports.targetSubTitle = "Explore vetted businesses/startups and discover the right deal for you";
exports.targetSubTitleAr = "استكشف الشركات/الشركات الناشئة التي تم فحصها واكتشف الصفقة المناسبة لك";
exports.preScreenedtitle = "Pre-screened \n opportunities";
exports.preScreenedtitleAr = "فرص تم فرزها مسبقاً";
exports.preScreenedSubtitle = "Explore vetted startups and businesses for sale in the MENA region across multiple industries";
exports.preScreenedSubtitleAr = "استكشف الشركات الناشئة والشركات المعروضة للبيع في منطقة الشرق الأوسط وشمال أفريقيا التي تم فحصها مسبقاً في العديد من القطاعات";
exports.saasTitle = "A leading chain of beauty clinics, offering advanced skincare treatments and cosmetic procedures across multiple locations";
exports.saasTitleAr = "سلسلة رائدة من عيادات التجميل، تقدم علاجات متطورة للعناية بالبشرة وإجراءات تجميلية عبر مواقع متعددة";
exports.saasSubTitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua";
exports.communityTitle = "Be the first to be part of Nami's community";
exports.communityTitleAr = "كن أول من يصبح جزءًا من مجتمع نامي";
exports.arabicLine = "Original listing in Arabic";
exports.arabicLineAr = "القائمة الأصلية باللغة العربية";
exports.anonymousTitle = "Anonymous, Simple Steps & No Fees!";
exports.anonymousTitleAr = "مجهول، خطوات بسيطة وبدون رسوم!";

exports.mobileContent = "Popular food delivery app, connecting users with local restaurants; efficient order system, expansive reach, and solid growth trajectory";
exports.mobileContentAr = "تطبيق شهير لتوصيل الطعام، يربط المستخدمين بالمطاعم المحلية؛ نظام طلب فعال، وانتشار واسع، ومسار نمو قوي";
exports.broadReach = "Broad Reach:";
exports.broadReachAr = "انتشار واسع النطاق:";
exports.broadReachContent = "Reach a vast network of qualified buyers";
exports.broadReachContentAr = "الوصول إلى شبكة واسعة من المشترين المؤهلين";
exports.simpleListing = "Simple Listing:";
exports.simpleListingAr = "قائمة بسيطة:";
exports.simpleListingContent = "List your business with simple steps";
exports.simpleListingContentAr = "أدرج نشاطك التجاري بخطوات بسيطة";
exports.dataProtection = "Data Protection:";
exports.dataProtectionAr = "حماية البيانات:";
exports.dataProtectionContent = "Share your business information securly";
exports.dataProtectionContentAr = "شارك معلومات عملك بأمان";
exports.diverseChoices = "Diverse Choices:";
exports.diverseChoicesAr = "خيارات متنوعة:";
exports.diversechoicesContent = "Explore a wide array of businesses and startups";
exports.diversechoicesContentAr = "استكشف مجموعة واسعة من الأعمال والشركات الناشئة";
exports.smoothJourney = "Smooth Journey:";
exports.smoothJourneyAr = "رحلة سلسة:";
exports.smoothJourneyContent = "Navigate acquisitions seamlessly";
exports.smoothJourneyContentAr = "التنقل في عمليات الاستحواذ بسلاسة";
exports.tailoredMatches = "Tailored Matches:";
exports.tailoredMatchesAr = "مطابقات مصممة خصيصاً:";
exports.tailordedContent = "Get opportunities that align with your interest";
exports.tailordedContentAr = "احصل على الفرص التي تتوافق مع اهتماماتك";
exports.annualRevenue = "Annual revenue:";
exports.annualRevenueAr = "الإيرادات السنوية:";
exports.annualProfit = "Annual profit:";
exports.annualProfitAr = "الأرباح السنوية:";
exports.basedIn = "Based in:";
exports.basedInAr = "مقرها في:";
exports.numberEmployee = "Number of employees:";
exports.numberEmployeeAr = "عدد الموظفين";
exports.foundeIn = " Founded in:";
exports.foundeInAr = " تأسست في:";
exports.valuation = "Proposed valuation:";
exports.valuationAr = "التقييم المقترح:";
exports.whatPeopleSayTitle = "What People say About Us";
exports.whatPeopleSayTitleAr = "ما يقوله الناس عنا";
exports.whatPeopleSayText = '“ consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut ”';
exports.whatPeopleSayTextHovered = "consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.";
exports.oneYearWithUs = "One Year With Us";
exports.oneYearWithUsAr = "سنة واحدة معنا";
exports.whatPeopleSayName = "Mohamed Ahmed";
exports.apiMethodTypePut = "PUT"
exports.trafficSourcesEndPoint = "bx_block_trafficsources2/traffic_sources/update_visits"

exports.SaasTxt = "Saas"
exports.SaasTxtAr = "ساس"
exports.healthCareTxt = "Healthcare";
exports.healthCareTxtAr = "الرعاية الصحية";
exports.mobileAppTxt = "Mobile App";
exports.mobileAppTxtAr = "تطبيق الهاتف المحمول";
exports.eCommerceTxt = "eCommerce";
exports.eCommerceTxtAr = "التجارة الإلكترونية";
exports.energyTxt = "Energy"
exports.energyTxtAr = "الطاقة"
exports.startUpTxt = "Start Up";
exports.startUpTxtAr = "بدء التشغيل";
exports.businessTxt = "Business";
exports.businessTxtAr = "الأعمال";
exports.askingPrTxt = "Asking Price:"
exports.askingPrTxtAr = "السعر المطلوب"
exports.sarTxt = "SAR";
exports.mnTxt = "Mn"
exports.liveChatTxt = "LIVE CHAT"
exports.liveChatTxtAr = "الدردشة المباشرة"
exports.exampleNameTxt = "Ahmed Mohammed"
exports.verifiedTxt = "verified"
exports.verifiedTxtAr = "تم التحقق"
exports.buyerProfileTxt = "Buyer Profile"
exports.buyerProfileTxtAr = "الملف الشخصي للمشتري"
exports.privateEqTxt = "Private Equity"
exports.privateEqTxtAr = "الأسهم الخاصة"
exports.valuationRgTxt = "Valuation Range"
exports.valuationRgTxtAr = "نطاق التقييم"
exports.exampleRg = "SAR 1Mn - 3Mn"
exports.buyerInt = "Buyer interest"
exports.buyerIntAr = "مصلحة المشتري"
exports.platformTxt = "Platform"
exports.platformTxtAr = "المنصة"

exports.cardContent1 = "List your company, gain investor attraction, and share with confidence."
exports.cardContent2 = "We connect buyers and sellers on our user-friendly interface, designed to make your acquisition journey successful."
exports.cardContent3 = "Discover your ideal opportunity and make an offer within minutes.";
exports.cardContent1Ar = "أدرج شركتك، واحصل على جذب المستثمرين، وشارك بثقة."
exports.cardContent2Ar = "نحن نربط بين المشترين والبائعين من خلال واجهتنا سهلة الاستخدام، والمصممة لإنجاح رحلة الاستحواذ الخاصة بك."
exports.cardContent3Ar = "اكتشف فرصتك المثالية وقدِّم عرضاً في غضون دقائق.";

exports.examplePrice = "SAR 8Mn"
exports.examplePrice2 = "SAR 1.6Mn";
exports.exampleprice3 = "SAR 9.6Mn";
exports.examplePrice4 = "SAR 2.8Mn";
exports.examplePrice5 = "SAR 190K";
exports.examplePrice6 = "SAR 14Mn";




exports.jeddahTxt = "Jeddah"
exports.jeddahTxtAr = "جدة"
exports.riyadhTxt = "Riyadh"
exports.riyadhTxtAr = "الرياض"
exports.examplePE = "6.0x P/E";
exports.exampleEVRe = "5.0x EV/Revenue";

exports.getFeedbackEndpoint = 'bx_block_request_management/deals/customer_feedback_hub'
// Customizable Area End