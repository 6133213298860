import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import TranslateText from "../../../components/src/GoogleTranslateAPI";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
boxTitle: string;
  boxContent: string;
  isVisible: boolean;
confirmCategory: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
openBox: boolean;
  opneModal: boolean;
  openCompanyBox: boolean;
  selectedCategoryID: any;
  selectstartup:any;
  selectBusiness:any;
  isClickedAll : boolean;
  topHeading:boolean;
  pageTxtData: any;
  language: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
boxContent: "",
      boxTitle: "",
      confirmCategory: false,
      dropdownCategoryStatus: false,
openBox: false,
      openCompanyBox: false,
      opneModal: false,
      activeModalType: "",
      selectedCategoryID: [],
      selectstartup:"",
      selectBusiness:"",
      isClickedAll: false,
      topHeading:true,
      pageTxtData: {
      },
      language: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const messageName = getName(MessageEnum.SessionResponseMessage);
    if (messageName === message.id) {
      runEngine.debugLog("Message Received", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, this.getCategories);
      this.getLanguage();
    } else {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Received", message);

      if (responseJson.data) {
        if (apiRequestCallId === this.getCategoriesApiCallId) {
          let array = responseJson.data.map((item: any) => ({
            ...item,
            expand: false,
            Check: false
          }));
          this.setState({ categoriesArray: array });
        } else if (apiRequestCallId === this.addCategoryApiCallId) {
          this.setState({ isVisible: false, category: "" }, this.getCategories);
        } else if (apiRequestCallId === this.addSubCategoryApiCallId) {
          this.setState(
            {
              isVisible: false,
              category: "",
              subCategory: "",
              selectedCategoryID: []
            },
            this.getCategories
          );
        }
      } else if (
        apiRequestCallId === this.deleteCategoriesApiCallId &&
        !responseJson.error
      ) {
        this.getCategories();
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getContent = (englishContent:string , arabicContent:string) => {
    return this.state.language === "en" ? englishContent : arabicContent;
  }
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
handleBox = () => {
    const content = this.getContent(this.state.pageTxtData.assetDesc,this.state.pageTxtData.assetDescArabic);
    const title = this.getContent(this.state.pageTxtData.assets,this.state.pageTxtData.assetsArabic);
    if(this.state.confirmCategory){
      this.setState({ openBox: true});
    }
    else{
      this.setState({ openBox: !this.state.openBox });
    }
    this.setState({ openCompanyBox: false });
    this.setState({ boxTitle: title });
    this.setState({ boxContent: content });
  };
  
  handleCompanyBox = () => {
    const title = this.getContent(this.state.pageTxtData.company,this.state.pageTxtData.companyArabic);
    const companyContent = this.getContent(this.state.pageTxtData.companyDesc,this.state.pageTxtData.companyDescArabic);
    this.setState({ openBox: false });
    this.setState({ openCompanyBox: !this.state.openCompanyBox });
    this.setState({ boxTitle: title });
    this.setState({ boxContent: companyContent });
  };
  handleBack=()=>{
    this.props.navigation.navigate('CustomizableUserSubscriptions')
  };
  handleForward=()=>{
    this.props.navigation.navigate('Companytype')
  };
  
  confirmSubCategory = () => {
    if (this.state.openBox) {
      this.setState({ confirmCategory: true });
    }
    if (this.state.confirmCategory) {
      this.setState({ opneModal: true });
    }
    if (this.state.openCompanyBox) {
      // window.location.href='/Companytype'
      this.props.navigation.navigate("Companytype");
    }
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLanguage = async () => {
    let lang = await getStorageData('language');
    return this.setState({ language: lang || 'en' }, () => this.applyTranslation())
  }

  applyTranslation = async () => {
    let translatedObj = await TranslateText(configJSON.pageTextData, this.state.language, 'en')
    this.setState({ pageTxtData: translatedObj });
  }

  getCategories = () => {

    if (!this.state.token) {
      return;
    }
    
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
