// Customizable Area Start
import React from "react";
import EmailAccountRegistrationBlockController, {
  Props
} from "./EmailAccountRegistrationBlockController.web";
import {
  Typography,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  Box,
  Button,
} from "@material-ui/core";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
const images = require("./assets");
let theme = createTheme();
theme = responsiveFontSizes(theme);
export default class EmailAccountPopup extends EmailAccountRegistrationBlockController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <HeaderBlock />
        <Box style={styles.outerbox} className="OutlineBorder">
          <Box className="Bg-color">
            {/* @ts-ignore */}
            <Typography style={styles.typoRegister} className="typoRegister">
              Register Your Interest
            </Typography>

            <Box sx={styles.rightImageWithSuccess} className="successFullLogo">
              <img src={images.imageRight} width={60} height={60} />
              <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <Typography style={styles.typoSuccess}>Success!</Typography>
              </ThemeProvider>
            </Box>

            <Box sx={styles.successfullySubmitted} className="successFullText">
              <Box>
                {/* @ts-ignore */}
                <p style={styles.typoThird}>
                  Your registration form has been successfully submitted.
                </p>
              </Box>
            </Box>
            <Box sx={styles.welcomeCommunity} className="successFullText">
              <Box>
                {/* @ts-ignore */}
                <p style={styles.typoThird}>
                  Thank you for Your interest. We are eager to welcome you to
                  our community soon.
                </p>
              </Box>
            </Box>
            <Box sx={styles.gotoToHomePage}>
              <Button
                style={styles.typoHome}
                className="backToHome"
                variant="outlined"
                onClick={this.handleBackToHomePage}
              >
                Back to homepage
              </Button>
            </Box>
          </Box>
        </Box>
        <FooterBlock />
      </>
    );
  }
}

const styles: any = {
  outerbox: {
    // width: "45.55%",
    height: "auto",
    alinItem: "center",
    margin: "50px auto 50px auto",
    padding: "2px"
  },
  typoSuccess: {
    color: "black",
    fontFamily: "Montserrat , Sans serif",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "50px",
    display: "flex",
    alignItems: "center"
  },
  typoHome: {
    color: "#10BFB8",
    border: "1px solid #10BFB8",
    borderRadius: "60px",
    width: "35.83%",
    height: "48px",
    textTransform: "inherit"
  },
  typoThird: {
    color: "black",
    fontFamily: "Montserrat , Sans serif",
    fontSize: "16px",
    fontStyle: "normal"
  },

  typoRegister: {
    paddingTop: "30px",
    textAlign: "center",
    color: "#5412FC",
    fontFamily: "Montserrat , Sans serif",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: "bold",
    marginBottom: "30px"
  },
  rightImageWithSuccess: {
    display: "flex",
    margin: "35px auto",
    alignItem: "center",
    width: "239px",
    gap: "10px",
    marginTop: "35px",
    flexWrap: "wrap",
    textAline: "center"
  },
  successfullySubmitted: {
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    width: "80%",
    margin: "5px auto",
    textAlign: "center",
    marginTop: "35px"
  },
  welcomeCommunity: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alinItem: "center",
    width: "80%",
    margin: "35px auto",
    textAlign: "center",
    marginTop: "15px"
  },
  gotoToHomePage: {
    textAlign: "center",
    marginTop: "30px",
    paddingBottom: "35px"
  }
};
// Customizable Area End
