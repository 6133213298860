export const namiLogo = require("../assets/namilogo.png");
export const ellipsePlayBtn = require("../assets/Ellipse.png");
export const polygonPlayBtn = require("../assets/Polygon.png");
export const mainLandingBg = require("../assets/MainlandingBg.svg");
export const buyerLogo = require("../assets/Buyer.svg");
export const sellerLogo = require("../assets/Seller.svg");
export const platformLogo = require("../assets/Platform.svg");
export const companyLogo = require("../assets/Heroimage.svg");
export const targetLogo = require("../assets/Target.svg");
export const unlocklogo = require("../assets/Unlock.svg");
export const arrowListIcon = require("../assets/Vector.svg");
export const healthCareLogo = require("../assets/HealthcareNew.svg");
export const businessLogo = require("../assets/Business.svg");
export const rocketIcon = require("../assets/Rocket.svg");
export const bookMark = require("../assets/Bookmark.svg");
export const bookMarkHover = require("../assets/bookmarkHover.svg");
export const bookMarked = require("../assets/bookmarked.svg");
export const flag = require("../assets/Flag.svg");
export const flagHover = require("../assets/flagHover.svg");
export const flaged = require("../assets/flagged.svg");
export const share = require("../assets/Share.svg");
export const shareHover = require("../assets/shareHover.svg");
export const shared = require("../assets/shared.svg");
export const info = require("../assets/Info.svg");
export const communityLogo = require("../assets/CommunityLogo.svg");
export const foldedArrow = require("../assets/FoldedArrow.svg");
export const revenueLogo = require("../assets/Revenue.svg");
export const profitLogo = require("../assets/Profit.svg");
export const mapLogo = require("../assets/Map.svg");
export const employeesLogo = require("../assets/Employees.svg");
export const calendarLogo = require("../assets/Calendar.svg");
export const valuationLogo = require("../assets/Valuation.svg");
export const mobileIcon = require("../assets/MobileIcon.svg");
export const arrowBg = require("../assets/arrow.svg");
export const Figma = require("../assets/Figma.gif");
export const heroAnimated = require("../assets/Hero_ani.gif");
export const Purpleline = require("../assets/P_SVG.svg");
export const Greenline = require("../assets/G_SVG.svg");
export const MobilePurple = require("../assets/MobilePurple.svg");
export const MobileGreen = require("../assets/MobileGreen.svg");
export const usersIcon = require("../assets/users.svg");
export const marketIcon = require("../assets/market.svg");
export const blockChainIcon = require("../assets/blockchain.svg");
export const oneListingIcon = require("../assets/1.svg");
export const threeListingIcon = require("../assets/3.svg");
export const ExpandIcon = require("../assets/ExpandIcon.png");
export const fileUpload = require("../assets/prime_upload.svg");
export const documentUpload = require("../assets/document-upload.svg");
export const selectMenuIcon = require("../assets/select-menu-icon.svg");
export const calenderIcon = require("../assets/calender-icon.svg");
export const close = require("../assets/close.svg");
export const plus = require("../assets/plus.svg");
export const Archive = require("../assets/ion_archive-outline.svg");
export const report = require("../assets/material-symbols_report-outline-rounded.svg");
export const ILD = require("../assets/codicon_error.svg");
export const SOF = require("../assets/icon-park-outline_report.svg");
export const IOS = require("../assets/bug-outline.svg");
export const DL = require("../assets/VectorduplicateListing.svg");
export const BOTOS = require("../assets/octicon_discussion-duplicate-24.svg");
export const dot = require("../assets/dot.svg");
export const linkIcon = require("../assets/uil_link.svg");
export const whatsappIcon = require("../assets/whatsapp.svg");
export const linkedInIcon = require("../assets/LinkedIn.svg");
export const twitterIcon = require("../assets/Twitter.svg");
export const facebookIcon = require("../assets/Facebook.svg");
export const instagramIcon = require("../assets/instagram.svg");
export const FlagReportIcon = require("../assets/FlagReportIcon.svg");
