export const imgPasswordVisible = require("../assets/ic_password_visible.svg");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const bgimg = require("../assets/bgimg.svg");
export const user_accouunt = require("../assets/user_accouunt.png");
export const email_icon = require("../assets/email_icon.png");
export const imageRight = require("../assets/imageRight.svg");


export const downArrow = require("../assets/downArrow.svg");





