import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
import { WithStyles } from "@material-ui/core";
import { setStorageData } from "../../../framework/src/Utilities";
import { conditionSort, getToastPosition } from "../../../components/src/Helper";

interface Image {
  id: number;
  url: string;
}

interface NextPreviousIds {
  previous: number;
  next: number;
}

interface WritingChallenge {
  title: string;
  sub_title: string;
  content: string;
  arabic_details: string;
  arabic_title: string;
  arabic_sub_title: string;
  image: Image;
  next_previous_ids: NextPreviousIds;
}

interface ChangeMailSectionText {
  title: string;
  descriptionMail: string;
  descriptionCode: string;
  errorMsg: string;
  emailLabel: string;
  emailPlaceholder: string;
  codeLabel: string;
  codePlaceHolder: string;
  firstBlockNote: string;
  enterButton: string;
  verifyButton: string;
  confirmMessage: string;
  resendCode: string;
  successTitle: string;
  successMessage: string;
  settings: string;
  backText: string;
}
// Customizable Area End
type NotificationSettingsState = {
  sms_active: boolean;
  email_active: boolean;
  new_message_sms: boolean;
  access_request_sms: boolean;
  deal_updates_sms: boolean;
  promotions_sms: boolean;
  new_message_email: boolean;
  access_request_email: boolean;
  deal_updates_email: boolean;
  promotions_email: boolean;
};
export const configJSON = require("./config");

export type Props =  WithStyles & {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  mobTexts: {
    changeMob: string;
    enterInfo: string;
    enterMobNo: string;
    mobNo: string;
    code: string;
    enter: string;
    valid: string;
    success: string;
    successTitle: string;
    afterInfo: string;
    verify: string;
    enterCode: string;
    confirmMessage: string;
resendCode: string;
  },
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeTab: string;
  activeSettingPage: number;
  email: string;
  emailError: boolean,
  code: string;
  codeError: boolean,
  phone: string;
  phoneError: boolean,
  currentPassword: string;
  currentPasswordError: boolean;
  newPassword: string;
  confirmPassword: string;
  queryType: string;
  question1: string;
  description: string;
  deactivateConfirmation: boolean;
  userData: any;
  queryTypeList: any;
  allNotificationData:Array<any>;
  resoucesList: [];
  resoucesData: WritingChallenge;
  detailsText: {
    upNext:string;
    previous:string;
    resource:string;
    resources:string;
    readMore: string;
  }

  sms_active: boolean;
  email_active: boolean;

  new_message_sms: boolean;
  access_request_sms: boolean;
  deal_updates_sms: boolean;
  promotions_sms: boolean;
  
  new_message_email: boolean;
  access_request_email: boolean;
  deal_updates_email: boolean;
  promotions_email: boolean;
  language: string;
  changeMailPageText: ChangeMailSectionText;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileAPICallId: string = "";
  updatePrefLangAPICallId: string = "";
  getResourceAPICallId: string = "";
  getResourceDetailAPICallId: string = "";
  changeEmailCallId: string = "";
  verifyOtpEmailCallId: string = "";
  changePhoneNoCallId: string = "";
  verifyOtpPhoneNoCallId: string = "";
  verifyOldPasswordCallId: string = "";
  changePasswordCallId: string = "";
  getQueryListCallId: string = "";
  sendContactUsCallId: string = "";
  accountDeactiveCallId: string = "";
  getNotificationAPICallId:string="";
  readNotificationId:string="";
  getNotificationSettingAPICallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      mobTexts: {
        changeMob: '',
        enterInfo: '',
        enterMobNo: '',
        afterInfo: '',
        mobNo: '',
        code: '',
        enter: '',
        valid: '',
        enterCode: '',
        verify: '',
        success: '',
        successTitle: '',
        confirmMessage: '',
resendCode: '',
      },
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      allNotificationData:[],
      resoucesList: [],
      activeTab: "notifications",
      activeSettingPage: 1,
      code: "",
      codeError: false,
      ...this.changeEmailDefaultValues,
      ...this.changePhoneDefaultValues,
      ...this.changePasswordefaultValues,
      queryType: "",
      question1: "",
      description: "",
      deactivateConfirmation: false,
      userData: {},
      queryTypeList: [],
      resoucesData: {
        title: "",
        sub_title: "",
        content: "",
        arabic_details: "",
        arabic_title: "",
        arabic_sub_title: "",
        image: {
          id: 0,
          url: ""
        },
        next_previous_ids: {
          previous: 0,
          next: 0
        }
      },
      detailsText: {
        upNext:"",
        previous:"",
        resource:"",
        resources:"",
        readMore: ""
      },
      sms_active: false,
      email_active: false,
    
      new_message_sms: false,
      access_request_sms: false,
      deal_updates_sms: false,
      promotions_sms: false,
      
      new_message_email: false,
      access_request_email: false,
      deal_updates_email: false,
      promotions_email: false,
      language: "",
      changeMailPageText: {
        title: "",
        descriptionMail: "",
        descriptionCode: "",
        errorMsg: "",
        emailLabel: "",
        emailPlaceholder: "",
        codeLabel: "",
        codePlaceHolder: "",
        firstBlockNote: "",
        enterButton: "",
        verifyButton: "",
        confirmMessage: "",
        resendCode: "",
        successTitle: "",
        successMessage: "",
        settings: "",
        backText: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseAPIId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getNotificationAPICallId === responseAPIId) {
        this.setState({ allNotificationData: responseJson.data});
      }
      if(this.getResourceAPICallId === responseAPIId) {
        this.setState({resoucesList: responseJson.data})
      }
      if(this.getResourceDetailAPICallId === responseAPIId  && responseJson.data) {
        this.setState({resoucesData: responseJson.data.attributes})
      }
      if (this.getNotificationSettingAPICallId === responseAPIId) {
        this.setState({ 
          sms_active: responseJson.notification_setting.sms_active,
          email_active: responseJson.notification_setting.email_active,
        
          new_message_sms: responseJson.notification_setting.new_message_sms,
          access_request_sms: responseJson.notification_setting.access_request_sms,
          deal_updates_sms: responseJson.notification_setting.deal_updates_sms,
          promotions_sms: responseJson.notification_setting.promotions_sms,
          
          new_message_email: responseJson.notification_setting.new_message_email,
          access_request_email: responseJson.notification_setting.access_request_email,
          deal_updates_email: responseJson.notification_setting.deal_updates_email,
          promotions_email: responseJson.notification_setting.promotions_email

        });
      }
    }
    this.handleResForUserProfile(from, message)
    this.handleResForChangeEmailId(from, message)
    this.handleResForPrefLang(from, message)
    this.handleResForVerifyOtpEmailId(from, message)
    this.handleResForChangePhoneNo(from, message)
    this.handleResForVerifyOtpPhoneNo(from, message)
    this.handleResForVerifyOldPassword(from, message)
    this.handleResForChangePassword(from, message)
    this.handleResForQueryList(from, message)
    this.handleResForContactUs(from, message)
    this.handleResForAccountDeactive(from, message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      activeTab: this.props.navigation.getParam("option"),
    });
    let curLang = await getStorageData("language") || 'en';
    this.setState({language: curLang}, () => {
      this.translateText();
    })
    this.getUserProfile()
    this.getQueryList()
    this.getNotifications()
    this.getResourceData();
    this.getResourceDetails();
    this.getNotificationsSettingValueApi();
  }

  handleOnInputChange = async (value: string) => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };
    const httpBody = {
      "pref_lang": value
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let userData = {...this.state.userData};

    userData.attributes.pref_lang = value;
    this.setState({userData: userData});
    this.updatePrefLangAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.prefEndpoint}?locale=${this.state.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getContent = (englishContent:string, arabicContent: string) => {
    return this.state.language === "en" ? englishContent : arabicContent;
  }

  translateText = () => {
    let detailsTexts = this.state.language == 'ar' ? configJSON.arDetailsText: configJSON.enDetailsText;
    let mobText = this.state.language == 'ar' ? configJSON.mobArText: configJSON.mobEnText;
    let changeEmailText = this.state.language == 'ar' ? configJSON.changeEmailArabic : configJSON.changeEmailEnglish;
    this.setState({detailsText: detailsTexts, mobTexts: mobText,changeMailPageText: changeEmailText})

  }
  componentDidUpdate(prevProps: Props) {
    const { navigation } = this.props;
    if (navigation && navigation.getParam("option") !== prevProps.navigation.getParam("option")) {
      this.componentDidMount()
    }
      if(prevProps.navigation.getParam("id") != this.props.navigation.getParam("id")){
        this.componentDidMount()
    }
  }

  queryTypeList = [
    { value: "0", title: "Technical" },
    { value: "1", title: "Non Technical" },
  ];

  changeEmailDefaultValues = {
    email: "",
    emailError: false,
  };

  changePhoneDefaultValues = {
    phone: "",
    phoneError: false,
  };

  changePasswordefaultValues = {
    currentPassword: "",
    currentPasswordError: false,
    newPassword: "",
    confirmPassword: ""
  };

  onChangeTab = (tab: string) => {
    this.setState({
      activeTab: tab,
    });
    this.props.navigation.navigate(`Settings2`, { option: tab });
  };

  handleBackPage = () => {
    conditionSort(window.history.length > 2,
      this.props.navigation.goBack(),
      this.props.navigation.navigate('Home')
    );
    
  };

  handleNextPage = (page: string) => {
    this.props.navigation.navigate(`UpdateSettings`, { option: page });
  };

  navigateTo = (urlId: number) => {
    this.props.navigation.navigate(`ResourceDetails`, {id:urlId})
  }
  handleBackbtn=()=>{
    this.props.navigation.goBack();
  }
  isValidEmailId = (emailId: any) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailId);
  }

  handleChangeEmail = (e: any) => {
    const { value } = e.target;
    if (!this.isValidEmailId(value)) {
      this.setState({
        ...this.state,
        email: value,
        emailError: true,
      });
    } else {
      this.setState({
        ...this.state,
        email: value,
        emailError: false, // Clear error when the field changes
      });
    }
  };

  handleChangeInput = (e: any, field: string, error: string) => {
    const { value } = e.target;
    this.setState({
      ...this.state,
      [field]: value,
      [error]: false, // Clear error when the field changes
    });
  };

  handleConditon = (condition: any, trueSta: any, falseSta: any) => {
    return condition ? trueSta : falseSta
  }

  handleQueryTypeChange = (
    value: string,
    field: string,
  ) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  handleFirstPage = () => {
    this.setState({
      activeSettingPage: 2,
    });
  };

  handleVerifyOTP = () => {
    this.setState({
      activeSettingPage: 3,
    });
  };

  handleSubmitHelp = () => {
    this.setState({
      activeSettingPage: 3,
    });
  };

  handleDeactivateAccount = () => {
    this.setState({
      deactivateConfirmation: true,
    });
  };

  getUserProfile = async () => {
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userProfileEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getResourceDetails = async () => {

    let resourceId: string = this.props.navigation.getParam("id");

    if(!resourceId) {
       return;
    }
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getResourceDetailAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resourceEndpoint}/${resourceId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getResourceData = async () => {
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getResourceAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resourceEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotifications = async () => {
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  shouldDisableCheckbox(item:any) {
    const isSmsOption = item.value.includes('sms');
    const isEmailOption = item.value.includes('email');
    return (isSmsOption && !this.state.sms_active) || (isEmailOption && !this.state.email_active);
  }
  handleReadNotification=async(id:number, appUrl:string)=>{
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const httpBody = {
      "in_app_notification": {
        "is_read": true
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readNotificationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/in_app_notifications/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.notificationReadAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleUrl(appUrl)
   

  }
  handleUrl=async(inAppUrl:string)=>{
    const urlDivide = inAppUrl.split("/");
    let app_url="";
    if(urlDivide[0]==="startupDetails" ){
      app_url="StartUpDetails";
    }
   if(inAppUrl==="listing_content.app_url" ){
    this.props.navigation.navigate("Feedback")
   }else if(inAppUrl.includes("startupDetails")){
    this.props.navigation.navigate(app_url,{ id:urlDivide[1]});
   }else if(inAppUrl.includes("CompanyProfileEdit")){
    this.props.navigation.navigate("CompanyProfileEdit",{ id:urlDivide[1]});
   }else if(inAppUrl.includes("AcquisitionProcess")){
    await setStorageData("id",urlDivide[1])
    this.props.navigation.navigate("AcquisitionProcess");
   }
   else if(inAppUrl.includes("MyAcquisition")){
    await setStorageData("NotificationId",urlDivide[1])
    this.props.navigation.navigate("MyAcquisition");
   }
    else if(inAppUrl.includes("Deals")){
    this.props.navigation.navigate("Deals");
   }
   else if(inAppUrl.includes("Settings2")){
    const urlDivideset = inAppUrl.split("/");
    this.props.navigation.navigate(`Settings2`, { option: urlDivideset[1] });
   }else{
    this.props.navigation.navigate(inAppUrl);
   }

  }
  getSmsvalue=(item:boolean)=>{
    return  this.state.sms_active ? item : false
  }
  getEmailvalue=(item:boolean)=>{
    return  this.state.email_active ? item : false
  }
  getStateValue = (optionValue: string) => {
    const stateMap: { [key: string]: boolean | undefined } = {
      new_message_sms: this.getSmsvalue(this.state.new_message_sms),
      access_request_sms:this.getSmsvalue(this.state.access_request_sms),
      deal_updates_sms: this.getSmsvalue(this.state.deal_updates_sms),
      promotions_sms: this.getSmsvalue(this.state.promotions_sms ),

      new_message_email: this.getEmailvalue( this.state.new_message_email),
      access_request_email:this.getEmailvalue(this.state.access_request_email),
      deal_updates_email:this.getEmailvalue( this.state.deal_updates_email),
      promotions_email: this.getEmailvalue(this.state.promotions_email),
    };
    return stateMap[optionValue] ?? false;
  };

  handleNotificationSettingMain=(e: React.ChangeEvent<HTMLInputElement>)=>{
    const { name, checked } = e.target;
    this.setState({ [name]: checked } as Pick<NotificationSettingsState, keyof NotificationSettingsState>);
    if (name === 'sms_active' && !checked) {
      this.setState({
        new_message_sms: false,
        access_request_sms: false,
        deal_updates_sms: false,
        promotions_sms: false,
      });
    }
    if (name === 'email_active' && !checked) {
      this.setState({
        new_message_email: false,
        access_request_email: false,
        deal_updates_email: false,
        promotions_email: false,
      });
    }
    this.notificationSettingApi();
  }

  notificationSettingApi =  async () => {
      const tokenNo = await getStorageData("token");
      
      const privateInfoHeader = {
        "Content-Type": configJSON.validationApiContentType,
        token: tokenNo,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
     
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.notificationSettingEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(privateInfoHeader)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          "notification_preference": {
            "new_message_sms": this.state.new_message_sms,
            "access_request_sms": this.state.access_request_sms,
            "deal_updates_sms": this.state.deal_updates_sms,
            "promotions_sms": this.state.promotions_sms,
            "sms_active": this.state.sms_active,
            "new_message_email": this.state.new_message_email,
            "access_request_email": this.state.access_request_email,
            "deal_updates_email": this.state.deal_updates_email,
            "promotions_email": this.state.promotions_email,
            "email_active": this.state.email_active
          }
        })
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
        
  }
  getNotificationsSettingValueApi = async () => {
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationSettingAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationSettingEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  changeEmailId = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const httpBody = {
      "email": this.state.email
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeEmailEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  verifyOtpEmailId = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.verifyOtpEmailEndpoint}?email_otp=${this.state.code}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changPhoneNo = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const httpBody = {
      "new_phone_number": this.state.phone
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePhoneNoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePhoneNoEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  verifyOtpPhoneNo = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const httpBody = {
      "otp": this.state.code
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpPhoneNoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.verifyOtpPhoneNoEndpoint}?locale=${this.state.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  verifyOldPassword = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const httpBody = {
      "password": this.state.currentPassword
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOldPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOldPasswordEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changePassword = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const httpBody = {
      "password": this.state.newPassword,
      "confirm_password": this.state.confirmPassword
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQueryList = async () => {
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQueryListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQueryEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  contactUs = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const httpBody = {
      "need_help": {
        "query_type_id": this.state.queryType,
        "answer": this.state.question1,
        "description": this.state.description
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendContactUsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deactiveAccount = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType
    };

    const httpBody = {
      "data": {
        "attributes": {
          "flag": 'true'
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountDeactiveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactiveAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForUserProfile = async (from: string, message: Message) => {
    if (this.getUserProfileAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ userData: responseJson.data })
      } else {
        toast.error(conditionSort(this.state.language == 'ar', configJSON.swwAr, configJSON.swwEn), getToastPosition())
      }
    }
  }

  handleResForChangeEmailId = async (from: string, message: Message) => {
    if (this.changeEmailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.handleFirstPage()
      } else {
        toast.error(conditionSort(this.state.language == 'ar', configJSON.swwAr, configJSON.swwEn), getToastPosition())
      }
    }
  }

  handleResForPrefLang = async (from: string, message: Message) => {
    if (this.updatePrefLangAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
        toast.success(responseJson.message, getToastPosition())
    }
  }

  handleResForVerifyOtpEmailId = async (from: string, message: Message) => {
    if (this.verifyOtpEmailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.handleVerifyOTP()
      } else {
        toast.error(conditionSort(this.state.language === "ar", "الرمز المدخل غير صحيح أو منتهي الصلاحية", 'Invalid or expired OTP'), getToastPosition() )
      }
    }
  }

  handleResForChangePhoneNo = async (from: string, message: Message) => {
    if (this.changePhoneNoCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.handleFirstPage()
      } else if (responseJson.errors.includes('Invalid phone number')) {
        this.setState({ phoneError: true })
      } else {
        toast.error(conditionSort(this.state.language == 'ar', configJSON.swwAr, configJSON.swwEn), getToastPosition())
      }
    }
  }

  handleResForVerifyOtpPhoneNo = async (from: string, message: Message) => {
    if (this.verifyOtpPhoneNoCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.handleVerifyOTP()
      } else {
        toast.error(conditionSort(this.state.language === "ar", "الرمز المدخل غير صحيح أو منتهي الصلاحية", 'Invalid or expired OTP'), getToastPosition())
      }
    }
  }

  handleResForVerifyOldPassword = async (from: string, message: Message) => {
    if (this.verifyOldPasswordCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.Password) {
        this.handleFirstPage()
      } else if (responseJson.errors.includes('Incorrect password')) {
        toast.error(conditionSort(this.state.language == 'ar', 'كلمة مرور غير صحيحة', 'Incorrect password'), getToastPosition())
      } else {
        toast.error(conditionSort(this.state.language == 'ar', configJSON.swwAr, configJSON.swwEn), getToastPosition())
      }
    }
  }

  handleResForChangePassword = async (from: string, message: Message) => {
    if (this.changePasswordCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.handleVerifyOTP()
      } else {
        toast.error(conditionSort(this.state.language == 'ar', configJSON.swwAr, configJSON.swwEn), getToastPosition())
      }
    }
  }

  handleResForQueryList = async (from: string, message: Message) => {
    if (this.getQueryListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ queryTypeList: responseJson.data })
      } else {
        toast.error(conditionSort(this.state.language == 'ar', configJSON.swwAr, configJSON.swwEn), getToastPosition())
      }
    }
  }

  handleResForContactUs = async (from: string, message: Message) => {
    if (this.sendContactUsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.handleSubmitHelp()
      } else {
        toast.error(conditionSort(this.state.language == 'ar', configJSON.swwAr, configJSON.swwEn), getToastPosition())
      }
    }
  }

  handleResForAccountDeactive = async (from: string, message: Message) => {
    if (this.accountDeactiveCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.handleDeactivateAccount()
        removeStorageData('token')
        this.props.navigation.navigate('Home')
      } else {
        toast.error(conditionSort(this.state.language == 'ar', configJSON.swwAr, configJSON.swwEn), getToastPosition())
      }
    }
  }

  handleLanguage = () => {
    if (this.state.language === configJSON.englishLanguage) {
      return true;
    }
    else {
      return false;
    }
  };
  // Customizable Area End
}
