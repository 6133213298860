import React from "react";

import {
  Box,
  Button,
  Typography,
  Dialog,
  styled,
  createTheme,
  responsiveFontSizes

} from "@material-ui/core";
import { MailOutline   } from "@material-ui/icons";

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { user_accouunt , email_icon} from "./assets";
import "./ForgotPassword.web.css";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import {
  withStyles
} from "@material-ui/core/styles";
let themeProvider = createTheme();
themeProvider = responsiveFontSizes(themeProvider);
//Customizable Area End

export default  class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start

    //Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
<>
<HeaderBlock/>
      <Box  className="outer_wrapper">
      {/* Customizable Area Start */}
  <Box  className="inner_wrapper">
        <div className="registrationBlockMain">
          <Box style={styles.containerForm} className="mainconatiner">
            <div className=" ForgotPassword_WhiteBox " style={styles.ForgotPassword_WhiteBox}>
              <form  className="form_wrapper">
                <Box sx={styles.firstRow}>
                  <Typography variant="h3" style={styles.mainHeading_wrapper} className="mainHeading">
                    {this.state.forgotPasswordPageText.ForgotPasswordTitleText}
                  </Typography>
                  <ContentBox>

                    <Typography variant="h6" style={{...styles.subpera}} className="subpera">
                      {this.getSpecifiedLangString(this.state.lang, 
                        this.state.forgotPasswordPageText.fgtPwdParaOne, 
                        `أدخل معرف البريد الإلكتروني الخاص بك الذي استخدمته للتسجيل في المنصة.`) }
                    </Typography>
                    <Typography className="subpera" variant="h6" style={{...styles.subpera}}  >
                      {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.fgtPwdParaTwo, ` سوف تتلقى رابطًا عبر البريد الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك`)}
                    </Typography>
                  </ContentBox>
                      {this.state.emailErrors ? 
                        <Box className="errormsg">
                      <Typography className="errormsg_text" style={{fontFamily:"Montserrat",fontWeight:700}}>
                        {this.getSpecifiedLangString(this.state.lang, 
                          this.state.forgotPasswordPageText.pleaseEnterValidEmailText, 
                          `البريد الإلكتروني المدخل غير صحيح`)}
                      </Typography>
                      </Box>:null
                      }
                      {this.state.emailNotReg?   <Box className="errormsg">
                      <Typography className="errormsg_text" style={{fontFamily:"Montserrat",fontWeight:700}}>
                        {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.emailNotRegistedText, "البريد الإلكتروني غير مسجل في نامي")}
                      </Typography> </Box>:null}
                  <Box sx={styles.RowElement} className="RowElementInput">
                  <InputField >

                    <input
                      type="Email"
                      className="RowElementInput inputField"
                      placeholder={this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.emailIdPlaceHolder, "أدخل معرف البريد الإلكتروني المسجل")}
                      name="firstName"
                      data-test-id="firstName"
                      style={{...styles.RowElementInput,fontFamily:"Montserrat",fontWeight:500, fontSize:"18px" }}
                      onChange={this.handleChangeEmail}
                      value={this.state.email}
                      onFocus={ this.onFocus.bind(this)}
                      onBlur={this.onBlur.bind(this)}
                    />
                    <Box className="input-icon">
                        <MailOutline
                          style={{
                            ...styles.greyColor,
                            opacity: (this.state.emailFocused || this.state.email) ? "1" : "0.5"
                          }}
                        />
                    </Box>
                      
                    </InputField>
                  </Box>

                  <Box  className="footer-links" style={{ display: "flex",justifyContent: "space-between", width: "100%", flexWrap: "wrap" }}>
                    {this.state.resendmail?
                    <Typography variant="h6" style={styles.subpera} className="subpera" >
                      {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.didNotReviedLinkText, "لم تتلقى الرابط؟")}</Typography>
                      :<Typography variant="h6" style={{...styles.subpera, cursor: "pointer"}} className="subpera" onClick={this.reSendmail} >
                      {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.didNotReviedLinkText, "لم تتلقى الرابط؟")}</Typography>}
                    <Typography variant="h6" style={{...styles.subpera, cursor: "pointer"}} className="subpera" data-test-id="forgot-email" onClick={this.handleForgotEmailId}>
                      {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.forgotEmailIdText, "هل نسيت البريد الإلكتروني؟")}</Typography>
                  </Box>
                  <Box
                    sx={{ ...styles.buttonElement, display: 'flex', flexDirection: 'column', alignItems:"center", marginTop: 10 }}
                    className="footerButtons"
                  >
                    <CustomsubparaBox className="submit_btn"
                      style={{
                        border: "1px solid #8C8C8C",
                        color: "white",
                        borderRadius: "24px",
                        width: "200px",
                        height: "48px",
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily: "Montserrat",
                        textTransform:"capitalize",
                        fontStyle: "normal",
                        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        justifyContent: "center",
                        backgroundColor: this.state.btndisabled?"#8C8C8C":"#10BFB8",
                        marginBottom: "12px"
                      }}
                      disabled={this.state.btndisabled}
                      data-test-id="submitButton"
                      onClick={this.onclickSubmit}
                    >
                      {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.enterText, "دخول")}
                    </CustomsubparaBox>
                    <Box className="clearButton-wrapper">
                      <Box style={{marginBottom:"10px", textAlign:"center"}}>
                    <Typography variant="h6" style={styles.subpera} className="subpera">
                    {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.addNewUserText, "مستخدم جديد؟")}
                    </Typography>
                      </Box>

                    <Button
                      className="signupButton"
                      variant="outlined"
                      data-test-id="ClearBtn"
                      onClick={this.handlenavigation}
                    >
                      <img src={user_accouunt} className="btn-icon" />
                      {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.signUpText, "اشتراك")}
                    </Button>
                   
                    </Box>

                  </Box>
                  <Box>

                  </Box>
                </Box>
              </form>
            </div>
        </Box>
        </div>
        </Box>
        {/* Customizable Area End */}
      </Box>


      <Popup
          className={classes.popUp}
          open={this.state.modalOpen}
          onClose={this.toggleModal}
          maxWidth="md"
        >
          <Box>
            <Box className={classes.successfullySubmitted}>
              <Box >
                {/* @ts-ignore */}
                {this.state.linksend?<p className="classes.errormsg" style={{fontFamily: "Montserrat",}}>{this.state.linksendmsg}</p>:null}
                    {this.state.emailNotExist?"":<p className="classes.errormsg" style={{fontFamily: "Montserrat",}}>{this.state.linksendError}</p>}
              </Box>
            </Box>

            <Box className={classes.successfullySubmitted}>
              <Button
                className={classes.typoHome}
                variant="contained"
                onClick={this.toggleModal} >
                {this.getSpecifiedLangString(this.state.lang, this.state.forgotPasswordPageText.okText, "تم")}
              </Button>
            </Box>
          </Box>
        </Popup>
      <FooterBlock/>

</>
    );
  }
}

// Customizable Area Start

const styles: any = {
 

  ForgotPassword_WhiteBox: {
    backgroundColor: '#fff',
    borderRadius: '18px',
    padding: '18px',
   
  },
  subpera:{
    fontSize:"0.875rem",
    textAlign:"cneter",
    fontFamily: "Montserrat",

  },
  mainHeading_wrapper:{
      marginTop: 0,
      textAlign: "center",
      color: "rgb(84, 18, 252)",
      fontWeight: 700,
      fontFamily: "Montserrat",
      fontSize: 30,
      lineHeight:"36px",
      fontStyle: "normal",
  },

  button: {
    marginTop: 14,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1,
  },

  
  containerForm: {
    margin: "0 auto",
    height: "relative",
    border: "2px solid transparent",
    borderRadius: "20px",
   
  },

  RowElementInput: {
    padding: "12px",
    borderRadius: "12px",
    border: "1px solid #8C8C8C",
    width: "100%",
    height: "48px",
   
  },
  RowElement: {
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    margin: "0px",
   
  },
  rightImageWithSuccess: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "28px",
    marginBottom: "30px",
    "& img": {
      width: "60px",
      height: "60px"
    },
    "@media (max-width:600px)": {
      gap: "18px",
      marginBottom: "26px",
      "& img": {
        width: "39px",
        height: "39px"
      }
    }
  },
  typoSuccess: {
    color: "black",
    fontSize: "30px !important",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "24px",
    "@media (max-width:600px)": {
      fontSize: "24px !important"
    }
  },
  successfullySubmitted: {
    textAlign: "center"
  },
  errormsg:{
    color: "#000",
textAlign: "center",
fontFamily: "Montserrat",
fontSize: "20px",
fontStyle: "normal",
fontWeight: 500,
lineHeight: "normal",
  },
  typoThird: {
    color: "black",
    fontSize: "24px !important",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "36px",
    marginBottom: "48px",
    "@media (max-width:600px)": {
      fontSize: "14px !important",
      lineHeight: "26px",
      marginBottom: "34px"
    }
  },
  typoHome: {
    backgroundColor: "#10BFB8",
    borderRadius: "24px",
    padding: "14px 74px",
    color: "#FFF",
    fontSize: "20px !important",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "22px",
    textTransform: "inherit",
    "&:hover": {
      backgroundColor: "#10BFB8"
    },
    "@media (max-width:600px)": {
      padding: "7px 36px",
      fontSize: "12px !important"
    }
  },
};

const CustomsubparaBox = styled(Button)({
  '&:hover': {
    backgroundColor: '#10BFB8 !important',
    color: 'white!important',
    border: 'none',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  '@media(max-width: 600px)': {
   marginBottom:"25px !important",
  }
});
const ContentBox = styled(Box)({
marginTop: 30,marginBottom: 30, marginInline: 30,textAlign:"center",
  '@media(max-width: 600px)': {
    marginInline: '0px'
  }
});
const InputField = styled("div")({
  position:"relative",

  "& .inputField":{
    "@media (max-width:600px)": {
      fontSize: '10px !important',
      padding:"4px !important",
      paddingLeft:"10px !important",
     fontFamily: "Montserrat",
      },
  },

"& .inputField::placeholder ":{
  color: '#8C8C8C',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  "@media (max-width:600px)": {
    fontSize: '10px',
    },

 
}

})
const Popup = styled(Dialog)(() => ({

  "& .MuiPaper-root": {
    // height: "265px",
    width: "795px",
    borderRadius: "20px",
    border: "1px #262626 solid",
    boxShadow: "none",
    padding: "50px",
    overflow: "hidden",
    display:"flex",
    justifyContent:"center",
    "@media (max-width:600px)": {
      height: "auto",
      width: "231px"
    }
  },
  "& .MuiBackdrop-root": {
    background: "rgba(140, 140, 140, 0.60)"
  }
}));

export  const ForgotPasswordStyle = withStyles(styles, {
  withTheme: true
})(ForgotPassword);