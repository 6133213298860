// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Typography,
  Dialog,

} from "@material-ui/core";

import {  styled,withStyles } from "@material-ui/core/styles";
import { user_accouunt, imgPasswordVisible } from "./assets";
import "./ForgotPassword.web.css";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";

export default class NewPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;

    const isSubmitDisabled =
    !(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/.test(this.state.copyPassword) &&
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/.test(this.state.copyResetPassword) &&
      this.state.copyPassword === this.state.copyResetPassword
    );
   
    return (
      <>
      <HeaderBlock/>
      
      <Box  className="outer_wrapper">
        <Box className="inner_wrapper">
          <div className="registrationBlockMain">
            <Box style={styles.containerForm} className="mainconatiner">
              <div className=" ForgotPassword_WhiteBox " style={styles.ForgotPassword_WhiteBox}>
                <form className="form_wrapper new_newpassword_wrapper">
                  <Box sx={styles.firstRow}>
                    <Typography className="mainHeading" variant="h3" style={styles.mainHeading}>
                      {this.state.forgotPasswordPageText.ForgotPasswordTitleText}
                    </Typography>

                    <Box sx={styles.RowElement} className="RowElementInput">
                      <Box className="lable" style={styles.subpera}>   {this.state.forgotPasswordPageText.newPasswordText}   </Box>
                      <Box style={{ position: "relative" }}>

                        <input 
                        data-testId={'description-txt'}
                          type="text"

                          placeholder="**********"

                          name="firstName"
                          data-test-id="firstName"
                          onKeyDown={this.handleKey}
                          style={{...styles.RowElementInput,fontSize:"18px",fontFamily:"Montserrat",fontWeight:500}}
                          onChange={this.handlePwdChange}
                          value={this.state.showPassword?this.state.copyPassword:"*".repeat(this.state.password.length)}                        
                          onFocus={ this.onFocus.bind(this)}
                           onBlur={this.onBlur.bind(this)}
                        />
                       {this.renderNewPasswordVisibilityIcon()}
                      </Box>

                    </Box>

                    <Box sx={styles.RowElement} className="RowElementInput">
                      <Box className="lable" style={styles.subpera}>   {this.state.forgotPasswordPageText.reEnterNewPasswrdTxt}  </Box>
                      <Box style={{ position: "relative" }}>
                        <input
                          type="text"
                          placeholder="***********"
                          name="firstName"
                          data-test-id="firstName"
                          style={{...styles.RowElementInput,fontSize:"18px", fontFamily:"Montserrat",fontWeight:500}}
                          onChange={this.handleResetPwd}
                          value={this.state.showResetPassword?this.state.copyResetPassword:"*".repeat(this.state.resetPassword.length)}                        
                          onFocus={ this.onFocusNewpassword.bind(this)}
                          onKeyDown={this.handleResetKey}

                          onBlur={this.onBlurNewpassword.bind(this)}
                        />
                       {this.renderVisibilityIcon()}
                      </Box>
                    </Box>

                    <Box
                      sx={{ ...styles.buttonElement, display: 'flex', flexDirection: 'column', marginTop: 40 }}
                      className="footerButtons"
                    >
                      <CustomsubperaBox className="submit_btn"
                        variant="contained"
                        style={{
                          border: "1px solid #8C8C8C",
                          color: "white",
                          borderRadius: "24px",
                          width: "200px",
                          height: "48px",
                          fontWeight: "bold",
                          fontSize: "20px",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          justifyContent: "center",
                          backgroundColor: isSubmitDisabled?"#8C8C8C":"#10BFB8",
                          marginBottom: "50px",
                          textTransform:"capitalize",
                        }}
                        data-test-id="submitButton"
                      disabled={isSubmitDisabled}
                        
                        onClick={this.apiPasswordDetails}
                      >
                        {this.state.forgotPasswordPageText.enterText}
                      </CustomsubperaBox>
                      <Box className="clearButton-wrapper">
                        <Box style={{ marginBottom: "20px", textAlign: "center" }}>
                          <Typography 
                            style={styles.subpera} 
                            className="subpera"
                            >
                            {this.state.forgotPasswordPageText.addNewUserText}
                          </Typography>
                        </Box>
                        <Button className="signupButton"
                          variant="outlined"
                          data-test-id="ClearBtn" onClick={this.handlenavigation}
                        >
                          <img src={user_accouunt} className="btn-icon" />

                               {this.state.forgotPasswordPageText.signUpText}
                        </Button>

                          </Box>

                      </Box>
                      <Box>

                    </Box>
                  </Box>
                </form>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
      <Box><Typography>error</Typography></Box>
      <Popup
          className={classes.popUp}
          open={this.state.modalOpen}
          onClose={this.toggleModal}
          maxWidth="md"
        >
          <Box>
            <Box className={classes.successfullySubmitted}>
              <Box style={{textAlign:"center"}}>
               
                {this.state.newPasswordTokenError?<p className="classes.errormsg" style={{fontFamily: "Montserrat",}}>Token has Expired Redirecting to Forgot Password</p>:null}
              </Box>
            </Box>
          </Box>
        </Popup>
      <FooterBlock/>
      </>

      // Customizable Area End
    );
  }
  renderVisibilityIcon() {
    return (
    <Box className="input-icon">
    <span  onClick={this.toggleShowResetPassword}>

{ !this.state.showResetPassword? 

<VisibilityOff  style={{  color: (this.state.Newpassword || this.state.copyResetPassword) ? "#000000":"#8C8C8C"   }}/>
: <Visibility  style={{  color: (this.state.Newpassword || this.state.copyResetPassword) ? "#000000":"#8C8C8C"   }} />  

 }
    </span>
  </Box>
)
}

renderNewPasswordVisibilityIcon () {
 return ( <Box className="input-icon">
  <span  onClick={this.toggleShowNewPassword}>
  <Box className="input-icon">

</Box>
{ !this.state.showPassword? 
  <VisibilityOff style={{  color: (this.state.emailFocused || this.state.copyPassword) ? "#000000":"#8C8C8C"   }}  />
  : <Visibility style={{  color: (this.state.emailFocused || this.state.copyPassword) ? "#000000":"#8C8C8C"   }}  />}
      </span>

  </Box>
 )
}
}


const styles: any = {
  
  subpera: {
    fontSize: "0.875rem",
    textAlign: "cneter",
    fontFamily: "Montserrat",


  },
  containerMobile: {
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
   
  },
  ourter_wrapper: {
    border: '1px solid red !important',
    padding: "20px"

  },
  ForgotPassword_WhiteBox: {
    borderRadius: '18px',
    padding: '48px 10.95%',
    backgroundColor: '#fff',
  
  },
  mainHeading: {
    fontWeight: 800,
    fontFamily: "Montserrat",
    fontSize: 28,
    fontStyle: "normal",
    marginTop: 0,
    textAlign: "center",
    color: "rgb(84, 18, 252)",


  },
  subpera_wrapper: {
    marginTop: 30,
    marginInline: 30,
    "@media screen and (max-width: 500px)": {
      marginInline: 0,

    },

  },


  button: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1,
  },

 




  containerForm: {
    margin: "0 auto",
    marginTop: "100px",
    marginBottom: "150px",
    height: "relative",
    border: "2px solid transparent",
    borderRadius: "20px",
 
  },


  RowElement: {
    margin: "0px",
    width: "100%",
    justifyContent: "space-between",
    height: "48%",
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
   
  },
  RowElementInput: {
    padding: "12px",
    borderRadius: "12px",
    border: "1px solid #8C8C8C",
    width: "100%",
    height: "48px",
 
  },
  firstRow: {
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    marginTop: "40px",
    display: "flex",
    flexDirection: "row",
   
  },

  errormsg:{
    color: "#000",
textAlign: "center",
fontFamily: "Montserrat",
fontSize: "24px",
fontStyle: "normal",
fontWeight: 500,
lineHeight: "normal",
  },


};

const CustomsubperaBox = styled(Button)({
  '&:hover': {
    border: 'none',
    backgroundColor: '#10BFB8 !important',
    color: 'white!important',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  }
});
const ContentBox = styled(Box)({
  marginTop: 30, marginBottom: 30,
  textAlign: "center",
  marginInline: 30,
  '@media(max-width: 600px)': {
    marginInline: '0px'
  }
});
const Popup = styled(Dialog)(() => ({

  "& .MuiPaper-root": {
    // height: "265px",
    width: "795px",
    borderRadius: "20px",
    border: "1px #262626 solid",
    boxShadow: "none",
    padding: "50px",
    overflow: "hidden",
    display:"flex",
    justifyContent:"center",
    "@media (max-width:600px)": {
      height: "auto",
      width: "231px"
    }
  },
  "& .MuiBackdrop-root": {
    background: "rgba(140, 140, 140, 0.60)"
  },




}));


export  const NewPasswordWebStyle = withStyles(styles, {
  withTheme: true
})(NewPasswordWeb);


// Customizable Area End
