import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
import { getStorageData } from "../../../framework/src/Utilities";
import { conditionSort } from "../../../components/src/Helper";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: { [key: string]: string };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  email:any;
  password:any;
  newpassword:any;
  confirmpassword:any;
  emailErrors:boolean;
  newPasswordToggle:any;
  confirmPasswordToggle:any;
  showResetPassword:any;
  btndisabled:boolean;
  linksend:boolean;
  linksendmsg:any;
  linksendError:any;
  emailNotExist:boolean;
  modalOpen: boolean;
  resendmail: boolean;
  newPasswordtoken:any;
  showPassword:any;
  copyPassword:any;
  emailFocused: boolean;
  emailNotReg: boolean;
  Newpassword: boolean;
  newPasswordTokenError: boolean;
  copyResetPassword: any;
  resetPassword: any;
  lang:string;
  forgotPasswordPageText : {
    ForgotPasswordTitleText:string;
    fgtPwdParaOne:string;
    fgtPwdParaTwo:string;
    pleaseEnterValidEmailText:string;
    emailNotRegistedText:string;
    didNotReviedLinkText:string;
    forgotEmailIdText:string;
    enterText : string;
    addNewUserText:string;
    signUpText:string;
    okText:string;
    newPasswordText:string;
    reEnterNewPasswrdTxt:string;
    emailIdPlaceHolder:string;
  }

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  emailDetailsCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  email:any;
  newpassword:any;
  confirmpassword:any;
  navigate: any;

  resetPasswordId:any

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      email:"",
      emailErrors:false,
      newpassword:'',
      confirmpassword:'',
      newPasswordToggle:false,
      confirmPasswordToggle:false,
      btndisabled:true,
      resetPassword:"",
      copyResetPassword:"",
  linksend:false,
  linksendmsg:'',
  linksendError:'',
  modalOpen: false,
  resendmail: true,
  newPasswordtoken:'',
  emailFocused: false,
  emailNotReg: false,
  Newpassword: false,
  newPasswordTokenError: false,
  password:"",
  showPassword:false,
  copyPassword:"",
  showResetPassword: false,
  lang:"en",
  forgotPasswordPageText :{
    ForgotPasswordTitleText: "",
    fgtPwdParaOne: "",
    fgtPwdParaTwo: "",
    pleaseEnterValidEmailText: "",
    emailNotRegistedText: "",
    didNotReviedLinkText: "",
    forgotEmailIdText: "",
    enterText : "",
    addNewUserText: "",
    signUpText: "",
    okText: "",
    newPasswordText: "",
    reEnterNewPasswrdTxt: "",
    emailIdPlaceHolder : ""
  },









  emailNotExist:true,


    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
     // Customizable Area Start
    this.validationRulesRequest();
    this.getLang();
     // Customizable Area End
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    //Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      await this.handleRestAPIResponse(message);
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigationPayload(message);
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.handleCountryCodeMessage(message);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.emailDetailsCallId !== null &&
      this.emailDetailsCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      await this.handleEmailDetailsResponse(message);
    }
     // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
toggleeyeicon(){
  this.setState({confirmPasswordToggle:!this.state.confirmPasswordToggle})
}
togglenewpwseyeicon(){
  this.setState({newPasswordToggle:!this.state.newPasswordToggle})
}

getLang = async () => {
  let lang = await getStorageData('language')
  if (lang) {
    return   this.setState({ lang: lang },  () => this.applyTranslation())
  }
  this.setState({ lang: 'en' })
}

getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
  if(lang ==="en"){
    return stringOne
  }else{
    return stringTwo
  }
}

applyTranslation = async () => {
  this.setState({ forgotPasswordPageText: conditionSort(this.state.lang == 'ar', configJSON.forgetTextAr, configJSON.forgetTextEn) });
}


  isValidEmail=(email:any) =>{
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  } 

  handleChangeEmail = (e: any) => {
    const { value } = e.target;
    if (!this.isValidEmail(value)) {
    this.setState({ email: value, emailErrors:true,btndisabled:true, });
    }else{
    this.setState({ email: value, emailErrors:false ,btndisabled:false ,emailNotReg:false});
    }
   
    
  };
  onclickSubmit = () => {
       if (this.isValidEmail(this.state.email)) { 
          this.apiEmailDetails()
       }
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const contentheader = {
      "Content-Type": contentType,
      "locale":this.state.lang
    };
    const apirequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apirequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(contentheader)
    );
    apirequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apirequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      apirequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(apirequestMessage.id, apirequestMessage);
    return apirequestMessage.messageId;
  };

  apiPasswordDetails = async () => {
    this.resetPasswordId = await this.apiCall({
      contentType: configJSON.apiEmailDetailsContentType,
      method: configJSON.apiEmailDetailsMethod,
      endPoint: 'account_block/password',
      body:{
        "data": {
            "attributes": {
              "token": window.location.href.split('=')[1].split('?')[0],
            "new_password": this.state.copyPassword,
            "confirm_password": this.state.copyResetPassword
            }
        }
      }
      
      
    }
    );
    

  }

  apiEmailDetails = async () => {
    this.emailDetailsCallId = await this.apiCall({
      contentType: configJSON.apiEmailDetailsContentType,
      method: configJSON.apiEmailDetailsMethod,
      endPoint: configJSON.apiEmailDetailsEndPoint,
      body:{
        "data": {
            "attributes": {
                "email": this.state.email,
                "reset_password_url":`${window.location.protocol}//${window.location.hostname}/NewPassword`

            }
        }
    }
    
    });
    this.setState({btndisabled:true})
  }
  apiCallFunctions = (apiRequestCallId: string, responseJson: any) => {

    if (apiRequestCallId === this.resetPasswordId) {
      
      this.props.navigation.navigate("SuccessModel");
    
     
    }

    if (apiRequestCallId === this.emailDetailsCallId) {
      this.toggleModal()
      this.setState({
        linksend: true,
        linksendmsg: this.state.lang === "ar" ? `تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور إلى ${this.state.email}`: responseJson.message,
        emailNotExist: true,
      });
     }
    
    

  }


  handleChangepassword = (e: any,field:any) => {
    const {  value } = e.target ;
  
    if(field==='newpassword'){
      
    this.setState({ newpassword: value });
  

    }else{
    this.setState({confirmpassword : value });
    }
   
  };
   handlenavigation = () => {
    this.props.navigation.navigate("EmailAccountRegBlock");
  }
  handleBackToHomePage=()=>{
    this.props.navigation.navigate("EmailAccountLoginBlock");

  }
  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }
  reSendmail = () => {
    this.apiEmailDetails()
  }
  onFocus() {
    this.setState({ emailFocused: true })
  }

  onBlur() {
    this.setState({ emailFocused: false })
  }
  onFocusNewpassword() {
      this.setState({Newpassword: true })
    
  
}
onBlurNewpassword() {
  if(this.state.newpassword ){      
    this.setState({Newpassword: true })

  }
  this.setState({Newpassword: false })
}


  async handleRestAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
      this.apiCallFunctions(apiRequestCallId, responseJson);
    }
    if (responseJson && responseJson.errors) {
      this.handleEmailDetailsOther(apiRequestCallId,responseJson)
    }
  }
  
  handleNavigationPayload(message: Message) {
    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );
  
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ token: otpAuthTkn });
      if (this.isChangePassword) {
        this.setState({ accountStatus: "ChangePassword" });
      }
      this.otpToken = this.state.token;
    } else {
      const accountType = message.getData(
        getName(MessageEnum.NavigationForgotPasswordPageInfo)
      );
      if (accountType) {
        this.startForgotPassword(accountType);
      }
    }
    
  }

  handleForgotEmailId = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotEmailId");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
  }
  
  handleCountryCodeMessage(message: Message) {
    let selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );
  
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode,
      });
    }
    // Add any other logic related to CountryCodeMessage here
  }
  
  async handleEmailDetailsResponse(message: Message) {
    
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  

    if (responseJson.success) {
      
      this.handleEmailDetailsSuccess(responseJson);
      
    } else if (responseJson.errors) {

      this.handleEmailDetailsErrors(responseJson);
    } else {

    }
  }
 

  
  handleEmailDetailsSuccess(responseJson: { message: any; }) {
    this.setState({
      linksend: true,
      linksendmsg: responseJson.message,
      emailNotExist: true,
    });
    this.setState({ resendmail: false });
    this.toggleModal(); 

  }
  
  handleEmailDetailsErrors(responseJson: any) {
    this.setState({ emailNotReg: false });
    this.toggleModal(); 

  }
  
  handleEmailDetailsOther(apiRequestCallId: string, responseJson: any) {
        
        if(responseJson.errors[0].message==="Token has Expired"){
          this.setState({newPasswordTokenError:true})
          this.toggleModal()
          setTimeout(() => {
                  this.toggleModal()
                  this.props.navigation.navigate("ForgotPassword");
              }, 3000);
        }
        if(responseJson.errors[0].message==="Account not exist"){
          this.setState({emailNotReg:true})
        }
  }

  handlePwdChange = (event: any) => {
    const { value } = event.target;
    const { showPassword, copyPassword } = this.state;

    this.setState({ password: value });
    if (!showPassword) {
      const a = copyPassword + value.split("*").join("");
      this.setState({ copyPassword: a });
    } else {
      this.setState({ copyPassword: value });
    }

  };

  handleResetPwd = (event: any) => {
    const { value } = event.target;
    const { showResetPassword, copyResetPassword } = this.state;

    this.setState({ resetPassword: value });
    if (!showResetPassword) {
      const a = copyResetPassword + value.split("*").join("");
      this.setState({ copyResetPassword: a });
    } else {
      this.setState({ copyResetPassword: value });
    }

  };
  toggleShowNewPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  toggleShowResetPassword = () => {
    this.setState((prevState) => ({
      showResetPassword: !prevState.showResetPassword,
    }));
  };

  

  handleKey = (event: any) => {
    const { showPassword, copyPassword } = this.state;
    const keyCode = event.keyCode || event.which;
    if (keyCode == 8) {
      const caretPosition = event.target.selectionStart;
      if (!showPassword) {
        let a = copyPassword.split("")
        a.splice(caretPosition - 1, 1)
        this.setState({ copyPassword: a.join("") })

      }
    }
  }

  handleResetKey = (event: any) => {
    const { showResetPassword, copyResetPassword } = this.state;
    const keyCode = event.keyCode || event.which;
    if (keyCode == 8) {
      const caretPosition = event.target.selectionStart;
      if (!showResetPassword) {
        let a = copyResetPassword.split("")
        a.splice(caretPosition - 1, 1)
        this.setState({ copyResetPassword: a.join("") })

      }
    }
  }
  
  
  // Customizable Area End
}
