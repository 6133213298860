import React from "react";
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
    actionTabStep3RequestedDocumentSend: {
        display: "flex",
        justifyContent: "flex-end",
        "@media (max-width:768px)":{
          justifyContent: "center",
          marginTop:'10px'
        },
      },
      actionTabStep3SendButton: {
        borderRadius: "12px",
        border: "2px #10BFB8 solid",
        background: "#10BFB8",
        padding: "12px 48px",
        color: "white",
        fontSize: "24px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        lineHeight: "24px",
        "@media (max-width:768px)":{
          fontSize:'18px'
        },
        "&:hover": {
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
        "&:disabled": {
          border: "2px #8C8C8C solid",
          background: "white",
          color: "#8C8C8C",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },

}))

interface AcquisitionActionTypeProps {
    requestedDocumentsList: { id: number; title: string; file: any; }[];
    isDocumentsRevied: boolean;
    labels: {
      send: string,
      sent: string
    }
    updateDocumentsFromSeller:() => Promise<void>;
}

export const AcquisitionProcessAction: React.FC<AcquisitionActionTypeProps> = ({
    requestedDocumentsList,
    isDocumentsRevied,
    updateDocumentsFromSeller,
    labels
   
}) => {
    const classes = useStyles();
   const  getIfConditionApplied = (condition: boolean, ifthisTrue: any, ifthisFalse: any) => {
        return condition ? ifthisTrue : ifthisFalse;
      };
    return (
        <>
           <div className={classes.actionTabStep3RequestedDocumentSend}>
                <button
                  className={classes.actionTabStep3SendButton}
                  disabled={
                    (! requestedDocumentsList?.every(
                      (list) => list.file
                    ) || isDocumentsRevied)
                  }
                  onClick={updateDocumentsFromSeller}
                >
                  {getIfConditionApplied(isDocumentsRevied, labels.sent, labels.send)}
                </button>
              </div>
        </>
    );
};