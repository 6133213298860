import {createStyles} from "@material-ui/core";
export const Styles = () => createStyles({
    detailWrapper:{
        "width": "1060px",
        "boxSizing": "border-box",
        margin: "130px auto",
        display: "block"
    },
    NewsDetailsInner:{
      "maxHeight": "1027px",
      "overflowY": "auto",
      display: "flex",
      gap: "56px",
      width: "100%",
      paddingRight: "20px",
      '&::-webkit-scrollbar': {
        width: '6px',
        height:'45px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#D9D9D9', // Color of the scrollbar thumb
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'darkgrey', // Color of the scrollbar thumb on hover
      }
    },
    NewsDetailsLeft:{
      "& div":{
        columns: 2,
        "color": "#000",
        "marginTop": "0px",
        "fontFamily": "Montserrat",
        "lineHeight": "35px",
      }
    },
    NewsDetailsRight: {
      "display": "flex",
      "flexDirection": "column",
      width: "calc(100% - 568px)",
      flex: "0 0 calc(100% - 568px)",
      "& p":{
        "color": "#000",
        "marginTop": "0px",
        "fontFamily": "Montserrat",
        "fontSize": "24px",
        "fontStyle": "normal",
        "fontWeight": 300,
        "lineHeight": "35px",
      }
    },
    newsFooterTop:{
      "display": "flex",
      "alignContent": "center",
      "justifyContent": "center",
      "margin": "46px 0px",
      "width": "100%",
      "& a":{
        "display": "inline-flex",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px"
      }
    },
    NewsBlockDetails:{
      "& .newsFooterAction": {
        display: "flex",
        justifyContent: "space-between"
    },
      "& .prevButtonContent": {
      display: "flex",
      gap: "40px",
      "& > span ": {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        textAlign: "left",
        "& label": {
          "color": "#262626",
          "fontFamily": "Montserrat",
          "fontSize": "18px",
          "fontStyle": "normal",
          "fontWeight": 600,
          "lineHeight": "24px",
          "textTransform": "capitalize"
        },
        "& span": {
          "color": "#262626",
          "fontFamily": "Montserrat",
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": 400,
          "textTransform": "capitalize",
          "lineHeight": "24px"
        }
      }
    },
    "& .nextButtonContent":{
      display: "flex",
      gap: "40px",
      "& > span ": {
        flexDirection: "column",
        order: "0",
        gap: "6px",
        display: "flex",
        textAlign: "right",
        "& label": {
          "color": "#262626",
          "fontStyle": "normal",
          "fontSize": "18px",
          "fontWeight": 600,
          "textTransform": "capitalize",
          "lineHeight": "24px",
          "fontFamily": "Montserrat"
        },
        "& span": {
          "fontFamily": "Montserrat",
          "color": "#262626",
          "fontSize": "16px",
          "fontStyle": "normal",
          "textTransform": "capitalize",
          "lineHeight": "24px",
          "fontWeight": 400
        }
      }
    },
      "& .arabicNewsFooter": {
        flexDirection: "row-reverse"
      }
    },
    prevButton:{
      padding: "0px",
      minWidth: 0,
      "&:hover":{
        backgroundColor: "#fff"
      }
    },
    nextButton:{
      padding: "0px",
      minWidth: 0,
      "&:hover":{
        backgroundColor: "#fff"
      }
    },
    imgDetailsBlock: {
        marginBottom: "44px",
        display: "block",
        "& img":{
            width: "100%",
            borderRadius: "20px",
        }
    },
    newsDatadetails:{
        marginTop: "11px",
        "color": "#000",
        "fontFamily": "Montserrat",
        "fontSize": "24px",
        "fontStyle": "normal",
        "fontWeight": 300,
        "lineHeight": "35px",
        "& p": {
            marginTop: "0px"
        }
    },
    wrapper:{
      "borderRadius": "20px",
      "border": "2px solid transparent",
      "background": "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
      "margin": "2rem auto",
      "width": "1000px",
      "boxSizing": "border-box",
    },
    detailHeader:{
      "padding" : "0px 0 60px",
    },
    header:{
      "padding" : "2rem 0",
    },
    inner_wrapper:{
      "boxSizing": "border-box",
      "padding" : "0px 24px",
      "width": "calc(100% - 7px)",
      "minHeight": "600px",
      "maxHeight": "900px",
      "height": "100%",
      "overflowY": "auto",
      "marginBottom": "16px",
      '&::-webkit-scrollbar': {
        width: '6px',
        height:'45px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#D9D9D9', // Color of the scrollbar thumb
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'darkgrey', // Color of the scrollbar thumb on hover
      }
    },
    pageTitle: {
      "color": "#5412FC",
      "textAlign": "center",
      "fontFamily": "Montserrat",
      "fontSize": "26px",
      "fontStyle": "normal",
      "fontWeight": 700,
      "lineHeight": "34px"
    },
    readMoreBtn:{
      "borderRadius": "8px",
      "border": "2px solid #10BFB8",
      "background": "#FFF",
      "color": "#10BFB8",
      "textAlign": "center",
      "fontFamily": "Montserrat",
      "fontSize": "16px",
      "fontStyle": "normal",
      "fontWeight": 700,
      "lineHeight": "26px",
      "padding": "3px 18px",
      "textTransform": "capitalize",
      "boxSizing": "border-box",
      "transition": "0.3s",
      "&:hover": {
        backgroundColor: "#fff",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
      }
    },
    NewsWrapper:{
      "display": "flex",
      "flexWrap": "wrap",
      "justifyContent": "center",
      "gap" : "24px",
      "marginBottom": "44px"
    },
    NewsBlock:{
      "flex": "0 0 calc(50% - 44px)",
      "borderRadius": "20px",
      "border": "1px solid #8C8C8C",
      "background": "#FFF",
      "padding": "24px",
      "maxWidth": "calc(50% - 42px)",
      "boxSizing": "border-box"
    },
    NewsHeading:{
      "display": "flex",
      "gap": "32px",
      "& span":{
        "color": "#000",
        "fontFamily": "Montserrat",
        "fontSize": "20px",
        "fontStyle": "normal",
        "fontWeight": 700,
        "lineHeight": "28px",
      },
    },
    imgBlock:{
      width: "160px",
      height: "160px",
      borderRadius: "20px",
      flex: "0 0 160px",
      overflow: "hidden",
      "& img":{
        width: "100%",
        height: "100%",
        objectFit: "cover"
      }
    },
    newsData:{
      "color": "#000",
      "fontFamily": "Montserrat",
      "fontSize": "16px",
      "fontStyle": "normal",
      "fontWeight": 300,
      "lineHeight": "26px",
      "display": "block",
      "margin": "24px 0px 15px"
    },
    NewsBlockFooter:{
      "display": "flex",
      "justifyContent": "end"
    },
    '@media (max-width: 1200px)': {
      wrapper:{
        "width": "90%",
      }
    },
    '@media (max-width: 1180px)': {
      detailWrapper:{
        "width": "90%",
      },
      NewsHeading:{
        "gap": "25px",
        "& span":{
          margin: "15px 0px"
        }
      },
      imgBlock:{
        width: "100px",
        height: "100px",
        flex: "0 0 100px",
        borderRadius: "14px",
      }
    },
    '@media (max-width: 992px)': {
      NewsBlock: {
        padding: "25px 30px",
        maxWidth: "calc(50% - 20px)"
      },
      NewsWrapper:{
        gap: "40px",
      },
      inner_wrapper:{
        padding: "0px 40px 60px"
      }
    },
    '@media (max-width: 767px)': {
      NewsDetailsInner:{
        width: "100%",
        paddingRight: "10px",
        gap: "20px",
        flexDirection : "column"
      },
      NewsDetailsLeft:{
        "& div":{
          columns: 1,
          gap: "40px",
          fontSize: "12px !important",
          lineHeight: "20px"
        }
      },
      NewsDetailsRight:{
        flex: "0 0 100%",
        width: "100%",
        "& p":{
          fontSize: "12px !important",
          lineHeight: "20px"
        }
      },
      wrapper:{
        "width": "90%",
        margin: "48px auto 50px",
        padding: "20px 0px"
      },
      detailWrapper:{
        margin: "68px auto 50px",
      },
      header:{
        padding: "10px 0 30px"
      },
      inner_wrapper:{
        padding: "0px 26px"
      },
      NewsWrapper:{
        flexDirection: "column",
        marginBottom: "14px",
        gap: "12px"
      },
      NewsBlock:{
        maxWidth: "100%",
        width: "100%",
        flexDirection: "column"
      },
      pageTitle: {
        fontSize: "16px"
      },
      detailHeader:{
        paddingBottom: "10px",
      },
      NewsHeading:{
        "gap": "25px",
        "& span":{
          fontSize: "12px !important",
          lineHeight: "20px"
        }
      },
      newsDatadetails:{
        fontSize:"12px",
        lineHeight: "20px"
      },
      newsData:{
        fontSize:"12px",
        lineHeight: "20px"
      },
      prevButtonContent:{
        gap: "20px",
        "& > span ":{
          gap: "0px",
          textAlign: "left",
          "& label":{
            fontSize:"12px",
            lineHeight: "20px"
          },
          "& span":{
            fontSize:"12px",
            lineHeight: "20px"
          }
        }
      },
      prevButton:{
        "&:hover":{
          backgroundColor: "#fff"
        },
        "& img":{
          width: "40px",
          height: "40px"
        }
      },
      nextButtonContent:{
        gap: "20px",
        "& > span ":{
          gap: "0px",
          textAlign: "left",
          "& label":{
            fontSize:"12px",
            lineHeight: "20px"
          },
          "& span":{
            fontSize:"12px",
            lineHeight: "20px"
          }
        }
      },
      nextButton:{
        "&:hover":{
          backgroundColor: "#fff"
        },
        "& img":{
          width: "40px",
          height: "40px"
        }
      },
      imgDetailsBlock:{
        marginRight: "40px",
        marginBottom: "22px"
      },
      readMoreBtn: {
        fontSize: "12px !important",
        lineHeight: "15px",
        padding: "3px 13px"
      }
    },
    '@media (max-width: 560px)': {
      imgDetailsBlock:{
        width: "100%",
        marginRight: "0px"
      },
    }
})