import React, { useEffect } from "react";
import { Box, AccordionSummary, Divider, AccordionDetails, Accordion } from "@material-ui/core";
import { getStorageData } from "framework/src/Utilities";



export const SelectQueryTypeStyle = () => {
    return {
        border: "1px #8C8C8C solid",
        borderRadius: "12px",
        marginTop: "32px",
        overflow: "hidden",
        width: "100%",
        "& .MuiAccordion-root": {
            boxShadow: "none",
            borderRadius: "0 !important",
            "& .MuiAccordionSummary-root": {
                minHeight: "auto",
                padding: "0",
                "& .MuiIconButton-root": {
                    padding: "0 !important",
                    margin: "0 !important",
                    "& .MuiIconButton-label": {
                        width: "28px",
                        height: "28px",
                        padding: "10px 20px",
                    },
                },
                "& .MuiAccordionSummary-content": {
                    margin: "0",
                    "& .heading": {
                        padding: "12px 24px",
                        color: "black",
                        fontSize: "20px",
                        fontFamily: "Montserrat",
                        fontWeight: 400,
                        wordWrap: "break-word",
                        "& img": { width: "24px", marginRight: "28px" },
                    },
                    "& .placeholder": {
                        padding: "12px 24px",
                        color: "#AAA",
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 300,
                        lineHheight: "20px"
                    }
                },
            },
            "& .MuiCollapse-wrapperInner": {
                margin: "0px 20px 20px",
                "& .MuiDivider-root": {
                    backgroundColor: "#8C8C8C",
                },
                "& .MuiAccordionDetails-root": {
                    padding: "8px 0 0",
                    display: "block",
                    "& *": {
                        cursor: "pointer",
                    },
                    "& .option": {
                        padding: "8px",
                        color: "black",
                        fontSize: "16px",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        lineHeight: "24px",
                        wordwrap: "break-word",
                        display: "flex",
                        alignItems: "center",
                        "&:hover": {
                            background: "rgb(140, 140, 140, 0.5)",
                            borderRadius: "20px",
                        },
                        "& img": {
                            width: "24px",
                            marginRight: "28px",
                        },
                    },
                },
            },
        },
        "& .MuiAccordion-root.Mui-disabled": {
            backgroundColor: "transparent",
        },
    };
};

interface SelectQueryTypeProps {
    selectedQueryType?: string;
    queryTypeList: { value: string; title: string; icon?: string }[];
    disabled?: boolean;
    onChange?: (value: string) => void;
    Icon: string,
    language: string
}

export const SelectQueryType: React.FC<SelectQueryTypeProps> = ({
    selectedQueryType,
    onChange,
    queryTypeList,
    disabled,
    Icon,
    language
}) => {
    const [isExpanded, setExpanded] = React.useState(false);
    const getSelectedQuery: any =
    queryTypeList.find((queryType: any) => queryType.attributes.id === selectedQueryType) ||
    {};
    

const getHeadingName = () =>{
    if(language == "en"){
        return getSelectedQuery?.attributes?.name
    }else{
        return getSelectedQuery?.attributes?.arabic_name
    }
}
    
    return (
        <>
            <Box sx={SelectQueryTypeStyle()} data-test-id="accordion">
                <Accordion
                    data-test-id="expand-accordion"
                    disabled={disabled}
                    expanded={isExpanded}
                    onClick={() => (disabled ? {} : setExpanded(!isExpanded))}
                >
                    <AccordionSummary
                        expandIcon={<img src={Icon} />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <div className={getSelectedQuery?.attributes?.name ? "heading" : 'placeholder'}>
                            {getSelectedQuery?.attributes?.name ? getHeadingName() : (language === "en" ? 'Type of query' : "نوع الاستعلام")}
                        </div>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        {queryTypeList.map((queryType: any) => (
                            <div
                                data-test-id="accordion-select-queryType-option"
                                className="option"
                                onClick={() => {
                                    onChange && onChange(queryType.attributes.id);
                                    setExpanded(false);
                                }}
                            >
                                {language === "en" ? queryType.attributes?.name :  queryType.attributes?.arabic_name}
                            </div>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};