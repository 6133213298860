import React from "react";
// Customizable Area Start
import { Typography } from "@material-ui/core";
import {
  createStyles,
  Theme as AugmentedTheme,
  withStyles
} from "@material-ui/core/styles";
import {
  forwardArrow,
  mainTutorialBg,
  mainTutorialBgMobile,
  namiLogo
} from "./assets";
import FooterBlock from "../../../components/src/FooterBlock.web";
import TutorialsController, { Props } from "./TutorialsController";
// Customizable Area End

export class Tutorials extends TutorialsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div className={classes.mainDiv}>
          <main>
            <div className={classes.firstContainer}>
              <nav className={classes.navbar}>
                <div
                  style={{
                    display: "flex",
                    gap: "119px",
                    alignItems: "center"
                  }}
                >
                  <img src={namiLogo} />
                  
                </div>
               
               
              </nav>
              <div className={classes.welcomeHeadingBox}>
                <Typography className={classes.welcomeHeading}>
                  {this.state.name}
                </Typography>
                <Typography className={classes.contentHeading}>
                  {this.state.description}
                </Typography>
              </div>
              <div className={classes.videoContainer}>
                <iframe
                  className={classes.iframeBox}
                  src={this.state.responseUrl}
                  title="YouTube video player"
                  frameBorder={"0"}
                  allowFullScreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                />
              </div>
            </div>

            <div
              data-test-id="proceed-to-button"
              className={classes.ButtonBox}
              onClick={this.navigateToProceed}
            >
              <div className={classes.ButtonBoxContent}>
                <Typography className={classes.ButtonTypography}>
                  {this.state.language === "en" ? `Proceed To Website` : "الإستمرار إلى الموقع"}
                </Typography>
                <img className={classes.arrowImge} src={forwardArrow} />
              </div>
            </div>
          </main>
          <FooterBlock />
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const customStyle: {} = (theme: AugmentedTheme) => 
  createStyles({
    firstContainer: {
      marginTop: "66px",
      paddingLeft: "65px",
      paddingRight: "65px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "42px",
        paddingLeft: "18px",
        paddingRight: "18px"
      }
    },
    mainDiv: {
      backgroundImage: "url(" + mainTutorialBg + ")",
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      
      "& *": {
        boxSizing: "border-box"
      },
      "& main": {
        maxWidth: "1440px",
        margin: "0 auto"
      },
      "@media(max-width: 650px)": {
        backgroundImage: "url(" + mainTutorialBgMobile + ")"
      },
    },
    headingTypography: {
      color: "white",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      },
      "&:hover": {
        cursor: "pointer"
      }
    },
    welcomeHeading: {
      fontWeight: 900,
      fontFamily: "Montserrat",
      fontSize: "28px",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px !important"
      }
    },
    welcomeHeadingBox: {
      marginTop: "5rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      gap: "22px",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        marginTop: "41px"
      },
      "@media(max-width: 900px)": {
        marginTop: "3rem",
        gap: "12px"
      },
    },
    contentHeading: {
      fontWeight: 500,
      fontFamily: "Montserrat",
      fontSize: "16px",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px !important"
      }
    },
    contentHeadingBox: {
      marginTop: "41px",
      display: "flex",
      justifyContent: "center",
      width: "800px",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("xs")]: {
        marginTop: "8px",
        width: "274px"
      }
    },
    videoContainer: {
      marginTop: "71px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        marginTop: "35px"
      },
      "@media(max-width: 900px)": {
        marginTop: "3rem",
      },
    },
    iframeBox: {
      width: "800px",
      height: "500px",
      borderRadius: "20px",
      "@media(max-width: 900px)": {
        width: "calc(100% - 50px) !important",
        height: "500px"
      },
      "@media(max-width: 600px)": {
        width: "calc(100% - 30px) !important",
        height: "500px"
      },
      "@media(max-width: 475px)": {
        width: "301px",
        height: "279px"
      }
    },
    ButtonBox: {
      marginTop: "63px",
      marginBottom: "63px",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        marginTop: "42px",
        marginBottom: "71px"
      },
      "&:hover": {
        cursor: "pointer"
      }
    },
    ButtonBoxContent: {
      width: "270px",
      height: "56px",
      borderRadius: "100px",
      border: "3px solid #10BFB8",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "21px",
      gap: "9px",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        height: "34px",
        gap: "34px"
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#10BFB8",
        "& p": {
          color: "white"
        }
      },
      "& *": {
        cursor: "pointer"
      }
    },
    ButtonTypography: {
      color: "#10BFB8",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px !important"
      },
      "&:hover": {
        color: "white"
      }
    },
    arrowImge: {
      width: "42px",
      height: "39px",
      [theme.breakpoints.down("xs")]: {
        width: "23px",
        height: "23px"
      }
    },
    navbar: {
      borderRadius: "20px",
      background: "rgba(217, 217, 217, 0.50)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "65px",
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "35px",
      paddingRight: "36px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "32px",
        borderRadius: "12px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "22px",
        paddingRight: "18px"
      },
      "& .desktop": {
        display: "block",
        padding: "12px 25px",
        borderRadius: "12px",
        border: "1px solid #10BFB8",
        background: "#10BFB8",
        color: "#FFF",
        fontFamily: "Montserrat,sans-serif",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: "none" as "none",
        cursor: "pointer",
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "#10BFB8"
        },
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      "& .mobile": {
        height: "19px",
        padding: "0",
        border: "0",
        background: "transparent",
        display: "none",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
          display: "block"
        }
      },
      "& img": {
        maxWidth: "100%",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          height: "24px"
        }
      }
    }
  });

export default withStyles(customStyle, {
  withTheme: true
})(Tutorials);
// Customizable Area End
