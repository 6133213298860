import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    boxTitle: string;
    boxContent: string;
    openStartUpBox: boolean;
    openBuisnessBox: boolean;
    categoriesData:any[];
    pageTextData: any;
    language: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class LoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            boxTitle: "",
            boxContent: "",
            openStartUpBox: false,
            openBuisnessBox: false,
            categoriesData: [],
            pageTextData: {},
            language: ""
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        this.getLanguage();
        this.getCategoriesDetails();
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseAPIId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.getCategoriesId === responseAPIId) {
              this.setState({categoriesData: responseJson.data})
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    createListID ="";
    getCategoriesId="";
    getLanguage = async () => {
      let lang = await getStorageData('language')
      if (lang) {
        return this.setState({ language: lang }, () => this.applyTranslation())
      }
      this.setState({ language: 'en' }, () => this.applyTranslation())
    }
  
    applyTranslation = async () => {
      
      let translatedObj = await TranslateText(configJSON.companyTypeTextData, this.state.language, 'en')
      this.setState({ pageTextData: translatedObj });
    }
    handlegoBackSubCatgories = () => {
        this.props.navigation.navigate('Categoriessubcategories')
    };

    getContent = (englishContent:string , arabicContent:string) => {
      return this.state.language === "en" ? englishContent : arabicContent;
    }

    handleStartUpBox = () => {
        const content = this.getContent(this.state.pageTextData.startUpDesc,this.state.pageTextData.startUpDescArabic);
        const title = this.getContent(this.state.pageTextData.startUp,this.state.pageTextData.staetUpArabic);
        this.setState({ openBuisnessBox: false });
        this.setState({ openStartUpBox: true });
        this.setState({ boxTitle: title });
        this.setState({ boxContent: content });
    };

    handleBuisnessBox = () => {
        const content = this.getContent(this.state.pageTextData.businessDesc,this.state.pageTextData.businessDescArabic);
        const title = this.getContent(this.state.pageTextData.business,this.state.pageTextData.businessArabic);
        this.setState({ openBuisnessBox: true });
        this.setState({ openStartUpBox: false });
        this.setState({ boxTitle: title });
        this.setState({ boxContent: content });
    };

    handleCompanyTypeSelection = async() => {
        let select = undefined;
        if (this.state.openBuisnessBox) {
            setStorageData('companyType', 'business')
            select = this.getCategoryID("SME");
        }
        if (this.state.openStartUpBox) {
            setStorageData('companyType', 'startup')
            select = this.getCategoryID("start Up")
        }
        const token = await getStorageData("token");
        const httpBody ={
            category_id: select
        }
        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: token
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.createListID = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createListAPIEndPoint
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        this.props.navigation.navigate('SellerProfile')
    }

    getCategoriesDetails = async() => {
        
        const token = await getStorageData("token");
        const header = {
            "Content-Type": configJSON.categoryApiContentType,
            token: token
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getCategoriesId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCategoriesEndPoint
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }

    getCategoryID = (categoryName:string) => {
        const category = this.state.categoriesData.find(item => item.attributes.name.toLowerCase() === categoryName.toLowerCase());
        return category ? category.attributes.id : null;
    }
    // Customizable Area End

}