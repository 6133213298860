// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/styles";

import PrivacySettingsController, {
  Props,
  configJSON,
} from "./PrivacySettingsController";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
// Customizable Area End

export class PrivacyPolicy extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        <div className={classes.mainBox} data-test-id="main-privacy-policy">
          <div className={classes.privacyPolicyText}>
            {this.state.privacyPolicyData.title}
          </div>
          <div className={classes.privacyPolicyBox}>
            <div className={classes.privacyPolicyContentBox}>
              <div
                className={classes.privacyPolicyContent}
                dangerouslySetInnerHTML={{
                  __html: this.state.privacyPolicyData.description,
                }}
              />
            </div>
          </div>
          <button
            data-test-id="close-button"
            className={classes.privacyPolicyButton}
            onClick={this.navigateToCategory}
          >
            {this.state.lang==="en"? configJSON.close:configJSON.closeTextArabic}
          </button>
        </div>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  mainBox: {
    maxWidth: "1000px",
    // height: "1335px",
    borderRadius: "20px",
    border: "2px transparent solid",
    margin: "2rem auto",
    padding: "28px 18px",
    display: "grid",
    justifyItems: "center",
    background:
      "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
    "@media (max-width:600px)": {
      borderRadius: "10px",
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  privacyPolicyText: {
    textAlign: "center",
    color: "#5412FC",
    fontSize: "28px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    lineHeight: "34px",
    wordWrap: "break-word",
    "@media (max-width:600px)": {
      fontSize: "16px",
    },
  },
  privacyPolicyBox: {
    width: "calc(100% - 100px)",
    background: "white",
    borderRadius: "20px",
    border: "1px #8C8C8C solid",
    margin: "28px auto",
    padding: "28px 0",
    "@media (max-width:600px)": {
      border: "1px #8C8C8C solid",
      margin: "14px auto 32px",
      padding: "20px 0 30px",
      width: "calc(100% - 0px)",
    },
  },
  privacyPolicyContentBox: {
    height: "600px",
    padding: "0 72px 0 28px",
    marginRight: "7px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D9D9D9",
      borderRadius: "4px",
    },
    "@media (max-width:600px)": {
      padding: "0 20px",
    },
  },
  privacyPolicyContent: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "34px",
    wordWrap: "break-word",
    "@media (max-width:600px)": {
      fontSize: "10px",
      lineHeight: "15px",
    },
  },
  privacyPolicyButton: {
    width: "200px",
    height: "48px",
    background: "#10BFB8",
    borderRadius: "12px",
    border: "2px #10BFB8 solid",
    textAlign: "center",
    color: "white",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    lineHeight: "34px",
    wordWrap: "break-word",
    cursor: "pointer",
    "transition": "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "#10BFB8",
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    "@media (max-width:600px)": {
      width: "106px",
      height: "26px",
      borderRadius: "5px",
      fontSize: "12px",
      lineHeight: "22px",
    },
  },
};

export default withStyles(styles)(PrivacyPolicy);
// Customizable Area End
