export const UKFlag = require('./assets/emojione-v1_flag-for-united-kingdom.svg');
export const arrowDownIcon = require('./assets/ep_arrow-up.svg');
export const profilePic = require('./assets/profile-pic.png');
export const hamburgerIcon = require('./assets/hamburger-menu.svg');
export const arrowIcon=require("./assets/arrow_icon.svg");
export const alertIcon=require("./assets/alert.svg");
export const crossIcon=require("./assets/cross.svg");
export const arabicLogo = require("./assets/arabiclogo.svg");
export const arabicLogoBlacl = require("./assets/arabic_black.svg");
export const arabicLang = require("./assets/arabic.svg")
export const userDefaultImage = require("./assets/userDefault.jpg");
