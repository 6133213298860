import React from 'react'

// Customizable Area Start
import {
    Box, Typography, Divider, Button, IconButton,
    Input,CircularProgress
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { conditionSort, formatNumber } from '../../../components/src/Helper';
import { backgroundArrow1, backArrow, foldedArrow } from './assets'
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { getStorageData } from '../../../framework/src/Utilities';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

import CustomFormTermController, {
    Props,
    configJSON
} from "./CustomFormTermController";

export class CustomSignatureForm extends CustomFormTermController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const type=await getStorageData('typeOfUser');
        this.getUserProfile();
        type==="seller"?  this.getListings(): this.getDealsRequest();
        this.getContent(this.props.id);
        
    }

    GenInput = (label: string, inputName: string, formikProps: any, maxlength: string = '') => {
        const { classes } = this.props;
        
        const handleNumChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const rawValue = event.target.value.replace(/,/g, '');
            if (!isNaN(Number(rawValue)) || rawValue === '') {
                const formattedValue = formatNumber(rawValue);
                formikProps.setFieldValue(inputName, formattedValue);
            }
        }
        return (
            <Box className={classes.fieldContainer}>
                <Typography className={classes.fieldLabel}>
                    {label}
                </Typography>
                {conditionSort(inputName == 'revised_asking_price', <div className={classes.inputContainer}>
                    {conditionSort( formikProps.values[inputName], <span className={conditionSort(this.state.language == 'ar', classes.staticTextAr, classes.staticText)}>{conditionSort(this.state.language == 'ar', "ر.س", 'SAR')}</span>, null)}

                    <Input data-test-id={`testID${inputName}`} name={inputName}
                        inputProps={{ maxlength }}
                        value={formikProps.values[inputName]}
                        onChange={conditionSort(inputName == 'revised_asking_price', handleNumChange, formikProps.handleChange)}
                        className={classes.fieldInput}
                        style={conditionSort(this.state.language == 'ar', {
                            paddingRight: "45px",
                        }, {
                            paddingLeft: "40px",
                        })}
                        disableUnderline
                    />
                </div>, <Input data-test-id={`testID${inputName}`} name={inputName}
                    inputProps={{ maxlength }}
                    value={formikProps.values[inputName]}
                    onChange={conditionSort(inputName == 'revised_asking_price', handleNumChange, formikProps.handleChange)}
                    className={classes.fieldInput}
                    disableUnderline
                />
                )}

                {formikProps.errors[inputName] && formikProps.touched[inputName] && <Typography className={classes.errorContainer}>
                    {formikProps.errors[inputName]}
                </Typography>}
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, id } = this.props;
        const { userData } = this.state;
        const heading = this.getIfCondition(configJSON[`${id}Header`], configJSON[`${id}HeaderAr`]);
        const subHeader = this.getIfElseCondition(this.state.language === "en", configJSON[`${id}TermsHeader`], configJSON[`${id}TermsHeaderAr`]);
        const pdfData = {userDate:this.state.userDate,sellerName:this.state.sellerName,
        sellerAmount:this.state.sellerAmount,buyerName:this.state.buyerName,buyerAddress:this.state.buyerAddress,amountSpecificPercentage:this.state.amountSpecificPercentage,companyName:this.state.companyName,jurisdiction:this.state.jurisdiction, sellerAddress: this.state.sellerAddress}
        const {pageText} = this.state;

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <HeaderBlock />

                <Box className={classes.backGroundContain}>
                    <Box className={classes.container}>

                        <Box className={classes.backArrowCont}>
                            <IconButton onClick={this.handleGoBack} style={{ padding: "0" }}>
                            <img src={backArrow} style={this.state.language === "ar" ? {transform: "rotate(180deg)"} : {transform: "rotate(360deg)"}} />
                            </IconButton>
                            <Typography className={classes.backArrowTxt}>{pageText.back}</Typography>
                        </Box>

                        <Box className={classes.headerCont}>
                            <Typography className={classes.headerTxt} data-test-id="spaTextTestId">
                                {heading}
                            </Typography>
                        </Box>

                        <Formik
                            data-test-id="formComp"
                            enableReinitialize
                            validateOnBlur={false}
                            initialValues={{
                                firstName: userData.attributes.first_name,
                                lastName: userData.attributes.last_name,
                                role: userData.attributes.role?.user_roles,
                                company_name: this.state.companyName,
                                revised_asking_price: this.state.sellerAmount.replace("/-", "")
                            }}
                            validationSchema={Yup.object().shape({
                                firstName: Yup.string().required(this.getIfElseCondition(this.state.language === 'en', 'First Name is required', 'الإسم الأول مطلوب')),
                                lastName: Yup.string().required(this.getIfElseCondition(this.state.language === 'en', 'Last Name is required', 'اسم العائلة مطلوب')),
                                role: Yup.string().required(this.getIfElseCondition(this.state.language === 'en', 'Role is required', 'الدور مطلوب')),
                                company_name:  conditionSort(this.props.id == 'AutoSign', Yup.string(), Yup.string().required(this.getIfElseCondition(this.state.language === 'en', 'Company name is required', 'اسم الشركة مطلوب'))),
                                revised_asking_price: conditionSort(this.props.id == 'AutoSign', Yup.string(), Yup.string().required(this.getIfElseCondition(this.state.language === 'en', 'Revised Asking Price is required', 'السعر مطلوب')))
                            })}
                            onSubmit={(values) => {
                                this.handleRevisedPrice(values.revised_asking_price)
                                values.revised_asking_price = this.handleEndSign(values.revised_asking_price)
                                let htmlStr = PopulateValuePdf(values, subHeader, this.state.description, this.props.id, this.state.language, pdfData);
                                this.generatePdf(htmlStr,this.props.id)
                            }}
                        >
                            {({ values, handleChange, handleSubmit, errors, touched, resetForm, setFieldValue }) => {
                            
                                return (
                                    <form
                                        onSubmit={handleSubmit}
                                    >
                                        <Box className={classes.mainBoxCont}>
                                            <Typography className={classes.signTitleTxt}>{pageText.signature}</Typography>

                                            <Box className={classes.signContainer}>
                                                <Typography className={classes.genSignTxt}>
                                                    {values.firstName} {values.lastName}
                                                </Typography>
                                                <Divider className={classes.dividerRage} />
                                            </Box>

                                            <Typography className={classes.signingAsTxt}>{pageText.signingAs}</Typography>

                                            <Box className={classes.twoLayoutField}>
                                                {this.GenInput(pageText.fName, "firstName", { values, handleChange, errors, touched, setFieldValue }, '12')}
                                                {this.GenInput(pageText.lName, "lastName", { values, handleChange, errors, touched, setFieldValue }, '12')}
                                            </Box>
                                            <Box className={classes.twoLayoutField}>
                                            {id !== "AutoSign" && this.GenInput(pageText.companyName, "company_name", { values, handleChange, errors, touched, setFieldValue })}
                                            {this.GenInput(pageText.role, "role", { values, handleChange, errors, touched, setFieldValue })}
                                            </Box>
                                            {id !== "AutoSign" && <>
                                                {this.GenInput(pageText.revisedPrice, "revised_asking_price", { values, handleChange, errors, touched, setFieldValue })}

                                                <Typography style={{ marginTop: "5px" }} className={classes.fieldLabel}>
                                                    {pageText.intialPrice}{formatNumber(this.state.askingPriceData)} 
                                                </Typography>
                                            </>}


                                        </Box>

                                        <Box className={classes.actionBtn1}>
                                            <Button data-test-id="formSubmitBtn" type='submit' disabled={this.state.isLoading}
                                            startIcon={this.state.isLoading && <CircularProgress size={20} />}
                                              className={classes.saveBtn}>{pageText.save}</Button>
                                            <Button data-test-id="clearForm" onClick={() => {
                                                resetForm()
                                                this.resetRole();
                                            }} className={classes.clearBtn}>{pageText.clear}</Button>
                                        </Box>
                                    </form>
                                )
                            }}
                        </Formik>

                    </Box>
                </Box>

                <FooterBlock />
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export const webStyles = createStyles({
    backGroundContain: {
        backgroundImage: `url(${backgroundArrow1})`,
        backgroundSize: 'cover',
        backgroundPositionX: 'center'
    },
    container: {
        padding: "10px 60px",
        boxSizing: "border-box",
        "@media (max-width:450px)":{
            padding: "10px 30px",
          },
    },
    backArrowCont: {
        display: "flex",
        gap: "20px",
        alignItems: "center"
    },
    backArrowTxt: {
        fontSize: "20px",
        fontWeight: 700
    },
    headerCont: {
        marginTop: "5px"
    },
    headerTxt: {
        fontSize: "20px",
        fontWeight: 700
    },
    mainBoxCont: {
        marginTop: "20px",
        width: "760px",
        "border": "3px solid transparent",
        "margin": "0px auto",
        "padding": "5px 20px 5px 20px",
        "background": "linear-gradient(white, white) padding-box padding-box, linear-gradient(135deg, rgb(0, 248, 162), rgb(84, 18, 252)) border-box border-box",
        "boxSizing": "border-box",
        "borderRadius": "20px",
        "@media (max-width:916px)":{
            width:"700px",
          },
          "@media (max-width:850px)":{
            width:"650px",
          },
          "@media (max-width:790px)":{
            width:"550px",
          },
          "@media (max-width:670px)":{
            width:"450px",
          },
          "@media (max-width:560px)":{
            width:"350px",
          },
          "@media (max-width:400px)":{
            width:"250px",
          },
    },
    signTitleTxt: {
        fontSize: "20px",
        fontWeight: 500
    },
    signingAsTxt: {
        fontSize: "20px",
        fontWeight: 500,
        marginTop: "10px"
    },
    signContainer: {
        width: "100%",
        marginTop: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        "boxSizing": "border-box",
        padding: "10px",
        backgroundColor: "rgba(140, 140, 140, 0.2)",
        height: "55px",
        "borderRadius": "20px",
        "backgroundImage": "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='rgb(140, 140, 140)' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e\")",
    },
    genSignTxt: {
        fontSize: "25px",
        fontFamily: "Seaweed Script"
    },
    dividerRage: {
        width: "100%",
        height: "1px",
        background: "rgb(140, 140, 140)"
    },
    fieldLabel: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#8C8C8C"
    },
    fieldInput: {
        width: "100%",
        height: "45px",
        borderRadius: "12px",
        border: "1px solid #8C8C8C",
        boxSizing: "border-box",
        fontSize: "16px",
        padding: "5px 10px"
    },
    fieldContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "10px",
        marginTop: "10px"
    },
    twoLayoutField: {
        display: "flex",
        gap: "80px",
        "@media (max-width:670px)":{
            display:"block"
          },
    },
    actionBtn1: {
        "margin": "10px auto",
        padding: "0 40px",
        boxSizing: "border-box",
        width: "760px",
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width:916px)":{
            width:"700px",
          },
          "@media (max-width:850px)":{
            width:"650px",
          },
          "@media (max-width:790px)":{
            width:"550px",
          },
          "@media (max-width:670px)":{
            width:"450px",
            flexDirection: "column",
            alignItems: "center",
            gap:"10px"
            
          },
          "@media (max-width:560px)":{
            width:"350px",
          },
          "@media (max-width:400px)":{
            width:"250px",
          },
    },
    actionBtn: {
        "margin": "50px auto",
        padding: "0 40px",
        boxSizing: "border-box",
        width: "760px",
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width:916px)":{
            width:"700px",
          },
          "@media (max-width:850px)":{
            width:"650px",
          },
          "@media (max-width:790px)":{
            width:"550px",
          },
          "@media (max-width:670px)":{
            width:"450px",
            flexDirection: "column",
            alignItems: "center",
            gap:"10px"
            
          },
          "@media (max-width:560px)":{
            width:"350px",
          },
          "@media (max-width:400px)":{
            width:"250px",
          },
    },
    saveBtn: {
        width: "210px",
        height: "40px",
        border: "2px solid #10BFB8",
        background: "#fff",
        borderRadius: "12px",
        fontSize: "24px",
        fontWeight: 700,
        color: "#10BFB8",
        textTransform: "none"
    },
    clearBtn: {
        width: "210px",
        textTransform: "none",
        height: "40px",
        border: "2px solid #FF7575",
        background: "#fff",
        borderRadius: "12px",
        fontSize: "24px",
        fontWeight: 700,
        color: "#FF7575"
    },
    errorContainer: {
        "color": "#FF7575",
        "padding": "4px 15px",
        "fontSize": "12px",
        "fontStyle": "normal",
        "marginTop": "5px",
        "textAlign": "center",
        "fontFamily": "Montserrat",
        "fontWeight": 700,
        "borderRadius": "12px",
        "backgroundColor": "rgba(255, 117, 117, 0.30)"
    },
    paraHeader: {
        fontSize: "24px",
        fontWeight: 700
    },
    paraContainer: {
        marginTop: "10px",
        width: "90%"
    },
    paraTxt: {
        fontSize: "24px",
        fontWeight: 500,
        textAlign: "justify"
    },
    customInputField:{
        margin : "0 5px",
    },
    inputContainer: {
        position: "relative",
        display: "inline-block",
      },
      staticText: {
        position: "absolute",
        top: "50%",
        left: "14px",
        fontFamily:"Montserrat",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        color: "black",
        fontSize: "14px",
      },
      inputField: {
        paddingLeft: "40px",
        fontSize: "14px",
        margin : "0 5px",
      },
      staticTextAr: {
        position: "absolute",
        fontFamily:"Montserrat",
        top: "50%",
        right: "14px",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        color: "black",
        fontSize: "14px",
      },
      inputFieldAr: {
        paddingRight: "45px",
        fontSize: "14px",
        margin : "0 5px",
      },
    scrollBar: {
        maxHeight: "600px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "10px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: 'transparent',
            '-webkit-border-radius': '10px',
            'borderRadius': '10px',
            margin: '10px'
        },
        "&::-webkit-scrollbar-thumb": {
            '-webkit-border-radius': "10px",
            borderRadius: '10px',
            background: " #D9D9D9"
        }
    },
  buttonDiv :{
    display:"flex", 
    justifyContent:'space-between',
    width:500,
    "@media (max-width:768px)":{
      width:300,
    }
  },
  buttonStylesYesNo:{
    width:'200px',
     height:'40px',
     background:'white', 
     fontSize:'20px', 
     lineHeight:'24px', 
     fontWeight:500,
     fontFamily:"Montserrat",
     "@media (max-width:768px)":{
      width:'200px',
      fontSize:"16px",
      lineHeight:"20px",
      height:"40px"
    }
  },
  terminateAccess:{
    "& .MuiDialog-paper": {
      maxWidth: "700px",
      height: "512px",
      boxShadow: "none",
      position: "relative",
      padding: "0 60px",
      borderRadius: "20px",
      "@media (max-width:768px)":{
        width:'300px',
        padding:"0 40px"
      }
    },
  },
  learnMoreDialogContent: {
    display: "grid",
    justifyItems: "center",
    alignContent: "center",
    height: "100%",
  },
  learnMoreDialogTitle: {
    color: "black",
    fontSize: "28px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    margin: "20px 0 0px",
    textAlign: "center",
  },
  areYouSure:{
    color:'black',
    margin:0,
    fontSize:'28px',
    textAlign:'center',
    fontWeight:700,
    fontFamily: "Montserrat",
  },
  learnMoreDialogDescription: {
    color: "black",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "40px",
    textAlign: "center",
    "@media (max-width:768px)":{
      fontSize:'18px',
      lineHeight:'24px'
    }
  },
  learnMoreDialogCloseButton: {
    position: "absolute",
    top: 52,
    right: 52,
    background: "transparent",
    border: "none",
    padding: 0,
  },
  buttonStyles:{
    textDecoration:'none',
  }

})

export default withStyles(webStyles)(CustomSignatureForm);

const PopulateValuePdf = (data: any,heading:string,description:string,id:string,language:string,pdfData:{userDate:string,sellerName:string,
    sellerAddress:string,buyerName:string,buyerAddress:string,amountSpecificPercentage:string,companyName:string,jurisdiction:string,sellerAmount:string}) => {
let revisedAskingPrice = "";
if(id !="AutoSign"){
    revisedAskingPrice = 
    `<tr>
    <td>Revised Asking Price:</td>
    <td id="revisedAskingPrice">SAR ${data.revised_asking_price}</td>
    </tr>`
}
let spaContent ="";
if(id === "SalePurchaseAgreement"){
    if(language === "en"){
        spaContent =` <div>
                                
        <p>
        <span style={{fontWeight:700}}>THIS SALE PURCHASE AGREEMENT</span>
        (the "Agreement") is made as of
        ${pdfData.userDate},
        by and between
        ${pdfData.sellerName}
        with a principal place of business at
        ${pdfData.sellerAddress}
        (the "Seller"), and
        ${pdfData.buyerName}
        with a principal place of business at
            ${pdfData.buyerAddress}
        (the "Buyer").
        </p>
    </div>
    <div>
                             
    <p>
    <span style={{fontWeight:700}}>RECITALS: </span>
        WHEREAS, the Seller owns
        ${pdfData.amountSpecificPercentage}
        of the shares/assets of
        ${pdfData.companyName},
        a
        ${pdfData.jurisdiction}
        corporation (the "Company").
        WHEREAS, the Seller desires to sell and the Buyer desires to purchase
        ${pdfData.sellerAmount}
        of the shares of the Company/the assets of the Company, under the terms and conditions set forth in this Agreement.
    </p>
    </div>`
    }
}
return(`<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Seaweed+Script&display=swap" rel="stylesheet">
    <title>PDF Layout</title>
    <style>
        /* Add A4 page size */
        @page {
            size: A4;
            margin: 0;
        }

        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            
        }

        .container {
            width: 80%;
            margin: 20px auto;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        .header {
            text-align: center;
            margin-bottom: 20px;
        }

        .logo {
            max-width: 200px;
        }

        .date {
            float: right;
            margin-bottom:5px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
        }

        th,
        td {
            padding: 10px;
            border-bottom: 1px solid #ddd;
        }

        th {
            text-align: left;
            background-color: #f2f2f2;
        }

        .signature-section {
            margin-top: 50px;
        }

        .signature {
            display: flex;
            padding: 0 20px;
            width: fit-content;
            align-items: center;
            justify-content: center;
            height: 50px;
            font-family: "Seaweed Script";
            border: 1px solid #000;
            margin-top:10px;
        }
        .description{
            width: 100%;
            border: 2px solid #10BFB8;
            padding: 10px;
            background-color: #fff;
            
        }
    </style>
</head>

<body>
    <div class="container">
        <div class="header">
            <img width="50" src="${foldedArrow}" alt="Company Logo" class="logo">
        </div>
        <div class="header">
            <h3>${heading}</h3>
        </div>
        ${spaContent}
        <div class="description">
            ${description}
        </div>
        <div class="date">
            Date: <span id="currentDate">${new Date().toLocaleDateString()}</span>
        </div>
        <table>
            <tr>
                <th>Field</th>
                <th>Value</th>
            </tr>
            <tr>
                <td>First Name:</td>
                <td id="firstName">${data.firstName}</td>
            </tr>
            <tr>
                <td>Last Name:</td>
                <td id="lastName">${data.lastName}</td>
            </tr>
            <tr>
            <td>Company Name:</td>
            <td id="companyName">${data.company_name}</td>
        </tr>
            <tr>
                <td>Role:</td>
                <td id="role">${data.role}</td>
            </tr>
           ${revisedAskingPrice}
        </table>
        <div class="signature-section">
            <div>
                Authorized Signature:
                <div class="signature" id="signature">
                    ${data.firstName} ${data.lastName}
                </div>
            </div>
        </div>
    </div>
</body>

</html>
`)}
// Customizable Area End