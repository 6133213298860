import React from "react"
import {Box,Button,Typography, styled, withStyles } from "@material-ui/core";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
const images = require("./assets");
import { Styles } from "../../contentmanagement/src/Styles.web";
import { leftArrow, logoIcon, rightArrow, nameLogoArabic, quote } from "./assets";
import Settings2Controller from "./Settings2Controller";
//Customizable Area Start
import { conditionSort, getHtml } from "../../../components/src/Helper";
//Customizable Area End

export class ResourceDetails extends Settings2Controller {
  
  //Customizable Area Start
  //Customizable Area End


  render() {
    //Customizable Area Start
    const { classes } = this.props;

    //Customizable Area End
    return (
    <>
    <HeaderBlock/>

    
    {/* Customizable Area Start */}
    <Box className={classes.detailWrapper} >
      <Box className={classes.detailHeader}>
        <Typography data-testid="h1" component="h1" className={classes.pageTitle}>{conditionSort(this.state.language == 'ar', this.state.resoucesData.arabic_title, this.state.resoucesData.title)}</Typography>
        <Typography component="h3" className={classes.pageTitle} style={{fontSize: "16px"}}>{conditionSort(this.state.language == 'ar', this.state.resoucesData.arabic_sub_title, this.state.resoucesData.sub_title)}</Typography>
      </Box>
        <Box className={classes.NewsBlockDetails}>
            <Box className={classes.NewsDetailsInner}>
                <Box className={classes.NewsDetailsLeft}>
                    <div>
                    <span className={classes.imgDetailsBlock}>
                        <img src={this.state.resoucesData.image?.url} alt={this.state.resoucesData.title} className={classes.newsThumb} />
                    </span>
                    {getHtml(conditionSort(this.state.language == 'ar', this.state.resoucesData.arabic_details, this.state.resoucesData.content))}</div>  
                </Box>
            </Box>

            <Box className={classes.newsFooterTop}>
                        <div style={{
                            "display": "inline-flex",
                            "justifyContent": "center",
                            "alignItems": "center",
                            "gap": "8px"
                        }}>
                <img src={quote} /><img style={{height: "26px"}} src={this.state.language == 'ar' ? nameLogoArabic : logoIcon} />
                </div>
            </Box>
            
                    <FooterBox className={classes.newsFooterAction}>
                        <Box className={conditionSort(this.state.language == "en", `${classes.prevButtonContent} btnBox`, `${classes.prevButtonContent} btnBox arPrevBtnBox`)}>
                    <Button data-testid="previous" className={classes.prevButton} disabled={!this.state.resoucesData.next_previous_ids.previous} 
                    style={{}} onClick={() => this.navigateTo(this.state.resoucesData.next_previous_ids.previous)}>
                        <img src={leftArrow} alt="" />
                    </Button>
                            <span className={`${classes.labelContaint} btnText`}>
                        <label>{this.state.detailsText.previous}</label>
                        <span>{this.state.detailsText.resource}</span>
                    </span>
                </Box>
                        <Box className={conditionSort(this.state.language == "en", `${classes.nextButtonContent} btnBox`, `${classes.nextButtonContent} btnBox arBtnBox`)}>
                            <span className={`${classes.labelContaint} btnText`}>
                        <label>{this.state.detailsText.upNext}</label>
                        <span>{this.state.detailsText.resource}</span>
                    </span>
                    <Button data-testid="next" className={classes.nextButton} disabled={!this.state.resoucesData.next_previous_ids.next}
                     style={{}} onClick={() => this.navigateTo(this.state.resoucesData.next_previous_ids.next)}>
                        <img src={rightArrow} alt="" /></Button>
                </Box>
                    </FooterBox>
        </Box>
    </Box>
    {/* Customizable Area End */}

    <FooterBlock/></>
    );
    
  }
}

export default withStyles(Styles)(ResourceDetails);

const FooterBox = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "& .btnBox": {
        display: "flex",
        gap: "40px",
        alignItems: "center"
    },
    "& .arBtnBox": {
        flexDirection: "row-reverse",
        order: 1
    },
    "& .arPrevBtnBox": {
        flexDirection: "row-reverse",
        order: 2
    },
    "& .btnText": {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        "& label, span": {
            color: "#262626",
            fontSize: "20px",
            fontStyle: "normal",
            fontFamily: "Montserrat",
            fontWeight: 600,
            lineHeight: "24px",
            textTransform: "capitalize"
        },
        "&  span": {
            fontWeight: 400
        }
    }
});