// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/styles";

import FeedbackController, { FeedbackObj, Props } from "./FeedbackController.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { Divider } from "@material-ui/core";
import { addIcon } from "./assets";
import { conditionSort } from "../../../components/src/Helper";
// Customizable Area End

export class Feedback extends FeedbackController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        <div className={classes.mainComponent} data-test-id="main-feedback">
          <Divider orientation="vertical" />
          <div className={classes.rightComponent}>
            <div className={classes.rightHeadingDiv} style={conditionSort(this.state.lauguageSelect == 'en', {paddingRight: "42px"}, {paddingLeft: "42px"})}>
              <div className={classes.feedbackTitle}  data-test-id="feedback-title">{this.state.lauguageSelect==="ar"?"تعليق":"Feedback"}</div>
              {conditionSort(this.state.feedbackGiven, <button
                data-test-id="add-feedback-button"
                className={classes.addYourFeedbackButton}
                onClick={() => this.navigateTo("AddFeedback")}
              >
                <img src={addIcon} />
                {this.state.lauguageSelect === "ar" ? "أضف ملاحظاتك" : "Add Your Feedback"}
              </button>, <></>)}
            </div>
            <div className={classes.feedbackListDiv} style={conditionSort(this.state.lauguageSelect == 'en', {paddingRight: "32px"}, {paddingLeft: "32px"})} data-test-id="list">
              {this.state.feedbackList.map((feedbackObj: FeedbackObj) => (
                <div key={feedbackObj.id} className={classes.feedbackList}>
    
                  <div className={classes.feedbackImage} style={feedbackObj.attributes.account?.image ? {}: {background: "#D9D9D9"}}><img className={classes.feedbackImage} src={feedbackObj.attributes.account.image?.image} /></div>
                  <div className={classes.feedbackText}>
                    {feedbackObj.attributes.user_feedback}
                  </div>
                </div>
              ))}
              <div></div>
            </div>
          </div>
        </div>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  mainComponent: {
    padding: "30px 0px",
    display: "flex",
    height: "auto",
    "& .MuiDivider-root": {
      backgroundColor: "#8C8C8C",
    },
  },
  leftComponent: {
    padding: "30px 60px",
    "& .left-menu-button": {
      border: "none",
      background: "transparent",
      display: "flex",
      alignItems: "center",
      gap: "125px",
      margin: "60px 0",
      color: "rgba(38, 38, 38, 0.80)",
      fontSize: "24px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "24px",
      width: "252px",
      "&.selected": {
        margin: "52px 0",
        color: "#5412FC",
      },
    },
    "@media (max-width:959px)": {
      "& .left-menu-button": {
        gap: "60px",
        width: "187px",
      },
    },
  },
  rightComponent: {
    padding: "0px 82px 0px 120px",
    width: "100%",
    "@media (max-width:959px)": {
      padding: "0px 42px 0px 60px"
    },
  },
  rightHeadingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    marginBottom: "25px",
    "@media (max-width:959px)": {
      display: "grid",
      justifyContent: "normal",
      marginBottom: "15px"
    },
  },
  feedbackTitle: {
    color: "#5412FC",
    fontSize: "30px",
    fontFamily: "Montserrat",
    fontWeight: 700,
  },
  addYourFeedbackButton: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "4px 24px",
    background: "white",
    borderRadius: "12px",
    border: "2px #10BFB8 solid",
    color: "#10BFB8",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "24px",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  feedbackListDiv: {
    display: "grid",
    alignContent: "start",
    gap: "40px",
    height: "500px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D9D9D9",
      borderRadius: "4px",
    },
    "@media (max-width:959px)": {
      height: "450px"
    },
  },
  feedbackList: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    padding: "10px",
    borderRadius: "20px",
    border: "2px #8C8C8C solid",
    height: "fit-content",
    "@media (max-width:959px)": {
      display: "grid",
      justifyItems: "center",
    },
  },
  feedbackImage: {
    borderRadius: "20px",
    width: "100px",
    height: "100px",
  },
  feedbackText: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "24px",
    "@media (max-width:959px)": {
      textAlign: "center",
    },
  }
};

export default withStyles(styles)(Feedback);
// Customizable Area End
