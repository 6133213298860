export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgRightArrow = require("../assets/arrow_right.svg");
export const imgChangeEmail = require("../assets/change_email.svg");
export const imgChangePhone = require("../assets/change_phone.svg");
export const imgChangePassword = require("../assets/change_password.svg");
export const imgCustomerHelp = require("../assets/customer_help.svg");
export const imgDeactivateAccount = require("../assets/deactivate_account.svg");
export const imgSuccessIcon = require("../assets/Success_icon.svg");
export const imgSuccessIcon2 = require("../assets/Success_icon_blue.svg");
export const imgAlertIcon = require("../assets/alert-icon.svg");
export const leftArrow=require('../assets/ei_arrow_left.svg');
export const rightArrow=require('../assets/ei_arrow_up.svg');
export const logoIcon=require('../assets/logo.svg');
export const nameLogoArabic = require('../assets/useNameLogoarabic.png');
export const quote = require('../assets/quote.svg')
