//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
const CategoryData = [
  {
    idss: 0,
    title: "User 1",
    value: 'user_1'
  },
  {
    idss: 1,
    title: "User 2",
    value: 'user_2'

  },
];

interface Imagetype {
  uris: string;
  width: number;
  height: number;
}
interface CategoryType {
  idss: number;
  title: string;
  value: string;
}

interface ProductItem  {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  catagorydata: CategoryType[];
  result:any;
  filterResult:any;
  text:string;
  showCategory: boolean;
  category: CategoryType;
  showModel: boolean;
  images: Imagetype[];
  visibleItem: boolean;
  imagesWeb: File[];
  showNoResult: boolean;
  baseImages: string[];
  title: string;
  description: string;
  price: string;
  quantity: string;
  userDataList:ProductItem[]
  lang:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      catagorydata: CategoryData,
      showCategory: false,
      category: {
        idss: 0,
        title: "User 1",
      value:'user_1'
      },
      showModel: false,
      images: [],
      imagesWeb:[],
      baseImages: [],
      visibleItem:true,
      title: "",
      description: "",
      result:[],
      showNoResult: false,
      filterResult:[],
      text:"",
      price: "",
      quantity: "",
      userDataList:[],
      lang:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(this.getAllFaqBack===apiRequestCallId){
        if(responseJson && responseJson.data){
        
          const translationPromises = responseJson.data.map(async(item:ResponseType) => {
            const quesAnswerData={
              question: this.getSpecifiedLangString(this.state.lang, item.attributes.question, item.attributes.arabic_question),
              answer: this.getSpecifiedLangString(this.state.lang, item.attributes.answer, item.attributes.arabic_answer)
            }
           return quesAnswerData

          });
          const record = await Promise.all(translationPromises);
          this.setState({result:[...record]})
        }
      }

    
    // Customizable Area End
  }

  // Customizable Area Start

async componentDidMount() {
  this.getUserDataListApi()
  this.getLang();
  this.getFaq()
}

getLang = async () => {
  let lang = await getStorageData('language')
  if (lang) {
    return   this.setState({ lang: lang })
  }
  this.setState({ lang: 'en' })

}

getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
  if(lang ==="en"){
    return stringOne
  }else{
    return stringTwo
  }
}

  getUserDataListApi = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDataListCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl+this.state.category.value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFaq = async () => {

    const headers = {
      "content-type": "application/json"
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FaqApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.getMethod}`
    );

    this.getAllFaqBack= requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChange=(e)=>{
    const { value } = e.target;
    
    const filteredResult = this.state.result.filter((item) =>
      item.question.toLowerCase().includes(value.toLowerCase())
    );
    
    this.setState({
      text: value,
      filterResult: value.length === 0 ? this.state.result : filteredResult,
      showNoResult: filteredResult.length <= 0 && value.length > 0
    });
  
  }
  postAddDataApi=async()=>{
    const header = {
    };
    const formData = new FormData();
    formData.append("title", this.state.title)
    formData.append("description", this.state.description)
    formData.append("price", this.state.price);
    formData.append("quantity", this.state.quantity)
    formData.append("user_type", this.state.category.value)

    if(this.isPlatformWeb()){
      this.state.imagesWeb.forEach((item: File) => {
        formData.append("images[]", item)
      })
    }else{
      this.state.images.forEach((item: Imagetype) => {
        formData.append("images[]", {
          uri: item.uris,
          type: "image/png",
          name: "image"
        })
      })
    }

   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.addDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


  }

  showCategory = () =>
    this.setState({
      showCategory: !this.state.showCategory,
    });

  onSelectCategory = (item: CategoryType) => {
    this.setState({ category: item, showCategory: false },()=>{
      this.getUserDataListApi()
    });
  };



  addNewProduct = () => this.setState({ showModel: true });

  hideModal = () => this.setState({ showModel: false });

  openImagePicker = () => {
  };

  onChangeTitle = (text: string) => {
    this.setState({ title: text });
  };

  onChangeDescription = (text: string) => {
    this.setState({ description: text });
  };

  onChangePrice = (text: string) => {
    this.setState({ price: text });
  };

  onChangeQuantity = (text: string) => {
    this.setState({ quantity: text });
  };

  // Customizable Area End
}
