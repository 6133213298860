import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { conditionSort } from "../../../components/src/Helper";
import { toast } from "react-toastify";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export type PageTextKeys = 'accepted' | 'rejected' | 'pending';

interface IListData {
  id: string ;
  type: string;
  attributes: {
    listing_id: number;
    access_request_status: string;
    deal_stage: string;
    buyer: {
      id: string;
      name: string;
      url: string;
      description: string;
    };
  };
}

interface IUserList {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    progress: string;
    category_id: number;
    account_id: number;
    hash_id: string;
    category_name: string;
    image: {
        id: number;
        url: string;
    };
}
}

const selectedList = {
  id: "",
  type: "",
  attributes: {
    id: "",
    name: "",
    progress: "",
    category_id: 0,
    account_id: 0,
    hash_id: "",
    category_name: "",
    image: {
      id: 0,
      url: ""
    }
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notificationId:string;
  isApproveSwitch: boolean;
  selectedFilter: string;
  menuAnchorEl: null | HTMLElement;
  userListData: IUserList[];
  selectedList: IUserList;
  acquisition: any;
  listRequestsData: IListData[];
  maxStage: number;
  isNdaCompleted : boolean;
  isAutoSignCompleted: boolean;
  currentListId: string;
  typeOfList:string;
  language: string;
  pageText: {
    viewProfile: string;
    listing: string;
    evaluateTheCompany: string;
    handlingProposals: string;
    undergoingDueDiligence: string;
    facilitatePayment: string;
    finalizeAcquisition: string;
    buyers: string;
    autoApprove: string;
    accepted: string;
    rejected: string;
    pending: string;
    noData: string;
    stage: string;
    accept: string;
    reject: string;
    acceptAgainText: string;
    turnOn: string;
cancelPopup: string;
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserCallId: string = "";
  getStageCallId: string = "";
  getRequestsCallId: string = "";
  acceptRejectReqCallId: string = "";
  getListingFullDetailsCallId: string = "";
  updateAutoApproveCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      notificationId:"",
      isApproveSwitch: false,
      selectedFilter: 'pending',
      menuAnchorEl: null,
      userListData: [],
      selectedList: selectedList,
      acquisition: null,
      listRequestsData: [],
      maxStage: 0,
      isNdaCompleted:false,
      isAutoSignCompleted: false,
      currentListId:"",
      typeOfList:"",
      language: "",
      pageText: {
        viewProfile: "",
        listing: "",
      evaluateTheCompany: "",
      handlingProposals: "",
      buyers: "",
      autoApprove: "",
      accepted: "",
      rejected: "",
      undergoingDueDiligence: "",
      facilitatePayment: "",
      finalizeAcquisition: "",
      pending: "",
      noData: "",
      stage: "",
      accept: "",
      reject: "",
        acceptAgainText: "",
      turnOn: "",
      cancelPopup: ""
        
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.handleResForGetUserList(from, message)
    this.handleResForGetRequests(from, message)
    this.handleResForCurrentStage(from, message)
    this.handleResForAcceptRejectReq(from, message)
    this.handleResForListingFullDetails(from, message)
    this.handleResForUpdateAutoApprove(from, message)
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let notificationIdNew: string = await getStorageData("NotificationId")
      this.setState({notificationId:notificationIdNew})
    let language: string = await getStorageData("language") || 'en';
    this.setState({language: language}, () => {this.translateTexts();
      this.getUserList();
    });
    let acquisition = await getStorageData("acquisition", true)
    if(acquisition) {
      this.setState({ acquisition, menuAnchorEl: null, selectedList: acquisition }, () => {
        this.getRequests();
        this.getListingFullDetails()
        this.getCurrentStage();
      })
    }
  }

  handleSwitch = () => {
    this.setState({ isApproveSwitch: !this.state.isApproveSwitch }, () => this.updateAutoApproveRequest())
  }

  handleFilterChip = (filter: string) => {
    this.setState({ selectedFilter: filter, listRequestsData: [] }, () => this.getRequests())
  }

  handleMenu = (anchorEl: HTMLElement | null) => {
    this.setState({ menuAnchorEl: anchorEl })
  }

  handleSelectMenu = async (list: IUserList) => {
    await setStorageData("acquisition", JSON.stringify(list))
    this.setState({ menuAnchorEl: null, selectedList: list }, () => {
      this.getRequests();
      this.getListingFullDetails()
      this.getCurrentStage();
    })
  }

  getUserList = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.requestApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserListApiEndPoint}?locale=${this.state.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCurrentStage = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.requestApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCurrentStageApiEndpoint}?listing_id=${this.state.selectedList.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRequests = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.requestApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRequestsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestsApiEndpoint}?language=${this.state.language}&status=${this.state.selectedFilter}&listing_id=${this.state.selectedList.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkCondtions = async (id:number, action : string,listId:string) => {
    await setStorageData("listingIdForNDA", listId);
    if(this.state.isAutoSignCompleted && ['accept'].includes(action)){
      this.acceptRejectReq(id,action);
    }else{
      await setStorageData("accessId", id);
      if(action === "accept"){
        this.setState({isNdaCompleted: true , selectedFilter: "accepted"})
      }
      else{
        this.acceptRejectReq(id,action);
        this.setState({ selectedFilter: "pending" });

      }
    }
  }
  acceptRejectReq = async (id: number, action: string) => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.requestApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptRejectReqCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.requestActionApiEndpoint}/${id}/${action}?locale=${this.state.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getListingFullDetails = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.requestApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListingFullDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListingFullDetails}?locale=${this.state.language}&listing_id=${this.state.selectedList.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAutoApproveRequest = async () => {
    const endPoint = conditionSort(this.state.selectedList.attributes.category_name == 'Start Up', configJSON.updateStartupAutoApproveEndpoint, configJSON.updateBusinessAutoApproveEndpoint)

    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.requestApiContentType
    };

    const updateProperty = conditionSort(this.state.selectedList.attributes.category_name == 'Start Up', 'private_information', 'private_business_information')

    const httpBody = {
      listing_id: this.state.selectedList.id,
      [updateProperty]: {
        auto_accept_request: this.state.isApproveSwitch
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAutoApproveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}?locale=${this.state.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleResForGetUserList = async (from: string, message: Message) => {
    if (this.getUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const selectedData = this.state.notificationId 
        ? responseJson.data.find((data:IUserList) => data.id === this.state.notificationId) 
        :  responseJson.data[0];
        this.setState({ userListData: responseJson.data, selectedList: this.state.acquisition || selectedData}, () => {
          this.getRequests();
          this.getListingFullDetails();
          this.getCurrentStage();
        })
        localStorage.removeItem("NotificationId");
      }
    }
  }

  handleResForGetRequests = async (from: string, message: Message) => {
    if (this.getRequestsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ listRequestsData: responseJson.data })
      }
    }
  }
  handleResForCurrentStage = async (from: string, message: Message) => {
    if (this.getStageCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.highest_stage) {
        this.setState({ maxStage: responseJson.highest_stage.replace("stage", "") })
      } else if(responseJson.error) {
        this.setState({maxStage: 0})
      }
    }
  }

  handleResForAcceptRejectReq = async (from: string, message: Message) => {
    if (this.acceptRejectReqCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.getRequests()
        toast.success(responseJson.message,{position:this.state.language==="ar"?"top-left":"top-right"});
      }
    }
  }

  handleResForListingFullDetails = async (from: string, message: Message) => {
    if (this.getListingFullDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        await setStorageData("listingId", responseJson.data.id);
          await setStorageData("typeOfList", conditionSort(['Start Up', 'بدء', 'شركة ناشئة', 'Start up'].includes(responseJson.data.attributes.category_name), 'Start Up', 'SME'));
        this.setState({ isApproveSwitch: responseJson.data.attributes.private_information.data.attributes.auto_accept_request,
           isAutoSignCompleted: responseJson.data.attributes.private_information.data.attributes?.auto_sign_nda || responseJson.data.attributes.private_information.data.attributes.auto_sign,
           currentListId: responseJson.data.id,
           typeOfList : conditionSort(['Start Up', 'بدء', 'شركة ناشئة', 'Start up'].includes(responseJson.data.attributes.category_name), 'Start Up', 'SME')
         });
      }
    }
  }

  handleResForUpdateAutoApprove = async (from: string, message: Message) => {
    if (this.updateAutoApproveCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.getListingFullDetails();
        this.getCurrentStage();
        const acessId = await getStorageData("accessId");
        const listingIdForNDA = await getStorageData("listingIdForNDA");
        this.checkCondtions(acessId, "accept", listingIdForNDA);
      }
    }
  }
  sellersNavigation = async(id:string, status:string) =>{
    if(status === "accepted"){
      await setStorageData("id",id)
      await removeStorageData("saleId");
      await removeStorageData("letterofIntentId");
      this.props.navigation.navigate("AcquisitionProcess",{ id: id})
    }
  }

  navigateTo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, buyerId: string) => {
    event.stopPropagation();

    this.props.navigation.navigate(`ViewBuyerProfile`, {id: buyerId})

  }
  navigateToNdaScreens = async() =>{
    const msgessageFrom = new Message(getName(MessageEnum.NavigationMessage));
            msgessageFrom.addData(getName(MessageEnum.NavigationTargetMessage), "AutoSignTerms");
              msgessageFrom.addData(
                    getName(MessageEnum.NavigationPropsMessage),
                    this.props
                );
                this.send(msgessageFrom)
             
  }
  translateTexts = async () => {
    this.setState({pageText: this.state.language === 'en' ? configJSON.staticEnText : configJSON.staticArText})
  }
  // Customizable Area End
}