import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import {generateRequestMessage, getResponseVariables} from "./ForgotEmailIdController.web";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
import { conditionSort, getToastPosition } from "../../../components/src/Helper";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export type Props =  WithStyles & {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otp_code: string;
  isValidOTP: boolean;
  isSubmitted: boolean;
  errorMsg: string;
  otpResendMsg: string;
  language: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotEmailOTPController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgetEmailOTPCallBack: string = "";
  resendOtpCallBack: string = "";
  // Customizable Area End



  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
        otp_code: "",
        isValidOTP: false,
        isSubmitted: false,
        errorMsg: "",
        otpResendMsg: "",
        language: "",
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({language: await getStorageData("language") || "en"})
    // Customizable Area End
  }

  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.messageSubmitOTPApi(message);
    this.messageSendOTPApi(message);
    // Customizable Area End
  }

  // Customizable Area Start

  messageSendOTPApi = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if(this.resendOtpCallBack === apiRequestCallId){
      this.setState({otp_code: ""})
      if(responseJson && responseJson?.message){
        this.setState({errorMsg: ""});
        toast.success(conditionSort(this.state.language == 'en', 'OTP sent successfully', 'تم إرسال كلمة المرور لمرة واحدة فقط بنجاح'), getToastPosition())
      }else if(responseJson && responseJson?.errors){
        this.setState({errorMsg: responseJson?.errors[0][Object.keys(responseJson?.errors[0])[0]], otpResendMsg: ""});
      }else{
        this.setState({otpResendMsg: "", errorMsg: configJSON.wrongTxtMsg});
      }
    }
  } 

  handleResendOTP = async () => {
      const data = {
        data: {
          attributes: {
            full_phone_number: await getStorageData("mobileNo")
          }
        }
      }
      
      const requestMessage = await generateRequestMessage(
        configJSON.forgetEmailIdEndPoint,
        `${configJSON.httpPostMethod}`
      );
      this.resendOtpCallBack = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  messageSubmitOTPApi = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if(this.forgetEmailOTPCallBack === apiRequestCallId){
      if(responseJson && responseJson?.message){
        this.setState({isSubmitted: true, errorMsg: "",
        otpResendMsg: ""});
      }
      else if(responseJson && responseJson?.errors){
        this.setState({
          errorMsg: responseJson?.errors[0].otp,
          otpResendMsg: ""
        });       
      }else{
        this.setState({
          errorMsg: configJSON.wrongTxtMsg,
          otpResendMsg: ""
        })
      }
    }
  } 

  isSubmitted = async() => {
    const {otp_code, isValidOTP} = this.state;
    if(otp_code.length > 0 && isValidOTP){
      const phoneNo = (await getStorageData("mobileNo"));
      let payload = {
        "data":{
          "attributes": {
            otp: otp_code,
            full_phone_number: phoneNo?.replace("+", "")
          }
        }
      }
      this.sendOTPApi(payload);
    }
  }

  handleChangeOTP = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const isValidOtp = value.length === 4 ? true : false;
    const rejex = configJSON.phoneRejex;
    if (value != "" && !(rejex.test(value))) {
      return
    }
    if(value.length > 4){
      return
    }
    this.setState({ otp_code: value, isValidOTP: isValidOtp });
  };



  backToSignUp = () => {
    this.props.navigation.navigate("EmailAccountRegBlock");
  }
  
  backToSignIn = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  //action 
  sendOTPApi = async (data: any) => {
    const requestMessage = await generateRequestMessage(
      configJSON.forgetEmailOtpEndPoint,
      `${configJSON.httpPostMethod}`
    );
    this.forgetEmailOTPCallBack = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
