Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.staticText= {
  "viewDashboard": "View Dashboard",
  "filters": "Filters",
  "sortBy": "Sort By",
  "availableRequests":"Available requests this week:",
  "viewByList": "View By List",
  "all": "All", 
  "accepted":"Accepted", 
  "pending":"Pending", 
  "rejected":"Rejected", 
  "cancelled":"Cancelled",
  "ltmGrossRevenue": "LTM Gross Revenue:",
  "ltmNetProfit": "LTM Net Profit:",
  "aksingPrice": " Asking Price:",
  "status": "Status",
  "acceptedOn":'Accepted on',
  "sentOn":'Sent on',
  "addToList": "Add to list...",
  "shareThisDeal": "Share this listing",
  "chooseThisWillText": "Choosing this will",
  "whyReportingDeal": "Why are you reporting this deal",
  "archiveThisDeal": "Problem with this deal?",
  "archiveText": "Archive",
  "flagTheListing": "archive the listing",
  "hideTheListingText": "hide the listing",
  "reportText": "Report",
  "linkedInText": "LinkedIn",
  "twitterText": "Twitter",
  "copiedTxtMsg": "Link Copied",
  "faceBookText": "Facebook",
  "whatsAppText": "WhatsApp",
  "instragramText": "Instragram",
  "createNewList": "Create new list",
  "rejectedOn":'Rejected on',
  "cancelledOn":"Cancelled on",
  "search": "Search",
  "noData": "Data not available",
  "Request Accepted": "Request Accepted",
  "Request Sent": "Request Sent",
  "Request Rejected": "Request Rejected",
  "Request Cancelled": "Request Cancelled",
  "No Action is Taken": "No Action is Taken",
  "archivedText": "Archived",
  "viaLinkText": "Via Link",
  "archivedOn": "Archived On"
};

exports.staticTextArabic = {
  "viewDashboard": "عرض لوحة القيادة",
  "filters": "المرشحات",
  "sortBy": "ترتيب حسب",
  "availableRequests":"الطلبات المتوفرة لهذا الأسبوع:",
  "linkedInText": "لينكد إن",
  "viewByList": "عرض حسب القائمة",
  "all": "الجميع",
  "accepted":"مقبول", 
  "shareThisDeal": "شارك هذه القائمة",
  "pending":" قيد الانتظار", 
  "rejected":"مرفوض", 
  "cancelled":"ملغي",
  "ltmGrossRevenue": "الايرادات آخر 12 شهر",
  "viaLinkText": "عبر الرابط",
  "instragramText": "انستغرام",
  "copiedTxtMsg": "تم نسخ الرابط",
  "whatsAppText": "واتساب",
  "ltmNetProfit": "صافي الربح آخر 12 شهر:",
  "aksingPrice": "السعر المطلوب:",
  "status": "الحالة",
  "acceptedOn":'مقبول على',
  "twitterText": "X",
  "sentOn":'أرسلت في',
  "addToList": "أضف إلى قائمة...",
  "archiveThisDeal": "هل هناك مشكلة في هذه الصفقة؟",
  "whyReportingDeal": "لماذا تقوم بالإبلاغ عن هذه الصفقة؟",
  "archiveText": "أرشيف",
  "flagTheListing": "يخفي القائمة",
  "hideTheListingText": "يضيف القائمة للأرشيف",
  "reportText": "إبلاغ",
  "chooseThisWillText": "هذا الإختيار سوف",
  "createNewList": "إنشاء قائمة جديدة",
  "rejectedOn":'رفض على',
  "cancelledOn":"تم الإلغاء على",
  "faceBookText": "فيسبوك",
  "search":"بحث",
  "noData": "لا تتوافر بيانات",
  "Request Accepted": "تم قبول الطلب",
  "No Action is Taken": "لم يتم اتخاذ أي إجراء",
  "Request Sent": "تم ارسال الطلب",
  "archivedText": "مؤرشف",
  "Request Rejected": "طلب رفض",
  "Request Cancelled": "تم إلغاء الطلب",
  "archivedOn": "مؤرشف في"

};
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.getAccessCountEndpoint = "account_block/access_requests/total_this_month";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.activitiesTitle = "Your Activities";
exports.getDealsStatusEndPoint = "/account_block/access_requests/buyer_deals";
exports.getBookmarkEndPoint = "/bx_block_catalogue/catalogues_lists";
exports.getCategoriesAPIEndPoint = "bx_block_categories/categories?sub_categories=true";

exports.locationTxt = "Location"
exports.staticTextEn = {
  "complete": "Complete",
  "yourProfile": "Your Profile",
  "yourDeals":"Your Deals",
  "location":"Location",
  "yourActivities": "Your Activities",
  "exploreListings":"Explore Listings",
  "availableThisWeek": "Available Requests this week",
  "yourSubscription":"Your Subscription",
  "aboutYourself":"About your company",
  "linkedInProfile":"LinkedIn Profile:",
  "viewInLinkedIn":"View in LinkedIn",
  "noOfAcq":"Number of Acquisitions Closed:",
  "projectedAnnualAcq":"Projected Annual Acquisitions:",
  "accreditedBuyer":"Accredited Buyer",
  "completeYourProfile": "Complete your profile",
  "viewFullProfile": "View full profile",
  "loremIpsum": "Lorem Ipsum Dolor Set",
  "dealsSub": "Track the Progress of Your Deals",
  "fO": "Find More Potential Opportunities",
  "archived": "Archived",
  "unArchived": "Unarchive",
  "vP": "View Your Current Plan",
}
exports.staticTextAr = {
  "complete": "مكتمل",
  "yourProfile":"ملفك الشخصي",
  "availableThisWeek": "الطلبات المتوفرة هذا الاسبوع",
  "yourActivities": "الأنشطة الخاصة بك",
  "exploreListings":"استكشف الفرص المعروضة",
  "yourDeals":"صفقاتك",
  "yourSubscription":"إدارة الاشتراك",
  "completeYourProfile":'أكمل ملفك الشخصي',
  "location":"الموقع",
"aboutYourself":"نبذة عن شركتك",
"linkedInProfile":" رابط صفحة لنكدان:",
"viewInLinkedIn":"عرض في LinkedIn",
"noOfAcq":"عدد عمليات الاستحواذ المغلقة:",
"projectedAnnualAcq":"عمليات الاستحواذ السنوية المتوقعة:",
"accreditedBuyer":"مشتري معتمد",
"viewFullProfile": "عرض الملف الشخصي كاملا",
  "loremIpsum": "لوريم إيبسوم",
  "dealsSub": "تتبع الصفقات",
  "unArchived": "إلغاء الأرشفة",
  "archived": "مؤرشف",
  "fO": "البحث عن الفرص",
  "vP":"عرض الخطة"

};
exports.preAccreditedBuyerOptions = [
  {title: "I have an annual income of at least SAR 600,000 in each of the last two years", arTitle: "لديّ دخل سنوي لا يقل عن 600,000 ريال سعودي في كل سنة من السنتين الأخيرتين", value: "I have an annual income of at least SAR 600,000 in each of the last two years"},
  {title: "I have a minimum of SAR 1 million in investments in securities", arTitle: "لدي استثمارات في الأوراق المالية لا تقل عن مليون ريال سعودي", value: "I have a minimum of SAR 1 million in investments in securities"},
  {title: "We are a company that has a total assets of at least SAR 10 million", arTitle: "نحن شركة لا يقل إجمالي أصولها عن 10 مليون ريال سعودي", value: "We are a company that has a total assets of at least SAR 10 million"},
  {title: "I have a net worth of at least SAR 5 million", arTitle: "لديّ صافي ثروة لا تقل عن 5 ملايين ريال سعودي", value: "I have a net worth of at least SAR 5 million"},
  {title: "I have a net worth of at least SAR 3 million", arTitle: "لديّ ثروة صافية لا تقل عن 3 ملايين ريال سعودي", value: "I have a net worth of at least SAR 3 million"},
  {title: "I work or have worked for at least three years in the financial sector in a professional position related to investment in securities or mergers and acquisition", arTitle: "أعمل أو عملت لمدة ثلاث سنوات على الأقل في القطاع المالي في وظيفة مهنية تتعلق بالاستثمار في الأوراق المالية أو الاندماج والاستحواذ", value: "I work or have worked for at least three years in the financial sector in a professional position related to investment in securities or mergers and acquisition"},
  {title: "None of the above", arTitle: "لا شيء مما سبق", value: "None of the above"}
];


exports.getArchivedListingEndPoint = "bx_block_catalogue/archieved_listings";
exports.bookmarkEndPoint = "bx_block_catalogue/create_bookmark";
exports.unBookmarkEndPoint = "bx_block_catalogue/update_bookmark_catalogue";
exports.bookmarkListEndPoint = "bx_block_catalogue/catalogues_lists";
exports.reportListEndPoint = "bx_block_catalogue/reports?locale={lang}";
exports.fetchBookmarkEndPoint = "bx_block_catalogue/favourites_list";
exports.shareUrl = "/CompanyProfile/{dealId}";
exports.instaUrl = "https://www.instagram.com/direct/new/?text=$";
exports.putAPIMethod = "PUT";
exports.postAPIMethod = "POST";
exports.reportApiEndPoint = "bx_block_catalogue/archieve_and_report";
exports.bookmarkPath = "M4.66602 5.83398C4.66602 4.90573 5.03476 4.01549 5.69114 3.35911C6.34752 2.70273 7.23776 2.33398 8.16602 2.33398H19.8327C20.7609 2.33398 21.6512 2.70273 22.3076 3.35911C22.9639 4.01549 23.3327 4.90573 23.3327 5.83398V24.5333C23.3327 25.9566 21.7227 26.785 20.5653 25.9578L13.9993 21.2678L7.43335 25.9578C6.27485 26.7861 4.66602 25.9578 4.66602 24.5345V5.83398ZM8.16602 4.66732C7.8566 4.66732 7.55985 4.79023 7.34106 5.00903C7.12227 5.22782 6.99935 5.52456 6.99935 5.83398V23.4005L12.982 19.127C13.2788 18.9149 13.6345 18.8009 13.9993 18.8009C14.3642 18.8009 14.7198 18.9149 15.0167 19.127L20.9993 23.4005V5.83398C20.9993 5.52456 20.8764 5.22782 20.6576 5.00903C20.4388 4.79023 20.1421 4.66732 19.8327 4.66732H8.16602Z";
exports.flagPath = "M4.66602 17.5H20.8418C21.0635 17.4999 21.2806 17.4367 21.4676 17.3177C21.6547 17.1987 21.804 17.0289 21.898 16.8281C21.992 16.6273 22.0269 16.404 21.9985 16.1841C21.9701 15.9642 21.8797 15.757 21.7378 15.5867L17.4993 10.5L21.7378 5.41333C21.8797 5.243 21.9701 5.03576 21.9985 4.8159C22.0269 4.59604 21.992 4.37265 21.898 4.17189C21.804 3.97113 21.6547 3.80131 21.4676 3.68232C21.2806 3.56332 21.0635 3.50008 20.8418 3.5H4.66602V24.5";
exports.sharePath = "M15.1669 4.66602V9.33268C7.49605 10.532 4.64355 17.252 3.50022 23.3327C3.45705 23.573 9.78155 16.377 15.1669 16.3327V20.9993L24.5002 12.8327L15.1669 4.66602Z";
exports.infoIconPath = "M10 8.75C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V9.375C9.375 9.20924 9.44085 9.05027 9.55806 8.93306C9.67527 8.81585 9.83424 8.75 10 8.75ZM10 7.8125C10.1231 7.8125 10.245 7.78825 10.3588 7.74114C10.4725 7.69402 10.5759 7.62497 10.6629 7.53791C10.75 7.45086 10.819 7.34751 10.8661 7.23377C10.9132 7.12002 10.9375 6.99811 10.9375 6.875C10.9375 6.75189 10.9132 6.62998 10.8661 6.51623C10.819 6.40249 10.75 6.29914 10.6629 6.21209C10.5759 6.12503 10.4725 6.05598 10.3588 6.00886C10.245 5.96175 10.1231 5.9375 10 5.9375C9.75169 5.9375 9.51355 6.03614 9.33797 6.21172C9.16239 6.3873 9.06375 6.62544 9.06375 6.87375C9.06375 7.12206 9.16239 7.3602 9.33797 7.53578C9.51355 7.71136 9.75169 7.81 10 7.81V7.8125ZM2.5 10C2.5 9.01509 2.69399 8.03982 3.0709 7.12987C3.44781 6.21993 4.00026 5.39314 4.6967 4.6967C5.39314 4.00026 6.21993 3.44781 7.12987 3.0709C8.03982 2.69399 9.01509 2.5 10 2.5C10.9849 2.5 11.9602 2.69399 12.8701 3.0709C13.7801 3.44781 14.6069 4.00026 15.3033 4.6967C15.9997 5.39314 16.5522 6.21993 16.9291 7.12987C17.306 8.03982 17.5 9.01509 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5C8.01088 17.5 6.10322 16.7098 4.6967 15.3033C3.29018 13.8968 2.5 11.9891 2.5 10ZM10 3.75C8.3424 3.75 6.75269 4.40848 5.58058 5.58058C4.40848 6.75269 3.75 8.3424 3.75 10C3.75 11.6576 4.40848 13.2473 5.58058 14.4194C6.75269 15.5915 8.3424 16.25 10 16.25C11.6576 16.25 13.2473 15.5915 14.4194 14.4194C15.5915 13.2473 16.25 11.6576 16.25 10C16.25 8.3424 15.5915 6.75269 14.4194 5.58058C13.2473 4.40848 11.6576 3.75 10 3.75Z";
// Customizable Area End