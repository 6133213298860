import React from "react";
// Customizable Area Start
import {
  Grid,
  Typography,
  Box,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Dialog,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  Container
} from "@material-ui/core";
import { arrowBg, imageRight } from "./assets";
import {
  createStyles,
  Theme as AugmentedTheme,
  withStyles
} from "@material-ui/core/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EmailAccountRegistrationBlockController, {
  Props,
  configJSON
} from "./EmailAccountRegistrationBlockController.web";
import { Form, Formik } from "formik";
import { MailOutline, PhoneIphone } from "@material-ui/icons";
import * as Yup from "yup";
import { styled } from "@material-ui/styles";
import HeaderBlockWeb from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";

const Popup = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    height: "265px",
    width: "795px",
    borderRadius: "20px",
    border: "1px #262626 solid",
    boxShadow: "none",
    padding: "50px",
    overflow: "hidden",
    "@media (max-width:600px)": {
      height: "auto",
      width: "231px"
    }
  },
  "& .MuiBackdrop-root": {
    background: "rgba(140, 140, 140, 0.60)"
  }
}));

let themeProvider = createTheme();
themeProvider = responsiveFontSizes(themeProvider);

// Customizable Area End

export class EmailAccountVerification extends EmailAccountRegistrationBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  VerifyValidations = Yup.object().shape({
    email: Yup.number()
      .min(4)
      .required(),
    phone: Yup.number()
      .min(4)
      .required()
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { remainingTime, isTimeOver }  = this.state;
    const { mobileRemainingTime, mobileIsTimeOver }  = this.state;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    const minutesMobile = Math.floor(mobileRemainingTime / 60);
    const secondsMobile = mobileRemainingTime % 60;
    const pageText = this.state.signupPageText
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
      <div className={classes.mainDiv}>
   <HeaderBlockWeb />
   <Container className={classes.innerContainer}>
     <Grid container >
       <Grid
         item
         lg={12}
         md={12}
         sm={12}
         xs={12}
         className={classes.mainSignupContainer}
       >
        <Grid item lg={4} md={6} sm={8} xs={10} className={classes.form}>
          <Formik
            validationSchema={this.VerifyValidations}
            initialValues={{
              email: "",
              phone: ""
            }}
            onSubmit={this.handleVerify}
            isInitialValid={false}
            data-test-id="formik"
          >
            {({ values, isValid, setFieldValue, handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                style={styles.verificationForm}
                translate={undefined}
              >
                <Typography className={classes.verificationHeading}>
                  {this.getSpecifiedLangString(this.state.language, pageText.verification, "التحقق")}
                </Typography>
                <Typography className={classes.formLabel}>
                  {this.getSpecifiedLangString(this.state.language, pageText.pleaseEnterVerificationText, "الرجاء إدخال رمز التحقق المرسل إلى البريد الإلكتروني الخاص بك")}
                </Typography>
                <FormControl className={classes.formInput}>
                  <label>{this.getSpecifiedLangString(this.state.language, pageText.EnterCodeText, "أدخل الرمز")}</label>
                  <OutlinedInput
                    placeholder={this.getSpecifiedLangString(this.state.language, "code", "الرمز")}
                    name="email"
                    onChange={e => setFieldValue("email", e.target.value)}
                    onFocus={() => this.setState({ emailCodeFocused: true })}
                    onBlur={() => this.setState({ emailCodeFocused: false })}
                    fullWidth
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">
                        <MailOutline
                          style={{
                            ...styles.greyColor,
                            opacity:
                              this.state.emailCodeFocused || values.email
                                ? "1"
                                : "0.5"
                          }}
                        />
                      </InputAdornment>
                    }
                    value={values.email}
                    id="emailCode"
                  />
                  {this.state.verificationEmailError && (
                    <Box
                      className={classes.errorText}
                      data-test-id="email-error"
                    >
                      {this.state.verificationEmailError}
                    </Box>
                  )}
                </FormControl>
                <Box 
              style={{color: !this.state.isTimeOver?"black":"#8C8C8C"}} >
                      <Typography className={classes.otpTimmer}>
                      {this.getSpecifiedLangString(this.state.language, pageText.timeRemaingText, "الوقت المتبقي")} {minutes < 10 ? "0" : ""} 
                        {minutes}:{seconds < 10 ? "0" : ""}
                        {seconds} {this.getSpecifiedLangString(this.state.language, pageText.sText, "ثانية")}
                      </Typography>
                 </Box>
                <Typography   className={classes.codeNotReceived}
              style={{color: !this.state.isTimeOver?"#8C8C8C":"black"}} 
              >
                  {this.getSpecifiedLangString(this.state.language, pageText.didNotReceivedCodeText, "لم تتلق الرمز؟")}{" "}
                
                  <Button onClick={this.handleResendOTP} disabled={!isTimeOver} data-testid='resend-code' className={classes.resendOtpBtn}
              style={{color: !this.state.isTimeOver?"#8C8C8C":"black"}}   >
                     <u   data-test-id="resend-code">
                     {this.getSpecifiedLangString(this.state.language, pageText.resendCodeText, "أعد إرسال الرمز")}
                     </u>
                 </Button>
                </Typography>
                <Typography className={classes.formLabel}>
                  {this.getSpecifiedLangString(this.state.language, pageText.phoneNumberVerificationText, "الرجاء إدخال رمز التحقق المرسل إلى رقم هاتفك")}
                </Typography>
                <FormControl className={classes.formInput}>
                <label>{this.getSpecifiedLangString(this.state.language, pageText.EnterCodeText, "أدخل الرمز")}</label>
                  <OutlinedInput
                    placeholder={this.getSpecifiedLangString(this.state.language, "code", "الرمز")}
                    name="phone"
                    onChange={e => setFieldValue("phone", e.target.value)}
                    onFocus={() => this.setState({ phoneCodeFocused: true })}
                    onBlur={() => this.setState({ phoneCodeFocused: false })}
                    fullWidth
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">
                        <PhoneIphone
                          style={{
                            ...styles.greyColor,
                            opacity:
                              this.state.phoneCodeFocused || !!values.phone
                                ? "1"
                                : "0.5"
                          }}
                        />
                      </InputAdornment>
                    }
                    value={values.phone}
                    id="mobileCode"
                  />
                </FormControl>
                <Box
                style={{color: !this.state.mobileIsTimeOver?"black":"#8C8C8C"}}  
                >
                      <Typography className={classes.otpTimmer}>
                      {this.getSpecifiedLangString(this.state.language, pageText.timeRemaingText, "الوقت المتبقي")} {minutes < 10 ? "0" : ""} 
                        {minutesMobile}:{secondsMobile < 10 ? "0" : ""}
                        {secondsMobile} {this.getSpecifiedLangString(this.state.language, pageText.sText, "ثانية")}
                      </Typography>
                 </Box>
                <Typography className={classes.codeNotReceived}
                style={{color: !this.state.isTimeOver?"#8C8C8C":"black"}}  >
                  {this.getSpecifiedLangString(this.state.language, pageText.didNotReceivedCodeText, "لم تتلق الرمز؟")}{" "}
                 
                  <Button onClick={this.MobileHandleResendOTP} data-testid="resend-mobile-code"
                   disabled={!mobileIsTimeOver} 
                   className={classes.resendOtpBtn}
                style={{color: !this.state.isTimeOver?"#8C8C8C":"black"}}  >
                     <u  data-test-id="resend-code">
                      {this.getSpecifiedLangString(this.state.language, pageText.resendCodeText, "أعد إرسال الرمز")}
                     </u>
                 </Button>
                </Typography>
              

                <Button
                  id="submitButton"
                  className={classes.submitBtn}
                  type="submit"
                  variant="contained"
                  disabled={!isValid}
                >
                  {this.getSpecifiedLangString(this.state.language, pageText.verifyText, "تحقق")}
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Popup
          className={classes.popUp}
          open={this.state.modalOpen}
          maxWidth="md"
        >
          <Box>
            <Box className={classes.rightImageWithSuccess}>
              <img src={imageRight} width={40} height={40} />
              <ThemeProvider theme={themeProvider}>
                {/* @ts-ignore */}
                <Typography
                  className={classes.typoSuccess}
                  data-test-id="success"
                >
                  {this.getSpecifiedLangString(this.state.language,pageText.successText,configJSON.successTextAr)}
                </Typography>
              </ThemeProvider>
            </Box>

            <Box className={classes.successfullySubmitted}>
              <Box>
                {/* @ts-ignore */}
                <p className={classes.typoThird}>
                  {this.getSpecifiedLangString(this.state.language,pageText.verificationSuccessfulText,configJSON.verificationSuccessfulTextAr)}
                  <br />
                  {this.getSpecifiedLangString(this.state.language,pageText.proceedText,configJSON.proceedTextAr)}
                </p>
              </Box>
            </Box>

            <Box className={classes.successfullySubmitted}>
              <Button
                className={classes.typoHome}
                variant="contained"
                onClick={this.handleVerificationPageSubmit}
              >
                {this.getSpecifiedLangString(this.state.language,pageText.nextText,configJSON.nextTextAr)}
              </Button>
            </Box>
          </Box>
        </Popup>
        </Grid>
      </Grid>
    </Container>
    <FooterBlock />
    </div>
    </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  verificationForm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Montserrat,sans-serif"
  },
  greyColor: { color: "#262626" }
};

const customStyle: {} = (theme: AugmentedTheme) =>
  createStyles({
    innerContainer: {
      width: "100%",
      maxWidth: "none",
      paddingLeft: "0",
      paddingRight: "0",
      fontFamily: "Montserrat,sans-serif"
    },
    mainDiv: {
      width: "100%",
      height: "1200px",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("sm")]: {
        height: "600px"
      },
      fontFamily: "Montserrat,sans-serif"
    },
    navbar: {
      flexShrink: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center" as "center",
      padding: "19px 19px !important",
      fontFamily: "Montserrat,sans-serif",
      "& img": {
        maxWidth: "100%",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          height: "2.5vh"
        }
      }
    },
    mainSignupContainer: {
      backgroundImage: "url(" + arrowBg + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      display: "flex",
      alignItems: "center", 
      justifyContent: "center"
    },
    form: {
      border: "solid 1px #262626",
      backgroundColor: "#FFFFFF",
      borderRadius: "20px",
      marginTop: "24px",
      marginBottom: "24px",
      minWidth: "600px",
      padding: "16px 108px 16px",
      "@media (max-width:600px)": {
        minWidth: "300px",
        maxWidth: "300px",
        marginTop: "75px",
        marginBottom: "90px",
        padding: "16px 40px 36px"
      }
    },
    formInput: {
      width: "100%",
      marginBottom: "16px",
      "& label": {
        color: "#262626",
        fontSize: "18px !important",
        fontFamily: "Montserrat",
        fontWeight: "500",
        lineHeight: "24px",
        marginBottom: "12px"
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "12px",
        margin: "0"
      },
      "& .MuiInputBase-input": {
        color: "#262626",
        fontSize: "18px !important",
        fontFamily: "Montserrat",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "3.60px",
        wordWrap: "break-word"
      },
      "& .MuiOutlinedInput-input": {
        padding: "14px 32px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px #8C8C8C solid"
      },
      "@media (max-width:600px)": {
        marginBottom: "15px",
        "& label": {
          fontSize: "10px !important",
          lineHeight: "20px",
          marginBottom: "4px"
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px"
        },
        "& .MuiInputBase-input": {
          fontSize: "10px !important",
          letterSpacing: "1px"
        },
        "& .MuiOutlinedInput-input": {
          padding: "6px 13px"
        },
        "& .MuiSvgIcon-root": {
          width: "18px",
          height: "18px"
        },
        "& .MuiOutlinedInput-adornedEnd": {
          paddingRight: "10px"
        }
      }
    },
    validSubmitBtn: {
      backgroundColor: "#10BFB8",
      color: "#FFF",
      fontWeight: "bold",
      fontFamily: "Montserrat,sans-serif"
    },
    submitBtn: {
      backgroundColor: "#10BFB8",
      borderRadius: "24px",
      color: "#FFF",
      fontSize: "20px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "22px",
      textTransform: "none",
      padding: "14px 70px",
      marginTop: "20px",
      "&:disabled": {
        color: "#FFF",
        backgroundColor: "#8C8C8C"
      },
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#10BFB8"
      },
      "@media (max-width:600px)": {
        fontSize: "12px !important",
        padding: "7px 36px",
        marginTop: "36px"
      }
    },
    formLabel: {
      color: "#000",
      fontSize: "18px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "22px",
      margin: "16px 0",
      "@media (max-width:600px)": {
        fontSize: "12px !important",
        lineHeight: "16px",
        margin: "27px 0"
      }
    },
    formLabel2: {
      fontSize: "16px !important",
      color: "#000",
      fontWeight: "bold",
      width: "100%",
      marginBottom: "30px",
      fontFamily: "Montserrat,sans-serif"
    },
    codeNotReceived: {
      color: "#8c8c8c",
      fontSize: "12px !important",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "24px",
      "& u": {
        // cursor: "pointer"
      },
      "@media (max-width:600px)": {
        fontSize: "10px !important",
        lineHeight: "22px"
      }
    },
    verificationHeading: {
      textAlign: "center",
      fontSize: "30px !important",
      fontWeight: 700,
      lineHeight: "34px",
      color: "#5412FC",
      fontFamily: "Montserrat",
      "@media (max-width:600px)": {
        fontSize: "16px !important"
      }
    },
    rightImageWithSuccess: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "28px",
      marginBottom: "30px",
      "& img": {
        width: "60px",
        height: "60px"
      },
      "@media (max-width:600px)": {
        gap: "18px",
        marginBottom: "26px",
        "& img": {
          width: "39px",
          height: "39px"
        }
      }
    },
    typoSuccess: {
      color: "black",
      fontSize: "30px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "24px",
      "@media (max-width:600px)": {
        fontSize: "24px !important"
      }
    },
    successfullySubmitted: {
      textAlign: "center"
    },
    typoThird: {
      color: "black",
      fontSize: "24px !important",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "36px",
      marginBottom: "48px",
      "@media (max-width:600px)": {
        fontSize: "14px !important",
        lineHeight: "26px",
        marginBottom: "34px"
      }
    },
    typoHome: {
      backgroundColor: "#10BFB8",
      borderRadius: "24px",
      padding: "14px 74px",
      color: "#FFF",
      fontSize: "20px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "22px",
      textTransform: "inherit",
      "&:hover": {
        backgroundColor: "#10BFB8"
      },
      "@media (max-width:600px)": {
        padding: "7px 36px",
        fontSize: "12px !important"
      }
    },
    errorText: {
      backgroundColor: "rgba(255, 116.88, 116.88, 0.30)",
      borderRadius: "12px",
      textAlign: "center",
      padding: "4px",
      color: "#FF7575",
      fontSize: "16px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "24px",
      marginTop: "12px",
      "@media (max-width:600px)": {
        padding: "4px",
        fontSize: "8px !important",
        lineHeight: "12px",
        marginTop: "8px"
      }
    },
    resendOtpBtn:{
      border: 'none',
      background: 'white',
      // color: 'gray',
      fontSize: '12px', 
      fontFamily: 'Montserrat',
      fontWeight: 500,
      lineHeight: '24px',
      margin: 0,
      padding: 0,
      textTransform: "capitalize",
    },
    otpTimmer:{
      fontSize: '12px !important', 
      fontFamily: 'Montserrat',
      fontWeight: 500,
      lineHeight: '24px',
      marginBottom:16,
    }
  });

  export default withStyles(customStyle)(EmailAccountVerification);
// Customizable Area End
