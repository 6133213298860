import React from "react";

// Customizable Area Start
import { cancelIcon, sendIcon, documentIcon, UserDefault } from "./assets";
import { conditionSort } from "../../../components/src/Helper";

// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (

      <div style={webStyles.container}>
        <div style={webStyles.chat} id="chat-comp">
          {this.state.chatList.map((chatObj: any, numIndex: number) => {
            let date = new Date(chatObj.attributes.created_at);
            let chatDay = date.getDate().toString().padStart(2, '0');
            let month = (date.getMonth() + 1).toString().padStart(2, '0'); 
            let year = date.getFullYear();
            let dateString = `${chatDay}/${month}/${year}`;
            chatObj.created_at = dateString;

            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let timeString = `${hours}:${minutes}`;
            chatObj.created_time = timeString;
            return(
            <>
            {conditionSort(numIndex === 0 || dateString != this.state.chatList[numIndex - 1].created_at, <div style={{fontSize:"14px", fontFamily: "Montserrat", textAlign: 'center'}}>{dateString}</div>, null)}
            
              <div key={numIndex} style={chatObj.attributes.account_id === this.state.accountId ? webStyles.receiverMessage : webStyles.senderMessage}>
                <img
                  src={conditionSort(chatObj.attributes.profile?.url, chatObj.attributes.profile?.url, UserDefault) }
                  alt={chatObj.attributes.user_name} style={{ ...webStyles.avatar, marginRight: chatObj.attributes.account_id !== this.state.accountId ? "10px" : "",
                    marginLeft: conditionSort(chatObj.attributes.account_id === this.state.accountId, "10px", "") }} />
                <div style={{
                  ...webStyles.messageText,
                  background: ((this.state.typeOfUser === "seller") == (chatObj.attributes.account_id === this.state.accountId)) ? "rgba(16, 191, 184, 0.3)" : "rgba(84, 18, 252, 0.3)",
                  borderRadius: chatObj.attributes.account_id !== this.state.accountId ? "20px 20px 20px 0" : "20px 20px 0px 20px"
                }}>
                  {chatObj.attributes.attachments?.length > 0 ? 
                  <>{chatObj.attributes.attachments.map((fileObj: any) => (
<div style={webStyles.file}>
                    <img src={documentIcon} />
                    <div style={webStyles.fileText}>{fileObj.title || 'Lorem ipsum'}</div>
                  </div> 
                  ))}
                  
                  </>
                  : 
                  <></>}
                  {chatObj.attributes.message}</div>

              </div>
              <div style={{ ...webStyles.userName, flexDirection: chatObj.attributes.account_id === this.state.accountId ? "row-reverse" : "row" }}>{chatObj.created_time}</div>
            </>
            )
                  })}
        </div>
        <div style={webStyles.input}>
          <input
            type="text"
            placeholder=""
            value={this.state.message}
            data-test-id="input-msg"
            onKeyPress={(ev) => this.handleMessageKeyDown(ev.key)}
            onChange = {(ev) => this.handleMessageChange(ev.target.value)}
            style={webStyles.inputField}
          />
          <img data-test-id="btnSend" style={webStyles.button} src={sendIcon} alt="send message" onClick={() => this.handleSendMessage()}/>
          <img data-test-id="btnCloseModal" src={cancelIcon} style={webStyles.button} onClick={() => this.props.closeChat()} alt="close chat"/>
        </div>
      </div>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    overflow: 'hidden',
    height: "100%"
  },
  chat: {
    flex: 1,
    padding: '32px',
    overflowY: 'auto' as 'auto',
  },
  senderMessage: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderRadius: "10px",
    flexDirection: "row" as "row"
  },
  receiverMessage: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    flexDirection: "row-reverse" as "row-reverse"
  },
  avatar: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    alignSelf: "flex-end"
  },
  userName: {
    marginBottom: "20px", 
    display: "flex",
    fontSize:"12px",
    fontFamily: "Montserrat",
  },
  messageText: {
    backgroundColor: '#f2f2f2',
    maxWidth: "75%",
    fontSize:"12px",
    padding: '16px',
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  file: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    border: "1px solid",
    borderRadius: "12px",
    fontSize: "20px",
    marginBottom: "20px",
    fontFamily: "Montserrat",
    alignItems: "center"
  },
  fileText: {
    marginRight: "auto",
    marginLeft: "20px",
    paddingRight: "20px"
  },
  sender: {
    background: "pink"
  },
  receiver: {
    background: "blue"
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  inputField: {
    flex: 1,
    padding: '14px',
    marginRight: '10px',
    border: '1px solid #ccc',
    borderRadius: '10px',
  },
  button: {
    cursor: 'pointer',
    marginLeft: "16px"
  },

};
// Customizable Area End
