import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { RouterProps } from "react-router"
import { getStorageData } from "../../../framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
// Customizable Area Start
type NewsAttributesData ={
  titleNew: string;
  descriptionNew: string;
  shortDescription: string;
}

type TranslatedNewsData ={
  titleNew: string;
  descriptionNew: string;
  shortDescription: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface MatchType  {
    params: {
        id: string;
    }
}
export type Props =  WithStyles & RouterProps & {
    navigation: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
    id: string;
    match: MatchType;
  // Customizable Area Start
  // Customizable Area End
}

export interface AttributesType {
  id: number,
  title: string,
  description: string,
  image_url: string | null,
  short_description: string,
  next_previous_ids: {
    previous: string,
    next: string
  }
}


export interface ResponseType {
  id: string,
  type: string,
  attributes: AttributesType
}

interface S {
  // Customizable Area Start
  newsId: string | number;
  newsData: ResponseType;
  leftData: string;
  isPreview: boolean;
  isNext: boolean;
  rightData: string;
  lang:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
 


// Customizable Area Start
// Customizable Area End

export default class NewsDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNewsCallBackID: string = "";
  getPrevNewsCallBackID: string = "";
  getNextNewsCallBackID: string = "";
  // Customizable Area End



  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
  
    this.state = {
        lang:"",
        newsId: props.navigation.getParam("id"),
        newsData: {id:"", type: "news", attributes: {
          title: "",
          image_url: "",
          description: "",
          next_previous_ids: {
            previous: "",
            next: ""
          }
        }} as ResponseType,
        isPreview: true,
        isNext: true,
        leftData: "",
        rightData: ""
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getLang();
    this.getNewsApi();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if(prevProps.navigation.getParam("id") != this.props.navigation.getParam("id")){
      this.setState({newsId: this.props.navigation.getParam("id")},()=> this.getNewsApi())
    }
  }
  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.messageNewsDetailsApi(message);
    // Customizable Area End
  }

  // Customizable Area Start

  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }
  
  getLang = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return   this.setState({ lang: lang })
    }
    this.setState({ lang: 'en' })
  }


  applyTranslation = async (newsAttributesData:any) => {
    let translatedObj : any = await TranslateText(newsAttributesData, this.state.lang, 'en')
      return translatedObj
  }
  messageNewsDetailsApi = async (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.getNewsCallBackID === apiRequestCallId){
      if(responseJson && responseJson.data){ 
        const {id, type, attributes} = responseJson.data
        const transationValue : ResponseType ={
          id: id,
          type: type,
          attributes:{
            id: attributes.id,
            title: this.getSpecifiedLangString(this.state.lang, attributes.title, attributes.arabic_title),
            description: this.getSpecifiedLangString(this.state.lang, attributes.description, attributes.arabic_description),
            short_description:   this.getSpecifiedLangString(this.state.lang, attributes.short_description, attributes.arabic_short_description),
            image_url: responseJson.data.attributes.image_url,
            next_previous_ids: responseJson.data.attributes.next_previous_ids
          }
        }
        this.setState({
          newsData: transationValue,
          newsId: responseJson.data.id,
          isPreview: (responseJson.data.attributes.next_previous_ids.previous !== responseJson.data.attributes.id),
          isNext: (responseJson.data.attributes.next_previous_ids.next !== responseJson.data.attributes.id)
        });
      }
    }
  }
  

  prevNews = (prevId: string) => {
    this.props.navigation.navigate(`NewsDetails`, {id:prevId});
  }

  nextNews = (nextId: string) => {
    this.props.navigation.navigate(`NewsDetails`, {id:nextId});
  }

  getNewsApi = async () => {
    const {newsId} = this.state;
    const headers = {"content-type": "application/json"};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.NewsApiEndpoint}/${newsId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),`${configJSON.getMethod}`);
    this.getNewsCallBackID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
