export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const namiLogo = require("../assets/NamiLogo.svg");
export const namiLogoBlack = require("../assets/namiLogoBlack.svg");
export const nameLogoArabic = require("../assets/useNameLogoarabic.png");
export const faceBook = require("../assets/facebookColored.png");
export const youTube = require("../assets/YoutubeColored.png");
export const twitterLogo = require("../assets/TwitterColored.png");
export const imageLinkedin = require("../assets/LinkedInColored.png");

export const imageRight = require("../assets/rightPick.png");

export const youTubeColored = require("../assets/YoutubeColored.png");
export const twitterLogoColored = require("../assets/TwitterColored.png");
export const imageLinkedinColored = require("../assets/LinkedInColored.png");
export const faceBookColored = require("../assets/facebookColored.png");

export const downTriangleLogo = require("../assets/downTriangle.png");
export const registerInfo = require("../assets/info.svg");
export const searchIcon = require("../assets/tabler_search.png");
export const downArrow = require("../assets/downArrow.svg");

export const arrowBg = require("../assets/arrow.svg");
export const close = require("../assets/close.svg");
