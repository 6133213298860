import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { externalLink, availableRequest, deal, exploreListing, subscription, seeMoreArrow } from "./assets";
import FooterBlock from "../../../components/src/FooterBlock.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import { Box, Button, CircularProgress, Link as NavTag } from "@material-ui/core";
import { userDefaultImage } from "../../../components/src/assets";
import { conditionSort } from "../../../components/src/Helper";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      avatar, bio, role,
      linkedinLink, acquisitionClosed,
      annualAquisitions, accreditedBuyer,
      country, city,
    } = this.state;

    const found = webConfigJSON.preAccreditedBuyerOptions.find((objec: {arTitle: string, title: string, value: string}) => objec.value == accreditedBuyer) ||  {};
    const accBuyer = conditionSort(this.state.language == 'ar', found.arTitle, found.title);
    return (
      <>
        <HeaderBlock />
        {this.state.loading ? (
          <Box width={"100%"} height={"300px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <div className={classes.container}>
          <main className={classes.content}>
            <div className={classes.profileSummary}>
              <div className={classes.summaryAvatarSection}>
                <img data-test-id="img-b"
                  src={avatar || userDefaultImage} 
                  alt="buyer avatar" 
                  className={`${classes.avatar} ${classes.summaryAvatar}`} />
                <div className={classes.buyerBriefIntro}>
                  <div className={classes.buyerBriefIntroName}>{this.getFullName()}</div>
                  <div className={classes.buyerBriefIntroDesc}>{conditionSort(this.state.language == 'en', role, conditionSort(role =='buyer', 'المشتري', 'البائع'))}</div>
                </div>
              </div>

              <div className={classes.locationWrapper}>
                <div className={classes.label}>{this.state.pageText.location}</div>
                <div className={classes.locationText}>{city}, {country}</div>
              </div>
              <div className={classes.introWrapper}>
                <div className={classes.label}>{this.state.pageText.aboutYourself}</div>
                <div className={classes.textareaContainer}>
                  <textarea 
                    readOnly
                    className={classes.introText}
                    value={bio}
                  />
                </div>
              </div>
              <div className={classes.linkedinWrapper}>
                <div className={classes.label}>{this.state.pageText.linkedInProfile} </div>
                <div className={classes.linkedinLinkWrapper}>
                  <a
                    href={linkedinLink}
                    target="blank"
                    className={`${classes.link} ${classes.linkedinLink}`}>
                    <div>{this.state.pageText.viewInLinkedIn}</div>
                    <img src={externalLink} alt="external link icon" />
                  </a>
                </div>
              </div>

              <div className={classes.partition}></div>

              <div className={classes.closedAcquisitionsWrapper}>
                <div className={classes.label}>{this.state.pageText.noOfAcq}</div>
                <div className={classes.closedAcquisitionsText}>{acquisitionClosed}</div>
              </div>
              <div className={classes.projectedAcquisitionsWrapper}>
                <div className={classes.label}>{this.state.pageText.projectedAnnualAcq}</div>
                <div className={classes.projectedAcquisitionsText}>{annualAquisitions}</div>
              </div>

              <div className={classes.partition}></div>

              <div className={classes.accreditedBuyerWrapper}>
                <div className={classes.label}>{this.state.pageText.accreditedBuyer}</div>
                <div className={classes.accreditedBuyerText}>{ accBuyer}</div>
              </div>

              <div className={classes.fullProfileBtnWrapper}>
                <Button
                variant="contained"
                  onClick={this.handleProfileClick}
                  className={`${classes.dashboardBtn} ${classes.fullProfileBtn}`}>
                  {this.state.pageText.viewFullProfile}
                </Button>
              </div>
            </div>
            <div className={classes.profileStatus}>
              <div className={classes.decorativeDiv}>
                <div className={classes.profile_progress_bar} style={{width:`${this.state.profile_percentage}%`}}></div>
              </div>
              <div className={classes.statusContentWrapper}>
                <div className={classes.completePercentage} data-test-id="progress_id">
                  {this.state.language === "en" ? `${this.state.profile_percentage}% ${this.state.pageText.complete}` : `${this.state.pageText.complete} ${this.state.profile_percentage}%`}
                </div>
                <div className={classes.statusAvatarSection}>
                  <img 
                    src={avatar || userDefaultImage} 
                    alt="buyer avatar" 
                    className={`${classes.avatar} ${classes.statusAvatar}`} />
                  <div className={classes.buyerProfileWrapper}>
                    <div className={classes.profileLabel}>{this.state.pageText.yourProfile}</div>
                    <div className={classes.buyerName}>{this.getFullName()}</div>
                  </div>
                </div>
                <div className={classes.completeProfileBtnWrapper}>
                  {Number(this.state.profile_percentage) !== 100 && <Button 
                     variant="contained"
                     onClick={this.handleCompleteProfile}
                    className={ `${classes.dashboardBtn} ${classes.completeProfileBtn}`}
                  >
                    {this.state.pageText.completeYourProfile}
                  </Button>}
                </div>
              </div>
            </div>
            <div className={classes.activities}>
              <div className={classes.activitiesTitle}>{this.state.pageText.yourActivities}</div>
              <div className={classes.activitiesWrapper}>
                <NavTag 
                  id="cata"
                  onClick={() => this.send(this.getNameRoute('Catalogue'))}
                  className={classes.activityWrapper}>
                  <div className={classes.activitiesWrapperDiv}>
                    <img
                      className={classes.activityIcon}
                      src={availableRequest} 
                      alt="available request icon" />
                    <div className={classes.activityText}>
                      <div className={classes.activityName}>{this.state.pageText.availableThisWeek}</div>
                    </div>
                  </div>
                  <div className={classes.requestStatistics} style={this.state.language == 'ar' ? {marginRight: 'auto', marginLeft: 'unset'}: {}}>
                    {`0${this.state.requestRemaining}`} / 05
                  </div>
                </NavTag>

                <NavTag
                  id="deals"
                  onClick={() => this.send(this.getNameRoute('Deals'))}
                  className={classes.activityWrapper}>
                    <div className={classes.activitiesWrapperDiv}>
                      <img
                        className={classes.activityIcon}
                        src={deal} 
                        alt="your deals icon" />
                      <div className={classes.activityText}>
                        <div className={classes.activityName}>{this.state.pageText.yourDeals}</div>
                        <div className={classes.activityDesc}>{this.state.pageText.dealsSub}</div>
                      </div>
                    </div>
                  <div className={this.handleConditon(this.state.language == 'en' , classes.seeMore , classes.seeMoreAr)}>
                    <img 
                      className={this.handleConditon(this.state.language == 'en' , classes.seeMoreArrow , classes.seeMoreArrowAr)}
                      src={seeMoreArrow} 
                      alt="Go to deals icon" />
                  </div>
                </NavTag>

                <NavTag
                  id="catalogueId"
                  onClick={this.handleCatalogueNavigation}
                  data-test-id="catalogueNavTestId"
                  className={classes.activityWrapper}>
                  <div className={classes.activitiesWrapperDiv}>
                    <img
                      className={classes.activityIcon}
                      src={exploreListing} 
                      alt="explore listings icon" />
                    <div className={classes.activityText}>
                      <div className={classes.activityName} data-test-id="catalogueNavTextId" >{this.state.pageText.exploreListings}</div>
                      <div className={classes.activityDesc}>{this.state.pageText.fO}</div>
                    </div>
                  </div>
                  <div className={this.handleConditon(this.state.language == 'en' , classes.seeMore , classes.seeMoreAr )}>
                    <img 
                      className={this.handleConditon(this.state.language == 'en' , classes.seeMoreArrow , classes.seeMoreArrowAr)}
                      src={seeMoreArrow} 
                      alt="listing icon" />
                  </div>
                </NavTag>

                <NavTag 
                  id={"anchorID"}
                onClick={() => this.send(this.getNameRoute('BuyerSubscription'))}
                  className={classes.activityWrapper}>
                  <div className={classes.activitiesWrapperDiv}>
                    <img
                      className={classes.activityIcon}
                      src={subscription} 
                      alt="your subscription icon" />
                    <div className={classes.activityText}>
                      <div className={classes.activityName}>{this.state.pageText.yourSubscription}</div>
                      <div className={classes.activityDesc}>{this.state.pageText.vP}</div>
                    </div>
                  </div>
                  <div className={this.handleConditon(this.state.language == 'en' , classes.seeMore , classes.seeMoreAr)}>
                    <img 
                      className={this.handleConditon(this.state.language == 'en' , classes.seeMoreArrow , classes.seeMoreArrowAr)}
                      src={seeMoreArrow} 
                      alt="subscription icon" />
                  </div>
                </NavTag>
              </div>
            </div>
          </main>
        </div>
        )}
        <FooterBlock />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const customStyles = (theme: Theme) => {
  return createStyles({
    container: {
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Montserrat, sans-serif",
      "& *": {
        boxSizing: "border-box",
      },
      "& a": {
        textDecoration: "none",
      },
    },
    content: {
      width: "100%",
      maxWidth: "1440px",
      padding: "50px",
      
      display: "grid",
      gridTemplateColumns: "39.3% calc(100% - 39.3% - 40px)",
      gridTemplateRows: "auto 1fr", // the 2nd row to take the remaining space
      gridGap: "40px 40px",
      
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "100%",
        gridGap: "15px 0px",
        paddingTop: "49px",
        paddingLeft: "23px",
        paddingRight: "22px",
        paddingBottom: "67px",
      }
    },
    profileSummary: {
      display: "flex",
      flexDirection: "column",
      gap: "18px",
      borderRadius: "20px",
      border: "1px solid #8C8C8C",
      padding: "18px 32px",
      gridArea: "1 / 1 / 3 / 2",
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        gridArea: "2 / 1 / 3 / 1",
        paddingTop: "40px",
        paddingLeft: "42px",
        paddingRight: "42px",
        paddingBottom: "68px",
        '& > *:not(:first-child):not(:last-child)': {
          marginLeft: "8px",
        },
      },
    },
    summaryAvatarSection: {
      display: "flex",
      gap: ".5rem",
      alignItems: "center"
    },
    avatar: {
      borderRadius: "50%",
    },
    summaryAvatar: {
      width: "100px",
      height: "100px",
        objectFit:"cover",
      [theme.breakpoints.down("sm")]: {
        width: "78px",
        height: "78px",
      },
    },
    buyerBriefIntro: {
      display: "flex",
      flexDirection: "column",
      gap: "8px"
    },
    buyerBriefIntroName: {
      color: "#262626",
      fontSize: "24px",
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    buyerBriefIntroDesc: {
      textTransform: "capitalize",
      color: "#8C8C8C",
      fontSize: "16px",
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    locationWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down("sm")]: {
      },
    },
    locationText: {
      color: "#262626",
      fontSize: "18px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    introWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down("sm")]: {
      },
    },
    textareaContainer: {
      height: "110px",
      borderRadius: "12px",
      border: "1px solid #8C8C8C",
      padding: "20px",
      paddingRight: 5,
      [theme.breakpoints.down("sm")]: {
        padding: "8px 16px",
        height: "82px",
        paddingRight: 3,
      },
    },
    introText: {
      width: "100%",
      height: "100%",
      border: "none",
      outline: "none",
      color: "#8c8c8c",
      fontSize: "16px",
      fontWeight: 400,
      resize: "none",
      paddingRight: 10,
      fontFamily:"Montserrat, sans-serif ",
      '&::-webkit-scrollbar': {
        width: 6, // Adjust the width as needed
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9', // Scrollbar color
        borderRadius: 4, // Border radius
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'darkgrey', // Hovered scrollbar color
      },
      
      // Styles for Firefox
      scrollbarWidth: 'thin', // "thin" or "auto"
      scrollbarColor: '#D9D9D9 darkgrey', // Scrollbar color and hover color
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
        paddingRight: 5,
      },
    },
    linkedinWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down("sm")]: {
      },
    },
    linkedinLinkWrapper: {
      color: "#262626",
      fontSize: "18px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    linkedinLink: {
      color: "inherit",
      display: "inline-flex",
      gap: "10px",
      fontSize: "16px"
    },
    closedAcquisitionsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down("sm")]: {
      },
    },
    closedAcquisitionsText: {
      color: "#262626",
      fontSize: "18px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
      },
    },
    projectedAcquisitionsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down("sm")]: {
      },
    },
    projectedAcquisitionsText: {
      color: "#262626",
      fontSize: "18px",
      fontWeight: 600,
    },
    accreditedBuyerWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down("sm")]: {
      },
    },
    accreditedBuyerText: {
      color: "#262626",
      fontSize: "18px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    profileStatus: {
      borderRadius: "20px",
      border: "1px solid #8C8C8C",
      lineHeight: "24px",
    },
    decorativeDiv: {
      borderRadius: "20px 20px 0px 0px",
      width:"100%",
      backgroundColor:"rgba(84, 18, 252, 0.5)",
      height: "18px",
      overflow:"hidden",
      [theme.breakpoints.down("sm")]: {
        background: "rgba(84, 18, 252, 0.50)",
      },
    },
    profile_progress_bar:{
    borderRadius: "0px 20px 20px 0px",
    background: "#5412FC",
    height:"18px",
    },
    statusContentWrapper: {
      padding: "8px 18px 14px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "9px",
        paddingLeft: "20px",
        paddingRight: "25px",
        paddingBottom: "21px",
      },
    },
    completePercentage: {
      textAlign: "right",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    statusAvatarSection: {
      display: "flex",
      gap: ".5rem",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        gap: "14px",
      },
    },
    statusAvatar: {
      width: "64px",
      height: "64px",
      objectFit:"cover",
      [theme.breakpoints.down("sm")]: {
        width: "57px",
        height: "57px",
      },
    },
    buyerProfileWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "8px"
    },
    profileLabel: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#595959",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        color: "#000",
      },
    },
    buyerName: {
      fontSize: "24px",
      fontWeight: 700,
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    completeProfileBtnWrapper: {
      display: "flex",
      justifyContent: "end",
      [theme.breakpoints.down("sm")]: {
      },
    },
    activities: {
      display: "flex",
      flexDirection: "column",
      lineHeight: "24px",
      gap: "24px",
      [theme.breakpoints.down("sm")]: {
      },
    },
    activitiesTitle: {
      color: "#000",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    activitiesWrapper: {
      flex: 1,
      gap: "22px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        gap: "15px",
      },
      "& .MuiLink-root": {
        textDecoration: "none"
      }
    },
    activityWrapper: {
      borderRadius: "20px",
      border: "1px solid #8C8C8C",

      padding: "24px 48px",
      minHeight: "118px",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "16px",
        paddingRight: "21px",
        paddingBottom: "16px",
        paddingLeft: "20px",
        minHeight: "82px",
      },
    },
    activitiesWrapperDiv: {
      display: "flex",
      alignItems: "center",
      gap: "18px"
    },
    activityIcon: {
      width: "52px",
      height: "52px",
      [theme.breakpoints.down("sm")]: {
        width: "32px",
        height: "32px",
      },
    },
    activityText: {
      display: "flex",
      flexDirection: "column",
      gap: "8px"
    },
    activityName: {
      color: "#262626",
      fontSize: "18px",
      fontWeight: 700,
      "&:not(:last-child)": {
        [theme.breakpoints.down("sm")]: {
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    activityDesc: {
      color: "#8c8c8c",
      fontSize: "16px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    requestStatistics: {
      padding: "17px 27px",
      borderRadius: "12px",
      border: "1px solid #5412FC",
      boxShadow: "0px 0px 4px 2px rgba(84, 18, 252, 0.30) inset",
      color: "#5412FC",
      fontSize: "18px",
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        padding: "11px 15px",
        borderRadius: "8px",
        fontSize: "12px",
      },
    },
    seeMore: {
      paddingRight: "46px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "20px",
      },
    },
    seeMoreAr: {
      paddingRight: "46px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "20px",
      },
    },
    seeMoreArrow: {
      width: "32px",
      height: "32px",
      [theme.breakpoints.down("sm")]: {
        width: "16px",
        height: "16px",
      },
    },
    seeMoreArrowAr: {
      width: "32px",
      height: "32px",
      rotate:"180deg",
      [theme.breakpoints.down("sm")]: {
        width: "16px",
        height: "16px",
      },
    },
    partition: {
      height: "1px",
      backgroundColor: "#8c8c8c",
    },
    label: {
      color: "#8C8C8C",
      fontSize: "16px",
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    fullProfileBtnWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: "18px"
    },
    dashboardBtn: {
      width: "228px",
      height: "44px",
      boxSizing: "border-box",
      fontSize: "16px",
      fontFamily: "inherit",
      border: "none",
      borderRadius: "12px",
      background: "#10BFB8",
      color: "#fff",
      fontWeight: 700,
      textTransform:"none",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "5px",
        fontSize: "10px",
      },
      "&:hover":{
        backgroundColor:"10BFB8"
      }
    },
    fullProfileBtn: {
      width: "calc(100% - 56px)",
      height: "48px",
      boxSizing: "border-box",
      fontSize: "16px",
      [theme.breakpoints.down("sm")]: {
        padding: "6px 62px",
      },
    },
    completeProfileBtn: {
      [theme.breakpoints.down("sm")]: {
        padding: "7px 10px",
        lineHeight: "22px",
      },
    },
    link: {
      border: "none",
      background: "none",
      textDecoration: "none",
    },
  });
};

export const BuyerDashboard = withStyles(customStyles)(Dashboard);
// Customizable Area End
