import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  selectedType: number;
  onHover: number;
  pageTxtData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  addUserRoleCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      selectedType: 0,
      onHover: 0,
      pageTxtData: {
        iAmA: configJSON.iAmA,
        onboardingTypeSeller: configJSON.onboardingType.Seller,
        seller: configJSON.seller,
        onboardingTypeBuyer: configJSON.onboardingType.Buyer,
        buyer: configJSON.buyer,
        proceed: configJSON.proceed,
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getListCallId) {
          this.setState({
            subscriptions: responseJson.data
          });
        }
      }
    }
    this.handleResForAddUserRole(from,message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    let lang = await getStorageData("language");
    this.applyTranslation(lang);

    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start
  setSelectedType = (type: number) => {
    this.setState({ selectedType: type });
  };

  setOnHover = (type: number) => {
    this.setState({ onHover: type });
  };

  addUserRole = async() => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const data = {
      attributes: {
        "user_type": this.state.selectedType 
      },
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserRoleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addRoleEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForAddUserRole = async (from: string, message: Message) => {
    if (this.addUserRoleCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        if(responseJson.data.attributes.user_type == configJSON.onboardingType.Seller){
          setStorageData('typeOfUser','seller');
          this.props.navigation.navigate("Categoriessubcategories");
        }
        if(responseJson.data.attributes.user_type == configJSON.onboardingType.Buyer){
          setStorageData('typeOfUser','buyer')
          this.props.navigation.navigate("BuyerSubscription");

        }
      }
    }
  }

  applyTranslation =  async (lang: string) => {
    
    if(!lang || lang === 'en') {
      await TranslateText(this.state.pageTxtData, lang, 'en')  
    }
    else {
      this.setState({ pageTxtData: configJSON.staticTextArabic });

    }
  }
  // Customizable Area End
}
