import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";

// Customizable Area Start

type NewsAttributesData ={
  titleNew: string;
  descriptionNew: string;
  shortDescription: string;
}

type TranslatedNewsData ={
  titleNew: string;
  descriptionNew: string;
  shortDescription: string;
}

// Customizable Area End

export const configJSON = require("./config");
export interface MatchType  {
  params: {
      id: string;
  }
}

export type Props =  WithStyles & {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
  match: MatchType;
  // Customizable Area Start
  // Customizable Area End
}

export interface AttributesType {
  arabic_title?: string;
  arabic_description?: string;
  arabic_short_description?: string;
  id: number,
  title: string,
  description: string,
  image_url: string | null,
  short_description: string
}


export interface ResponseType {
  id: string,
  type: string,
  attributes: AttributesType
}

interface S {
  // Customizable Area Start
  newsData: Array<AttributesType>;
  lang:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
 


// Customizable Area Start
// Customizable Area End

export default class NewsGridController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllNewsCallBack: string = "";
  // Customizable Area End



  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
  
    this.state = {
      lang:"",
      newsData: []
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getLang();
    this.getAllNewsApi();
    // Customizable Area End
  }

  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.messageSendOTPApi(message)
    // Customizable Area End
  }

  // Customizable Area Start


  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }

  trimString = (data: string, limit: number) => {
    if(data.length < limit){
      return data;
    }
    return data.substring(0, limit).substring(0, data.substring(0, limit).lastIndexOf(' ')) + '...';
  }

  moreNews = (newsId: number) => {
    this.props.navigation.navigate(`NewsDetails`, {id: newsId});
  }

  messageSendOTPApi = async(message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.getAllNewsCallBack === apiRequestCallId){
      if(responseJson && responseJson.data){
        const record : AttributesType[] = await Promise.all(responseJson.data.map(async(item:ResponseType) => {
          const newsAttributesData : NewsAttributesData={
              titleNew: this.getSpecifiedLangString(this.state.lang, item.attributes.title, item.attributes.arabic_title),
              descriptionNew: this.getSpecifiedLangString(this.state.lang, item.attributes.description, item.attributes.arabic_description),
              shortDescription:  this.getSpecifiedLangString(this.state.lang, item.attributes.short_description, item.attributes.arabic_short_description)
          }
         const transaltionNewsData : TranslatedNewsData= await this.applyTranslation(newsAttributesData)
            const transationValue : AttributesType ={
              id: item.attributes.id,
              title: transaltionNewsData.titleNew,
              description: transaltionNewsData.descriptionNew,
              short_description:  transaltionNewsData.shortDescription,
              image_url: item.attributes.image_url
 
            }
          return transationValue

          }))
        this.setState({
          newsData: record
        });
      }
    }
  } 

  getLang = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return   this.setState({ lang: lang })
    }
    this.setState({ lang: 'en' })
  }


  applyTranslation = async (newsAttributesData:NewsAttributesData) => {
    let translatedObj : any = await TranslateText(newsAttributesData, this.state.lang, 'en')
      return translatedObj
  }
  getAllNewsApi = async () => {

    const headers = {
      "content-type": "application/json"
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.NewsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.getMethod}`
    );

    this.getAllNewsCallBack = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
