// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import React from "react";
import { conditionSort, getToastPosition } from "../../../components/src/Helper";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
export const baseURL = window.location.origin;
export interface SortByType {
  name: string;
  id: string;
}
interface CatalogueListAttributes {
  id: number;
  title: string;
}

export interface CountryOption {
  icon: string;
  country: string;
  cities: string[];
}

export  interface CatalogueList {
  id: string;
  type: string;
  attributes: CatalogueListAttributes;
}

interface ArchivedList {
  id: string;
  type: string;
  attributes: {
    id: number;
    listing: ListingDetails | ListingWithDeal[];
    image_sub_category: ImageSubCategory | null;
  };
}

interface ListingDetails {
  name: string | null;
  status: string;
  ltm_gross_revenue: string | null;
  ltm_net_profit: string | null;
  asking_price: string | null;
  arabic_name: string | null;
  description: string;
  status_updated_on: string;
  arabic_description: string;
  flag: boolean;
  category_name: string;
  arabic_category_name: string;
}

interface ListingWithDeal {
  data: {
    id: string;
    type: string;
    attributes: {
      listing_id: number;
      status: string;
      updated_at: string;
      auto_sign_nda: {
        url: string;
      };
      listing: {
        category_name: string;
        arabic_category_name: string;
        image: string;
        description: string;
        ltm_gross_revenue: string;
        ltm_net_profit: string;
        asking_price: string;
        status_updated_on: string;
        is_bookmarked: boolean;
        is_archieved: boolean;
      };
      is_reported: boolean;
      name: string;
      arabic_name: string;
      arabic_description: string;
      is_read: boolean;
    };
  };
}

interface ImageSubCategory {
  url: string;
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
};

interface ArchivedResponse {
  data: Array<ArchivedList>
}

interface ReportAttributes {
  id: number;
  name: string;
  arabic_name: string;
}

interface Report {
  id: string;
  type: string;
  attributes: ReportAttributes;
}

interface CardDetailType {
  type: string;
      imagePath: string;
      heading: string;
      status: string;
      ltmGrassRevenue: string;
      ltmNetProfit: string;
      askingPrice: string;
      statusUpdatedOn : string;
      description : string;
      id: string;
      isBookmarked: boolean;
      isArchived: boolean;
      isReported: boolean;
      listingId: number;
      isAutoSignIn : string;
}

interface Favlist {
  id: string;
  type: string;
  attributes: {
    id: number;
    listing_id: number;
    catalogue_list_id: number;
    account_id: number;
    bookmarked: boolean;
    bookmarked_listings: BookmarkedListingSingle | BookmarkedListingMultiple[];
    image: Image;
  };
}

interface BookmarkedListingSingle {
  name: string;
  status: string;
  ltm_gross_revenue: string;
  ltm_net_profit: string;
  asking_price: string;
  arabic_name: string;
  description: string;
  arabic_description: string;
  flag: boolean;
  category_name: string;
  arabic_category_name: string;
}

interface BookmarkedListingMultiple {
  data: {
    id: string;
    type: string;
    attributes: {
      listing_id: number;
      status: string;
      updated_at: string;
      auto_sign_nda: AutoSignNDA;
      listing: Listing;
      is_reported: boolean;
      is_read:boolean;
      name: string;
      arabic_name: string;
      arabic_description: string;
    };
  };
}

export interface Category {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

interface CategoryAttributes {
  id: number;
  name: string;
  arabic_category: string;
  logo: Image;
  dark_icon: Image | null;
  dark_icon_active: Image | null;
  dark_icon_inactive: Image | null;
  light_icon: Image | null;
  light_icon_active: Image | null;
  light_icon_inactive: Image | null;
  rank: number;
  created_at: string;
  updated_at: string;
  sub_categories: SubCategory[];
}

interface Image {
  url: string;
}

interface SubCategory {
  id: number;
  name: string;
  image: Image;
  arabic_sub_category: string;
  created_at: string;
  updated_at: string;
}

interface AutoSignNDA {
  url: string;
}

interface Listing {
  category_name: string;
  arabic_category_name: string;
  image: string;
  description: string;
  ltm_gross_revenue: string;
  ltm_net_profit: string;
  asking_price: string;
  status_updated_on: string;
  is_bookmarked: boolean;
  is_archieved: boolean;
}

interface Image {
  id: number;
  url: string;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cardDetails: any[];
  selectedButton : string;
  language: string;
  searchText: string;
  categoriesList: [],
  select: string;
  avatar: string;
  sortByData: SortByType[];
  anchorEl: null | HTMLElement;
  firstName: string;
  availableRequests: number;
  filters: any;
  bookmarkList: CatalogueList[];
  filterSortBy: string;
  menuOpen: boolean;
  pageText: {
    "viewDashboard": string,
    "filters": string,
    "sortBy": string,
    "availableRequests":string,
    "viewByList": string,
    "all": string,
    "accepted":string, 
    "pending":string, 
    "rejected":string, 
    "cancelled":string,
    "ltmGrossRevenue": string,
    "ltmNetProfit": string,
    "aksingPrice": string,
    "status": string,
    "acceptedOn": string,
    "search": string,
    "noData": string,
  
    requestAccepted: string,
    requestSent: string,
    requestRejected: string,
    shareThisDeal: string,
    viaLinkText: string,
    twitterText: string,
    instragramText: string,
    copiedTxtMsg: string,
    whatsAppText: string,
    faceBookText: string,
    linkedInText: string,
    archiveThisDeal: string,
    archiveText: string,
    hideTheListingText: string,
    flagTheListing: string,
    chooseThisWillText: string,
    whyReportingDeal: string,
    reportText: string,
    requestCancelled: string,
    sentOn: string,
    rejectedOn: string,
    cancelledOn: string,
    addToList: string,
    createNewList: string,
    archivedText: string,
    archivedOn: string
  }
  archivedView: boolean;
  archivedListings: Array<CardDetailType>;
  openBookmarkModal: boolean;
  openSharing: boolean;
  openArchiveModal: boolean;
  openReportReason: boolean;
  addList: boolean;
  listName: string;
  toBeBookmarked: string;
  reportReason: Array<Report>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export class DealsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      cardDetails:[],
      categoriesList: [],
      menuOpen: false,
      filters: {
        location: { country: "", city: "" }
      },
      selectedButton:'all',
      bookmarkList: [],
      sortByData: [],
      filterSortBy: "date_newest",
      anchorEl: null,
      language: "",
      searchText: "",
      select: "",
      avatar: "",
      firstName: "",
      availableRequests: 0,
      pageText: {
        "viewDashboard": "",
        "filters": "",
        "sortBy": "",
        "availableRequests":"",
        "viewByList": "",
        "all": "",
        "accepted":"", 
        "pending":"", 
        "rejected":"", 
        "cancelled":"",
        "ltmGrossRevenue": "",
        "ltmNetProfit": "",
        "aksingPrice": "",
        "status": "",
        "acceptedOn": "",
        "noData": "",
        "search": "",
        "sentOn": "",
        "rejectedOn": "",
        "cancelledOn": "",
        requestAccepted: "",
        requestSent: "",
        requestRejected: "",
        requestCancelled: "",
        reportText: "",
        archiveThisDeal: "",
        whyReportingDeal: "",
        hideTheListingText: "",
        chooseThisWillText: "",
        flagTheListing: "",
        archiveText: "",
        archivedText: "",
        addToList: "",
        createNewList: "",
        shareThisDeal: "",
        linkedInText: "",
        faceBookText: "",
        instragramText: "",
        twitterText: "",
        copiedTxtMsg: "",
        whatsAppText: "",
        viaLinkText: "",
        archivedOn: ""
      },
      archivedView: false,
      openBookmarkModal: false,
      addList: false,
      listName: "",
      openArchiveModal: false,
      openReportReason: false,
      openSharing: false,
      toBeBookmarked: "",
      archivedListings: [],
      reportReason: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getListing();
    this.getBookmarks();
    this.getCategoriesRequest();
    this.getAccessRequest();
    this.translateText();
    this.getDashboardData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleAnyApiResponse(message);
      const responseAPIId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseAPIId === this.cardDetailsApiId){
        this.mapApiResponse(responseJson);
      }
      if (this.getAccessRequestAPI === responseAPIId) {
        this.receiveAccessRequest(responseJson);
      }
      if (responseAPIId === this.getApiBuyerDashboardCallId) {
        this.handleGetApiBuyerDashboardResponse(responseJson);
      }
      if (responseAPIId === this.bookmarkApiId && responseJson.data) {
        this.setState({bookmarkList: responseJson.data});
      }
      if (this.getCategoriesAPI === responseAPIId) {
        this.receiveCategoriesAPI(responseJson);
      }
      if (this.getArchivedListingCallID === responseAPIId) {
        this.handleArchived(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleFilterSortByMenu = (event: any) => {
    this.setState(
      {
        select: "",
        menuOpen: false,
        filterSortBy: (event.target as HTMLInputElement).value,
      },
      () => this.getListing()
    );
  };

  countriesData: CountryOption[] = [
    {
    icon: "https://flagcdn.com/sa.svg",
    country: 
      "Saudi Arabia",
    cities: [
        "Jubail",
        "Taif",
        "Mecca",
        "Medina",
        "Riyadh",
        "Jeddah",
        "Khobar",
        "Dhahran",
        "Dammam",
        "Buraydah",
      ],
    },
    {
      icon: "https://flagcdn.com/om.svg",
    country: "Oman",
    cities: [
        "Sohar",
        "As Suwayq",
        "Ibri",
        "Saham",
        "Barka",
        "Rustaq",
        "Muscat",
        "Seeb",
        "Salalah",
        "Bawshar",
      ],
    },
    {
      icon:"https://flagcdn.com/ae.svg",
    country: "United Arab Emirates",
    cities: [
        "Dubai",
        "Abu Dhabi",
        "Umm Al-Quwain",
        "Ras Al Khaimah",
        "Sharjah",
        "Al Ain",
        "Ajman",
        "Fujairah",
      ],
    },
    {
    cities: [
        "Doha","Al Wakrah","Umm Salal Ali","Al Rayyan","Al Khor","Mesaieed","Madinat ash Shamal","Umm Salal Muhammad",
      ],
    country: "Qatar",
    icon:"https://flagcdn.com/qa.svg"
    },
    {
      icon: "https://flagcdn.com/kw.svg",
    country: "Kuwait",
    cities: [
        "Kuwait City","Hawalli","Salmiya","Fintas","Jahra","Sabah Al Salem","Al Ahmadi","Mubarak Al-Kabeer","Farwaniya",
      ],
    },
    {
      cities: [
        "Riffa",
        "Muharraq",
        "Hamad Town",
        "Manama",
        "A'ali",
        "Isa Town",
        "Sitra",
        "Budaiya",
      ],
    country: "Bahrain",
    icon:"https://flagcdn.com/bh.svg"
    },
    {
    cities: [
        "Cairo",
        "Alexandria",
        "Suez",
        "Luxor",
        "Asyut",
        "Ismailia",
        "Tanta",
        "Giza",
        "Shubra El Kheima",
        "Port Said",
      ],
       country: "Egypt",
       icon:"https://flagcdn.com/eg.svg"
    },
  ];

  countriesDataAr: CountryOption[] =  [
    {icon: "https://flagcdn.com/sa.svg",country:"المملكة العربية السعودية",cities:["الرياض","جدة","مكة المكرمة","المدينة المنورة","الدمام","الخبر","الظهران","الجبيل","الطائف","بريدة",]},
    {icon:"https://flagcdn.com/om.svg" ,country:"عُمان",cities:["مسقط","السيب","السويق","عبري","صحم","بركاء","الرستاق","صلالة","بوشر","صحار",]},
    {icon:"https://flagcdn.com/eg.svg" ,country:"مصر",cities:["القاهرة","الإسكندرية","الجيزة","شبرا الخيمة","بورسعيد","السويس","الأقصر","أسيوط","الإسماعيلية","طنطا",]},
    {icon: "https://flagcdn.com/qa.svg",country:"قطر",cities:["الدوحة","الوكرة","مسيعيد","مدينة الشمال","أم صلال علي","أم صلال محمد","الريان","الخور",]},
    {icon: "https://flagcdn.com/ae.svg",country:"الإمارات العربية المتحدة",cities:["دبي","أبو ظبي","الشارقة","العين","عجمان","الفجيرة","أم القيوين","رأس الخيمة",]},
    {icon: "https://flagcdn.com/bh.svg",country:"البحرين",cities:["المنامة","الرفاع","المحرق","مدينة حمد","عالي","مدينة عيسى","سترة","البديع",]},
    {icon: "https://flagcdn.com/kw.svg",country:"الكويت",cities:["مدينة الكويت","حولي","السالمية","الفروانية","الفنطاس","الجهراء","صباح السالم","الأحمدي","مبارك الكبير",]}
  ]

handleLocationChange = (stateName: string, selectedValue: CountryOption | null) => {
  const { filters } = this.state;
  let newFilter = filters;
  if (stateName === "selectedCountry") {
    newFilter.location.country = selectedValue;
    newFilter.location.city = "";
  } else {
    newFilter.location.city = selectedValue;
  }
  this.setState(
    {
      filters: newFilter,
      menuOpen: false,
    },
    () => this.getListing()
  );
};


  handleTypeChange = (typeId: string) => {
    const { filters } = this.state;
    let newFilter = filters;
  
      newFilter.category_id = typeId;
  
    this.setState(
      {
        filters: newFilter,
        menuOpen: false,
      },
      () => this.getListing()
    );
  };

  handleCityOpen = () => {
    const { filters } = this.state;
    if (filters.location.country === "") {
      return [];
    }
  };

  handleConditon = (condition: any, trueSta: any, falseSta: any) => {
    return condition ? trueSta : falseSta
  }

  handleFavorite = (bookmarkId: string) => {
    const { filters } = this.state;
    let newFilter = filters;
  
      newFilter.bookmark_id = conditionSort(bookmarkId == newFilter.bookmark_id, "", bookmarkId);
    if (newFilter.bookmark_id === "") {
    this.setState({ filters: newFilter, archivedView: false, selectedButton: "all" }, () => this.getListing());
    }
    else {
      this.setState({ filters: newFilter, archivedView: false, selectedButton: "" }, () => this.fetchBookmarked());
    } 

  }
  handlePlaceHolder = (condition: any, trueSta:string, falseSta: string) => {
    return condition ? trueSta : falseSta
  }
  handleContries = () => {return (this.handleConditon(this.state.language === "en" , this.countriesData , this.countriesDataAr))}
  handleFilterMenuClick = (event: any, selection: string) => {
    this.setState({ select: selection, anchorEl: event.currentTarget, menuOpen: true });
  };

  clearFilterMenu = () => {
    this.setState({ select: "", anchorEl: null, menuOpen: false });
  };

  getCategoriesRequest = async () => {
    await getStorageData("language");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: await getStorageData('token'),
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.getCategoriesAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetApiBuyerDashboardResponse(responseJson: any) {
    const {
      first_name, last_name, image,
    } = responseJson.data.attributes;
  
    this.setState({
      avatar: image?.url || '',
      firstName: `${first_name} ${last_name}` 
    });
  }
  receiveAccessRequest = (responseJson: any) => {
   
     if (responseJson && responseJson.total_requests_remaining_this_week) {
      this.setState({availableRequests: responseJson.total_requests_remaining_this_week});
    }
    
  }
  getAccessRequestAPI= "";
  getAccessRequest = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token" :  await getStorageData("token")
    };

    let endPoint = `${configJSON.getAccessCountEndpoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccessRequestAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      this.setState({ searchText: event.target.value }, () => {
        this.getListing();
      });
    }
  }
  getIfElseConditions = (condition: boolean, ifTrue: string, ifFalse: string) => {
    return condition ? ifTrue : ifFalse;
  };
  getClass=(val:any,classstyle:any,classText:any)=>{
  return this.state.selectedButton ===val ? classstyle: classText
  }
  receiveCategoriesAPI = (responseJson: any) => {    
      this.setState({ categoriesList: responseJson.data });

  };
  translateText = async () => {
    const sortedDataAr =[
      { id: "price_low_to_high", name: "السعر: من الأدنى إلى الأعلى"},
      { id: "price_high_to_low", name: "السعر: من الأعلى إلى الأدنى" },
      { id: "a_to_z", name: "من الألف إلى الياء" },
      { id: "date_oldest", name: "تاريخ الإدراج: الأقدم" },
      { id: "date_newest", name: "تاريخ الإدراج: الأحدث" },
    ];

    const sortedData = [
      { id: "price_low_to_high", name: "Price: Low to High" },
    { id: "price_high_to_low", name: "Price: High to Low" },
    { id: "a_to_z", name: "A to Z" },
    { id: "date_oldest", name: "Date Listed: Oldest" },
    { id: "date_newest", name: "Date Listed: Newest" }
    ];
    let lang = await getStorageData("language") || 'en';
    this.setState({sortByData : lang ==="ar" ? sortedDataAr : sortedData})

    this.setState({pageText: lang === 'en' ? configJSON.staticText : configJSON.staticTextArabic, language: lang});
  }
  updateStatus = (cat?:string) =>{
    let { filters } = this.state;
    filters.bookmark_id = "";
    if (cat === "archived") {
      this.setState({ selectedButton: cat, filters }, () => {
        this.showArchived();
      });
    }
    else {
      this.setState({ selectedButton: cat || 'all', archivedView: false, cardDetails: [],archivedListings: [], filters }, () => { this.getListing() });
    };

  }

  cardDetailsApiId= "";
  bookmarkApiId= "";
  getApiBuyerDashboardCallId: string = "";
  getCategoriesAPI: string = "";
  getArchivedListingCallID: string = "";
  bookmarkAPICallId: string = "";
  unBookmarkAPICallId: string = "";
  bookmarkListAPICallId: string = "";
  archiveAPICallId: string = "";
  reportDealAPICallId: string = "";
  getReportsAPICallId: string = "";
  fetchBookmarkAPICallId: string = "";
  unArchiveAPICallId: string = "";
  getListing = async(cat?:string) =>{
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token" :  await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cardDetailsApiId = requestMessage.messageId;
    let lang = await getStorageData("language") || 'en';
    let endpoint = "";
    if(lang!== "en"){
      endpoint = `${configJSON.getDealsStatusEndPoint}?sort_by=${this.state.filterSortBy}&arabic_translate=true`
    }else{
      endpoint = `${configJSON.getDealsStatusEndPoint}?sort_by=${this.state.filterSortBy}`
    }
    endpoint = this.addCat(endpoint);
    endpoint = this.addSearchKeyToEndpoint(endpoint);
    endpoint = this.addBookmarkToEndpoint(endpoint);
    endpoint = this.addLocationFiltersToEndpoint(endpoint);
    endpoint = this.addTypeFiltersToEndpoint(endpoint);
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addLocationFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    if (!!filters.location.country) {
      endPoint += `&country=${filters.location.country.country}`;
    }
    if (!!filters.location.city) {
      endPoint += `&city=${filters.location.city}`;
    }
    return endPoint;
  };

  addTypeFiltersToEndpoint = (endPoint: string) => {
    const { filters } = this.state;
    
    if (!!filters.category_id) {
      endPoint += `&category_id=${filters.category_id}`;
    }
    return endPoint;
  };

  getBookmarks = async(cat?:string) =>{
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token" :  await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bookmarkApiId = requestMessage.messageId;
    let lang = await getStorageData("language") || 'en';
    let endpoint = `${configJSON.getBookmarkEndPoint}?locale=${lang}`;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addSearchKeyToEndpoint = (endPoint: string) => {
    const { searchText } = this.state;
    if (!!searchText) {
      endPoint += `&name=${searchText}`;
    }
    return endPoint;
  };

  addBookmarkToEndpoint = (endPoint: string) => {
    const { bookmark_id } = this.state.filters;
    if (!!bookmark_id) {
      endPoint += `&bookmark_id=${bookmark_id}`;
    }
    return endPoint;
  };

  addCat = (endPoint: string) => {
    const { selectedButton } = this.state;
    if (selectedButton && selectedButton != 'all') {
      endPoint += `&status=${selectedButton}`;
    }
    return endPoint;
  };


  async getDashboardData() {
    
    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiBuyerDashboardCallId  = reqMsg.messageId;

    // Set endpoint
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/start_ups/buyer_profile?language=${this.state.language}`
    );
    let header = {
      "Content-Type": configJSON.dashboarContentType,
      "token" :  await getStorageData("token")
    }
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Set HTTP method
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqMsg.id, reqMsg);
  }

  mapApiResponse = (responseJson:any) =>{
    if(responseJson.data.length > 0) {
      let mappedData ;
      if(this.state.language === "en"){

        mappedData = responseJson.data.map((item:any) => ({
          type: item.attributes.listing.category_name,
          imagePath: item.attributes.listing.image,
          heading: item.attributes.name,
          status: this.getStatus(item.attributes.status),
          ltmGrassRevenue:item.attributes.listing.ltm_gross_revenue,
          ltmNetProfit:item.attributes.listing.ltm_net_profit,
          askingPrice:item.attributes.listing.asking_price,
          statusUpdatedOn : item.attributes.listing.status_updated_on,
          description : item.attributes.listing.description,
          id: item.id,
          isBookmarked: item.attributes.listing.is_bookmarked,
          isArchived: item.attributes.listing.is_archieved,
          isReported: item.attributes.is_reported,
          listingId: item.attributes.listing_id,
          isAutoSignIn : item.attributes.auto_sign_nda,
          is_read: item.attributes.is_read
        }));
      }
      else{
        mappedData = responseJson.data.map((item:any) => ({
          type: item.attributes.listing.arabic_category_name,
          imagePath: item.attributes.listing.image,
          heading: item.attributes.arabic_name,
          status: this.getStatus(item.attributes.status),
          ltmGrassRevenue:item.attributes.listing.ltm_gross_revenue,
          ltmNetProfit:item.attributes.listing.ltm_net_profit,
          askingPrice:item.attributes.listing.asking_price,
          statusUpdatedOn : item.attributes.listing.status_updated_on,
          description : item.attributes.arabic_description,
          id: item.id,
          isArchived: item.attributes.listing.is_archieved,
          isBookmarked: item.attributes.listing.is_bookmarked,
          isReported: item.attributes.is_reported,
          listingId: item.attributes.listing_id,
          isAutoSignIn : item.attributes.auto_sign_nda,
          is_read: item.attributes.is_read
        }));
      }
      let { cardDetails } = this.state;
      cardDetails = [];
      mappedData.forEach((item: CardDetailType) => {
        if (!item.isReported && !item.isArchived) {
          cardDetails.push(item);
        };
      });
      this.setState({ cardDetails: cardDetails });
  }else{
    this.setState({cardDetails : []})
  }
  }

  navigateTo = () => {
    const msgessageFrom = new Message(getName(MessageEnum.NavigationMessage));
    msgessageFrom.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerDashboard");
    msgessageFrom.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msgessageFrom.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgessageFrom)
  }

  onClickofDeal = async(id:string, status:string,isAutoSignIn:null | {}, listingId:string) =>{
    if(!isAutoSignIn && status !== "No Action is Taken"){
      toast.warning(conditionSort(this.state.language == 'ar', 'يُرجى إكمال اتفاقية عدم الإفصاح التلقائي', "Please complete Auto Sign NDA"), getToastPosition());
     
      const msgessageFrom = new Message(getName(MessageEnum.NavigationMessage));
        msgessageFrom.addData(getName(MessageEnum.NavigationTargetMessage), "AutoSignTerms");
           msgessageFrom.addData(
                  getName(MessageEnum.NavigationPropsMessage),
                    this.props
                );
            const raiseMessage: Message = new Message(
                getName(MessageEnum.NavigationPayLoadMessage)
              );
              raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
              msgessageFrom.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(msgessageFrom)
            await setStorageData("accessId",id);
            return
    }
    if(status === "Request Accepted"){
      await setStorageData("id",id);
      await removeStorageData("saleId");
      await removeStorageData("letterofIntentId");
      this.props.navigation.navigate("AcquisitionProcess",{ id: id})
    }
    if (status === "No Action is Taken" || status === "Request Cancelled") {
      this.props.navigation.navigate("CompanyProfile", { id: listingId })
    };
  }

  getStatus = (status:string) => {
    switch (status) {
      case 'accepted':
        case 'قبلت':      
          return 'Request Accepted';
      case 'pending':
      case 'قيد الانتظار':
          return 'Request Sent';
      case 'rejected':
          return 'Request Rejected';
      case 'cancelled':
        return "Request Cancelled";
      case "No Action is Taken":
        return "No Action is Taken";
      default:
          return 'Unknown';
  }
  }
  getDateStatus = (status:string) =>{
    switch (status) {
      case 'Request Accepted':
          return this.state.pageText.acceptedOn;
      case 'Request Sent':
          return this.state.pageText.sentOn;
      case 'Request Rejected':
          return this.state.pageText.rejectedOn;
      case 'Request Cancelled':
        return this.state.pageText.cancelledOn;
      case "Archived On":
        return this.state.pageText.archivedOn;
      case "No Action is Taken":
        return "";
      default:
          return 'Unknown';
   }
  }

  returnFormattedDate = (date:string) =>{
    const statusUpdatedOn = new Date(date);
    const day = statusUpdatedOn.getDate().toString().padStart(2, "0");
    const month = (statusUpdatedOn.getMonth() + 1).toString().padStart(2, "0");
    const year = statusUpdatedOn.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    if(formattedDate === "NaN/NaN/NaN"){
      return "";
    };
    return(formattedDate)
  }

  returnRequestAccept = (status: string) => {
    if (status.toLowerCase() !== "request sent") {
      return true;
    } else {
      return false;
    }
  };

  showArchived = () => {
    this.getArchivedListing();
    this.setState({ archivedView: true, cardDetails: [] });
  };

  getArchivedListing = async () => {
    const token = await getStorageData("token");
    const privateInfoHeader = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getArchivedListingCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getArchivedListingEndPoint+this.getIfElseConditions(this.state.language==="ar",`?arabic_translate=${true}`,"")
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(privateInfoHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleArchived = (response: ArchivedResponse) => {
    if (response.data) {
      let { archivedListings, language } = this.state;
      response.data.forEach((item: ArchivedList) => {
        let listingData: ListingDetails | ListingWithDeal["data"]["attributes"]["listing"];
        let listingFlag: boolean;
        let listingDescription: string;
        let listingName: string;
        let listingStatus: string;
        let listingUpdated: string;
        let listingId: number;
        let listingBookmarked: boolean = false;
        let dealId: string;
        let listingAutoSignNda = "";
        if (Array.isArray(item.attributes.listing)) {
          let listingAttributes = item.attributes.listing[0].data.attributes;
          listingData = listingAttributes.listing;
          listingFlag = listingAttributes.is_reported;
          listingName = this.getIfElseConditions(language === "ar", listingAttributes.arabic_name, listingAttributes.name);
          listingStatus = listingAttributes.status;
          listingUpdated = listingData.status_updated_on;
          listingId = listingAttributes.listing_id;
          listingBookmarked = listingData.is_bookmarked;
          dealId = item.attributes.listing[0].data.id;
          listingAutoSignNda = listingAttributes.auto_sign_nda?.url;
          listingDescription = this.getIfElseConditions(language === "ar", listingAttributes.arabic_description, listingData.description);
        } else {
          listingData = item.attributes.listing;
          listingFlag = listingData.flag;
          listingName = this.getIfElseConditions(language === "ar", listingData.arabic_name as string, listingData.name as string);
          listingUpdated = listingData.status_updated_on;
          listingStatus = listingData.status;
          listingId = item.attributes.id;
          dealId = item.id;
          listingDescription = this.getIfElseConditions(language === "ar", listingData.arabic_description as string, listingData.description as string);
        }
        if (!listingFlag) {
          archivedListings.push({
            type: this.getIfElseConditions(this.state.language === "ar", listingData.arabic_category_name, listingData.category_name),
            imagePath: item.attributes.image_sub_category !== null ? item.attributes.image_sub_category?.url as string : "",
            heading: listingName,
            status: this.getStatus(listingStatus),
            ltmGrassRevenue: listingData.ltm_gross_revenue as string,
            ltmNetProfit: listingData.ltm_net_profit as string,
            askingPrice: listingData.asking_price as string,
            statusUpdatedOn: listingUpdated,
            description: listingDescription,
            id: dealId,
            isBookmarked: listingBookmarked,
            isArchived: true,
            isReported: listingFlag,
            listingId: listingId,
            isAutoSignIn: listingAutoSignNda
          });
        };
      })
      this.setState({ archivedListings: archivedListings });
    }
  };

  handleEmptyData = () => {
    if ((this.state.cardDetails.length === 0 && !this.state.archivedView) || (this.state.archivedListings.length === 0 && this.state.archivedView)) {
      return true;
    }
    return false;
  };

  openModal = (modal: string, dealId: string) => {
    if (modal === "bookmark") {
    this.setState({
      openBookmarkModal: true,
      toBeBookmarked: dealId
    });
    } else if (modal === "share") {
      this.setState({
        toBeBookmarked: dealId,
        openSharing: true
      });
    } else if (modal === "archive") {
      this.setState({ toBeBookmarked: dealId, openArchiveModal: true });
      this.getReportList();
    }
  };

  closeModal = (modal: string) => {
    if (modal === "bookmark") {
      this.setState({ openBookmarkModal: false });
    }
    else if (modal === "share") {
      this.setState({ openSharing: false, toBeBookmarked: "" });
    }
    else if (modal === "archive" && this.state.openReportReason) {
      this.setState({ openReportReason: false });
    } else if (modal === "archive" && !this.state.openReportReason) {
      this.setState({ openArchiveModal: false, toBeBookmarked: "" });
    }
  };

  addNewList = () => {
    this.setState({ addList: true });
  };

  closeField = () => {
    this.setState({ addList: false, listName: "" });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ listName: event.target.value });
  };

  handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && this.state.listName) {

      this.bookmarkListAPICallId = await this.apiCall({
        contentType: configJSON.dashboarContentType,
        method: configJSON.postAPIMethod,
        endPoint: configJSON.bookmarkListEndPoint,
        body: {
          catalogue_list: {
            title: this.state.listName
          },
        }
      });

    }
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body } = apiData;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  bookmarkDeal = async (listId: string) => {
    this.bookmarkAPICallId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.putAPIMethod,
      endPoint: configJSON.bookmarkEndPoint,
      body: {
        catalogue_list: {
          listing_id: this.state.toBeBookmarked,
          catalogue_list_id: listId,
        }
      }
    });
  };

  unBookmarkDeal = async (listId: string) => {
    this.setState({ toBeBookmarked: listId });
    this.unBookmarkAPICallId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.putAPIMethod,
      endPoint: configJSON.unBookmarkEndPoint,
      body: {
        id: listId
      }
    });
  };

  handleAnyApiResponse = (message: Message) => {

    const responseAPIId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseAPIId === this.bookmarkAPICallId && responseJson.message) {
        let updateDetails = this.state.cardDetails.map((item) => {
          return this.getIfCondition(item.listingId === this.state.toBeBookmarked , { ...item, isBookmarked: true } , item);
        });
        this.setState({ toBeBookmarked: "", openBookmarkModal: false, cardDetails: updateDetails }, () => {
          {toast.success(this.handleConditon(this.state.language === "en" , (responseJson.message[0]) , ("تمت الإضافة إلى القائمة بنجاح")), getToastPosition())};
        });
    };

    if (responseAPIId === this.unBookmarkAPICallId && responseJson.message) {
        let updateDetails = this.state.cardDetails.map((item) => {
          return this.getIfCondition(item.listingId === this.state.toBeBookmarked, { ...item, isBookmarked: false }, item);
        });
        this.setState({ cardDetails: updateDetails, toBeBookmarked: "" });
        { toast.success(this.handleConditon(this.state.language === "en" , (responseJson.message) ,("تمت الإزالة من القائمة بنجاح")), getToastPosition())};
    };

    if (responseAPIId === this.bookmarkListAPICallId && responseJson.data) {
        let { bookmarkList } = this.state;
        bookmarkList.push(responseJson.data);
        this.bookmarkDeal(responseJson.data.id);
        this.setState({ bookmarkList, addList: false, listName: "" });
    };

    if (this.archiveAPICallId === responseAPIId && responseJson.message) {
      toast.success(this.handleConditon(this.state.language === "en" , responseJson.message , responseJson.arabic_message), getToastPosition());
      this.handleArchiveApiResponse();
    };

    
      this.handleUnArchiveApiResponse(responseAPIId, responseJson);
    

    if (this.getIfCondition(this.getReportsAPICallId === responseAPIId && responseJson.data, true, false)) {
      this.setState({ reportReason: responseJson.data });
    };

    if (this.getIfCondition(this.reportDealAPICallId === responseAPIId && responseJson.message , true , false)) {
      this.handleArchiveApiResponse();
      toast.success(this.handleConditon(this.state.language === "en", responseJson.message , responseJson.arabic_message), getToastPosition());
    };

    if (this.getIfCondition(this.fetchBookmarkAPICallId === responseAPIId && responseJson.data, true, false)) {
      this.setState({cardDetails: []},()=>{
        this.handleBookmarkedData(responseJson.data);
      })
    };
  };

  handleShareLink = (text: string) => {
    text = text.replace("{dealId}", this.state.toBeBookmarked);
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(String(text));
    toast.success(this.state.pageText.copiedTxtMsg, getToastPosition());
  };

  shareToInstagram = () => {
    const shareUrl = window.location.origin + configJSON.shareUrl.replace("{dealId}", this.state.toBeBookmarked);
    const instagramShareUrl = configJSON.instaUrl+`${encodeURIComponent(shareUrl)}`;
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(String(shareUrl));
    window.open(instagramShareUrl, '_blank');
  };
  

  dealArchiveHandle = async () => {
    this.archiveAPICallId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.postAPIMethod,
      endPoint: configJSON.reportApiEndPoint,
      body: {
        user_archieved_catalogues: {
          is_archieved: true,
          listing_id: this.state.toBeBookmarked
        }
      }
    });
  };

  handleArchiveApiResponse = () => {
    const { cardDetails } = this.state;
    let updatedIndex = -1;
    cardDetails.forEach((item, index) => {
      if (item.listingId === this.state.toBeBookmarked) {
        updatedIndex = index;
      }
    });
    if (updatedIndex >= 0) {
      cardDetails.splice(updatedIndex, 1);
    }
    this.setState({ openArchiveModal: false, toBeBookmarked: "", openReportReason: false, cardDetails });
  };

  handleUnArchiveApiResponse = (responseAPIId: string, responseJson: {message: string , arabic_message:string}) => {
    if (this.unArchiveAPICallId === responseAPIId && responseJson.message) {
      toast.success(this.handleConditon(this.state.language === "en" , responseJson.message , responseJson.arabic_message), getToastPosition());
    const { archivedListings } = this.state;
    let updatedIndex = -1;
    archivedListings.forEach((item, index) => {
      if (item.listingId.toString() === this.state.toBeBookmarked) {
        updatedIndex = index;
      }
    });
    if (updatedIndex >= 0) {
      archivedListings.splice(updatedIndex, 1);
    }
    this.setState({ toBeBookmarked: "", archivedListings });
  }
  };

  flagReportReason = () => {
    this.setState({ openReportReason: true });
  };

  getReportList = async() => {
    this.getReportsAPICallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.reportListEndPoint.replace("{lang}",this.state.language)
    });
  };

  reportHandle = async(reasonId: number) => {
    this.reportDealAPICallId = await this.apiCall({
      method: configJSON.postAPIMethod,
      body: {
        user_archieved_catalogues: {
          report_id: reasonId,
          is_reported: "true",
          listing_id: this.state.toBeBookmarked,
          is_archieved:"false"
        }
      },
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.reportApiEndPoint+`?locale=${this.state.language}`
    });
  };

  getIfCondition = (condition: boolean, ifTrue: React.ReactElement | boolean , ifFalse: React.ReactElement | boolean ) => {
    return condition ? ifTrue : ifFalse;
  };
  
  fetchBookmarked = async () => {
    this.fetchBookmarkAPICallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.fetchBookmarkEndPoint+this.getIfElseConditions(this.state.language==="ar",`?arabic_translate=${true}`,"")
    });
  };

  handleBookmarkedData = (bookmarkedData: Array<Favlist>) => {
    let { cardDetails } = this.state;
    cardDetails = []
    bookmarkedData.forEach((bookmark) => {
      if (bookmark.attributes.catalogue_list_id == this.state.filters.bookmark_id) {
        let listingData: BookmarkedListingSingle | BookmarkedListingMultiple["data"]["attributes"]["listing"];
        let listingArchived: boolean = false;
        let listingStatus: string = "";
        let listingReported: boolean = false;
        let listingName: string = "";
        let listingUpdated = "";
        let listingAutoSignNda = "";
        let listingDealId: string = "";
        let listingDescription: string = "";
        let read_Chat:boolean=true;
        
        if (Array.isArray(bookmark.attributes.bookmarked_listings)) {
          let listingAttribute = bookmark.attributes.bookmarked_listings[0].data.attributes;
          listingData = listingAttribute.listing;
          listingName = this.getIfElseConditions(this.state.language === "ar", listingAttribute.arabic_name, listingAttribute.name);
          listingStatus = listingAttribute.status;
          listingReported = listingAttribute.is_reported;
          listingUpdated = listingData.status_updated_on;
          listingArchived = listingData.is_archieved;
          listingAutoSignNda = listingAttribute.auto_sign_nda?.url;
          listingDealId = bookmark.attributes.bookmarked_listings[0].data.id;
          listingDescription = this.getIfElseConditions(this.state.language === "en", listingData.description, listingAttribute.arabic_description);
          read_Chat=listingAttribute.is_read
        }
        else {
          listingData = bookmark.attributes.bookmarked_listings;
          listingStatus = listingData.status;
          listingName = this.getIfElseConditions(this.state.language === "ar", listingData.arabic_name, listingData.name);
          listingReported = listingData.flag;
          listingUpdated = "NA";
          listingDealId = bookmark.id;
          listingDescription = this.getIfElseConditions(this.state.language === "en", listingData.description, listingData.arabic_description);
        }
        if(!listingReported){
          
        cardDetails.push(
          {
            type: this.getIfElseConditions(this.state.language === "ar", listingData.arabic_category_name, listingData.category_name),
            imagePath: bookmark.attributes.image !== null ? bookmark.attributes.image.url : "",
            heading: listingName,
            status: this.getStatus(listingStatus),
            ltmGrassRevenue: listingData.ltm_gross_revenue,
            ltmNetProfit: listingData.ltm_net_profit,
            askingPrice: listingData.asking_price,
            statusUpdatedOn: listingUpdated,
            description: listingDescription,
            id: listingDealId,
            isBookmarked: bookmark.attributes.bookmarked,
            isArchived: listingArchived,
            isReported: listingReported,
            listingId: bookmark.attributes.listing_id,
            isAutoSignIn: listingAutoSignNda,
            is_read:read_Chat
          }
        );
      };
      }
    });
    this.setState({ cardDetails });
  };

  dealUnArchiveHandle = async (toUnarchive: string) => {
    this.setState({ toBeBookmarked: toUnarchive });
    this.unArchiveAPICallId = await this.apiCall({
      method: configJSON.postAPIMethod,
      endPoint: configJSON.reportApiEndPoint,
      contentType: configJSON.dashboarContentType,
      body: {
        user_archieved_catalogues: {
          listing_id: toUnarchive,
          is_archieved: false
        }
      }
    });
  };

  // Customizable Area End
}
