import React from "react"
import {Box,Button,Typography, withStyles, FormControl, Modal, Backdrop, Fade } from "@material-ui/core";
import ForgotEmailIdController, { configJSON } from "./ForgotEmailIdController.web";
import "./ForgotPassword.web.css";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import {Styles} from "./Style.web";
const images = require("./assets");
//Customizable Area Start
//Customizable Area End

export class ForgotEmailId extends ForgotEmailIdController {
 
  render() {
    //Customizable Area Start
    const { classes } = this.props;
    const textContent = this.state.forgotPasswordPageText;
    //Customizable Area End

    return (
    <>
    <HeaderBlock/>

    
    {/* Customizable Area Start */}
    <Box className="outer_wrapper">
        <Box  className="inner_wrapper">
            <div className={classes.registrationBlockMain}>
            <Box className={classes.containerForm + "mainconatiner"}>
                <div className={classes.ForgotPassword_WhiteBox}>
                <form>
                    <Box className={classes.firstRow}>
                    <Typography variant="h3" className={classes.mainHeading_wrapper + " mainHeading"}>
                        {this.getSpecifiedLangString(this.state.language, textContent.forgotEmailIdTitleText, "نسيت معرف البريد الإلكتروني")}
                    </Typography>
                    <Box className={classes.peraContainer}>
                        <Typography variant="h6" className={classes.subpera}>{this.getSpecifiedLangString(this.state.language, textContent.paraOneText, " أدخل رقم هاتفك المحمول الذي استخدمته للتسجيل في المنصة.")}</Typography>
                        <Typography className={classes.subpera} variant="h6" >{this.getSpecifiedLangString(this.state.language, textContent.paraTwoText, 
                        `  سوف تتلقى رمزًا عبر رسالة نصية لاسترداد معرف البريد الإلكتروني الخاص بك`)}</Typography>
                    </Box>

                    {this.state.phoneErrors && 
                        <Box className={classes.errormsg}>
                            <Typography className={classes.errormsgText}>{this.state.phoneNumberString ? this.state.phoneNumberString : this.getSpecifiedLangString(this.state.language, "Invalid Phone No","رقم الهاتف غير صحيح")}</Typography>
                        </Box>
                    }

                    <FormControl className={classes.formInput}>
                        <Box className={classes.phoneNumberWithCountryCode}>
                            <Typography
                            className={classes.formLabel}
                            data-testid="phoneNumber"
                            component="label"
                            >
                            {this.getSpecifiedLangString(this.state.language, textContent.phoneNumberLabelText, "رقم الهاتف")}*
                            </Typography>

                            <Box className={classes.countryCodeDownTriangleAndPhoneNumber}>
                                <Box className={classes.countryCodeWithDownTriangle}>
                                    <Box className={classes.flagWithCode}>
                                    {this.state.countryFlag === true && (
                                        <img src={this.state.countryItemObject.flags.png} />
                                    )}
                                    <p
                                        className={this.state.countryItemObject.idd.root ? classes.codeActive : classes.codeInActive }
                                    >
                                        {this.state.countryItemObject.idd.root +
                                        this.state.countryItemObject.idd.suffixes[0] ||
                                        configJSON.defaultCountryCode}
                                    </p>
                                    </Box>
                                </Box>

                                <Box
                                    data-testid="countryCodeOpenButton"
                                    className={classes.downArrowBtn}
                                    onClick={this.handleCountryModalOpen}
                                >
                                    <img src={images.downArrow} alt="arrow" />
                                </Box>
                                <Box className={classes.phoneInput} data-testid="countryCode">
                                    <input
                                    type="text"
                                    placeholder="551234578"
                                    onChange={this.handleChangePhoneNumber}
                                    value={this.state.phoneNumber}
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    data-testid="phoneNumberP"
                                    className={classes.thridRowInputElement}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </FormControl>


                    <FormControl className={classes.formAction}>
                        <Button data-testid="submitBtn" onClick={this.handleFormSubmit} className={(this.state.phoneNumber.length != 0 && !this.state.phoneErrors) ? classes.actveButton : classes.submitFormBtn}>
                            {this.getSpecifiedLangString(this.state.language, textContent.enterText, "دخول")}
                        </Button>
                        <Box className={classes.moreAction}>
                            <p>
                                {this.getSpecifiedLangString(this.state.language, configJSON.newUser, `مستخدم جديد؟`)}
                            </p>
                            <Button data-testid="goBackBtn" className={classes.signUpBtn} onClick={this.handlenavigation}>
                                <img src={images.user_accouunt} />
                                {this.getSpecifiedLangString(this.state.language, textContent.signUpText, `اشتراك`)}
                            </Button>
                        </Box>
                    </FormControl>
                    
                

                    </Box>
                </form>
                </div>
            </Box>
            </div>
        </Box>
        
    </Box>
    

    <Modal
        data-testid="CountryCodeModal"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal+ " Flag_Modal"}
        open={this.state.countrySelectionModalOpen}
        onClose={this.handleCountryFlagClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500
        }}
    >
        <Fade in={this.state.countrySelectionModalOpen}>
            <div className={classes.paper}>
                <div className="input-wrapper">
                <input
                    id="countryCode"
                    className={classes.inputTags}
                    data-testid="CountryCodeId"
                    type="text"
                    placeholder="Search"
                    value={this.state.inputValue}
                    onChange={e => this.handleChangeCountry(e.target.value)}
                />

                <img src={images.searchIcon} />
                </div>
                <div className={classes.resultList}>
                {this.state.resultval.length && this.state.resultval.map((result: any, id: number) => {
                    return (
                    <div
                        className={classes.searchList}
                        key={id}
                        data-testid="searchItem"
                        onClick={() => this.onClickParticularCoutnry(result)}
                    >
                        <div className="Flag_Country">
                        <div className="Flag_Margin_Righ">
                            <img
                            src={result.flags.png || ""}
                            alt="flag"
                            width={50}
                            height={30}
                            />
                        </div>
                        <div>
                        {this.state.language ==="en" ? result.name.common : result.name.nativeName.ara.common}
                        </div>
                        </div>
                        <div>
                        {result.idd.root}
                        {result.idd.suffixes[0]}
                        </div>
                    </div>
                    );
                })}
                </div>
            </div>
        </Fade>
    </Modal>
    {/* Customizable Area End */}


    <FooterBlock/></>
    );
    
  }
}



// Customizable Area Start
// Customizable Area End
export default withStyles(Styles)(ForgotEmailId);