export const externalLink = require("../assets/externalLink.svg");
export const availableRequest = require("../assets/availableRequest.svg");
export const deal = require("../assets/deal.svg");
export const exploreListing = require("../assets/exploreListing.svg");
export const subscription = require("../assets/subscription.svg");
export const seeMoreArrow = require("../assets/seeMoreArrow.svg");
export const businessIcon = require("../assets/BusinessIcon.svg");
export const rocketIcon = require("../assets/RocketIcon.svg");
export const archiveIcon = require("../../catalogue/assets/ion_archive-outline.svg");
export const reportIcon = require("../../catalogue/assets/material-symbols_report-outline-rounded.svg");
export const flagReportIcon = require("../../catalogue/assets/FlagReportIcon.svg");