// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import '../public/index.css'
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { getStorageData } from "framework/src/Utilities";
import { changeDirection } from "../../components/src/Helper";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ContentFlag from "../../blocks/contentflag/src/ContentFlag";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Documentstorage2 from "../../blocks/documentstorage2/src/Documentstorage2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import StartUpDetails from "../../blocks/catalogue/src/StartUpDetails.web";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import PrivacySettings from "../../blocks/privacysettings/src/PrivacySettings";
import Settings2 from "../../blocks/settings2/src/Settings2";
import ResourceDetails from "../../blocks/settings2/src/ResourceDetail.web";
import UpdateSettings from "../../blocks/settings2/src/UpdateSettings";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import Sorting from "../../blocks/sorting/src/Sorting";
import Cfdesigncustomization22 from "../../blocks/cfdesigncustomization22/src/Cfdesigncustomization22";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Trafficsources2 from "../../blocks/trafficsources2/src/Trafficsources2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import { EmailOtpVerificationStyle } from "../../blocks/email-account-login/src/EmailOtpVerification.web";

// import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import { ForgotPasswordStyle } from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import { NewPasswordWebStyle } from "../../blocks/forgot-password/src/NewPassword.web";
import SuccessModel from "../../blocks/forgot-password/src/SuccessModel.web";

import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Translation from "../../blocks/translation/src/Translation";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Cfdesigncustomization2 from "../../blocks/cfdesigncustomization2/src/Cfdesigncustomization2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories.web";
import ContentManagement from "../../blocks/contentmanagement/src/ContentManagement.web";
import Companytype from "../../blocks/categoriessubcategories/src/Companytype.web";

import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import { MainLandingPageStyle } from "../../blocks/landingpage/src/MainLandingPage.web";
import EmailAccountRegBlock from "../../blocks/email-account-registration/src/EmailAccountRegBlock.web";
import EmailAccountVerification from "../../blocks/email-account-registration/src/EmailAccountVerificationBlock.web";
import EmailAccountPopup from "../../blocks/email-account-registration/src/EmailAccountPopup.web"
import CustomizableUserSubscriptions from "../../blocks/customisableusersubscriptions/src/CustomizableUserSubscriptions.web"
import BuyerSubscription from "../../blocks/customisableusersubscriptions/src/BuyerSubscription.web"
import CompanyProfile from "../../blocks/user-profile-basic/src/CompanyProfile.web";
import CompanyProfileEdit from "../../blocks/user-profile-basic/src/CompanyProfileEdit.web";

import AcquisitionProcess from "../../blocks/requestmanagement/src/AcquisitionProcess.web"
import Feedback from "../../blocks/requestmanagement/src/Feedback.web"
import AddFeedback from "../../blocks/requestmanagement/src/AddFeedback.web"
import Tutorials from "../../blocks/onboardingguide/src/Tutorials.web"
import PrivacyPolicy from "../../blocks/privacysettings/src/PrivacyPolicy.web"
import ImportantLinks from "../../blocks/navigationmenu/src/ImportantLinks.web"

import ForgotEmailId from "../../blocks/forgot-password/src/ForgotEmailId.web";
import ForgotEmailOTP from "../../blocks/forgot-password/src/ForgotEmailOTP";
import NewsGrid from "../../blocks/contentmanagement/src/NewsGrid.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail.web";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import NewsDetails from "../../blocks/contentmanagement/src/NewsDetails.web";
import Deals from "../../blocks/dashboard/src/Deals.web";

import { BuyerDashboard } from "../../blocks/dashboard/src/Dashboard.web";
import { EditBuyerProfile } from "../../blocks/user-profile-basic/src/BuyerProfile.web";
import MyAcquisition from "../../blocks/requestmanagement/src/MyAcquisition.web"
import CustomSignatureForm from "../../blocks/requestmanagement/src/CustomSignatureForm.web"
import CustomTerms from "../../blocks/requestmanagement/src/CustomTerms.web"


const routeMap = {
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide"
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: "/Notificationsettings"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  ContentFlag: {
    component: ContentFlag,
    path: "/ContentFlag"
  },
  EmailAccountRegBlock: {
    component: EmailAccountRegBlock,
    path: "/EmailAccountRegBlock",
    routeType: "Auth"
  },
  EmailAccountPopup: {
    component: EmailAccountPopup,
    path: "/EmailAccountPopup"
  },

  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/Faq"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Documentstorage2: {
    component: Documentstorage2,
    path: "/Documentstorage2"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue",
    routeType: "Protected",
    roles: ["buyer", "seller"]
  },
  StartUpDetails: {
    component: StartUpDetails,
    path: "/StartUpDetails/:id?"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: "/PrivacySettings"
  },
  UpdateSettings: {
    component: UpdateSettings,
    path: "/UpdateSettings/:option"
  },
  Favourites: {
    component: Favourites,
    path: "/Favourites"
  },
  AddFavourites: {
    component: AddFavourites,
    path: "/AddFavourites"
  },
  LinkShare: {
    component: LinkShare,
    path: "/LinkShare"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Cfdesigncustomization22: {
    component: Cfdesigncustomization22,
    path: "/Cfdesigncustomization22"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  Trafficsources2: {
    component: Trafficsources2,
    path: "/Trafficsources2"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock",
    routeType: "Auth"
  },
  EmailOtpVerification: {
    component: EmailOtpVerificationStyle,
    path: "/EmailOtpVerificationStyle",
    routeType: "Auth"
  },
  ForgotPassword: {
    component: ForgotPasswordStyle,
    path: "/ForgotPassword",
    routeType: "Auth"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP",
    routeType: "Auth"
  },
  NewPassword: {
    component: NewPasswordWebStyle,
    path: "/NewPassword",
    routeType: "Auth"
  },
  SuccessModel: {
    component: SuccessModel,
    path: "/SuccessModel",
    routeType: "Auth"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  Translation: {
    component: Translation,
    path: "/Translation"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  Cfdesigncustomization2: {
    component: Cfdesigncustomization2,
    path: "/Cfdesigncustomization2"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  MainLandingPage: {
    component: MainLandingPageStyle,
    path: "/MainLandingPage"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Companytype: {
    component: Companytype,
    path: "/Companytype"
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: "/ReviewApprovalAdmin"
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },
  ForgotEmailId: {
    component: ForgotEmailId,
    path: "/ForgotEmailId"
  },
  ForgotEmailOTP: {
    component: ForgotEmailOTP,
    path: "/ForgotEmailOTP"
  },
  NewsGrid: {
    component: NewsGrid,
    path: "/News"
  },
  NewsDetails: {
    component: NewsDetails,
    path: "/NewsDetails/:id"
  },
  ResourceDetails: {
    component: ResourceDetails,
    path: "/ResourceDetails/:id"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditions"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditionsSignUp"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  CustomizableUserSubscriptions: {
    component: CustomizableUserSubscriptions,
    path: "/CustomizableUserSubscriptions"
  },

  BuyerSubscription: {
    component: BuyerSubscription,
    path: "/Subscription",
    routeType: "Auth",
    roles:["seller"]
  },

  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  ImportantLinks: {
    component: ImportantLinks,
    path: "/ImportantLinks"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Documentstorage2: {
    component: Documentstorage2,
    path: "/Documentstorage2"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: "/PrivacySettings"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2/:option"
  },
  Favourites: {
    component: Favourites,
    path: "/Favourites"
  },
  AddFavourites: {
    component: AddFavourites,
    path: "/AddFavourites"
  },
  LinkShare: {
    component: LinkShare,
    path: "/LinkShare"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Cfdesigncustomization22: {
    component: Cfdesigncustomization22,
    path: "/Cfdesigncustomization22"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  Trafficsources2: {
    component: Trafficsources2,
    path: "/Trafficsources2"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailOtpVerification: {
    component: EmailOtpVerificationStyle,
    path: "/EmailOtpVerification"
  },


  ForgotPassword: {
    component: ForgotPasswordStyle,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },

  NewPassword: {
    component: NewPasswordWebStyle,
    path: "/NewPassword"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  Translation: {
    component: Translation,
    path: "/Translation"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  Cfdesigncustomization2: {
    component: Cfdesigncustomization2,
    path: "/Cfdesigncustomization2"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  MainLandingPage: {
    component: MainLandingPageStyle,
    path: "/MainLandingPage"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: "/ReviewApprovalAdmin"
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },

  Home: {
    component: MainLandingPageStyle,
    path: '/',
    exact: true,
    routeType: "Auth"
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  EmailAccountVerification: {
    component: EmailAccountVerification,
    path: "/EmailAccountVerification"
  },

  BuyerDashboard: {
    component: BuyerDashboard,
    path: "/BuyerDashboard",
    routeType: "Protected",
    roles: ["buyer"]
  },

  EditBuyerProfile: {
    component: EditBuyerProfile,
    path: "/EditBuyerProfile",
    routeType: "Protected",
    roles: ["buyer"]
  },


  ViewBuyerProfile: {
    component: EditBuyerProfile,
    path: "/ViewBuyerProfile/:id",
    routeType: "Protected",
    roles: ["seller", "buyer"]
  },

  Tutorials: {
    component: Tutorials,
    path: "/Tutorials",
  },

  Deals: {
    component: Deals,
    path: "/Deals",
    routeType: "Protected"
  },

  CompanyProfile: {
    component: CompanyProfile,
    path: "/CompanyProfile/:id",
    routeType: "Protected"
  },
  CompanyProfileEdit: {
    component: CompanyProfileEdit,
    path: "/CompanyProfileEdit/:id?",
    routeType: "Protected"
  },
  AcquisitionProcess: {
    component: AcquisitionProcess,
    path: "/AcquisitionProcess",
    routeType: "Protected"
  },
  Feedback: {
    component: Feedback,
    path: "/Feedback",
  },
  AddFeedback: {
    component: AddFeedback,
    path: "/AddFeedback",
  },
  SellerProfile: {
    component: SellerProfile,
    path: "/SellerProfile",
    routeType: "Protected",
    roles: ["seller"]
  },
  MyAcquisition: {
    component: MyAcquisition,
    path: "/MyAcquisition",
    routeType: "Protected",
    roles: ["seller"]
  },
  LetterOfIntent: {
    component: LetterOfIntent,
    path: "/LetterOfIntent",
    routeType: "Protected"
  },
  LetterOfIntentTerms: {
    component: LetterOfIntentTerms,
    path: "/LetterOfIntentTerms",
    routeType: "Protected"
  },
  SalePurchaseAgreement: {
    component: SalePurchaseAgreement,
    path: "/SalePurchaseAgreement",
    routeType: "Protected"
  },
  SalePurchaseAgreementTerms: {
    component: SalePurchaseAgreementTerms,
    path: "/SalePurchaseAgreementTerms",
    routeType: "Protected"
  },
  AutoSign: {
    component: AutoSign,
    path: "/AutoSign",
    routeType: "Protected"
  },
  AutoSignTerms: {
    component: AutoSignTerms,
    path: "/AutoSignTerms",
    routeType: "Protected"
  },
  CustomTerms: {
    component: CustomTerms,
    path: "/CustomTerms",
    routeType: "Protected"
  }
};

function SellerProfile(props) {
  return <EditBuyerProfile {...props} sellerType={true} />
}

function LetterOfIntent(props) {
  return <CustomSignatureForm {...props} id="LetterOfIntent" />
}

function LetterOfIntentTerms(props) {
  return <CustomTerms {...props} id="LetterOfIntent" />
}

function SalePurchaseAgreement(props) {
  return <CustomSignatureForm {...props} id="SalePurchaseAgreement" />
}

function SalePurchaseAgreementTerms(props) {
  return <CustomTerms {...props} id="SalePurchaseAgreement" />
}

function AutoSign(props) {
  return <CustomSignatureForm {...props} id="AutoSign" />
}

function AutoSignTerms(props) {
  return <CustomTerms {...props} id="AutoSign" />
}

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  componentDidMount() {
    this.setDirection()
  }

  setDirection = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      changeDirection(lang)
    }
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');


    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        <ToastContainer />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
