// Customizable Area Start
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { conditionSort } from "../../../components/src/Helper";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface FeedbackObj {
  id: number,
  attributes: {
    star_rating: number,
    user_feedback: string,
    account: {
      image: {
        image: string;
      }
    }
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedMenu: string;
  openFeedbackDialog: boolean;
  feedbackInputValue: string;
  rating: number;
  feedbackError:boolean;
  ratingError :boolean;
  feedbackList: FeedbackObj[];
  lauguageSelect:string;
  feedbackGiven: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FeedbackController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postfeedbackCallID: string = "";
  getFeedbackListApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      feedbackError:false,
      ratingError :false,
      selectedMenu: "Buyer",
      openFeedbackDialog: true,
      feedbackInputValue: "",
      rating: 0,
      feedbackList: [],
      lauguageSelect:"en",
      feedbackGiven: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseAPIId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getFeedbackListApiCallId === responseAPIId && responseJson?.data) {
        let listArr = responseJson.data.sort((firstObj: FeedbackObj, secObj: FeedbackObj) => secObj.attributes.star_rating - firstObj.attributes.star_rating)
        this.setState({ feedbackList: listArr});
      }
      if (this.postfeedbackCallID === responseAPIId && responseJson) {
        toast.success(conditionSort(this.state.lauguageSelect ==="en",configJSON.thankyouMsg,configJSON.thankyouMsgAr),
        {position:conditionSort(this.state.lauguageSelect ==="ar","top-left","top-right")});
        await setStorageData("given_feedback", "true");
        this.props.navigation.navigate('AcquisitionProcess');
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("token");
    if(token && token.length) {
      this.setState({feedbackGiven: true});
    }
    let lang = await getStorageData('language');
    this.setState({
      lauguageSelect: conditionSort(lang, lang, "en")
    }, () => this.getFeedbackListFn())
  }

  handleFeedbackInputChange = (event: any) => {
    this.setState({
      feedbackInputValue: event.target.value,
    });
  };

  getContent = (englishText: string, arabicText: string) => {
    return this.state.lauguageSelect === "en" ? englishText : arabicText;
  }

  handleFeedbackInputClear = () => {
    this.setState({
      feedbackInputValue: "",
      rating:0
    });
  };
  validateForm = () => {
    const { feedbackInputValue, rating } = this.state;
    let feedbackError = false;
    let ratingError = false;

    if (!feedbackInputValue) {
      feedbackError = true;
      
    }

    if (rating === 0) {
      ratingError = true;
     
    }
    this.setState({ feedbackError, ratingError });
    return !feedbackError && !ratingError;
  };

  navigateTo = (url: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  getFeedbackListFn = async () => {
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeedbackListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFeedbackListApi}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSentRequestApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit =  async () => {
    if (this.validateForm()) {

      const tokenNo = await getStorageData("token");
      const dealId = await getStorageData("dealId");
      const privateInfoHeader = {
        "Content-Type": configJSON.exampleApiContentType,
        token: tokenNo,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
     
      this.postfeedbackCallID = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_request_management/deals/feeback`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(privateInfoHeader)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.createSendRequestApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          "deal_feedback": {
            "bx_block_request_management_deals_id":  Number(dealId),
            "user_feedback":  this.state.feedbackInputValue,
            "star_rating": this.state.rating
          }
        })
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleStarClick = (rating: number) => {
    this.setState({rating: rating})
  }
  
  // Customizable Area End
}
