import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { conditionSort } from "../../../components/src/Helper";
export const baseURLconfig = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  attributes: { created_at: string, account_id: number, message: {message: string} }, created_at: string, created_time: string

}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  dealDetails: any;
  closeChat: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  message: string;
  chatList: any[];
  isVisibleModal: boolean;
  typeOfUser: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  sendMessageApiCallId: string = "";
  socketSubscribeApiCallId: string = "";
  ws: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      message: "",
      chatList: [],
      isVisibleModal: false,
      typeOfUser: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    

    // Customizable Area End
  }

  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();
    const baseUrlWs = baseURLconfig.baseURL.replace("https", "wss");
    const localtoken = await getStorageData('token');
    this.ws = new WebSocket(`${baseUrlWs}/cable?token=${localtoken}`)

    this.ws.onmessage = (evt: any) => {
      const chatData = JSON.parse(evt.data);
      if (chatData.message?.message) {
        chatData.message.profile.url = conditionSort(chatData.message.profile.url, `${baseURLconfig.baseURL}${chatData.message.profile.url}`, '');
        chatData.message.created_at = new Date();
        this.setState({ chatList: [...this.state.chatList, { attributes: chatData.message }] })
      }
    }

    this.receiveTokenValue();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.receiveTokenValue();
      });
    }
  }

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMyChatsApiEndpoint}?deal_id=${this.props.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMessageChange = (value: string) => {
    this.setState({ message: value })
  }

  handleMessageKeyDown = (value: string) => {
    if (value === "Enter") {
      this.handleSendMessage();
    }
  }

  handleSendMessage = () => {
    let channel = JSON.stringify({ channel: 'ChatChannel', id: this.state.chatName });
    let obje = JSON.stringify({ message: this.state.message, action: 'speak' });
    this.ws.send(JSON.stringify({ command: 'message', identifier: channel, data: obje }));
    this.setState({ message: "" });
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (
      apiRequestCallId === this.getChatListApiCallId && responseJson.data
    ) {
      this.setState({ chatList: responseJson.data.attributes?.messages || [], chatName: responseJson.data.id });
    }

  }

  receiveTokenValue = async () => {
    const token = await getStorageData("token");
    const typeOfUser = localStorage.getItem('typeOfUser') || "";
    this.setState({
      token: token,
      accountId: typeOfUser === 'buyer' ? this.props.dealDetails.buyer_id : this.props.dealDetails.seller_id, typeOfUser: typeOfUser
    }, () => {
      if (token) {
        this.getChatList(token);
      }
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.chatName && this.state.chatName != prevState.chatName) {
      let iden = JSON.stringify({ channel: 'ChatChannel', id: this.state.chatName });
      this.ws.onopen = () => {
        this.ws.send(JSON.stringify({ command: 'subscribe', identifier: iden }));
      }
      
    }
    let element: HTMLElement = document.getElementById('chat-comp') as HTMLElement;
    element.scrollTop = element.scrollHeight;
  }
  // Customizable Area End
}
