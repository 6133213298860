import React from "react"
import {Box,Button,Typography, withStyles } from "@material-ui/core";
import TermsConditionsDetailController, { configJSON } from "./TermsConditionsDetailController.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
//Customizable Area Start
import {Styles} from "./Styles.web";
//Customizable Area End

export class TermsConditionsDetail extends TermsConditionsDetailController {
 
  render() {
    //Customizable Area Start
    const { classes } = this.props;
    //Customizable Area End
    
    return (
    <>
    <HeaderBlock/>
    {/* Customizable Area Start */}
    <Box className={classes.wrapper}>
      
        <Typography component="h1" className={classes.pageTitle}>
          {this.state.lang === "en" ? this.state.title : this.state.titleAr}
          </Typography>
        <Box className={classes.outerWrapper}>
          <Box className={classes.innerWrapper}>
            {(this.state.lang === "en" ? this.state.descrip : this.state.descripAr).map((data: string) => 
            <div dangerouslySetInnerHTML={{__html: data}}></div>
            )}
          </Box>
        </Box>
        <Box className={classes.closeWrapper}>
            <Button data-testid="closeBtn" onClick={this.handlenavigation} className={classes.closeButton}>{this.state.lang==="en"? configJSON.btnTxtClose:configJSON.closeButtonTextArabic}</Button>
        </Box>
    </Box>
    {/* Customizable Area End */}
    <FooterBlock/>
    </>
    );
  
  }
}


export default withStyles(Styles)(TermsConditionsDetail);
