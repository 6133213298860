import React from "react";
// Customizable Area Start
import {
  Grid,
  Typography,
  Box,
  Button,
  Dialog,
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@material-ui/core";
import { backgroundArrow1, imageRight } from "./assets";
import {
  createStyles,
  Theme as AugmentedTheme,
  withStyles
} from "@material-ui/core/styles";
import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";
import * as Yup from "yup";
import { styled } from "@material-ui/styles";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import OtpInputField from "./OtpInputField";

const Popup = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    height: "265px",
    width: "795px",
    borderRadius: "20px",
    border: "1px #262626 solid",
    boxShadow: "none",
    padding: "50px",
    overflow: "hidden",
    "@media (max-width:600px)": {
      height: "auto",
      width: "231px"
    }
  },
  "& .MuiBackdrop-root": {
    background: "rgba(140, 140, 140, 0.60)"
  }
}));

const FormBoxOTP = styled(Grid)({
  width: '100%',
  maxWidth: '478px',
  margin:"auto",
 
  '& .otp-group':{
      display:"flex",
      gap:"12.5px",
      justifyContent:"center",
      width:"100%",
      '& input':{
          border:" 1px solid #8C8C8C",
          borderRadius: '16px!important',
          background:"white",
          color:"black",
          fontFamily: "Montserrat",
          fontSize: "36px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight:"24px",
          boxShadow:" 0px 0px 4px 1px rgba(140, 140, 140, 0.75) inset",
          margin:"20px auto",
          "&:focus": {
          outlineColor:'#5412FC',
          border: '1px solid #5412FC',
          boxShadow: "0px 0px 4px 1px rgba(84, 18, 252, 0.50) inset",      
        },
        
      },
      '& .active':{
        border: '1px solid #5412FC',
        boxShadow: "0px 0px 4px 1px rgba(84, 18, 252, 0.50) inset",
      }
  },
  '@media (max-width: 600px)': {
      width: '90%',
      margin: '0 auto',
      justifyContent:'center'
  },
  '& .MuiInput-underline::before': {
      borderBottom: 'none',
  },
  '& .MuiInput-underline::after': {
      borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: 'none'
  },
  "& .MuiNativeSelect-select:focus": {
      backgroundColor: 'white',
          boxShadow:"red !important",

  },
  '& .MuiOutlinedInput-input': {
      fontSize: 31,
      border: '1px solid rgba(255, 255, 255, 0.50)',
      background: 'white',
      borderRadius: 12,
      color: 'white',
      height: '46px',
      textAlign: 'center',
  },
  "& .MuiCheckbox-root":{
      color:"#fff"
  },
  '& input::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
  },
  '& input::-webkit-inner-spin-button': {
  WebkitAppearance: 'none',
  },
  '& input[type="number"]': {
  MozAppearance: 'textfield',
  textDecoration: 'underline'
  }
});

let themeProvider = createTheme();
themeProvider = responsiveFontSizes(themeProvider);

// Customizable Area End

export default class EmailOtpVerification extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  VerifyValidations = Yup.object().shape({
    email: Yup.number()
      .min(4)
      .required(),
    phone: Yup.number()
      .min(4)
      .required()
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { remainingTime, isTimeOver } = this.state;
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;  
    
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box>
        <HeaderBlock/>
      <Box  className={classes.mainSignupContainer}
     >

    <Grid item lg={4} md={6} sm={8} xs={10} className={classes.form}>
          <Typography className={classes.verificationHeading}>{this.state.otpText.verificationText}</Typography>
          <Typography className={classes.formLabel}>{this.state.otpText.enterCodeSentText} </Typography>
{this.state.otpError? <Typography className={classes.errorMsg}> {this.state.otpText.invalidCodeText} </Typography>:null}
      
          <FormBoxOTP container id="OTP" style={{direction:"ltr"}}>
              <OtpInputField isActive={this.state.Active} value={this.state.otpState} valueLength={4} 
               onChange={this.handleOTPState} handleClickOutside={this.handleClickOutside}/>
          </FormBoxOTP>
                  <Box style={{marginTop:10}}  >
                      <Typography className={classes.otpTimmer}>
                      {this.state.otpText.timeRemainingText} {minutes < 10 ? "0" : ""}
                        {minutes}:{seconds < 10 ? "0" : ""}
                        {seconds} {this.state.otpText.sText}
                      </Typography>
                      <Button className={classes.codeNotReceived} 
                      disabled={!isTimeOver}  
                      onClick={(e) => this.doLogIn(e,true)}>
                      <u  data-test-id="resend-code"  style={{color: !this.state.isTimeOver?"#8C8C8C":"black"}}  >
                       {this.state.otpText.resendText}
                      </u>
                    </Button>
                </Box>

          <Button
            id="submitButton"
            data-testid="doLogIn"
            className={classes.submitBtn}
            type="submit"
            variant="contained"
            onClick={(e)=>this.doLogIn(e)}
            disabled={this.state.disabled}
            // disabled={!this.state.otpState} 
            >
            {this.state.otpText.verifyCodeText}
          </Button>
      
  </Grid>
  <Popup className={classes.popUp}
          open={this.state.modalOpen} onClose={this.toggleModal}   maxWidth="md"
        >
          <Box>
            <Box className={classes.rightImageWithSuccess}>
              <img src={imageRight} width={40} height={40} />
              <ThemeProvider theme={themeProvider}>
                {/* @ts-ignore */}
                <Typography
                  className={classes.typoSuccess}    data-test-id="success">
                  {this.state.otpText.successText}
                </Typography>
              </ThemeProvider>
            </Box>

            <Box className={classes.successfullySubmitted}>
              <Box>
                {/* @ts-ignore */}
                <p className={classes.typoThird}>
                 {this.state.otpText.verificationSuccessFulText}  <br />    {this.state.otpText.dashboardRedirectionText}
                </p>
              </Box>
            </Box>
          </Box>
        </Popup>
  </Box>
  <FooterBlock/>
  </Box>

   
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  
  greyColor: { color: "#262626" }
};

export const customStyle: {} = (theme: AugmentedTheme) =>
  createStyles({
    innerContainer: {
      width: "100%",
      paddingLeft: "0",
      paddingRight: "0",
      maxWidth: "none",
      fontFamily: "Montserrat,sans-serif"
    },
    mainDiv: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "1200px",
      [theme.breakpoints.down("sm")]: {
        height: "600px"
      },
      fontFamily: "Montserrat,sans-serif"
    },
    navbar: {
      flexShrink: 0,
      alignItems: "center" as "center",
      padding: "19px 19px !important",
      fontFamily: "Montserrat,sans-serif",
      display: "flex",
      justifyContent: "space-between",
    
      "& img": {
        height: "auto",
        maxWidth: "100%",
        [theme.breakpoints.down("sm")]: {
          height: "2.5vh"
        }
      }
    },
    mainSignupContainer: {
      backgroundImage: "url(" + backgroundArrow1 + ")",
      backgroundRepeat: "no-repeat",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundSize: "cover",
    
    },
    form: {
      border: "solid 1px #262626",
      backgroundColor: "#FFFFFF",
      marginBottom: "30px",
      minWidth: "600px",
      padding: "16px 28px 15px",
      textAlign:"center",
      borderRadius: "20px",
      marginTop: "30px",
     
      "@media (max-width:600px)": {
        minWidth: "300px",
        marginTop: "75px",
        marginBottom: "90px",
        padding: "16px 40px 36px"
      }
    },
    formInput: {
      width: "100%",
      marginBottom: "27px",
    
      "& .MuiOutlinedInput-root": {
        borderRadius: "12px",
        margin: "0"
      },
      "& label": {
        color: "#262626",
        fontSize: "18px !important",
        fontFamily: "Montserrat",
        fontWeight: "500",
        lineHeight: "24px",
        marginBottom: "12px"
      },
      "& .MuiInputBase-input": {
        color: "#262626",
        fontFamily: "Montserrat",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "3.60px",
        fontSize: "18px !important",
       
        wordWrap: "break-word"
      },
      "& .MuiOutlinedInput-input": {
        padding: "14px 32px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px #8C8C8C solid"
      },
      "@media (max-width:600px)": {
        marginBottom: "15px",
        "& label": {
          fontSize: "10px !important",
          lineHeight: "20px",
          marginBottom: "4px"
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px"
        },
        "& .MuiSvgIcon-root": {
          width: "18px",
          height: "18px"
        },
        "& .MuiOutlinedInput-adornedEnd": {
          paddingRight: "10px"
        },
        "& .MuiInputBase-input": {
          fontSize: "10px !important",
          letterSpacing: "1px"
        },
        "& .MuiOutlinedInput-input": {
          padding: "6px 13px"
        },
      
      }
    },
    validSubmitBtn: {
      backgroundColor: "#10BFB8",
      color: "#FFF",
      fontWeight: "bold",
      fontFamily: "Montserrat !important"
    },
    submitBtn: {
      backgroundColor: "#10BFB8",
      borderRadius: "24px",
      fontWeight: 700,
      lineHeight: "22px",
      textTransform: "none",
      padding: "14px 70px",
      marginTop: "30px",
      color: "#FFF",
      fontSize: "20px !important",
      fontFamily: "Montserrat",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#10BFB8"
      },
      "&:disabled": {
        color: "#FFF",
        backgroundColor: "#8C8C8C"
      },
     
      "@media (max-width:600px)": {
        fontSize: "12px !important",
        padding: "7px 36px",
        marginTop: "36px"
      }
    },
    formLabel: {
      color: "#000",
      fontSize: "18px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "22px",
      margin: "15px 0",
      "@media (max-width:600px)": {
        fontSize: "12px !important",
        lineHeight: "16px",
        margin: "27px 0"
      }
    },
    errorMsg:{
      color: "#FF7575",
      fontSize: "18px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "22px",
      margin: "15px 0",
      background: "rgba(255, 117, 117, 0.30)",
      borderRadius: "12px",
      padding:"6px 53px",



    },

    formLabel2: {
      fontSize: "16px !important",
      color: "#000",
      fontWeight: "bold",
      width: "100%",
      marginBottom: "30px",
      fontFamily: "Montserrat,sans-serif"
    },
    codeNotReceived: {
      color: "#8c8c8c",
      fontSize: "12px !important",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "24px",
      marginTop:"10px",
      textTransform:"capitalize",
      "& u": {
        cursor: "pointer",
        color: '#262626',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px', 
        textDecoration: 'underline',
        "@media (max-width:600px)": {
          lineHeight: "22px",
          fontSize: "12px !important",
         


        }
      },
      "@media (max-width:600px)": {
        lineHeight: "22px",
        fontSize: "10px !important",
      }
    },
  

    mt50: {
      marginTop: "50px",
      width: "70%"
    },
      verificationHeading: {
      textAlign: "center",
      fontSize: "30px !important",
      fontWeight: 700,
      lineHeight: "34px",
      color: "#5412FC",
      fontFamily: "Montserrat",
      "@media (max-width:600px)": {
        fontSize: "16px !important"
      }
    },
    rightImageWithSuccess: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "28px",
      marginBottom: "30px",
      "& img": {
        width: "60px",
        height: "60px"
      },
      "@media (max-width:600px)": {
        gap: "18px",
        marginBottom: "26px",
        "& img": {
          height: "39px",
          width: "39px",
        }
      }
    },
      successfullySubmitted: {
      textAlign: "center"
    },
    typoSuccess: {
      color: "black",
      fontSize: "30px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "24px",
      "@media (max-width:600px)": {
        fontSize: "24px !important"
      }
    },
  
    typoThird: {
        lineHeight: "36px",
      marginBottom: "48px",
      fontFamily: "Montserrat",
      color: "black",
      fontSize: "24px !important",
      fontWeight: 500,
      "@media (max-width:600px)": {
        marginBottom: "34px",
        fontSize: "14px !important",
        lineHeight: "26px",
      }
    },
    typoHome: {
      backgroundColor: "#10BFB8",
      borderRadius: "24px",
      padding: "14px 74px",
      fontWeight: 700,
      lineHeight: "22px",
      fontSize: "20px !important",
      fontFamily: "Montserrat",
      color: "#FFF",
    
      textTransform: "inherit",
      "&:hover": {
        backgroundColor: "#10BFB8"
      },
      "@media (max-width:600px)": {
        padding: "7px 36px",
        fontSize: "12px !important"
      }
    },
    errorText: {
      backgroundColor: "rgba(255, 116.88, 116.88, 0.30)",
      borderRadius: "12px",
      textAlign: "center",
      padding: "4px",
      color: "#FF7575",
      fontSize: "16px !important",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "24px",
      marginTop: "12px",
      "@media (max-width:600px)": {
        padding: "4px",
        fontSize: "8px !important",
        lineHeight: "12px",
        marginTop: "8px"
      }
    },
    otpTimmer:{
      fontSize: '20px !important', 
      fontFamily: 'Montserrat',
      fontWeight: 500,
      lineHeight: '24px',
    },
    
  });




export const EmailOtpVerificationStyle = withStyles(customStyle, {
  withTheme: true
})(EmailOtpVerification);
// Customizable Area End
