import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
import { userProfile } from "./assets";
import { Linking } from "react-native";
export const images = require("./assets");
type LinkType = {
  link: string;
  description: string;
};

type TranslatedLinkType = {
  id: string;
  link: string;
  description: string;
  arabic_description: string;
};

type TranslatedDataType = {
  idNew: string;
  nameNew: string;
};

type ImportantLinkListType = {
  id: string;
  name: string;
  links: TranslatedLinkType[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  lang:string;
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  selectedLinkType: string;
  importantLinkList: {
    id: string;
    name: string;
    links: { id: string; link: string, description: string }[];
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  getImportantLinksAPI: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      lang:"",
      webDrawer: false,
      token: "",
      drawerItems: [],
      selectedLinkType: "Buyer",
      importantLinkList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.apiGetDataCallId === apiRequestCallId) {
        if (responseJson) {
          this.setState({ drawerItems: responseJson });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.getImportantLinksAPI === apiRequestCallId) {
        this.receiveImportantLinksAPI(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getLang();
    this.getImportantLinksRequest();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleLinkTitleOnClick = (id: string) => {
    this.setState({ selectedLinkType: id });
  };
  getLang = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return   this.setState({ lang: lang })
    }
    this.setState({ lang: 'en' })
  }

  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }

  applyTranslation = async (item:any) => {
    let translatedObj : any = await TranslateText(item, this.state.lang, 'en')
    return translatedObj

  }
  getImportantLinksRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getImportantLinksAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getImportantLinksAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  receiveImportantLinksAPI = async(responseJson: any) => {
    if (responseJson && responseJson.data) {
      const importantLinkListAPIData : ImportantLinkListType[] = await Promise.all(responseJson.data.map(async(type: any) => {
        const dataLinkName : TranslatedDataType ={
          idNew: this.getSpecifiedLangString(this.state.lang, type[0].link_type, type[0].link_type),
          nameNew: this.getSpecifiedLangString(this.state.lang, type[0].link_type, type[0].arabic_link_type)
        }
        const newtranslatedData : TranslatedDataType = await this.applyTranslation(dataLinkName);
    
        const translatedLink : TranslatedLinkType[] =await Promise.all(type.map(async (link: any) => {
          const linkDiscription : LinkType ={
            link: link.link,
            description: this.getSpecifiedLangString(this.state.lang, link.description, link.arabic_description)
          }

        const linkDiscriptionData : TranslatedLinkType= await this.applyTranslation(linkDiscription);
          return {
            id:  link.id,
            link: linkDiscriptionData.link,
            description: linkDiscriptionData.description
          }
         
        }));
      

       return {
        id: newtranslatedData.idNew,
        name: newtranslatedData.nameNew,
        links: translatedLink.map((link: any) => ({
          id: link.id,
          link: link.link,
          description: link.description,
        })),
       }
      }));

      if (importantLinkListAPIData.length>0) {
        this.setState({
          importantLinkList: importantLinkListAPIData,
          selectedLinkType: importantLinkListAPIData[0].id,
        });
      }
    }
  };
  // Customizable Area End
}
