import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { setStorageData, removeStorageData } from "framework/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export type Props =  WithStyles & {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface CountryData {
    flags: {
      png: string
    },
    name: {
      common: string
    },
    idd: {
      root: string,
      suffixes: [string],
    },
    length: {
      min: number,
      max: number
    }
  }

export interface SendOtpPayload {
  data: {
    attributes:{
      full_phone_number : string
    }
  }
}
interface S {
  // Customizable Area Start
  countryFlag: boolean;
  countryCode: string;
  countryItemObject: CountryData;
  email: string;
  phoneNumberRegisteredAlready: boolean;
  phoneNumber: string;
  phoneErrors: boolean;
  phoneNumberString: string;
  countrySelectionModalOpen: boolean;
  inputValue: string;
  resultValCopy?: any;
  resultval?:any;
  isLoad: boolean;
  language:string;
  forgotPasswordPageText: {
    forgotEmailIdTitleText: string;
    paraOneText:string;
    paraTwoText:string;
    otpNotRecivedText:string;
    phoneNumberNotRegisteredText: string;
    phoneNumberLabelText:string;
    enterText:string;
    signUpText:string;
    addNewuserText: string;
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotEmailIdController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgetEmailIdCallBack: string = "";
  // Customizable Area End



  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
        countryCode: configJSON.defaultCountryCode,
        countryFlag: false,
        phoneNumberRegisteredAlready: false,
        countryItemObject: configJSON.countryObj,
        email: "",
        phoneNumber: "",
        phoneErrors: false,
        phoneNumberString: "",
        countrySelectionModalOpen: false,
        inputValue: "",
        resultval: configJSON.countryFlags,
        resultValCopy: configJSON.countryFlags,
        isLoad: false,
        language:"en",
        forgotPasswordPageText :{
          forgotEmailIdTitleText:configJSON.str_title,
          paraOneText: configJSON.str_pera1,
          paraTwoText: configJSON.str_pera2,
          otpNotRecivedText:configJSON.notOtpLink,
          phoneNumberNotRegisteredText: configJSON.errorMsg,
          phoneNumberLabelText:configJSON.phoneNoLabel,
          enterText: configJSON.submitTxt,
          signUpText:configJSON.signUpText,
          addNewuserText: configJSON.newUser
        }
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    removeStorageData("mobileNo");
    this.getLang();
    // Customizable Area End
  }

  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.messageSendOTPApi(message);
    // Customizable Area End
  }

  // Customizable Area Start

  getLang = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return   this.setState({ language: lang },  () => this.applyTranslation())
    }
    this.setState({ language: 'en' })
  }

  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }
  
  applyTranslation = async () => {
    let translatedObj : any = await TranslateText(this.state.forgotPasswordPageText, this.state.language, 'en')
      this.setState({ forgotPasswordPageText: translatedObj });
  }
  

  flagPhoneSetting = () => {
    let preFixCode = "";
    const  {phoneNumber, countryItemObject} = this.state;
    if(countryItemObject.idd.root){
      preFixCode = countryItemObject.idd.root + countryItemObject.idd.suffixes[0]
    }else{
      preFixCode = configJSON.defaultCountryCode
    }
    return preFixCode + phoneNumber;
  }

  handleFormSubmit = () => {
    const  {phoneNumber, phoneErrors} = this.state;
    if(phoneNumber.length != 0 && !phoneErrors){
      const RecordData = {
        "data":{
            "attributes": {
                "full_phone_number": this.flagPhoneSetting()
            }
        }
      }
      this.sendOTPApi(RecordData);
    }
  }

  handleChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({phoneNumberRegisteredAlready:false});
    const { value } = event.target
    const rejex = configJSON.phoneRejex;
    const flagObject = this.state.countryItemObject;
    this.setState({ phoneNumber: value });
    if (
      value === "" ||
      (rejex.test(value) && value.length >= flagObject.length.min && value.length <= flagObject.length.max)
    ) {
      this.setState({ phoneErrors: false });
    } else {
      this.setState({
        phoneErrors: true,
        phoneNumberString: configJSON.phoneNumberErrorsInvalid,
      });
    }
  };



  handleCountryModalOpen = () => {
    this.setState({ countrySelectionModalOpen: true });
  };

  handleCountryFlagClose = () => {
    this.setState({ countrySelectionModalOpen: false });
  };

  handleChangeCountry = (value: string) => {
    this.setState({inputValue:value});
    this.fetchCountryCodeData(value);
  };


  fetchCountryCodeData = (value: string) => {
    let backupStateOfResultVal = this.state.resultValCopy
    if(value){
      const startwith=this.state.resultValCopy.filter((user: CountryData) => {
          return (
              (user.name.common.toLowerCase().startsWith(value.toLowerCase())) ||  (user.idd.root.toLowerCase().includes(value))
          );
        });
        const endwith=this.state.resultValCopy.filter((user: CountryData) => {
            return (
                (user.name.common.toLowerCase().includes(value.toLowerCase())) ||  (user.idd.root.toLowerCase().includes(value))
            );
          });
        const result=[...(new Set([...startwith,...endwith]))];
      if(result.length > 0 ){
        this.setState({ resultval: result })
      }
    }
    else{
      this.setState({ resultval: backupStateOfResultVal })
    }
  };
  

  onClickParticularCoutnry = (countryItem : CountryData) => {
    this.setState({
      countryItemObject:countryItem,
      countrySelectionModalOpen:false,
      countryFlag:true,
    })
  }

  handlenavigation = () => {
    this.props.navigation.navigate("EmailAccountRegBlock");
  }



  messageSendOTPApi = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    const lang = this.state.language;
    if(this.forgetEmailIdCallBack === apiRequestCallId){
      if(responseJson && responseJson?.message){
        setStorageData("mobileNo", this.flagPhoneSetting());
        this.props.navigation.navigate("ForgotEmailOTP");
      }else if(responseJson && responseJson?.errors){
        this.setState({phoneNumberString: lang === "ar" ? "رقم الهاتف غير مسجل لدى نامي" : configJSON.errorMsg, phoneErrors: true});
      }else{
        this.setState({phoneErrors: true, phoneNumberString: configJSON.wrongTxtMsg});
      }
    }
  } 

  sendOTPApi = async (data: SendOtpPayload) => {
    const headers = {
      "content-type": "application/json",
      "locale":this.state.language
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetEmailIdEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.httpPostMethod}`
    );
    this.forgetEmailIdCallBack = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  
  
  
  // Customizable Area End
}

//Common Functions
export const generateRequestMessage =  async (endpoint: string, type: string ) => {
  const headers = {
    "content-type": "application/json"
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    type
  );
  return requestMessage;
};

export const getResponseVariables = (message: Message) => {
  const apiRequestCallID = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJSON = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  return [responseJSON, apiRequestCallID];
};