import React from "react"
import {Box,Button,Typography, withStyles } from "@material-ui/core";
import NewsDetailsController from "./NewsDetailsController.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
const images = require("./assets");
import { Link } from "react-router-dom";
import {Styles} from "./Styles.web";
//Customizable Area Start
import { conditionSort, getHtml } from "../../../components/src/Helper";

//Customizable Area End

export class NewsDetails extends NewsDetailsController {
  
  //Customizable Area Start
  //Customizable Area End


  render() {
    //Customizable Area Start
    const { classes } = this.props;
    const {newsData} = this.state;
    const item = newsData.attributes;
    //Customizable Area End
    return (
    <>
    <HeaderBlock/>

    
    {/* Customizable Area Start */}
    {(!!newsData) && (
    <Box data-test-id="news-discription-content" className={classes.detailWrapper} style={{margin: "2rem auto"}}>
      <Box className={classes.detailHeader}>
        <Typography component="h1" className={classes.pageTitle}>{item.title}</Typography>
      </Box>
        <Box className={classes.NewsBlockDetails}>
            <Box className={classes.NewsDetailsInner}>
                <Box className={classes.NewsDetailsLeft}>
                    <div>
                    <span className={classes.imgDetailsBlock} style={{paddingRight: '64px'}} >
                        <img src={item.image_url != null ? item.image_url : images.newsThumb} alt={item.title} className={classes.newsThumb} />
                    </span>
                    {getHtml(item.description)}
                    </div>  
                </Box>
            </Box>

            <Box className={classes.newsFooterTop}>
                <Link to="../"><img src={images.quote} /><img src={images.logo} /></Link>
            </Box>
            
                        <Box className={conditionSort(this.state.lang !== "ar", "newsFooterAction", "arabicNewsFooter newsFooterAction")}>
                            <Box className={conditionSort(this.state.lang !== "ar", "prevButtonContent", "arabicNewsFooter prevButtonContent")}>
                    <Button data-testid="previous" className={classes.prevButton} disabled={!this.state.isPreview} style={!this.state.isPreview ? {opacity: 0.3} : {}} onClick={() => this.prevNews(item.next_previous_ids.previous)}>
                        <img src={images.leftArrow} alt="" />
                    </Button>
                    <span className={classes.labelContaint} data-test-id="news-btn-previous">
                        <label> { this.state.lang==="ar"?"السابق":"Previous"}</label>
                        <span>{ this.state.lang==="ar"?"مقالة إخبارية":"News Article"}</span>
                    </span>
                </Box>
                            <Box className={conditionSort(this.state.lang !== "ar", "nextButtonContent", "arabicNewsFooter nextButtonContent")}>
                    <span className={classes.labelContaint}>
                        <label>{ this.state.lang==="ar"?"التالي":"Up Next"}</label>
                        <span>{ this.state.lang==="ar"?"مقالة إخبارية":"News Article"}</span>
                    </span>
                    <Button data-testid="next" className={classes.nextButton} disabled={!this.state.isNext} style={!this.state.isNext ? {opacity: 0.3} : {}} onClick={() => this.nextNews(item.next_previous_ids.next)}><img src={images.rightArrow} alt="" /></Button>
                </Box>
            </Box>
        </Box>
    </Box>)}
    {/* Customizable Area End */}

    <FooterBlock/></>
    );
    
  }
}

export default withStyles(Styles)(NewsDetails);