Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.createListAPIEndPoint ="bx_block_catalogue/listings/create_listing";
exports.updatePercentageEndPoint = "bx_block_catalogue/listings/update_list";
exports.getCategoriesEndPoint = "bx_block_categories/categories?sub_categories=true";

exports.pageTextData = {
  previous: "Previous",
  previousArabic: "السابق",
  typeOfRole: "Type of Role",
  typeOfRoleArabic: "نوع العملية",
  upNext: "Up Next",
  upNextArabic: "التالي",
  typeOfCompany: "Type of Company",
  typeOfCompanyArabic: "تصنيف الشركة",
  iAmSelling: "I'm selling...",
  iAmSellingArabic: "أريد بيع...",
  assets: "Assets",
  assetsArabic: "بيع أصول",
  company: "Company",
  companyArabic: "بيع شركة",
  assetDesc: `The sale of specific company assets, such as machinery, real estate, or 
  intellectual property, without transferring the company's ownership 
  (e.g., Etisalat selling its tower assets in Nigeria to IHS Holding)`,
  assetDescArabic: `بيع أصول مملوكة من قبل أفراد أو شركات، مثل الآلات أو العقارات أو الملكية الفكرية دون نقل ملكية الشركة (على سبيل المثال، قيام شركة اتصالات ببيع أبراجها في إلى شركة اخرى)
  `,
  companyDesc: `The transfer of ownership of an entire company, including its assets, 
  liabilities, and operations. (e.g., Amazon's acquisition of Souq.com, a
   prominent e-commerce platform in the Middle East)`,
   companyDescArabic: `نقل ملكية الشركة بأكملها، بما في ذلك أصولها والتزاماتها وعملياتها. (على سبيل المثال، استحواذ أمازون على سوق.كوم، وهي منصة بارزة للتجارة الإلكترونية في الشرق الأوسط)`,
   backToHome: `Back To Homepage`,
   backToHomeArabic: "الصفحة الرئيسية",
   
   next: "Next",
   nextArabic: "التالي",
   stayInformed: 'Stay Informed',
   stayInformedArabic: " ابق على اطلاع",
   assetsList: `You have been add to the assets list and we will keep an eye
   on you and will contact you`,
   assetsListArabic: "لقد تمت إضافتك إلى قائمة الأصول وسنراقبك وسنتواصل معك",
   success: 'Success',
   successArabic: "النجاح",
}

exports.companyTypeTextData = {
  typeOfRoleArabic: "نوع العملية",
  previous: "Previous",
  previousArabic: "السابق",
  typeOfRole: "Type of Role",
  isYourCompany: `Is your company a tech startup or an establishing business?`,
  isYourCompanyArabic: "ماهو تصنيف شركتك؟",
  startUp: "Start Up",
  staetUpArabic: "شركة تقنية ناشئة",
  business: "Business",
  businessArabic: "شركة قائمة",
  nextArabic: "التالي",
  next: "Next",
  startUpDesc: `Tech-oriented businesses with innovative ideas aiming for rapid growth
  by addressing a unique market need (e.g., An online food delivery 
   platform)`,
  startUpDescArabic: `شركات ذات توجه تقني و أفكار مبتكرة تهدف إلى النمو السريع من خلال تلبية احتياجات نوعية و فريدة للسوق (مثل تطبيقات التوصيل)`,
   businessDesc: `An established entity that offers products or services i.e. manufacturing, 
   trading, brokerage, services etc. (e.g., A family-owned fashion retail 
     brand)`,
  businessDescArabic: `كيان مؤسس يقدم منتجات أو خدمات مثل التصنيع والتجارة والوساطة والخدمات وما إلى ذلك (مثلا علامة تجارية لبيع الأزياء بالتجزئة)

  `,
}

// Customizable Area End