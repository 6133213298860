import React from "react";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme
} from "@material-ui/core";
import { accordionIcon } from "./assets";
import { conditionSort } from "../../../components/src/Helper";

interface AccorionMenuProps {
  selectedData?: string;
  optionList: { value: string; title: string , icon?:string }[];
  disabled?: boolean;
  onChange?: (selectedValue: string) => void;
}

export const AccordionSelect: React.FC<AccorionMenuProps> = ({
  selectedData,
  optionList,
  disabled,
  onChange
}) => {
  const theme = useTheme();
  const SelectMenuStyle = () => {
    return {
      border: "1px #8C8C8C solid",
      borderRadius: "12px",
      minHeight: "52px",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "8px",
        minHeight: "34px"
      },
      "& .MuiAccordion-root": {
        boxShadow: "none",
        borderRadius: "0 !important",
        "& .MuiAccordionSummary-root": {
          minHeight: "auto",
          padding: "0",
          "& .MuiIconButton-root": {
            padding: "0 !important",
            margin: "0 !important",
            "& .MuiIconButton-label": {
              // width: "28px",
              // height: "28px",
              cursor: "pointer",
              padding: "10px 20px",
              "& img": {
                [theme.breakpoints.down("sm")]: {
                  width: "16px",
                  height: "16px"
                }
              }
            }
          },
          "& .MuiAccordionSummary-content": {
            display: "grid",
            gap: "16px",
            margin: "0",
            "& .heading": {
              padding: "12px 24px",
              color: "black",
              fontSize: "20px",
              fontFamily: "Montserrat",
              fontWeight: 400,
              wordWrap: "break-word",
              cursor: "pointer",
              // borderBottom: "1px solid black",
              [theme.breakpoints.down("sm")]: {
                padding: "5px 12px",
                fontSize: "10px",
                lineHeight: "20px"
              }
            }
          }
        },
        "& .MuiCollapse-wrapper": {
          borderTop: "1px solid #8C8C8C"
        },
        "& .MuiCollapse-wrapperInner": {
          margin: "0px 20px 20px",
          [theme.breakpoints.down("sm")]: {
            margin: "0px 12px 12px"
          },
          "& .MuiAccordionDetails-root": {
            padding: "8px 0 0",
            display: "block",
            "& .option": {
              padding: "8px",
              color: "black",
              fontSize: "20px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: "24px",
              wordwrap: "break-word",
              cursor: "pointer",
              [theme.breakpoints.down("sm")]: {
                fontSize: "10px",
                lineHeight: "20px"
              },
              "&:hover": {
                background: "rgb(140, 140, 140, 0.5)",
                borderRadius: "12px",
                [theme.breakpoints.down("sm")]: {
                  borderRadius: "8px"
                }
              }
            }
          }
        }
      },
      "& .MuiAccordion-root.Mui-disabled": {
        backgroundColor: "transparent"
      }
    };
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const handleOptionClick = (selectedValue: string) => {
    setExpanded(false);

    if (onChange) {
      onChange(selectedValue);
    }
  };

  return (
    <>
      <Box sx={SelectMenuStyle()} data-test-id="accordion">
        <Accordion
          expanded={expanded}
          onChange={handleAccordionChange}
          disabled={disabled}
        >
          <AccordionSummary
            expandIcon={<img src={accordionIcon} />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className="heading">{selectedData}</div>
          </AccordionSummary>
          {/* <Divider /> */}
          <AccordionDetails>
            {optionList.map(menu => (
              <div
                key={menu.value}
                className="option"
                onClick={() => handleOptionClick(menu.value)}
              >
                {conditionSort(menu.icon, <img src={menu.icon} alt="" style={{ marginRight: 8, width: 20 }} />, null)}
                
                {menu.title}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
