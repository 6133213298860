import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ToastPosition, toast } from "react-toastify";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { FormikProps } from "formik";
import React from "react";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
import { conditionSort, formatNumber } from "../../../components/src/Helper";



export interface ListingsCards {
  id: string;
  title: string;
  titleImgSrc: any;
  type: string;
  description: string;
  annualRevenue: string;
  employeesCount: number;
  annualProfit: string;
  foundedIn: string;
  basedIn: string;
  proposedValuation: string;
  originListing: string;
  is_bookmarked: boolean | null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  expanded: string;
  business_type: string[];
  growthOpportunity: string[];
  growthOpputunityOther: boolean;
  keyAssets: string[];
  keyAssestsOther: boolean;
  self_funded: boolean;
  corporate_funded: boolean;
  shareholders_equity: boolean;
  loans: boolean;
  other: string[];
  otherCheckbox: boolean;
  otherCheckbox1: boolean;
  otherCheckbox2: boolean;
  otherCheckbox3: boolean;
  reason_for_selling: string;
  anchorEl: null | HTMLElement
  categoriesList: { id: string, name: string, subCategories: { id: string, name: string, isChecked: boolean }[] }[];
  initialData: any;
  data: any;
  errors: any;
  usersList: any;
  subCategoryList: any;
  keyAssetsOther: string;
  growthOpportunityOther: string;
  businessTypeOther: string;
  openFileModal: boolean;
  modalFile : any;
  modalFileTitle : string;
  openStartUpBox:boolean;
  openBuisnessBox: boolean;
  openBoxModal: boolean;
  isListingModalSuccess: boolean;
  successTextModal: boolean;
  boxContent: string,
  boxTitle: string,
  categoryId:string,
  docsArray: any,
  userData: any,
  language:string;
  countriesData : {icon:string, value:string, title:string}[];
  countriesDataAr : {icon:string, value:string, title:string}[];
  businessTypeCheckBoxes : string[];
  businessTypeCheckBoxesAr: string[];
  growthApportunities : string[];
  growthApportunitiesAr : string[];
  keyAssests : string[];
  keyAssestsAr : string[];
  cityItems :{country:string,cities:string[]}[];
  cityItemsAr :{country:string,cities:string[]}[];
  companyProfilePageText :{
    otherCatText: string,
    otherLabel: string,
    addNewListingText:string,
    addingNewListingCompleteText:string,
    otherCategoryText:string,
    privateInformationHeadingText:string,
    subHeadingLoremIpsumText:string,
    summaryHeadingText:string,
    commonHeadingText:string,
    companyOverViewText:string,
    acquisationDetailsHeading:string,
    financialHeadingText:string,
    customerAndVenderMetrics:string,
    uploadDocumentText:string,
    titleText:string,
    startUpText:string,
    businessText:string,
    NextbuttonText:string,
    successText:string,
    listingAddedText:string,
    youCanAccessText:string,
    companyNameInputLabel: string;
    companyWebsiteInputLabel: string;
    vendorsInputLabel: string;
    yesLabel: string;
    noLabel: string;
    commitmentsInputLabel: string;
    contractInputLabel: string;
    pAndLInputLabel: string;
    balanceSheetInputLabel: string;
    documentsFilesInputLabel: string;
    totalDeptInputLabel: string;
    totalAssetsInputLabel: string;
    totalShareholderEquityInputLabel: string;
    autoSignInputLabel: string;
    autoSignNdaLabel: string;
    autoAcceptRequestLabel: string;
    viewFullProfileText:string;
    errorMessage:string;
    fieldError: string;
    errorMessageUploadDoc:string;
    progressCompleteLabel: string;
    yourListingLabel: string;
    completeProfileText: string;
    needHelpText: string;
    completeProfileButtonLabel: string;
    exploreListingsButtonLabel: string;
    clearText:string;
    saveText:string;
    dateFoundedInputLabelText: string;
    employeesInputLabelText: string;
    businessTypeInputLabelText: string;
    countryInputLabelText: string;
    cityInputLabelText: string;
    headlineInputLabelText: string;
    descriptionInputLabelText: string;
    askingPriceInputLabelText: string;
    askingPriceReasoningInputLabelText: string;
    businessTypeInputLabel: string;
    listOfProductsInputLabel: string;
    revenueModelInputLabel: string;
    regionsMarketInputLabel: string;
    numberOfSuppliersInputLabel: string;
    competitorsInputLabel: string;
    growthOpportunityInputLabel: string;
    keyAssetsInputLabel: string;
    reasonForSellingLabelText:string;
    financingSoucesLabelText:string;
    selfFundedCheckboxLabel:string;
    corporateFundedCheckboxLabel:string;
    shareholdersEquityCheckboxLabel:string;
    loansCheckboxLabel:string;
    otherCheckboxLabel:string;
    ltmGrossRevenueInputLabel: string;
    ltmGrossRevenueTooltip: string;
    ltmNetProfitInputLabel: string;
    ltmNetProfitTooltip: string;
    annualRecurringRevenueInputLabel: string;
    annualRecurringRevenueTooltip: string;
    annualGrowthRateInputLabel: string;
    annualGrowthRateTooltip: string;
    revenueMultipleInputLabel: string;
    revenueMultipleTooltip: string;
    priceEarningsRatioInputLabel: string;
    priceEarningsRatioTooltip: string;
    howManyCustomersInputLabel:string;
    startupContentText:string;
    businessContentText:string;
    dataSavedText:string;
    successMessageToastText:string;
    successConfirmTextLine1: string;
    successConfirmTextLine2: string;
    successConfirmTextLine4:string;
    successConfirmTextLine3: string;
    invaildField: string;
  };
  hashId:string;
  otherSubCatId: string;
  otherCatText: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

const initialData = {
  private_information: {
    company_name: "",
    company_website: "",
    work_with_vendors: [""],
    already_commitments: "No",
    total_depth: "",
    total_assets: "",
    total_shareholder_equity: "",
    auto_sign: false,
    auto_accept_request: false,
    listing_id: true,
    suppliers_and_vendors_contract: null,
    balance_sheet: [],
    p_and_l_statement: {
      id: null,
      file_name: "",
      url: ""
    },
    documents: []
  },
  summarry: {data: {
    id: "",
    type: "",
    attributes: {
      id: "",
      date_founded: "",
      startup_team_size: null,
      number_of_employees: 0,
      country: "",
      description: "",
      asking_price: "",
      asking_price_reasoning: "",
      headline: "",
      city: "",
      sub_category_id: null,
      listing_id: null,
      start_type: ""
    }
  }},
  company_overview: {
    data: {
      id: null,
      type: "",
      attributes: {
        id: null,
        business_type: [],
        list_of_product_services: [],
        revenue_model: "",
        which_regions_business_operated: "",
        number_of_suppliers: "",
        competitors: [""],
        growth_opportunity: [],
        key_assets: [],
        listing_id: null
      }
    },
    list_of_product_services: [
      ""
    ]
  },
  acquisition_detail: {
    data: {
      id: "",
      type: "",
      attributes: {
        id: "",
        reason_for_selling: "",
        self_funded: false,
        corporate_funded: false,
        shareholders_equity: false,
        loans: false,
        other: "",
        listing_id: ""
      }
    }
  },
  financial: {
    data: {
      id: "",
      type: "",
      attributes: {
        id: '',
        ltm_gross_revenue: "",
        ltm_net_profit: "",
        annual_recurring_revenue: "",
        annual_growth_rate: "",
        revenue_multiple: "",
        price_earnings_ratio: "",
        listing_id: ''
      }
    }
  },
  customer_metric: {
    data: {
      id: "",
      type: "",
      attributes: {
        id: "",
        number_of_customer: "",
        listing_id: ""
      }
    }
  }
}

export default class CompanyProfileEditController extends BlockComponent<Props, S, SS> {
  formRef: React.RefObject<FormikProps<any>>;
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef();
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      otherSubCatId: "",
      otherCatText: "",
      business_type: [],
      expanded: '',
      growthOpportunity: [],
      growthOpputunityOther: false,
      keyAssets: [],
      keyAssestsOther: false,
      self_funded: false,
      corporate_funded: false,
      shareholders_equity: false,
      loans: false,
      other: [],
      otherCheckbox: false,
      otherCheckbox1: false,
      otherCheckbox2: false,
      otherCheckbox3: false,
      reason_for_selling: "",
      anchorEl: null,
      categoriesList: [],
      initialData: {},
      data: {},
      errors: {},
      usersList: [],
      subCategoryList: [],
      keyAssetsOther: "",
      growthOpportunityOther: "",
      businessTypeOther: "",
      openFileModal: false,
      modalFileTitle :"",
      modalFile:"",
      openStartUpBox:false,
      openBuisnessBox: false,
      openBoxModal: false,
      isListingModalSuccess: false,
      successTextModal: false,
      boxContent: '',
      boxTitle: '',
      categoryId: '',
      docsArray: [],
      userData: {},
      language:"en",
      countriesData:[],
      countriesDataAr: [],
      businessTypeCheckBoxes:configJSON.businessTypes,
      businessTypeCheckBoxesAr:configJSON.businessTypesAr,
      growthApportunities : configJSON.growthAppronities,
      growthApportunitiesAr : configJSON.growthAppronitiesAr,
      keyAssests : configJSON.keyAssests,
      keyAssestsAr : configJSON.keyAssestsAr,
      cityItems: configJSON.countriesData,
      cityItemsAr: configJSON.countriesDataAr,
      companyProfilePageText:{
        otherLabel: configJSON.otherLabel,
        invaildField: configJSON.invaildField,
        addNewListingText: configJSON.addNewListingText,
        addingNewListingCompleteText: configJSON.addingNewListingCompleteText,
        otherCategoryText: configJSON.otherCategoryText,
        privateInformationHeadingText: configJSON.privateInformationHeadingText,
        subHeadingLoremIpsumText: configJSON.subHeadingLoremIpsumText,
        summaryHeadingText: configJSON.summaryHeadingText,
        commonHeadingText: configJSON.commonHeadingText,
        companyOverViewText: configJSON.companyOverViewText,
        acquisationDetailsHeading: configJSON.acquisationDetailsHeading,
        financialHeadingText: configJSON.financialHeadingText,
        customerAndVenderMetrics: configJSON.customerAndVenderMetrics,
        uploadDocumentText: configJSON.uploadDocumentText,
        titleText: configJSON.titleText,
        startUpText: configJSON.startUpText,
        businessText: configJSON.businessText,
        NextbuttonText: configJSON.NextbuttonText,
        successText: configJSON.successText,
        listingAddedText: configJSON.listingAddedText,
        youCanAccessText: configJSON.youCanAccessText,
        companyNameInputLabel: configJSON.companyNameInputLabel,
        companyWebsiteInputLabel: configJSON.companyWebsiteInputLabel,
        vendorsInputLabel: configJSON.vendorsInputLabel,
        yesLabel: configJSON.yesLabel,
        noLabel: configJSON.noLabel,
        commitmentsInputLabel: configJSON.commitmentsInputLabel,
        contractInputLabel: configJSON.contractInputLabel,
        pAndLInputLabel: configJSON.pAndLInputLabel,
        balanceSheetInputLabel: configJSON.balanceSheetInputLabel,
        documentsFilesInputLabel: configJSON.documentsFilesInputLabel,
        totalDeptInputLabel: configJSON.totalDeptInputLabel,
        totalAssetsInputLabel: configJSON.totalAssetsInputLabel,
        totalShareholderEquityInputLabel: configJSON.totalShareholderEquityInputLabel,
        autoSignInputLabel: configJSON.autoSignInputLabel,
        autoSignNdaLabel: configJSON.autoSignNdaLabel,
        autoAcceptRequestLabel: configJSON.autoAcceptRequestLabel,
        viewFullProfileText:configJSON.viewFullProfileText,
        errorMessage:configJSON.errorMessage,
        fieldError: configJSON.fieldError,
        errorMessageUploadDoc: configJSON.errorMessageUploadDoc,
        progressCompleteLabel: configJSON.progressCompleteLabel,
        yourListingLabel: configJSON.yourListingLabel,
        completeProfileText: configJSON.completeProfileText,
        needHelpText: configJSON.needHelpText,
        completeProfileButtonLabel: configJSON.completeProfileButtonLabel,
        exploreListingsButtonLabel: configJSON.exploreListingsButtonLabel,
        clearText:configJSON.clearText,
        saveText:configJSON.saveText,
        dateFoundedInputLabelText: configJSON.dateFoundedInputLabelText,
        employeesInputLabelText: configJSON.employeesInputLabelText,
        businessTypeInputLabelText: configJSON.businessTypeInputLabelText,
        countryInputLabelText: configJSON.countryInputLabelText,
        cityInputLabelText: configJSON.cityInputLabelText,
        headlineInputLabelText: configJSON.headlineInputLabelText,
        otherCatText: configJSON.otherCatText,
        descriptionInputLabelText: configJSON.descriptionInputLabelText,
        askingPriceInputLabelText: configJSON.askingPriceInputLabelText,
        askingPriceReasoningInputLabelText: configJSON.askingPriceReasoningInputLabelText,
        businessTypeInputLabel: configJSON.businessTypeInputLabel,
        listOfProductsInputLabel: configJSON.listOfProductsInputLabel,
        revenueModelInputLabel: configJSON.revenueModelInputLabel,
        regionsMarketInputLabel: configJSON.regionsMarketInputLabel,
        numberOfSuppliersInputLabel: configJSON.numberOfSuppliersInputLabel,
        competitorsInputLabel: configJSON.competitorsInputLabel,
        growthOpportunityInputLabel: configJSON.growthOpportunityInputLabel,
        keyAssetsInputLabel: configJSON.keyAssetsInputLabel,
        reasonForSellingLabelText:configJSON.reasonForSellingLabelText,
        financingSoucesLabelText:configJSON.financingSoucesLabelText,
        selfFundedCheckboxLabel:configJSON.selfFundedCheckboxLabel,
        corporateFundedCheckboxLabel:configJSON.corporateFundedCheckboxLabel,
        shareholdersEquityCheckboxLabel: configJSON.shareholdersEquityCheckboxLabel,
        loansCheckboxLabel:configJSON.loansCheckboxLabel,
        otherCheckboxLabel: configJSON.otherCheckboxLabel,
        ltmGrossRevenueInputLabel: configJSON.ltmGrossRevenueInputLabel,
        ltmGrossRevenueTooltip: configJSON.ltmGrossRevenueTooltip,
        ltmNetProfitInputLabel: configJSON.ltmNetProfitInputLabel,
        ltmNetProfitTooltip: configJSON.ltmNetProfitTooltip,
        annualRecurringRevenueInputLabel: configJSON.annualRecurringRevenueInputLabel,
        annualRecurringRevenueTooltip: configJSON.annualRecurringRevenueTooltip,
        annualGrowthRateInputLabel: configJSON.annualGrowthRateInputLabel,
        annualGrowthRateTooltip: configJSON.annualGrowthRateTooltip,
        revenueMultipleInputLabel: configJSON.revenueMultipleInputLabel,
        revenueMultipleTooltip: configJSON.revenueMultipleTooltip,
        priceEarningsRatioInputLabel: configJSON.priceEarningsRatioInputLabel,
        priceEarningsRatioTooltip: configJSON.priceEarningsRatioTooltip,
        howManyCustomersInputLabel:configJSON.howManyCustomersInputLabel,
        startupContentText:configJSON.startupContentText,
        businessContentText:configJSON.businessContentText,
        dataSavedText :"Data saved successfully",
        successConfirmTextLine1: configJSON.successConfirmTextLine1,
        successConfirmTextLine2: configJSON.successConfirmTextLine2,
        successConfirmTextLine4:   configJSON.successConfirmTextLine4,
        successConfirmTextLine3: configJSON.successConfirmTextLine3,
        successMessageToastText: configJSON.successMessageToastText
      },
      hashId:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getLang();
        if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getUserProfileData = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserProfileEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNumChange = (name: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const rawValue = event.target.value.toString().replace(/,/g, '');
    if (!isNaN(Number(rawValue)) || rawValue === '') {
      let tempObj = { ...this.state.data };
      const formattedValue = formatNumber(rawValue);
      if(name == 'private_information') {
        tempObj[name][event.target.name] = formattedValue;

      } else {
        tempObj[name].data.attributes[event.target.name] = formattedValue;
      }
      this.setState({
        data: { ...tempObj }
      });
    }
  }

  formatNumForDatabase = (value: string) => value?.replace(/,/g, '');

  getSubCategoryList = (categoryId: string) => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategoryAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubCategoriesEndPoint}?category_id=${categoryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveUpdateResponse = (responseAPIId: string) => {
    if (this.updatePrivateInformationAPI === responseAPIId ||this.updateCustomerMetricAPI === responseAPIId ||
      this.updateFinancialAPI === responseAPIId ||
      this.updateSummarryAPI === responseAPIId ||
      this.updateCompanyOverViewAPI === responseAPIId || 
      this.updatePrivateInformationAPI === responseAPIId ||
      this.updateAcquisitionDetailApi === responseAPIId) {
      this.receiveUpdateApi();
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = await getStorageData("token");
      this.receiveTokenValue(token);
    }

    // receive from api
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseAPIId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      // get response
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (this.getSmeListAPI === responseAPIId) {
        this.receiveSmeListAPI(responseJson);
      }
      if (this.getCategoriesAPI === responseAPIId) {
        this.receiveCategoriesAPI(responseJson);
      }
      
      this.receiveUpdateResponse(responseAPIId)
      
      if (this.getPrivateInfoAPI === responseAPIId) {
        this.receivePrivateInfo(responseJson);
      }
      if (this.addSmeAPI === responseAPIId) {
        this.receiveSmeAPI(responseJson);
      }
      if (this.getSingleSmeAPI === responseAPIId) {
        this.receiveSelectedSmeAPI(responseJson);
      }
      if (this.getSubCategoryAPI === responseAPIId) {
        this.receiveSubCategoryAPI(responseJson);
      }
      this.handleResAddSubCat(responseAPIId, responseJson);

      this.receiveUserData(responseAPIId, responseJson);  
      this.receivePercentData(responseAPIId, responseJson);    
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getSmeListAPI = "";
  getCategoriesAPI = "";
  getPrivateInfoAPI = "";
  addSmeAPI = "";
  getSingleSmeAPI = "";
  getSubCategoryAPI = "";
  getUserProfileAPI = "";
  postNewCategoryAPIId = "";
  updateCustomerMetricAPI = "";
  updateFinancialAPI = "";
  updatePercentAPI = "";
  updateSummarryAPI = "";
  updatePrivateInformationAPI = "";
  updateCompanyOverViewAPI = "";
  updateAcquisitionDetailApi = ""
  countriesData = configJSON.countriesData;
  countriesDataAr = configJSON.countriesDataAr;
  
  receiveUserData = (responseAPIId: string, responseData: {data: {attributes: {}}}) => {
    if (this.getUserProfileAPI === responseAPIId && responseData.data) {
      this.setState({userData: responseData.data.attributes})
    }

  }

  receivePercentData = (responseAPIId: string, responseData: {data: {attributes: {hash_id:""}}}) => {
    if (this.updatePercentAPI === responseAPIId && responseData.data) {
      this.setState({successTextModal: true,hashId: responseData.data.attributes.hash_id})
    }

  }
  receiveUpdateApi = () => {
    this.raiseToast(this.state.companyProfilePageText.dataSavedText,"success");
    this.checkProgress();
  }
  handleListingModalClose=(stats:boolean)=>{
    this.setState({openBoxModal:stats,isListingModalSuccess:false})
    
    const dataNewList= this.state.usersList.reduce((max:any, item:any) => item.id > max.id ? item : max, this.state.usersList[0]);
    
    if (dataNewList.attributes.category_name === "SME") {
      this.props.navigation.history.push(
        `/CompanyProfileEdit/${dataNewList.attributes.id}`
      );
    } else {
      this.props.navigation.history.push(
        `/startupDetails/${dataNewList.attributes.id}`
      );
    }
  }

  handleListingModal=(stats:boolean)=>{
    if(this.state.data.id && parseInt(this.state.data.progress) != 100) {
      this.raiseToast(this.state.language==="ar"?configJSON.addingNewListingCompleteText_ar:configJSON.addingNewListingCompleteText, "error")
      return;
    }
    this.setState({openBoxModal:stats,isListingModalSuccess:false})
  }

  handleCloseModal = () => {
    this.setState({successTextModal:false, openBoxModal:false})
  }

  handleConditon = (condition: any, trueSta: any, falseSta: any) => {
    return condition ? trueSta : falseSta
  }

  handleStartUpBox = () => {
    const startUpContent = this.state.language === 'ar' ? configJSON.startupContentText_ar:configJSON.startupContentText
    const title = this.state.language === 'ar' ? configJSON.startUpText_ar:configJSON.startUpText
    // set data
    this.setState({ openStartUpBox: true });
    this.setState({ openBuisnessBox: false });
    this.setState({ boxTitle: title });
    this.setState({ boxContent: startUpContent });
  };
  
  // if user select business
  handleBuisnessBox = () => {
    const content =this.state.language === 'ar' ? configJSON.businessContentText_ar:configJSON.businessContentText;
    const title = this.state.language === 'ar' ? configJSON.businessText_ar : configJSON.businessText;
    // set data
    this.setState({ openStartUpBox: false });
    this.setState({ openBuisnessBox: true });
    this.setState({ boxTitle: title });
    this.setState({ boxContent: content });
  };
  

  handleChange = (ev: any) => {
    if(ev.target.name === 'keyAssetsOther') {
      this.setState({
        keyAssetsOther: ev.target.value
      })
    }
    if(ev.target.name === 'growthOpportunityOther') {
      this.setState({
        growthOpportunityOther: ev.target.value
      })
    }
    if(ev.target.name === 'businessTypeOther') {
      this.setState({
        businessTypeOther: ev.target.value
      })
    }
    
  }
  handleSwitch = (fieldName: string, name: string) => {
    let tempData = { ...this.state.data };
    tempData[fieldName][name] = !tempData[fieldName][name];
    this.setState({data: {...tempData}});
  }

  handleCheckboxChange = (fieldName: string, name: string, value: string) => {
    let tempData = { ...this.state.data };
    if(fieldName === "company_overview"){
      let tempValues = tempData[fieldName].data.attributes[name];
    if (tempValues.find((tempValue: string) => tempValue === value)) {
      tempValues = tempValues.filter((tempValue: string) => tempValue !== value)
    } else tempValues.push(value);
    tempData[fieldName].data.attributes[name] = tempValues;
    this.setState({
      data: { ...tempData }
    });
    }else{
    let tempValues = tempData[fieldName][name];
    if (tempValues.find((tempValue: string) => tempValue === value)) {
      tempValues = tempValues.filter((tempValue: string) => tempValue !== value)
    } else tempValues.push(value);
    tempData[fieldName][name] = tempValues;
    this.setState({
      data: { ...tempData }
    });
  }
    
  }

  handleArrayInput = (fieldName: string, name: string, value: string, index: number) => {
    if(name === "competitors" || name == "list_of_product_services"){
      let tempData = { ...this.state.data };
    let tempValues = tempData[fieldName].data.attributes[name]
    tempValues[index] = value;
    tempData[fieldName][name] = tempValues;
    this.setState({
      data: { ...tempData }
    });
    }else{
    let tempData = { ...this.state.data };
    let tempValues = tempData[fieldName][name];
    tempValues[index] = value;
    tempData[fieldName][name] = tempValues;
    this.setState({
      data: { ...tempData }
    });
  }
  }

  addNewItem = (fieldName: string, name: string) => {
    let tempData = { ...this.state.data };
    if(name === "competitors"){
      let tempValues = tempData[fieldName].data.attributes[name];
    tempValues.push("");
        tempData[fieldName].data.attributes[name] = tempValues;
        this.setState({
          data: { ...tempData }
        });
    }else{
      let tempValues = tempData[fieldName][name];
    tempValues.push("");
        tempData[fieldName][name] = tempValues;
        this.setState({
          data: { ...tempData }
        });
    }
    
    
  }
 
  handleOnInputChange = async (name: string, ev: any) => {
    let tempObj = { ...this.state.data };
   
      if (ev.target.name === 'country'){
        tempObj['summarry']['city'] = "";
      tempObj[name].data.attributes['city']="";
      }
      if (ev.target.name === 'number_of_employees') {
        const number_of_employees = ev.target.value
        if(!number_of_employees) {
          this.setState(prev => ({companyProfilePageText: {...prev.companyProfilePageText}}))
        }
        else if(number_of_employees && isNaN(number_of_employees)) {
          this.setState(prev => ({companyProfilePageText: {...prev.companyProfilePageText}}))
        }
      }
      if (name !== 'private_information') {
        tempObj[name].data.attributes[ev.target.name] = ev.target.value;
    }else{
      tempObj[name][ev.target.name] = ev.target.value;
    }
    this.setState({
      data: { ...tempObj }
    });

  }

  handleAccordionClick = (key: string) => {
    if(key === this.state.expanded){
      this.setState({
        expanded: ''
      })
    }else{
      this.setState({
        expanded: key
      });
    }
  }

  clearInfo = (name: string) => {
    let tempData = JSON.parse(JSON.stringify({...this.state.data}));
    switch (name) {
      case 'private_information':
        tempData['private_information'] = JSON.parse(JSON.stringify(initialData.private_information));
        this.setState({docsArray: []})
        break;
      case 'summarry': 
        tempData['summarry'] = JSON.parse(JSON.stringify(initialData.summarry));
        this.setState({ 
          errors: { work_with_vendors: false, already_commitments: false },
        }); 
        break;
      case 'company_overview': 
        tempData['company_overview'] = JSON.parse(JSON.stringify(initialData.company_overview));
        break;
      case 'acquisition_detail': 
        tempData['acquisition_detail'] = JSON.parse(JSON.stringify(initialData.acquisition_detail));
        break;
      case 'financial':
        tempData['financial'] = JSON.parse(JSON.stringify(initialData.financial));
        break;
      case 'customer_metric': 
        tempData['customer_metric'] = JSON.parse(JSON.stringify(initialData.customer_metric));
        break;
    }
    this.setState({ 
      data: {...tempData}
    });  
  }

  handleSaveClick = async(values?: any) => {
    let temp, tempError;
    switch (values) {
      case 'private_information':
        this.validatePrivateInformation()
        break;
      case 'summarry':
        this.validatePrivateSummaryData()
        break;
      case 'company_overview':
        this.validateCompanyOverviewData()
        break;
      case 'acquisition_detail':
        temp = {...this.state.data['acquisition_detail'].data.attributes};
        tempError = {
          reason_for_selling: temp.reason_for_selling ? false : true,
          financing_source: (conditionSort(this.state.otherCheckbox3 && !temp.other, false, true) && (temp.other || temp.loans || temp.shareholders_equity || temp.corporate_funded || temp.self_funded)) ? false : true,
          
        }
        if(this.checkForErrors(tempError)) this.saveAcquisitionDetails({...temp});
        this.setState({
          errors: {...tempError}
        });
        break;
      case 'financial':
        this.validateFinancialData();
        break;
      case 'customer_metric':
        temp = {...this.state.data['customer_metric']};
        tempError = {
          number_of_customer: temp.data.attributes.number_of_customer ? false : true
        }
        if(this.checkForErrors(tempError)) this.saveCustomerMetric({...temp});
        this.setState({
          errors: {...tempError}
        });
        break;
    }
  }
  
  validateRegex = (fieldVal: string) => {
    if (configJSON.nameRegex.test(fieldVal)) {
      return false;
    }
    return true;
  }

  validatePrivateInformation = () => {
    let temp = {...this.state.data['private_information']};

    let tempError = {
      company_name: this.validateRegex(temp.company_name),
      work_with_vendors: this.checkForArrayErrors(temp.work_with_vendors), 
      already_commitments: temp.already_commitments ? false : true,
      p_and_l_statement: temp.p_and_l_statement ? false : true,
      balance_sheet: temp.balance_sheet ? false : true,
    }
    temp.already_commitments = temp.already_commitments === "Yes" ? true : false;

    if(this.checkForErrors(tempError)) {
      this.savePrivateInfo({...temp});
    }
    this.setState({
      errors: {...tempError }
    });
  } 

  handleResAddSubCat = (responseAPIId: string, responseData: {data: [{id: string, attributes: {id: string, name: string}}]}) => {
    if (this.postNewCategoryAPIId === responseAPIId) {

        const temp = [...responseData.data];
        let tempData = {...this.state.data}
        let tempSum = {...tempData['summarry']};
        let found = temp.find((object) => object.attributes.name == this.state.otherCatText);
        tempSum.data.attributes.sub_category_id = found?.id;

        tempData['summarry'] = tempSum;
        this.setState({
          otherCatText: "",
          data: tempData
        }, () => {
          this.validatePrivateSummaryData();
          this.getCategoriesRequest();
        })
      }     
  };
  onNewCategorySave = async () => {
  
    const token = await getStorageData("token");
    const requestBody = {
      "sub_category_name": this.state.otherCatText,
      "category_id": this.state.categoryId
  }
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postNewCategoryAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCategoryEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  validatePrivateSummaryData = () => {
    let temp = {...this.state.data['private_information']};
    let tempSum = {...this.state.data['summarry'].data.attributes};

    let number_of_employees: boolean = false;
    if(!tempSum.number_of_employees || isNaN(tempSum.number_of_employees)) number_of_employees = true;
    let tempError = {
      date_founded: tempSum.date_founded ? false : true,
      number_of_employees,
      sub_category_id: tempSum.sub_category_id ? false : true,
      otherCatText: conditionSort(tempSum.sub_category_id == this.state.otherSubCatId && this.state.otherCatText == '', true, false),
      country: tempSum.country ? false : true,
      city: tempSum.city ? false : true,
      headline: tempSum.headline ? false : true,
      description: tempSum.description && tempSum.description.length < 200 ? false : true,
      asking_price: tempSum.asking_price ? false : true,
      asking_price_reasoning: tempSum.asking_price_reasoning ? false : true,
    }
    temp.already_commitments = temp.already_commitments === "Yes" ? true : false;
    if(this.checkForErrors(tempError)) {
      if (tempSum.sub_category_id == this.state.otherSubCatId && this.state.otherCatText) { 
        this.onNewCategorySave(); 
        return;
      }
      this.saveSummarry({...tempSum});
    }
    this.setState({
      errors: {...tempError }
    });
  } 
  validateFinancialData = () => {
    let temp = { ...this.state.data['financial'].data.attributes };
    let tempError = {
      ltm_gross_revenue: temp.ltm_gross_revenue ? false : true,
      ltm_net_profit: temp.ltm_net_profit ? false : true,
      annual_recurring_revenue: temp.annual_recurring_revenue ? false : true,
      annual_growth_rate: temp.annual_growth_rate ? false : true,
    }
    if (this.checkForErrors(tempError)) this.saveFinancial({ ...temp });
    this.setState({ errors: { ...tempError } });
  }

  getErrorStyle = (value: boolean) => {
    return { border: value ? "1px solid red" : "" }
  }

  validateCompanyOverviewData() {
    let temp = { ...this.state.data['company_overview'].data.attributes };
    temp.key_assets = this.handleIntersect(configJSON.keyValidAssests, temp.key_assets);
    temp.growth_opportunity = this.handleIntersect(configJSON.growthValidAppronities, temp.growth_opportunity);
    temp.business_type = this.handleIntersect(configJSON.businessValidTypes, temp.business_type);

    let tempError = {
      business_type: temp.business_type.length || this.state.businessTypeOther ? false : true,
      revenue_model: temp.revenue_model ? false : true,
      which_regions_business_operated: temp.which_regions_business_operated ? false : true,
      number_of_suppliers: temp.number_of_suppliers ? false : true,
      competitors: this.checkForArrayErrors(temp.competitors),
      growth_opportunity: temp.growth_opportunity.length || this.state.growthOpportunityOther ? false : true,
      key_assets: temp.key_assets.length || this.state.keyAssetsOther ? false : true,
    }
    tempError.business_type = conditionSort(this.state.otherCheckbox && !this.state.businessTypeOther, true, tempError.business_type);
    tempError.growth_opportunity = conditionSort(this.state.otherCheckbox1 && !this.state.growthOpportunityOther, true, tempError.growth_opportunity);
    tempError.key_assets = conditionSort(this.state.otherCheckbox2 && !this.state.keyAssetsOther, true, tempError.key_assets);
    this.setState({
      errors: { ...tempError }
    });
    if (this.checkForErrors(tempError)) this.saveCompanyOverview(temp);
  }

  savePrivateInfo = (data: any) => {
    const formData = new FormData();
    
    data.total_depth = this.formatNumForDatabase(data.total_depth);
data.total_assets = this.formatNumForDatabase(data.total_assets);
data.total_shareholder_equity = this.formatNumForDatabase(data.total_shareholder_equity);
    let tempDocsArray = [...this.state.docsArray];
    tempDocsArray.forEach((fileObj: any, index: number) => {
      if(!fileObj.id) {
        formData.append(`private_business_information[doc_titles_attributes[${index}][:document]]`, fileObj.file);
        formData.append(`private_business_information[doc_titles_attributes[${index}][:title]]`, fileObj.title);
      }
    });
    formData.append("private_business_information[work_with_vendors]", JSON.stringify(data.work_with_vendors));
    formData.append("private_business_information[already_commitments]", data.already_commitments);
    formData.append("private_business_information[company_name]", data.company_name || "");
    formData.append("private_business_information[company_website]", data.company_website || "");
    formData.append("private_business_information[auto_sign]", data.auto_sign|| "");
    formData.append("private_business_information[total_assets]", data.total_assets|| "");
    formData.append("private_business_information[total_depth]", data.total_depth|| "");
    formData.append("private_business_information[total_shareholder_equity]", data.total_shareholder_equity|| "");
    
    if(data.suppliers_and_vendors_contract && ! data.suppliers_and_vendors_contract?.id) formData.append("private_business_information[suppliers_and_vendors_contract]", data.suppliers_and_vendors_contract);
    if(data.p_and_l_statement && !data.p_and_l_statement?.id)
      formData.append("private_business_information[p_and_l_statement]", data.p_and_l_statement);
    if(data.balance_sheet && !data.balance_sheet.length) formData.append("private_business_information[balance_sheet]", data.balance_sheet);

    formData.append("private_business_information[auto_accept_request]", data.auto_accept_request || false);
    
    formData.append("listing_id", data.listing_id);


    const header = {
      // "Content-Type": configJSON.apiFormContentType,
      token: this.state.token,
      locale: this.state.language
    };

    let endPoint = `${configJSON.updateRpivateInformationApiEndpoint}?listing_id=${data.listing_id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePrivateInformationAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handlePopver = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  saveSummarry = (data: any) => {
    let apiReqData = {
      listing_id: data.listing_id,
      summarry: {
        ...data,
        asking_price: this.formatNumForDatabase(data.asking_price)
      }
    }
  const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };

    let endPoint = `${configJSON.updateSummarryApiEndpoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateSummarryAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiReqData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  saveCompanyOverview = (data: any) => {
    
    data.key_assets = [...data.key_assets, this.state.keyAssetsOther];
    data.growth_opportunity = [...data.growth_opportunity, this.state.growthOpportunityOther];
    data.business_type = [...data.business_type, this.state.businessTypeOther];
    
    const formData = new FormData();
    formData.append("business_type", JSON.stringify([...new Set(data.business_type.filter(Boolean))]));
    formData.append("list_of_product_services", JSON.stringify([...new Set(data.list_of_product_services.filter(Boolean))]));
    formData.append("revenue_model", data.revenue_model);
    formData.append("which_regions_business_operated", data.which_regions_business_operated);
    formData.append("number_of_suppliers", data.number_of_suppliers);
    formData.append("competitors", JSON.stringify([...new Set(data.competitors.filter(Boolean))]));
    formData.append("growth_opportunity", JSON.stringify([...new Set(data.growth_opportunity.filter(Boolean))]));
    formData.append("key_assets", JSON.stringify([...new Set(data.key_assets.filter(Boolean))]));
    formData.append("listing_id", data.listing_id);

    const header = {
      token: this.state.token,
    };

    let endPoint = `${configJSON.updateCompanyOverViewApiEndpoint}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyOverViewAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  saveAcquisitionDetails = (data: any) => {
    let apiReqData = {
      listing_id: data.listing_id,
      business_acquisition_detail: {...data}
    }
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };

    let endPoint = `${configJSON.updateAcquisitionDetailApiEndpoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAcquisitionDetailApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiReqData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  saveFinancial = (data: any) => {
    
    let sendData = {
      ltm_gross_revenue: this.formatNumForDatabase(data.ltm_gross_revenue),
      ltm_net_profit: this.formatNumForDatabase(data.ltm_net_profit),
      annual_recurring_revenue: this.formatNumForDatabase(data.annual_recurring_revenue),
      annual_growth_rate: this.formatNumForDatabase(data.annual_growth_rate),
      revenue_multiple: this.formatNumForDatabase(data.revenue_multiple),
      price_earnings_ratio: this.formatNumForDatabase(data.price_earnings_ratio),
    }

    let apiReqData = {
      listing_id: data.listing_id,
      business_financial: {
        ...sendData
      }
    }
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };

    let endPoint = `${configJSON.updateFinancialApiEndpoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFinancialAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiReqData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  saveCustomerMetric = (data: any) => {
    
    let apiReqData = {
      listing_id: data.data.attributes.listing_id,
      business_customer_vendor_metric: {
        number_of_customer: this.formatNumForDatabase(data.data.attributes.number_of_customer),
      }
    }
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };

    let endPoint = `${configJSON.updateCustomerMetricApiEndpoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCustomerMetricAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiReqData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  checkForErrors = (object: any) => {
    let result = true;
    for (const property in object) {
      if (object[property]) result = false;
    }
    return result;
  }

  checkForArrayErrors = (list: string []) => {
    let result = false;
    list.forEach((value: string) => {
      if(!value || !value.length) result = true;
    })
    return result;
  }

  handleFileChange = (fieldName: string, name: string, e: any) => {
    let tempData = { ...this.state.data };
    const file = e.target.files && e.target.files[0];
      if (file) {
        tempData[fieldName][name] = file
        this.setState({ data: {...tempData} });
      }
  }

  navigateToSignUp = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
  }

  receiveTokenValue = (token: string) => {
    this.setState({ token: token }, () => {
      if (token) {
        this.getCategoriesRequest();
        this.getUserProfileData();

      } else {
        this.navigateToSignUp();
      }
    });
  };

  getSmeListRequest = () => {
    this.setState({ anchorEl: null });
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };

    let endPoint = `${configJSON.usersListApiEndpoint}?locale=${this.state.language}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSmeListAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  receiveSmeListAPI = (responseJson: any) => {
     if (responseJson && responseJson.data) {
      responseJson.data.forEach((valueD: any, iInidex: number) => {
        responseJson.data[iInidex].attributes.category_name = conditionSort(['نحن', 'SME'].includes(valueD.attributes.category_name), 'SME', valueD.category_name)
      })
       this.setState({ usersList: responseJson.data, anchorEl: null});

       let profileId: string = this.props.navigation.getParam("id");
       if(profileId) {
        this.getSelectedSmeRequest(profileId)
       }
    }
  }

  getPrivateInfo = (id: any,lang:string) => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivateInfoAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPrivateInformationApiEndpoint}?listing_id=${id}&locale=${lang}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategoriesRequest = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // get categories response
  receiveCategoriesAPI = (responseJsonData: any) => {
    if (responseJsonData && responseJsonData.errors && responseJsonData.errors[0].token) {
      this.raiseToast(responseJsonData.errors[0].token,"error");
      this.navigateToSignUp();
  }
    if (responseJsonData && responseJsonData.data) {
      const categoriesListing = responseJsonData.data.map((response: any) => {
        const categories = {
          id: response.attributes.id.toString(),
          name: response.attributes.name,
          subCategories: response.attributes.sub_categories
        };
        return categories;
      });
      this.setState({ categoriesList: categoriesListing });
      let tempBusinessCat = categoriesListing.find((category: any) => category.name === 'SME');
      this.setState({categoryId: tempBusinessCat?.id},() => this.getSmeListRequest());
      this.getSubCategoryList(tempBusinessCat?.id);

    }
  };

  receivePrivateInfo = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      let temp = {...this.state.data};
      
      responseJson.data.attributes.total_depth = formatNumber(responseJson.data.attributes.total_depth);
      responseJson.data.attributes.total_assets = formatNumber(responseJson.data.attributes.total_assets);
      responseJson.data.attributes.total_shareholder_equity = formatNumber(responseJson.data.attributes.total_shareholder_equity);

      responseJson.data.attributes.work_with_vendors =
      responseJson.data?.attributes?.work_with_vendors?.length ? responseJson.data?.attributes?.work_with_vendors : [""];
      responseJson.data.attributes.already_commitments = responseJson.data.attributes.already_commitments ? "Yes" : "No"
      temp['private_information'] = responseJson.data.attributes;

      this.setState({ data: temp, 
        initialData: JSON.parse(JSON.stringify(temp)), 
        docsArray: responseJson.data?.attributes?.documents || []});
    }
  }

  createNewSme = () => {
        const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };

    let endPoint = `${configJSON.createSmeApiEndpoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addSmeAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    let categoryid = this.state.categoryId;
    if(this.state.openStartUpBox) {
      let temp: any = this.state.categoriesList.find((category: any) => category.name === 'Start Up');
      categoryid = temp.id;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({category_id: categoryid})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveSmeAPI = (responseJson: any) => {
    if (responseJson.message) {
      this.getSmeListRequest();
      this.setState({ isListingModalSuccess: true });
    } else {
      this.getSmeListRequest();
    }
  };

  redirectTo = (id: string, categoryId: string) => {
    this.props.navigation.history.push(categoryId != this.state.categoryId ? `/StartupDetails/${id}` : `/CompanyProfileEdit/${id}`);
  }

  getSelectedSmeRequest = (id: string) => {
    this.setState({data: {}, initialData: {}});
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSingleSmeAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSingleSmeEndPoint}?listing_id=${id}&locale=${this.state.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckboxOther = (label: string) => {
    let tempErr = {...this.state.errors};
    tempErr[label] = false;
    this.setState({errors: tempErr})
    switch(label) {
      case 'finance':
        let tempObj = { ...this.state.data };
        let temp = { ...tempObj['acquisition_detail'].data.attributes };
        temp.other = "";
        tempObj['acquisition_detail'].data.attributes = temp;
        this.setState({
          data: { ...tempObj}, otherCheckbox3: !this.state.otherCheckbox3,
          errors: {...this.state.errors, financing_source: false}
        });
        break;
      case 'key_assets':
        this.setState({otherCheckbox2: !this.state.otherCheckbox2, keyAssetsOther: ""});
        break;
      case 'growth_oppurtunity':
        this.setState({otherCheckbox1: !this.state.otherCheckbox1, growthOpportunityOther: ""});
        break;
      case 'business_type':
        this.setState({otherCheckbox: !this.state.otherCheckbox, businessTypeOther: ""});
    }

  }

  handleIntersect = (firstArr: string[], secondArr: string[]) => {
    return firstArr.filter(value => secondArr.includes(value));
  }

  receiveSelectedSmeAPI = async(responseJson: any) => {
    if (responseJson.error) {
      this.raiseToast(responseJson.error,"error");
      return;
    }
    if (responseJson && responseJson.data) {
      let temp = { ...responseJson.data };
      if (temp.attributes.category_id != this.state.categoryId) {
        this.props.navigation.history.push(`/StartupDetails/${temp.id}`);
        return;
      }
     
      temp.attributes.company_overview.data.attributes.competitors =
        temp?.attributes?.company_overview.data.attributes.competitors?.length ? temp?.attributes?.company_overview?.data.attributes.competitors : [""];
      temp.attributes.company_overview.list_of_product_services =
        temp?.attributes?.company_overview?.data.attributes?.list_of_product_services?.length ? temp?.attributes?.company_overview?.data.attributes.list_of_product_services : [""];
      
        temp.attributes.financial.data.attributes.ltm_gross_revenue = formatNumber(temp.attributes.financial.data.attributes.ltm_gross_revenue);
        temp.attributes.financial.data.attributes.ltm_net_profit = formatNumber(temp.attributes.financial.data.attributes.ltm_net_profit);
        temp.attributes.financial.data.attributes.annual_recurring_revenue = formatNumber(temp.attributes.financial.data.attributes.annual_recurring_revenue);
        temp.attributes.financial.data.attributes.annual_growth_rate = formatNumber(temp.attributes.financial.data.attributes.annual_growth_rate);
        temp.attributes.financial.data.attributes.revenue_multiple = formatNumber(temp.attributes.financial.data.attributes.revenue_multiple);
        temp.attributes.financial.data.attributes.price_earnings_ratio = formatNumber(temp.attributes.financial.data.attributes.price_earnings_ratio);
        temp.attributes.summarry.data.attributes.asking_price = formatNumber(temp.attributes.summarry.data.attributes.asking_price);
        
     
      let keyAssetOther = this.findKeyAssets(temp);
      let businessTypeOther = this.findBusiness(temp);
      let growthOpportunityOther = this.findGrowth(temp);
      let dateFounded = temp?.attributes.summarry.data.attributes.date_founded
      temp.attributes.summarry.data.attributes.date_founded = dateFounded ?? ""
     
      this.setState({
        initialData: JSON.parse(JSON.stringify(temp?.attributes)),
        data: temp?.attributes,
        keyAssetsOther: keyAssetOther,
        businessTypeOther: businessTypeOther,
        growthOpportunityOther: growthOpportunityOther,
        otherCheckbox: !!businessTypeOther,
        otherCheckbox1: !!growthOpportunityOther,
        otherCheckbox2: !!keyAssetOther,
      });
     
        this.getPrivateInfo(temp.id,this.state.language);
      
    }
  };

  handleNo = (ev: any) => {
    if (ev.target.value === "No") {
      let temp = { ...this.state.data };
      temp.private_information.suppliers_and_vendors_contract = null;
      this.setState({
        data: { ...temp }
      })
    }
  }
  findKeyAssets = (temp: any) => {
    let returnValue = "";
    let keyAssest = this.state.language === "en" ? configJSON.keyAssests : configJSON.keyAssestsAr;
    temp.attributes.company_overview.data.attributes.key_assets.forEach((value: string) => {
      if (!keyAssest.find((findValue: string) => value === findValue))
      {returnValue = value;}
    });
    return returnValue;
  }
  findBusiness = (temp: any) => {
    let returnValue = "";
    let businesstype = this.state.language === "en" ? configJSON.businessTypes : configJSON.businessTypesAr
    temp.attributes.company_overview.data.attributes.business_type.forEach((value: string) => {
      if (!businesstype.find((findValue: string) => value === findValue)){
        {returnValue = value;}
      }
    });
    return returnValue;
  }
  findGrowth = (temp: any) => {
    let returnValue = "";
    let growthApportunity = this.state.language === "en" ? configJSON.growthAppronities : configJSON.growthAppronitiesAr;
    temp.attributes.company_overview.data.attributes.growth_opportunity.forEach((value: string) => {
      if (!growthApportunity.find((findValue: string) => value === findValue))
      {returnValue = value;}
    });
    return returnValue;
  }


  receiveSubCategoryAPI = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      let temp: any = [];
      responseJson.data.forEach((valueObj: any) => {
        temp.push({title: this.state.language==='ar'? valueObj.attributes.arabic_sub_category: valueObj.attributes.name, arabic_title: valueObj.attributes.arabic_sub_category, key: valueObj.attributes.id});
        if(['other', 'أخرى'].includes(valueObj.attributes.name)) {
          this.setState({otherSubCatId: valueObj.id});
        }
      });
      
      this.setState({subCategoryList: temp});
    }
  }

  checkProgress = () => {
    let progress = 0;

  let temp = JSON.parse(JSON.stringify({...this.state.data['private_information']}));
  progress += conditionSort(temp.company_name, 2.95, 0);
  progress += conditionSort(temp.company_website, 2.95, 0);
  progress += conditionSort(this.checkForArrayErrors(temp.work_with_vendors), 0, 2.95);
  progress += conditionSort(temp.p_and_l_statement, 2.95, 0);
  progress += conditionSort(temp.balance_sheet, 2.95, 0);
  progress += conditionSort(this.state.docsArray?.length, 2.95, 0);
  progress += conditionSort(temp.total_depth, 2.95, 0);
  progress += conditionSort(temp.total_assets, 2.95, 0);
  progress += conditionSort(temp.total_shareholder_equity, 2.95, 0);


  let tempSum = JSON.parse(JSON.stringify({...this.state.data['summarry'].data.attributes}));
  progress += conditionSort(tempSum.date_founded, 2.95, 0);
  progress += conditionSort(tempSum.number_of_employees, 2.95, 0);
  progress += conditionSort(tempSum.sub_category_id, 2.95, 0);
  progress += conditionSort(tempSum.country, 2.95, 0);
  progress += conditionSort(tempSum.city, 2.95, 0);
  progress += conditionSort(tempSum.headline, 2.95, 0);
  progress += conditionSort(tempSum.description, 2.95, 0);
  progress += conditionSort(tempSum.asking_price, 2.95, 0);
  progress += conditionSort(tempSum.asking_price_reasoning, 2.95, 0);

  let tempCO = JSON.parse(JSON.stringify({ ...this.state.data['company_overview'].data.attributes }));
  tempCO.key_assets = this.handleIntersect(configJSON.keyValidAssests, tempCO.key_assets);
  tempCO.growth_opportunity = this.handleIntersect(configJSON.growthValidAppronities, tempCO.growth_opportunity);
  tempCO.business_type = this.handleIntersect(configJSON.businessValidTypes, tempCO.business_type);

  progress += conditionSort(tempCO.business_type.length || this.state.businessTypeOther, 2.95, 0);
  progress += conditionSort(tempCO.revenue_model, 2.95, 0);
  progress += conditionSort(tempCO.which_regions_business_operated, 2.95, 0);
  progress += conditionSort(tempCO.number_of_suppliers, 2.95, 0);
  progress += conditionSort(this.checkForArrayErrors(tempCO.competitors), 0, 2.95),
    progress += conditionSort(tempCO.growth_opportunity.length || this.state.growthOpportunityOther, 2.95, 0);
  progress += conditionSort(tempCO.key_assets.length || this.state.keyAssetsOther, 2.95, 0);

  let tempAq = JSON.parse(JSON.stringify({ ...this.state.data['acquisition_detail'].data.attributes }));
  progress += conditionSort(tempAq.reason_for_selling, 2.95, 0);
  progress += conditionSort((tempAq.other || tempAq.loans || tempAq.shareholders_equity || tempAq.corporate_funded || tempAq.self_funded), 2.95, 0);


  let tempF = JSON.parse(JSON.stringify({ ...this.state.data['financial'].data.attributes }));
  progress += conditionSort(tempF.ltm_gross_revenue, 2.95, 0);
  progress += conditionSort(tempF.ltm_net_profit, 2.95, 0);
  progress += conditionSort(tempF.annual_recurring_revenue, 2.95, 0);
  progress += conditionSort(tempF.annual_growth_rate, 2.95, 0);
  progress += conditionSort(tempF.revenue_multiple, 2.95, 0);
    progress += conditionSort(tempF.price_earnings_ratio, 2.95, 0);

  let tempCM = JSON.parse(JSON.stringify({ ...this.state.data['customer_metric'] }));
  progress += conditionSort(tempCM.data.attributes.number_of_customer, 2.95, 0);;

  progress = conditionSort(progress >= 98, 100, Math.round(progress));
    if(progress !== this.state.data?.progress){ 
      this.updatePercentage(progress);}
      let tempObj = {...this.state.data};
      tempObj.progress = progress;
      this.setState({data: tempObj})
  }

  updatePercentage = (progress: number) => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
      locale: this.state.language
    };

    let endPoint = `${configJSON.updateListPercentage}?listing_id=${this.state.data?.id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePercentAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({percent: progress})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if(progress === 100) {
      this.setState({openBoxModal: true})
    }

  }

  handleDocumentAdd = () => {
    if(this.state.modalFile && this.state.modalFileTitle) {
      let temp = [...this.state.docsArray, {title: this.state.modalFileTitle, file: this.state.modalFile}];
      this.setState({
        docsArray: temp,
        openFileModal: false,
        modalFileTitle: "",
        modalFile: ""
      })
       
       
    }
  }
 
  getLang = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      this.setState({ language: lang })
    }
    else{
      await setStorageData("language", 'en');
      this.setState({ language: 'en' })
    }
    return   this.setState({ language: lang },  () => this.applyTranslation())
  }

  applyTranslation = async () => {
    const countries : {icon:string, value:string, title:string}[] = this.state.language === "en" ? configJSON.selectedMenuList : configJSON.selectedMenuListAr;
    const valuesOfValues :{ [index: number]: string } = {};
    countries.forEach((item, index) => {
      valuesOfValues[index] = item.value
    });
    
    const valuesOfkeys :{ [index: number]: string } = {};
    countries.forEach((item, index) => { valuesOfkeys[index] = item.title;});
    const translatedValuesofKeys = await TranslateText(valuesOfkeys, this.state.language, this.state.language);
    const transaltedValuesofValues = await TranslateText(valuesOfValues, this.state.language, this.state.language);
    countries.forEach((item,index) => {
      item.title = translatedValuesofKeys[index];
      item.value = transaltedValuesofValues[index]})
      this.state.language === "en" ? this.setState({countriesData : countries}) :  this.setState({countriesDataAr : countries});
    const businessType : string[] = this.state.language === "en" ? configJSON.businessTypes : configJSON.businessTypesAr;
    const valuesArray = await this.translateList(businessType, this.state.language);
    this.setState({businessTypeCheckBoxes: valuesArray});

    this.state.language === "en" ? this.setState({growthApportunities : configJSON.growthAppronities}) : this.setState({growthApportunitiesAr : configJSON.growthAppronitiesAr});
     this.state.language === "en" ? this.setState({keyAssests: configJSON.keyAssests}) : this.setState({keyAssestsAr: configJSON.keyAssestsAr});

      const cityData = this.state.language === "en" ? configJSON.countriesData : configJSON.countriesDataAr;
      const tranaltedCity :{ country: string; cities: string[]; }[] = [];
   for(const data of cityData ){
    const obj = {country : data.country}
    const translatedCountry = await TranslateText(obj, this.state.language, this.state.language);
    const tranlastedList  = await this.translateList(data.cities, this.state.language);
    tranaltedCity.push({
      country : translatedCountry.country,
      cities : tranlastedList
    })
   }
   this.state.language === "en" ? this.setState({cityItems : tranaltedCity}) : this.setState({cityItemsAr : tranaltedCity, companyProfilePageText: {...this.state.companyProfilePageText, errorMessage: configJSON.errorMessageAr, dataSavedText: `تم حفظ البيانات بنجاح.`, errorMessageUploadDoc: configJSON.errorMessageUploadDocAr}})

    
    
    

  }

  translateList = async (items: string[], language: string) => {
    const valuesOfKeysComman: { [index: number]: string } = {};
    items.forEach((item, index) => {
      valuesOfKeysComman[index] = item;
    });
    const translatedValuesofKeys = await TranslateText(valuesOfKeysComman, language, this.state.language);
    const valuesArray : string[] = Object.values(translatedValuesofKeys);
    return valuesArray
  };

  returnStyles = () =>{
    if(this.state.language === "ar"){
      return "20px"
    }else{
      return "-15px"
    }
  }

  checkIfCondtionForToast = (valueForTrue: ToastPosition, valueForFalse: ToastPosition) =>{
    if(this.state.language === "ar"){
      return valueForTrue
    }else{
      return valueForFalse
    }
  }

  checkIfConditionForDirection = () =>{
    if(this.state.language ==="ar"){
      return "rtl"
    }else{
     return "ltr"
    }
  }

  raiseToast = (content:string, type: "error" | "success") =>{
    toast[type](content, {
      position : this.state.language ==="ar" ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      style : {
        direction :  this.state.language ==="ar" ? "rtl" : "ltr"
      }
    })
    this.setState({
      expanded:""
    })
  }

  // Customizable Area End
}
