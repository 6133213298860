import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReactElement } from "react";
import { formatNumber } from "../../../components/src/Helper";

interface UserProfile {
    id: string;
    type: string;
    attributes: {
        email: string;
        first_name: string;
        full_phone_number: string;
        last_name: string;
        type: string;
        gender: string;
        user_type: string;
        role:{
            id:number;
            user_roles:string;
        }
    };
}
interface CompanyProfileData {
    id: string;
    type: string;
    attributes: {
        listing_id: number;
        status: string;
        listing: {
            name: string;
            category_name: string;
            ltm_gross_revenue: string;
            ltm_net_profit: string;
            asking_price: string;
        }
    };
}

interface PageText {
    signature: string;
    signingAs: string;
    fName: string;
    lName: string;
    role: string;
    terminate: string;
    goBack: string;
    save: string;
    note: string;
    revisedPrice: string;
    companyName: string;
    agree: string;
    aboutToDisagree: string;
    ndaSuccessText: string;
    spaCreated: string;
    loiSuccessMsg: string;
    ndaUpdated: string;
    failMsg: string;
    disAgree: string;
    likeToProceed: string;
    back: string;
    intialPrice: string;
    clear: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: Record<string, string>;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userData: UserProfile;
    loiFile : string;
    description:string;
    isLoading:boolean;
    userDate:string;
    sellerName:string;
    sellerAddress:string;
    buyerName:string;
    buyerAddress:string;
    amountSpecificPercentage:string;
    companyName:string;
    jurisdiction:string;
    sellerAmount:string;
    language:string;
    typeOfUser: string;
    terminateAccess: boolean;
    companyData:CompanyProfileData[];
    askingPriceData:string;
    handlingProposalID: string;
    isAcknowledged: boolean;
    openDisclaimer: boolean;
    pageText: PageText;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CustomFormTermController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUserProfileAPICallId: string = ""
    diagreeAPICallId: string = ""
    createLetterOfIntentDraftId:string =""
    salePuchaseDraftId:string =""
    getContentId:string = ""
    createNdaApiCallerId:string = ""
    ndaApiCallerIdForSellerStartup = ""
    getDealRequestAPIId:string="";
    getListCompanyId:string="";
    sendRevisedPriceId: string = "";
    getRevisedPriceCallID: string = "";
    acceptRejectReqApiCallId:string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userData: {
                id: "",
                type: "",
                attributes: {
                    email: "",
                    first_name: "",
                    full_phone_number: "",
                    last_name: "",
                    type: "",
                    gender: "",
                    user_type: "",
                    role:{
                        id:1,
                        user_roles:""
                    }
                }
            },
            loiFile :"",
            description:"",
            isLoading:false,
            userDate:"",
            sellerName:"",
            sellerAddress:"",
            buyerName:"",
            buyerAddress:"",
            amountSpecificPercentage:"",
            companyName:"",
            jurisdiction:"",
            sellerAmount:"",
            language:"en",
            typeOfUser: "",
            terminateAccess: false,
            companyData:[{
                id: "",
                type: "",
                attributes: {
                    listing_id: 1,
                    status: "",
                    listing: {
                        name: "",
                        category_name: "",
                        ltm_gross_revenue: "",
                        ltm_net_profit: "",
                        asking_price: "",
        }
    }
}],
            askingPriceData: "",
            openDisclaimer: false,
            isAcknowledged: true,
            pageText: {
                signature: "",
                signingAs: "",
                fName: "",
                revisedPrice: "",
                likeToProceed: "",
                intialPrice: "",
                aboutToDisagree: "",
                note: "",
                back: "",
                terminate: "",
                goBack: "",
                agree: "",
                disAgree: "",
                lName: "",
                role: "",
                ndaSuccessText: "",
                loiSuccessMsg: "",
                spaCreated: "",
                ndaUpdated: "",
                failMsg: "",
                save: "",
                companyName: "",
                clear: ""
            },
            handlingProposalID: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResForUserProfile(from, message);
        this.handleResForCompanyProfile(from, message);
        this.handleResForDisagree(from, message);
        this.getLoidraftId(from,message);
        this.getSaleId(from,message);
        this.getContentFromApi(from,message);
        this.updateNdaDocument(from,message);
        this.apiResponseForSeller(from,message);
        this.handleResForGetCompanyList(from,message);
        this.handleResForAcceptRejectReq(from,message)
        if (this.sendRevisedPriceId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                toast.success(this.state.pageText.loiSuccessMsg,{position: this.toastPosition()});
                this.navigateToAcquisitionPage();
            } else {
                toast.error(this.state.pageText.failMsg, { position: this.toastPosition() });
            }
        };

        if (this.getRevisedPriceCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({sellerAmount:formatNumber(responseJson.data.attributes.revised_ask_price),handlingProposalID:responseJson.data.id},() => {
                    this.handleRevisedPrice(this.state.sellerAmount);
                });
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    handleGoBack = () => {
        this.props.navigation.goBack()
    }
    

    generatePdf = async (htmlTemplate: string, id:string) => {
        this.setState({isLoading: true});
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = htmlTemplate;
        document.body.appendChild(tempContainer);
        const canvas = await html2canvas(tempContainer, { scrollX: 0, scrollY: 0, scale: 1 });
        const imgData = canvas.toDataURL('image/png');

        const pdfWidth = canvas.width * 0.264583; 
        const pdfHeight = canvas.height * 0.264583;

        const pdf = new jsPDF({
            orientation: pdfWidth > pdfHeight ? 'landscape' : 'portrait',
            unit: 'mm',
            format: [pdfWidth, pdfHeight],
        });
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight,undefined,'FAST');
        const pdfBlob = pdf.output('blob');
        this.updatePDf(pdfBlob,id);
        document.body.removeChild(tempContainer);
    }

    updatePDf = (pdfBlob:Blob, id:string) =>{
        if(id === "LetterOfIntent"){
            this.updateFile(pdfBlob);
        }
        if(id === "SalePurchaseAgreement"){
            this.createDraftSalePurchaseAgreement(pdfBlob);
        }
        if(id === "AutoSign"){
            this.autoSignInNdaAgreement(pdfBlob);
        }
    }
    agreeTerms = async() => {
        if(this.props.id === "SalePurchaseAgreement"){
           const objectOfData = {
            userDate: this.state.userDate,
            sellerName: this.state.sellerName,
            sellerAddress: this.state.sellerAddress,
            buyerName : this.state.buyerName,
            buyerAddress: this.state.buyerAddress,
            companyName: this.state.companyName,
            amountSpecificPercentage: this.state.amountSpecificPercentage,
            jurisdiction: this.state.jurisdiction,
            sellerAmount : this.handleEndSign(this.state.sellerAmount)
           }
           if (
            objectOfData.userDate &&
            objectOfData.sellerName &&
            objectOfData.sellerAddress &&
            objectOfData.buyerName &&
            objectOfData.buyerAddress &&
            objectOfData.amountSpecificPercentage &&
            objectOfData.companyName &&
            objectOfData.jurisdiction && 
            objectOfData.sellerAmount
            
        ) {
            await setStorageData("spaData", JSON.stringify(objectOfData))
            this.sendRevisedPrice();
        } else {
            const alertMsg = this.state.language === "ar" ? "الرجاء ملء جميع التفاصيل" : "Please fill all the details";
            toast.error(alertMsg, {position: this.toastPosition()});
            return
        }
           
        }
        const navigate: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        navigate.addData(
            getName(MessageEnum.NavigationTargetMessage),
            `${this.props.id}`
        );
        navigate.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        runEngine.sendMessage(navigate.id, navigate);
       
    }

    disagreeTerms = async () => {
        this.setState({terminateAccess: true});
    }

    onTerminateClick = async () => {
        const accessRequestId = await getStorageData("accessId");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.diagreeAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.retracatEndpoint}${accessRequestId}?locale=${this.state.language}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "access_request": {
                    "status": "rejected"
                }
            })
          );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateRequestApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
       
     }

    getUserProfile = async () => {
        const header = {
            token: await getStorageData('token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUserProfileAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userProfileEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getDealsRequest = async() => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData('token'),
        };
    
        let endPoint = `${configJSON.getDealsStatusEndPoint}?deals_no_nda=${true}`;
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDealRequestAPIId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

    handleResForUserProfile = async (from: string, message: Message) => {
        if (this.getUserProfileAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({ userData: responseJson.data })
            } else {
                toast.error(this.state.pageText.failMsg, { position: this.toastPosition() });
            }
        }
    }
    handleResForCompanyProfile = async (from: string, message: Message) => {
        if(this.getDealRequestAPIId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            const accessId= await getStorageData('accessId');
            if (responseJson.data) {
                
                const filteredData = responseJson.data.filter((item:any)=> item.id === accessId);
                this.setState({ companyData: filteredData })
                this.setState({ askingPriceData: filteredData[0]?.attributes.listing.asking_price })
                this.setState({ companyName: filteredData[0]?.attributes.name})
                this.setState({amountSpecificPercentage:formatNumber(filteredData[0]?.attributes.listing.asking_price)})
            } 
        }
    }
    getListings = async () => {
       const listId =await getStorageData("listingIdForNDA");

        const token = await getStorageData("token");
        const header = {
          "Content-Type": configJSON.productApiContentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getListCompanyId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_catalogue/listings/get_list?listing_id=${listId}&locale=en`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      handleResForGetCompanyList = async (from: string, message: Message) => {
        if(this.getListCompanyId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            if (responseJson.data) {
                this.setState({ companyData: responseJson.data })
                this.setState({ companyName: responseJson.data.attributes.name})
              
            } 
        }
    }
    handleResForDisagree = async (from: string, message: Message) => {
        const listId =await getStorageData("listingIdForNDA");
        if (this.diagreeAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.message) {
                if(this.state.typeOfUser == 'seller') {
                    window.history.go(-1);
return;
                }
                this.props.navigation.history.push(
                    `/CompanyProfile/${listId}`
                  );
            } else {
                toast.error(this.state.pageText.failMsg, { position: this.toastPosition() });
            }
        }
    }

    getContentFromApi = async (from: string, message: Message) => {
        if (this.getContentId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                const description = this.getIfCondition(
                    responseJson.data[0].attributes.description,
                    responseJson.data[0].attributes.description_ar
                );
                this.setState({ description: description.toString() });
            }
        }
    }

    getLoidraftId = async (from:string,message:Message) => {
        if (this.createLetterOfIntentDraftId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({ handlingProposalID: responseJson.data.id }, () => {
                    this.sendRevisedPrice()
                });
                await setStorageData("letterofIntentId", responseJson.data.id);
                
            } else {
                toast.error(responseJson.error, {position: this.toastPosition()});
            }
        }
    }


    navigateToAcquisitionPage = () =>{
        const msgessageFrom = new Message(getName(MessageEnum.NavigationMessage));
        msgessageFrom.addData(getName(MessageEnum.NavigationTargetMessage), "AcquisitionProcess");
        msgessageFrom.addData(
                    getName(MessageEnum.NavigationPropsMessage),
                    this.props
                );
                const raiseMessage: Message = new Message(
                    getName(MessageEnum.NavigationPayLoadMessage)
                  );
                  raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
                  msgessageFrom.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
                this.send(msgessageFrom)
    }

    getSaleId = async (from:string,message:Message) => {
        if (this.salePuchaseDraftId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                toast.success(this.state.pageText.spaCreated, { position: this.toastPosition() });
                this.navigateToAcquisitionPage();
                await setStorageData("saleId", responseJson.data.id);
                
            } else {
                toast.error(this.state.pageText.failMsg, { position: this.toastPosition() });
            }
        }
    }

    updateNdaDocument = async (from:string,message:Message) => {
        if (this.createNdaApiCallerId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.message) {
                toast.success(this.state.pageText.ndaSuccessText, { position: this.toastPosition() });
                const msgessageFrom = new Message(getName(MessageEnum.NavigationMessage));
                msgessageFrom.addData(getName(MessageEnum.NavigationTargetMessage), "Deals");
                msgessageFrom.addData(
                    getName(MessageEnum.NavigationPropsMessage),
                    this.props
                );
                this.send(msgessageFrom)
                removeStorageData("accessId");
                removeStorageData("spaData");
            } else {
                toast.error(this.state.pageText.failMsg, { position: this.toastPosition() });
            }
        }
    }



    apiResponseForSeller = async (from:string,message:Message) => {
        if (this.ndaApiCallerIdForSellerStartup === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                toast.success(this.state.pageText.ndaUpdated, { position: this.toastPosition() });
      const accessId = await getStorageData("accessId",true);
                if (accessId) { this.acceptRejectReq(accessId, "accept") } else {
                    window.history.go(-2);
                }
            } else {
                toast.error(this.state.pageText.failMsg, { position: this.toastPosition() });
            }
        }
    }

    acceptRejectReq = async (id: number, action: string) => {
        const header = {
          token: await getStorageData('token'),
          "Content-Type": configJSON.requestApiContentType
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.acceptRejectReqApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.requestActionApiEndpoint}/${id}/${action}?locale=${this.state.language}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.updateRequestApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      handleResForAcceptRejectReq = async (from: string, message: Message) => {
        if (this.acceptRejectReqApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson.message) {
            toast.success(responseJson.message,{position:this.state.language==="ar"?"top-left":"top-right"});
            window.history.go(-2);
          }
        }
      }

    updateFile = async(file:Blob) =>{
        const token = await getStorageData("token");
        const DealId = await getStorageData("dealId");
        const privateInfoHeader = {
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const letterOfIntent = new FormData();
        letterOfIntent.append("handling_proposal[letter_of_intent]", file);
        this.createLetterOfIntentDraftId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_request_management/deals/${DealId}/handling_proposals`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(privateInfoHeader)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.createSendRequestApiMethod
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          letterOfIntent
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      replaceAll = (inpStr: string, search: string, replacement: string): string  => {
        return inpStr.split(search).join(replacement);
      }
    sendRevisedPrice = async () => {
        const token = await getStorageData("token");
        const DealId = await getStorageData("dealId");
        const apiBody = {
            "id": this.state.handlingProposalID,
            "handling_proposal": {
                "revised_ask_price": this.replaceAll(this.state.sellerAmount, ',', '')
            }
        }
        const privateInfoHeader = {
            "token": token,
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendRevisedPriceId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.revisedPriceEndPoint.replace("{DealId}", DealId)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(privateInfoHeader)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodPatch
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(apiBody)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    createDraftSalePurchaseAgreement = async(file :Blob) =>{
        const token = await getStorageData("token");
        const DealId = await getStorageData("dealId");
        const dueDeligenceId = await getStorageData("dueDiligenceId")
        const privateInfoHeader = {
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const salesPurchase = new FormData();
        salesPurchase.append("due_diligence[sell_purchase_agreement]", file);
        this.salePuchaseDraftId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_request_management/deals/${DealId}/due_diligences/${dueDeligenceId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(privateInfoHeader)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.updateRequestTextApiMethod
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          salesPurchase
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      autoSignInNdaAgreement = async(file :Blob) =>{
        const token = await getStorageData("token");
        const typeOfuser = await getStorageData("typeOfUser");
        const accessRequestId = await getStorageData("accessId");
        const listingId = await getStorageData("listingId");
        const getTypeOfList = await getStorageData("typeOfList");
        const privateInfoHeader = {
            token: token,
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
        if(typeOfuser === "seller"){
            if(getTypeOfList==="SME"){
            const salesPurchase = new FormData();
            salesPurchase.append("private_business_information[auto_sign_doc]", file);
            salesPurchase.append("private_business_information[auto_sign]","true")
            this.ndaApiCallerIdForSellerStartup = requestMessage.messageId;
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `bx_block_profile/business_profiles/update_private_business_information?listing_id=${listingId}?locale=${this.state.language}`
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(privateInfoHeader)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.updateRequestTextApiMethod
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              salesPurchase
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            }else{
                const salesPurchase = new FormData();
            salesPurchase.append("private_information[auto_sign_doc]", file);
            salesPurchase.append("private_information[auto_sign_nda]","true")
            salesPurchase.append("listing_id",listingId)
            this.ndaApiCallerIdForSellerStartup = requestMessage.messageId;
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `bx_block_profile/start_ups/update_private_informations?locale=${this.state.language}`
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(privateInfoHeader)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.updateRequestTextApiMethod
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              salesPurchase
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            }
        }else{
            const salesPurchase = new FormData();
            salesPurchase.append("access_request[buyer_auto_sign_nda]", file);
            salesPurchase.append("access_request[auto_sign]","true")
            this.createNdaApiCallerId = requestMessage.messageId;
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `account_block/access_requests/${accessRequestId}`
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(privateInfoHeader)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.updateRequestTextApiMethod
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              salesPurchase
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
        
       
      }
      
      getLang = async() =>{
            const lang = await getStorageData("language");
            const data = await getStorageData("spaData");
            const parsedData = JSON.parse(data);
            if(parsedData){
                this.setState({userDate: parsedData.userDate,sellerName: parsedData.sellerName,sellerAddress: parsedData.sellerAddress,buyerName:parsedData.buyerName,
                                buyerAddress:parsedData.buyerAddress,sellerAmount:parsedData.sellerAmount,amountSpecificPercentage:parsedData.amountSpecificPercentage, jurisdiction: parsedData.jurisdiction, companyName: parsedData.companyName})
            }
            if(lang){
                this.setState({language: lang})
            }
          const formText: PageText = lang === "en" ? configJSON.customFormTxt : configJSON.customFormTxtAr;
          this.setState({ pageText: formText });
      }


      getContent = async(id:string) =>{
            this.getLang();
          if (this.props.id === "SalePurchaseAgreement") {
              this.setState({ isAcknowledged: false });
          }
            const user = await getStorageData("typeOfUser");
            this.setState({typeOfUser:user})
            const header = {
                token: await getStorageData('token'),
            };
            let endPoint ;
            if(id ==="LetterOfIntent"){
                endPoint =  `${configJSON.getContentApi}?content_for=letter_of_intent`
            }
            if(id ==="SalePurchaseAgreement"){
                endPoint = `${configJSON.getContentApi}?content_for=sell_purchase_agreement`
                this.getRevisedPrice();
            }
            if(id ==="AutoSign"){
                endPoint = `${configJSON.getContentApi}?content_for=auto_sign_nda`
            }
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getContentId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
               endPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
      }

   

      handleInputChangeUserDate = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({userDate: event.target.value})
      }

      handleInputChangeSellerName = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({sellerName: event.target.value})
      }

      handleInputChangeSellerAddress = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({sellerAddress: event.target.value})
      }
      handleInputChangeBuyerName = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({buyerName: event.target.value})
      }
      handleInputChangeBuyerAddress = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({buyerAddress: event.target.value})
      }
      handleInputChangeAmountSpecificPercentage = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({amountSpecificPercentage: formatNumber(event.target.value)})
      }
      handleInputChangeJuridiction = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({jurisdiction: event.target.value})
      }
      handleInputChnageCompanyName = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({companyName: event.target.value})
      }
      handleInputChnageSellerAmount = (event: React.ChangeEvent<HTMLInputElement>) =>{
        this.setState({sellerAmount: formatNumber(event.target.value)})
      }

      getIfCondition = (englishContent: ReactElement,arabicContent: ReactElement) =>{
        if(this.state.language ==="en") {
            return englishContent
        }else{
            return arabicContent
        }
      }

      getIfElseCondition = (condition: boolean, ifTrue: string, ifFalse: string) => {
        return condition ? ifTrue : ifFalse;
      };
    
    handleRevisedPrice = (value: string) => {
        this.setState({ sellerAmount: value })
    };

    handleEndSign = (value: string) => {
        if (!value.endsWith(configJSON.endSign)) {
            value = value + configJSON.endSign
        };
        return value;
    };

    toastPosition = () => {
        if (this.state.language === "ar") {
            return  "top-left" 
        }
        else {
            return "top-right" 
        }
    };
    
    getRevisedPrice = async () => {

        const token = await getStorageData("token");
        const DealId = await getStorageData("dealId");
        const privateInfoHeader = {
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRevisedPriceCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getRevisedPriceEndPoint.replace("{DealId}", DealId)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(privateInfoHeader)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    handleDisclaimer = () => {
        this.setState({ openDisclaimer: true });
    };

    handleConditions = (condition: boolean, ifTrue: React.ReactElement | boolean, ifFalse: React.ReactElement | boolean) => {
        return condition ? ifTrue : ifFalse;
    };

    acknowledgeDisclaimer = () => {
        this.setState({ openDisclaimer: false, isAcknowledged: true });
    };

    resetRole = () => {
        let { userData } = this.state;
        this.setState({ userData });
    };

    // Customizable Area End
}
