// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/styles";

import CustomisableusersubscriptionsController, {
  Props,
  configJSON,
} from "./CustomisableusersubscriptionsController";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";

import { backgroundArrow, backgroundArrow1, backgroundArrow3 } from "./assets";
// Customizable Area End

export class CustomizableUserSubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getConditionalValue = (
    condition: boolean,
    ifTrue: string,
    ifFalse: string
  ) => {
    return condition ? ifTrue : ifFalse;
  };

  getOnHoverTextStyle = (type: number) => {
    const { classes } = this.props;

    return this.getConditionalValue(
      this.state.onHover === type,
      classes.sellerAndBuyerButtonTextHover,
      classes.sellerAndBuyerButtonText
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const getSellerAndBuyerButton = (type: number, name: string) => (
      <div
        data-test-id="seller-and-buyer-button"
        className={this.getConditionalValue(
          this.state.selectedType === type,
          classes.sellerAndBuyerButtonActive,
          classes.sellerAndBuyerButton
        )}
        onClick={() => this.setSelectedType(type)}
        onMouseEnter={() => this.setOnHover(type)}
        onMouseLeave={() => this.setOnHover(0)}
      >
        <div
          className={this.getConditionalValue(
            this.state.selectedType === type,
            classes.sellerAndBuyerButtonTextActive,
            this.getOnHoverTextStyle(type)
          )}
        >
          {name}
        </div>
        {this.state.selectedType === type &&
          (type === configJSON.onboardingType.Seller ? (
            <img
              src={backgroundArrow1}
              className={classes.backgroundArrowImgRight}
            />
          ) : (
            <img
              src={backgroundArrow3}
              className={classes.backgroundArrowImgLeft}
            />
          ))}
      </div>
    );
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        <div className={classes.mainSellerAndBuyerContainer}>
          <div className={classes.sellerAndBuyerBox}>
            <div className={classes.sellerAndBuyerText}>{this.state.pageTxtData.iAmA}</div>
            {getSellerAndBuyerButton(
              configJSON.onboardingType.Seller,
              this.state.pageTxtData.seller
            )}
            {getSellerAndBuyerButton(
              configJSON.onboardingType.Buyer,
              this.state.pageTxtData.buyer
            )}
            <button
              data-test-id="process-button"
              className={this.getConditionalValue(
                !!this.state.selectedType,
                classes.processButton,
                classes.processButtonDisable
              )}
              disabled={!this.state.selectedType}
              onClick={this.addUserRole}
            >
              {this.state.pageTxtData.proceed}
            </button>
          </div>
        </div>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  mainSellerAndBuyerContainer: {
    backgroundImage: `url(${backgroundArrow})`,
    backgroundSize: "cover",
    backgroundPositionX: "center",
  },
  sellerAndBuyerBox: {
    width: "600px",
    height: "70vh",
    background: "white",
    borderRadius: "20px",
    border: "1px #8C8C8C solid",
    margin: "60px auto",
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
    "@media (max-width:600px)": {
      width: "303px",
      height: "328px",
      padding: "25px 0",
    },
  },
  sellerAndBuyerText: {
    color: "#5412FC",
    fontSize: "30px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    lineHeight: "24px",
    wordWrap: "break-word",
    "@media (max-width:600px)": {
      fontSize: "16px",
      lineHeight: "34px",
    },
  },
  sellerAndBuyerButton: {
    width: "400px",
    height: "120px",
    background: "white",
    borderRadius: "20px",
    border: "4px #8C8C8C solid",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    color: "#8C8C8C",
    transition: "all 0.5s",
    cursor: "pointer",
    "&:hover": {
      color: "rgba(84, 18, 252, 0.80)",
      border: "4px solid rgba(84, 18, 252, 0.60)",
      boxShadow: "0px 6px 6px rgba(84, 18, 252, 0.30)",
    },
    "@media (max-width:600px)": {
      width: "160px",
      height: "66.6px",
      borderRadius: "10px",
      border: "2px #8C8C8C solid",
      "&:hover": {
        border: "2px solid rgba(84, 18, 252, 0.60)",
      },
    },
  },
  sellerAndBuyerButtonActive: {
    width: "400px",
    height: "120px",
    background: "white",
    borderRadius: "20px",
    border: "4px #5412FC solid",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    "@media (max-width:600px)": {
      width: "160px",
      height: "66.6px",
      borderRadius: "10px",
      border: "2px #5412FC solid",
    },
  },
  backgroundArrowImgLeft: {
    position: "absolute",
    top: 0,
    left: -3,
    "@media (max-width:600px)": {
      width: "45",
      left: -2,
    },
  },
  backgroundArrowImgRight: {
    position: "absolute",
    top: 0,
    right: -2,
    "@media (max-width:600px)": {
      width: "45",
    },
  },
  sellerAndBuyerButtonText: {
    fontSize: "36px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    wordWrap: "break-word",
    cursor: "pointer",
    "@media (max-width:600px)": {
      fontSize: "18px",
    },
  },
  sellerAndBuyerButtonTextHover: {
    fontSize: "36px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    wordWrap: "break-word",
    cursor: "pointer",
    "@media (max-width:600px)": {
      fontSize: "18px",
    },
  },
  sellerAndBuyerButtonTextActive: {
    color: "#5412FC",
    fontSize: "36px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    wordWrap: "break-word",
    cursor: "pointer",
    "@media (max-width:600px)": {
      fontSize: "18px",
    },
  },
  processButtonDisable: {
    width: "200px",
    height: "48px",
    background: "#8C8C8C",
    borderRadius: "24px",
    textAlign: "center",
    color: "white",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "22px",
    wordWrap: "break-word",
    border: "none",
    "@media (max-width:600px)": {
      width: "106px",
      height: "25.4px",
      fontSize: "12px",
    },
  },
  processButton: {
    width: "200px",
    height: "48px",
    background: "#10BFB8",
    borderRadius: "24px",
    textAlign: "center",
    color: "white",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "22px",
    wordWrap: "break-word",
    border: "none",
    cursor: "pointer",
    "@media (max-width:600px)": {
      width: "106px",
      height: "25.4px",
      fontSize: "12px",
    },
    "&:hover":{
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    }
  },
};

export default withStyles(styles)(CustomizableUserSubscriptions);
// Customizable Area End
