interface Translations {
    translatedText: string;
}

async function TranslateText<T extends Record<string, string>>(
    objectToTranslate: T,
    toLang: string,
    fromLang: string
): Promise<T> {
    if (toLang === fromLang) {
        return objectToTranslate;
    }

    const url = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyDG1JCpHrBSa4rgP0YBmMNJCo6VqP9MpzE`;
    const data = {
        q: Object.values(objectToTranslate),
        source: fromLang,
        target: toLang
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json() as { data?: { translations?: Translations[] } };

        if (responseData.data && responseData.data.translations && responseData.data.translations.length > 0) {
            const translations = responseData.data.translations;
          
            const translatedObject: T = {} as T;
            
            const objectKeys = Object.keys(objectToTranslate);
            for (let i = 0; i < objectKeys.length; i++) {
                const key = objectKeys[i];
                const translation = translations[i];
                if (translation) {
                    translatedObject[key as keyof T] = translation.translatedText as T[keyof T];
                } else {
                    translatedObject[key as keyof T] = objectToTranslate[key as keyof T];
                }
            }
            
            return translatedObject;
        } else {
            throw new Error('Translation failed.');
        }
    } catch (error) {
        console.log("There was an error with the translation request: ", error);
        return objectToTranslate;
    }
}


export default TranslateText