Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.emailErrorsEmpty = "Please Enter Email ID";
exports.emailErrorsInvalid = "Invalid Email ID"
exports.phoneNumberErrorsEmpty = "Please Enter Phone Number";
exports.phoneNumberErrorsInvalid = "Invalid Phone Number";
exports.countryCodeErrorsEmpty = "Please Select Country Code"

exports.apiCountrySelectorContentType = "application/json";
exports.apiCountrySelectorMethod = "GET";
exports.apiCountrySelectorEndPoint = "https://restcountries.com/v3.1/alpha?codes=AE,SA,BH,QA,OM,KWT,EG&fields=idd,name,flags";
exports.countries = [
  {
    dial_code: "+91",
    code: "IN"
  }
]

exports.resendCodeAPiEndPoint = "account_block/accounts/send_otps";
exports.verifyAPiEndPoint = "account_block/accounts/email_confirmations";
exports.emailPlaceHolder = "mohammedahmed@domain.com";
exports.firstNameValidationsRequired = "First Name Required";
exports.firstNameValidationsMatches = "Please enter alphabetic values only"
exports.firstNameValidationMin = "Please enter a minimum of 3 characters";
exports.firstNameValidationMax = "Please enter a Maximum  of 20 characters";
exports.lastNameValidationsRequired = "Last Name Required";
exports.emailIdValidation = "Invalid Email ID";
exports.emailIdValidationRequired = "Email ID Required";
exports.phonenumberIsRequired = "Phone Number Required";
exports.passwordRewuired="Password is required";
exports.confirmPasswordRequired="Confirm Password Required";
exports.passwordmissMatch ="Passwords must match";
exports.acceptTermsAndConditions="Accept Terms & Conditions";
exports.firstNamelabel = "First Name*";
exports.lastNameLabel="Last Name*";
exports.emailLabel="Email ID*";
exports.phoneNumberLabel="Phone Number*";
exports.passwordLabel ="Password*"
exports.passwordStrengthlabel="Password Strength:";
exports.confirmPasswordLabel="Confirm Password*";
exports.agreeToThe="I agree to the";
exports.optionsListOne="Minimum 1 Lower case character";
exports.optionsListTwo="Minimum 1 Upper case character";
exports.optionsListThree="Minimum 1 Symbol character";
exports.optionsListFour="Minimum 1 Numeric character";
exports.verification=" Verification";
exports.pleaseEnterVerificationText ="Please enter the verification code sent to your Email ID";
exports.EnterCodeText="Enter code"
exports.timeRemaingText ="Time Remaining";
exports.didNotReceivedCodeText="Did not receive code?";
exports.resendCodeText="Resend Code"
exports.phoneNumberVerificationText="Please enter the verification code sent to your Phone number";
exports.verifyText ="Verify";
exports.successText="Success!";
exports.successTextAr="نجاح!";
exports.verificationSuccessfulText="Your verification was successful.";
exports.verificationSuccessfulTextAr="تمت عملية التحقق الخاصة بك بنجاح.";
exports.proceedText="Please proceed to the next steps."
exports.proceedTextAr="يُرجى المتابعة إلى الخطوات التالية."
exports.nextText="Next";
exports.nextTextAr="التالي";
exports.sText="s";
exports.passwordStorngText="Strong";
exports.passwordWeakText="Weak";
exports.passwordMediumText="Medium";
exports.inValidPhoneNumberText="Invalid Phone Number";
exports.phoneNumberAlreadyExitsText="Phone number already registered"
exports.termsCondtionsText="terms & conditions";
exports.firstNamePlaceHolder="Mohammed";
exports.lastNamePlaceHolder="Ahmed";
exports.passwordLabelPlaceHolder="Password";
exports.confirmPasswordLabelAr="Confirm Password";
// Customizable Area End