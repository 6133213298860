// Customizable Area Start
import React from "react";


import ForgotPasswordController, { Props } from "./ForgotPasswordController";

import {
  Typography,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  Box,
  Button,
} from "@material-ui/core";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import "./ForgotPassword.web.css";
const images = require("./assets");
let theme = createTheme();
theme = responsiveFontSizes(theme);
export default class SuccessModel extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <HeaderBlock />
        <Box className="outer_wrapper">

        <Box style={styles.outerbox} className="OutlineBorder">
          <Box className="Bg-color">
            {/* @ts-ignore */}
            <Typography style={styles.typoRegister} className="typoRegister">
            {this.getSpecifiedLangString(this.state.lang, "Password change successful!", "تم تغيير كلمة المرور بنجاح!")}
            </Typography>

            <Box sx={styles.rightImageWithSuccess} className="successFullLogo">
              <img src={images.imageRight} width={60} height={60} />
              <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <Typography style={styles.typoSuccess}>Success!</Typography>
              </ThemeProvider>
            </Box>

            <Box sx={styles.successfullySubmitted} className="successFullText">
              <Box>
                {/* @ts-ignore */}
                <p style={styles.typoThird}>
                {this.getSpecifiedLangString(this.state.lang, "Your password was changed successfully.", "تم تغيير كلمة المرور الخاصة بك بنجاح.")}
                </p>
              </Box>
            </Box>
            <Box sx={styles.welcomeCommunity} className="successFullText">
              <Box>
                {/* @ts-ignore */}
                <p style={styles.typoThird}>
                {this.getSpecifiedLangString(this.state.lang, 
                  "Go back to the Sign in page, to sign in to your account using the new password.",
                  `  ارجع إلى صفحة تسجيل الدخول، لتسجيل الدخول إلى حسابك باستخدام كلمة المرور الجديدة.`)}
                </p>
              </Box>
            </Box>
            <Box sx={styles.gotoToHomePage}>
              <Button
                style={styles.typoHome}
                className="typoHome"
                variant="outlined"
                onClick={this.handleBackToHomePage}
              >
                {this.getSpecifiedLangString(this.state.lang, "Back to Sign in", "العودة إلى تسجيل الدخول")}
              </Button>
            </Box>
          </Box>
        </Box>
        </Box>

        <FooterBlock />
      </>
    );
  }
}

const styles: any = {
  outerbox: {
    // width: "45.55%",
    height: "auto",
    alinItem: "center",
    margin: "50px auto 50px auto",
    padding: "2px"
  },
  typoSuccess: {
    color: "black",
    fontFamily: "Montserrat , Sans serif",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "50px",
    display: "flex",
    alignItems: "center"
  },

  typoThird: {
    color: "black",
    fontFamily: "Montserrat , Sans serif",
    fontSize: "16px",
    fontStyle: "normal"
  },
  welcomeCommunity: {
    width: "80%",
    margin: "35px auto",
    textAlign: "center",
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alinItem: "center",
  },
  gotoToHomePage: {
    textAlign: "center",
    marginTop: "30px",
    paddingBottom: "35px"
  },

  typoRegister: {
    paddingTop: "30px",
    textAlign: "center",
    color: "#5412FC",
    fontFamily: "Montserrat",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: "bold",
    marginBottom: "30px"
  },
  rightImageWithSuccess: {
    alignItem: "center",
    width: "239px",
    gap: "10px",
    marginTop: "35px",
    display: "flex",
    margin: "35px auto",
    flexWrap: "wrap",
    textAline: "center"
  },
  successfullySubmitted: {
    width: "80%",
    margin: "5px auto",
    textAlign: "center",
    marginTop: "35px",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
 
  },
  typoHome: {
    color: "#10BFB8",
    border: "2px solid #10BFB8",
    borderRadius: "24px",
    textTransform: "inherit",
    fontWeight: 700,
    lineHeight: "24px",
    fontSize:"20px",
    padding:"12px 71px",
    fontFamily: "Montserrat",
    "&:hover": {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      background: "#fff"
    }
  },

};
// Customizable Area End
