// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  description: string;
  responseUrl: string;
  language: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TutorialsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUrlId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      name: "",
      description: "",
      responseUrl: "",
      language: "en",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    let lang = await getStorageData("language");
    if(!lang) {
      lang = 'en';
    }
    this.setState({language: lang})
    await this.getUrl();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.getUrlId == apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.setState({
          name: responseJson.data.attributes.name,
          description: responseJson.data.attributes.description,
          responseUrl: responseJson.data.attributes.link
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  navigateTo = (url: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  navigateToRegistration = () => {
    this.navigateTo("EmailAccountRegBlock");
  };

  navigateToSignIn = () => {
    this.navigateTo("EmailAccountLoginBlock");
  };

  navigateToProceed = async () => {
    const queryParams = new URLSearchParams(window.location.href);
    const queryParamsObject = Object.fromEntries(queryParams);

    const [firstValue] = Object.values(queryParamsObject);
    if(firstValue == 'signup') {
      this.navigateTo("CustomizableUserSubscriptions");
    } else {
      window.location.href = "/";

    }
  };

  getUrl = async () => {
    const headers = {
      "content-type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.videoUrl}?locale=${this.state.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.getMethod}`
    );

    this.getUrlId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
