// Customizable Area Start
import React, { useRef,useState } from "react";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Divider,
  FormControl,
  InputLabel,
  InputBase,
  Switch,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Checkbox,
  RadioGroup,
  Radio,
  IconButton,
  Tooltip,
  Modal,
  Button,
  Popover,
  Dialog,
  Container,
  Link as NavTag,
  Input
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FooterBlock from "../../../components/src/FooterBlock.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import { Link } from "react-router-dom";
import CompanyProfileEditController, {
  Props,
} from "./CompanyProfileEditController.web";
import {
  businessLogo,
  rocketIcon,
  ExpandIcon,
  fileUpload,
  documentUpload,
  selectMenuIcon,
  calenderIcon,
  dot
} from "./assets";
import InfoIcon from '@material-ui/icons/Info';
import { configJSON } from "./CompanyProfileEditController.web";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  styles,
  Top_heading,
  TypographyCategory,
  CustomizeButton,
  ButtonTypography,
  DotImg,
  openBox
} from "../../categoriessubcategories/src/Categoriessubcategories.web";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { conditionSort } from "../../../components/src/Helper";
import { userDefaultImage } from "../../../components/src/assets";
interface MultiMenuProps {
  options: string[];
  selectedValue: string[];
  fieldName: string;
  name: string;
  handleCheckboxChange: any;
  error?: any;
  otherValue?: string;
  handleChange?: any;
  inputName?: string;
  otherText:string;
  handleClick: any;
  otherCheckbox: boolean;
  otherName: string;
  errorMsg: string;
}
export const MultiCheckBox: React.FC<MultiMenuProps> = ({
  options,
  selectedValue,
  fieldName,
  name,
  handleCheckboxChange,
  error,
  otherValue,
  handleChange,
  inputName,
  otherText,
  handleClick,
  otherCheckbox,
  otherName,
  errorMsg

}) => {
  const style = () => {
    return {
      width: "100%",
      "& .check-box-div": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "40px",
        marginBottom: "20px",
        "@media (max-width:600px)": {
          marginTop: "0px",
        },
      },
      "& .check-box-wrap": {
        flexBasis: "33.33%",
        maxWidth: "500px",
        "@media (max-width:600px)": {
          flexBasis: "100%",
        },
      },
    };
  };


  return (
    <Box sx={style()}>
      <div
        style={{ display: "flex", justifyItems: "center" }}
        className="check-box-div"
      >
        {options && options.length > 0 && options.map((option, index) => (
          <div className="check-box-wrap">
            <FormControlLabel
              control={
                <Checkbox
                data-test-id={`checkbox-${index}`}
                  checked={
                    selectedValue?.find((value) => value === option)
                      ? true
                      : false
                  }
                  onChange={() => handleCheckboxChange(fieldName, name, option)}
                  style={{ color: conditionSort(!otherCheckbox && error, 'red', "") }}
                />
              }
              label={<Typography>{option}</Typography>}
            />
          </div>
        ))}
        <Grid justifyContent="center" className="check-box-wrap">
        <div>

          <FormControlLabel control={<Checkbox data-test-id={`checkbox-other-${otherName}`} 
            name={otherName} onChange={() => handleClick()} id={`checkbox-other-${otherName}`}
            style={{ color: error ? 'red' : "" }} />} label={otherText} checked={otherCheckbox}
          />
          <input
            onChange={(ev) => handleChange(ev)}
            value={otherValue}
            name={inputName}
            disabled={!otherCheckbox}
            data-test-id="input-value-other"
            style={{
              borderBottom: "1px solid #000000",
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              outline: "none",
              width: "100px",
              marginRight:"20px",
              borderColor: conditionSort(error && otherCheckbox && !otherValue, 'red', '#000000')
            }}
            className="input"
          />
          {conditionSort(error && otherCheckbox && !otherValue, <div style={{
    fontFamily: "Montserrat",
    color: "#FF7575",
    marginTop: "0px",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    padding: "4px 15px",
    marginBottom: "0px"}}>{errorMsg}</div>, <></>)}
           

</div>
        </Grid>

     
      </div>
    </Box>
  );
};

export default class CompanyProfileEdit extends CompanyProfileEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  

  MuiInputLabelRootCss = {
    color: "#8C8C8C",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    wordWrap: "break-word",
    transform: "translate(0, 1.5px)",
    right:0,
    "@media (max-width:1040px)": {
      fontSize: "15px",
    },
    "@media (max-width:980px)": {
      fontSize: "14px",
    },
    "@media (max-width:600px)": {
      position: "inherit",
      fontSize: "14px",
    },
    "& span": {
      color: "#FF7575",
    },
  };

  
  MuiInputBaseRoot = {
    margin: "30px 0 0",
    "@media (max-width:600px)": {
      marginBottom: "10px",
      marginTop: "10px",
    },
    borderRadius: "12px",
    border: "1px #8C8C8C solid",
    
    "& input[type='date']::-webkit-calendar-picker-indicator": {
      opacity: 1,
      display: "block",
      backgroundImage: `url(${calenderIcon})`,
      backgroundSize: "cover",
      height: "28px",
      width: "28px",
    },
    "& input[type='date']:focus::-webkit-datetime-edit": {
      color: "black !important",
    },
    "& .MuiInputBase-input": {
      padding: "12px 24px",
      fontWeight: 400,
      color: "black",
      fontFamily: "Montserrat",
      fontSize: "20px",
      wordWrap: "break-word",
    },
    "& input[type='date']:required:invalid::-webkit-datetime-edit": {
      color: "transparent",
    },
    
  };

  errorField = {
    fontFamily: "Montserrat",
    color: "#FF7575",
    marginTop: "0px",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    padding: "4px 15px",
    marginBottom: "0px",
    "@media (max-width:600px)": {
      marginTop: "4px",
      padding: "0"
    },
  };

  startUpDetailsStyle = () => {
    return {
      margin: "30px auto",
      maxWidth: "1242px",
      width: "100%",
      "& .drop-down": {
        borderRadius: "20px",
        border: "1px black solid",
        width: "320px",
        height: "80px",
        marginBottom: "30px",
      },
      "@media (max-width:600px)": {
        margin: "32px auto",
        maxWidth: "345px",
      },
    };
  };

  getPrivateInformation = () => {
    const style = () => {
      return {
       
        "& .staticText": {
          position: "absolute",
          top: "50%",
          fontFamily: "Montserrat",
          left: "10px",
          transform: "translateY(-50%)",
          pointerEvents: "none",
          color: "black",
          fontSize: "14px",
          marginTop: "16px"
        },
        "& .staticTextAr": {
          position: "absolute",
          fontFamily: "Montserrat",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          pointerEvents: "none",
          color: "black",
          fontSize: "14px",
          marginTop: "16px"
        },
        "& .m-0": {
          "@media (max-width:600px)": {
            marginTop: "0px",
          },
        },
        "& .mb-0": {
          "@media (max-width:600px)": {
            marginBottom: "0px",
          },
        },
        width: "100%",
        "& .MuiFormControl-root": {
          "@media (max-width:600px)": {
            marginBottom: "10px",
          },
          marginBottom: "24px",
          "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
          "& .MuiInputBase-root": this.MuiInputBaseRoot,
          "&.last": {
            marginBottom: "0",
          },
        },
        marginTop: "24px",
        "& .startup-profile-section": {
          display: "flex",
          gap: "100px",
          "@media (max-width:600px)": {
            gap: "20px",
            flexDirection: "column",
            marginBottom: "20px"
          },
          marginBottom: "24px",
          "& .profile-section": {
            border: "1px #8C8C8C solid",
            borderRadius: "12px",
            padding: "52px 40px",
            gap: "32px",
            display: "flex",
            "& img": {
              width: "100px",
              height: "100px",
            },
            "& .profile-details-acc": {
              display: "grid",
              justifyItems: "start",
              gap: "39px",
              "& .user-name": {
                color: "black",
                fontSize: "24px",
                fontFamily: "Montserrat",
                "& .user-alfa": {
                  opacity: 1
                },
                fontWeight: 700,
                lineHeight: "24px",
                wordWrap: "break-word",
              },
              "& .view-link-acc": {
                lineHeight: "24px",
                wordWrap: 'break-word',
                cursor: "pointer",
                fontSize: "16px",
                fontFamily: "Montserrat",
                color: "#262626",
                fontWeight: 500,
                textDecoration: "underline",
                
                "& .user-alfa": {
                  opacity: 1
                },
              },
            },
          },
          "& .startup-section-acc": {
            "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
            "& .MuiInputBase-root": this.MuiInputBaseRoot,
            "& .mb": {
              marginBottom: "24px",
              "@media (max-width:600px)": {
                marginBottom: "0px",
              },
            },
          },
        },
        "& .MuiDivider-root": {
          backgroundColor: "#8C8C8C !important",
        },
        "& .competitors-section": {
          margin: "19px 0 12px",
          "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
          "& .MuiInputBase-root": this.MuiInputBaseRoot,
          "@media (max-width:600px)": {
            marginBottom: 0
          },
        },      
        "& .p-l-section": {
          margin: "12px 0 12px",
          "@media (max-width:600px)": {
            marginBottom: "0px",
          },
          "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
          "& .upload-document": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .user-alfa": {
              opacity: 1
            },
            gap: "40px",
            marginTop: "30px",
            "@media (max-width:600px)": {
              marginTop: "20px",
              fontSize: "16px"
            },
            border: "2px #8C8C8C dashed",
            borderRadius: "20px",
            padding: "20px",
            color: "#8C8C8C",
            fontSize: "20px",
            fontFamily: "Montserrat",
            fontWeight: 500, lineHeight: "24px",
            wordWrap: "break-word",

            cursor: "pointer",
            "& .user": {
            },
            "&:hover": {
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
          "& .upload-document-disable": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.5,
            "& .user-alfa": {
              opacity: 1
            },
            gap: "40px",
            marginTop: "30px",
            "@media (max-width:600px)": {
              marginTop: "20px",
              fontSize: "16px"
            },
            borderRadius: "20px",
            border: "2px #8C8C8C dashed",
            padding: "20px",
            color: "#8C8C8C",
            fontSize: "20px",
            fontFamily: "Montserrat",
            fontWeight: 500,
            lineHeight: "24px",
            wordWrap: "break-word",
            cursor: "pointer",
          }
        },
        "& .documents-files-section": {
          cursor: "pointer",
          marginBottom: "20px",
          "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
          "& .documents-files": {
            display: "flex", 
            alignItems: "center",
            marginTop: "30px",
            justifyContent: 'center',
            "@media (max-width:600px)": {
              marginTop: "20px",
            },
            borderRadius: "20px",
            padding: "20px",
            border: "1px #8C8C8C solid", background: "rgba(140, 140, 140, 0.25)",
            cursor: "pointer",
            "& .doc-file": {
              opacity: 0
            },
            "&:hover": {
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        "& .auto-sign-section": {
          margin: "20px 0 0",
          "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
          "& .MuiFormGroup-root": {

            marginTop: "24px",
            flexDirection: "unset",
            justifyContent: "space-between",
            alignItems: 'center',
            "& .doc-file": {
              opacity: 0
            },
            padding: "0 30px",
            "@media (max-width:600px)": {
              padding: "0 0",
            },
            "& .MuiFormControlLabel-root": {
              margin: "0",
              gap: "145px",
              "@media (max-width:600px)": {
                gap: "20px",
              },
              "& .MuiTypography-root": {
                color: "#8C8C8C",
                fontSize: "20px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                lineHeight: "24px",
                wordWrap: "break-word",
              },
              "& .MuiFormControlLabel-label.Mui-disabled": {
                opacity: 0.6,
              },
              "& .MuiSwitch-root": {
                width: "84px",
                height: "40px",
                padding: "0",
                borderRadius: '20px',
                "& .doc-file": {
                  opacity: 1
                },
                "& .MuiButtonBase-root": {
                  padding: "4px 5px",
                  "& .MuiSwitch-thumb": {
                    width: "32px",
                    height: "32px",
                  },
                },
                "& .MuiSwitch-colorSecondary.Mui-checked": {
                  color: "#fff",
                },
                "& .MuiSwitch-switchBase.Mui-checked": {
                  transform: "translateX(42px)",
                },
                "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#5412FC",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  opacity: 1,
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "#8C8C8C",
                  opacity: 1,
                },
                "& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.4,
                },
                "& .MuiSwitch-colorSecondary.Mui-disabled": {
                  opacity: 0.8,
                  color: "white",
                  "& .doc-file": {
                    opacity: 1
                  },
                },
              },
            },
            "& .MuiDivider-vertical": {
              height: "80px",
              "@media (max-width:600px)": {
                display: "none",
              },
            },
          },
        },
      };
    };
    
    return (
      <Box sx={style()}>
        <div className="startup-profile-section">
          <div className="profile-section">
            <img src={this.state.userData?.image?.url || userDefaultImage} />
            <div className="profile-details-acc">
              <div className="user-name">{this.state.userData?.first_name}{' '}{this.state.userData?.last_name}</div>
              <Link to={"/SellerProfile"} className="view-link-acc">{this.handleConditon(this.state.language === "en", configJSON.viewFullProfileText , "عرض الملف الشخصي الكامل")}</Link>
            </div>
          </div>
          <div className="startup-section-acc">
            <FormControl fullWidth className="mb">
              <InputLabel shrink htmlFor="company-name-input">
                {this.handleConditon(this.state.language === "en",configJSON.companyNameInputLabel , "اسم الشركة :")}
              </InputLabel>
              <InputBase data-test-id="startup-name-input"
                name="company_name" onChange={(ev) => this.handleOnInputChange('private_information', ev)}
                id="company-name-input" value={this.state.data.private_information?.company_name} />
                {this.state.errors.company_name ? (
              <Box sx={this.errorField}>{this.state.companyProfilePageText.invaildField}</Box>
            ) : (
              <></>
            )}
            </FormControl>
            
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="company-website-input" data-test-id="comapny-web">
              {this.handleConditon(this.state.language === "en", configJSON.companyWebsiteInputLabel , "رابط موقع الشركة :")}              
              </InputLabel>
              <InputBase  data-test-id="startup-website-input" id="company-website-input" value={this.state.data.private_information?.company_website} 
              name="company_website" onChange={(ev) => this.handleOnInputChange('private_information', ev)}/>
            </FormControl>
          </div>
        </div>
        <Divider />
        <div className="competitors-section">
          <FormControl fullWidth style={{marginBottom: 0}}>

            <InputLabel shrink htmlFor="vendors-input">
            {this.handleConditon(this.state.language === "en", configJSON.vendorsInputLabel , configJSON.vendorsInputLabel_ar)}{" "}
              <span>*</span>
            </InputLabel>
            {this.state.data.private_information?.work_with_vendors?.map(
              (comp: string, index: number) => {
                return (
                  <div
                    style={{ display: "flex",justifyContent: "space-evenly", alignItems: "baseline" }}
                    key={index}
                  >
                    <div>
                      <Typography style={{ color: "#000000" }}>
                        {index + 1}.
                      </Typography>
                    </div>
                    <div style={{ display: "flex", width: "96%" }}>
                      <InputBase
                        value={comp}
                        type="number"
                        id={`work-with-vendor-${index}`}
                        fullWidth
                        style={this.getErrorStyle(this.state.errors.work_with_vendors && !comp)}
                        onChange={(ev) =>
                          this.handleArrayInput(
                            "private_information",
                            "work_with_vendors",
                            ev.target.value,
                            index
                          )
                        }
                      />
                    </div>
                  </div>
                );
              }
            )}
            {this.state.errors.work_with_vendors ? (
              <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
            ) : (
              <></>
            )}
            <div >
              <IconButton
                id="add-btn"
                className="m-0"
                onClick={() =>
                  this.addNewItem("private_information", "work_with_vendors")
                }
              >
                <AddCircleOutlineIcon style={{ fontSize: 30 }} />

              </IconButton>
            </div>
          </FormControl>
        </div>
        <div className="p-l-section m-0">
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="p-l-input">
          {this.handleConditon(this.state.language === "en",configJSON.commitmentsInputLabel,configJSON.commitmentsInputLabel_ar)} <span>*</span>
            </InputLabel>
            <div style={{ marginTop: "20px" }}>
              {this.state.data.private_information?.listing_id ? <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="already_commitments"
                id="already_commitments"
                onChange={(ev) => {
                  this.handleOnInputChange("private_information", ev)
                  this.handleNo(ev);
                }
              }
                value={this.state.data.private_information?.already_commitments}
              >
                <FormControlLabel
                  value={"Yes"}
                  control={<Radio />}
                  label={this.handleConditon(this.state.language === "en", configJSON.yesLabel, "نعم")}
                />
                <FormControlLabel value={"No"} control={<Radio />} label={this.handleConditon(this.state.language === "en",configJSON.noLabel,"لا")} />
              </RadioGroup> : <></>}
              
            </div>
            {this.state.errors.already_commitments ? (
              <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
            ) : (
              <></>
            )}
          </FormControl>

          <FormControl fullWidth style={{ marginBottom: "40px" }}>
            <InputLabel shrink htmlFor="p-l-input1">
              {this.handleConditon(this.state.language === "en", configJSON.contractInputLabel,configJSON.contractInputLabel_ar)}
            </InputLabel>

            <input type="file" id="p-l-input1" hidden disabled={this.state.data.private_information?.already_commitments === "No"}
             onChange={(e) => this.handleFileChange("private_information", "suppliers_and_vendors_contract", e)}/>
            <label htmlFor="p-l-input1" className={conditionSort(this.state.data.private_information?.already_commitments === "No", "upload-document-disable","upload-document")}>
              <img src={fileUpload} />
              {this.state.data?.private_information?.suppliers_and_vendors_contract?.file_name || 
              this.state.data?.private_information?.suppliers_and_vendors_contract?.name || (this.state.language === "ar"?configJSON.uploadDocumentText_ar:configJSON.uploadDocumentText)}
            </label>
          </FormControl>

          <Divider />

          <div className="p-l-section" style={{marginTop: "32px"}}>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="p-l-input2">
              {this.handleConditon(this.state.language === "en", configJSON.pAndLInputLabel, " ارفق قائمة الدخل للشركة")}<span>*</span>
              </InputLabel>

              <input type="file" id="p-l-input2" hidden data-test-id="p-l-input2"
              onChange={(e) => this.handleFileChange("private_information", "p_and_l_statement", e)}/>
              <label htmlFor="p-l-input2" className="upload-document" style={this.getErrorStyle(this.state.errors?.p_and_l_statement)}>
                <img src={fileUpload} />
                {this.state.data?.private_information?.p_and_l_statement?.file_name ||
                this.state.data?.private_information?.p_and_l_statement?.name ||  (this.state.language === "ar"?configJSON.uploadDocumentText_ar:configJSON.uploadDocumentText)}
              </label>
              {this.state.errors?.p_and_l_statement ? (
                <Box sx={this.errorField}>
                  {this.state.companyProfilePageText.errorMessageUploadDoc}
                </Box>
              ) : (
                <></>
              )}
            </FormControl>
          </div>
          <div className="p-l-section">
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="p-l-input3">
                {this.handleConditon(this.state.language === "en", configJSON.balanceSheetInputLabel, "ارفق قائمة الميزانية للشركة :")}<span>*</span>
              </InputLabel>

              <input type="file" id="p-l-input3" hidden 
              onChange={(e) => this.handleFileChange("private_information", "balance_sheet", e)}/>
              <label htmlFor="p-l-input3" className="upload-document"
              style={this.getErrorStyle(this.state.errors?.balance_sheet)}>
                <img src={fileUpload} />
                {this.state.data?.private_information?.balance_sheet?.[0]?.file_name ||
                this.state.data?.private_information?.balance_sheet?.name
                 ||  (this.state.language === "ar"?configJSON.uploadDocumentText_ar:configJSON.uploadDocumentText)}
              </label>
              {this.state.errors.balance_sheet ? (
                <Box sx={this.errorField}>
                 {this.state.companyProfilePageText.errorMessageUploadDoc}
                </Box>
              ) : (
                <></>
              )}
            </FormControl>
          </div>
        </div>
        <div className="documents-files-section">
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="documents-files-input">
              
              {this.handleConditon(this.state.language === "en", configJSON.documentsFilesInputLabel, "المستندات والملفات :")}

            </InputLabel>
            {this.state.docsArray?.map((ele: any) => (
                <div className="p-l-section">
                  <FormControl fullWidth>
                    <Typography style={{ fontSize: "20px", marginTop: "10px" }}>{ele.document_title || ele.title}</Typography>
                    <label className="upload-document" style={{ marginTop: 0 }}>
                      <img src={fileUpload} />
                      {ele?.file_name || ele.file?.name}
                    </label>
                  </FormControl>
                </div>
              ))}
            <button id="documents-files-input" className="documents-files" 
            data-test-id="file-upload-modal"
            onClick={() => this.setState({ openFileModal: true })}>
              <img src={documentUpload} />
            </button>
          </FormControl>
        </div>

        <Divider />
        <div className="profile-section" style={{ marginTop: "24px" }}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="total-dept-input">
              {this.handleConditon(this.state.language === "en", configJSON.totalDeptInputLabel, "إجـمالي الديون :")}
            </InputLabel>
            <InputBase id="total-dept-input" value={this.state.data.private_information?.total_depth}
              name="total_depth" onChange={(ev) => this.handleNumChange('private_information', ev)} />
          </FormControl>
          <FormControl fullWidth className="mb">
            <InputLabel shrink htmlFor="total-assets-input">
              {this.handleConditon(this.state.language === "en", configJSON.totalAssetsInputLabel, "إجـمالي الأصول :")}
            </InputLabel>

            <InputBase id="total-assets-input" value={this.state.data.private_information?.total_assets}
            data-test-id="total-assests"            
            name="total_assets" onChange={(ev) => this.handleNumChange('private_information', ev)}/>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="total-shareholder-equity">
              {this.handleConditon(this.state.language === "en", configJSON.totalShareholderEquityInputLabel, "إجـمالي حقوق المساهمين :")}

            </InputLabel>
            <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{this.handleConditon(this.state.language === "en", configJSON.currency,configJSON.currency_ar )}</span>

            <InputBase id="total-shareholder-equity" value={this.state.data.private_information?.total_shareholder_equity} 
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "40px",
            }, {
              paddingLeft: "40px",
            })}
            
            name="total_shareholder_equity" onChange={(ev) => this.handleNumChange('private_information', ev)}/>
          </FormControl>
        </div>

        <Divider />

        <div className="auto-sign-section">
          <FormControl fullWidth>
            <InputLabel shrink>{this.handleConditon(this.state.language === "en", configJSON.autoSignInputLabel , configJSON.autoSignInputLabel_ar)}</InputLabel>
            <FormGroup>
              {this.state.data.private_information?.listing_id ?
              <FormControlLabel 
              control={<Switch name="auto_sign" id="auto_sign" checked={this.state.data.private_information.auto_sign}
              onChange={(event) => this.handleSwitch("private_information", "auto_sign")} />}
              id="auto-sign"
              label={this.handleConditon(this.state.language === "en", configJSON.autoSignNdaLabel , configJSON.autoSignNdaLabel_ar)}
              labelPlacement="start"
            /> : <></>
              }
              
              <Divider orientation="vertical" />
              {this.state.data.private_information?.listing_id ?
              <FormControlLabel
                control={<Switch name="auto_accept_request" checked={this.state.data?.private_information.auto_accept_request}
                onChange={(event) => this.handleSwitch("private_information", "auto_accept_request")} />}
                id="auto-sign-input"
                label={this.handleConditon(this.state.language === "en", configJSON.autoAcceptRequestLabel ,configJSON.autoAcceptRequestLabel_ar)}
                labelPlacement="start"
                disabled={!this.state.data.private_information?.auto_sign}
              /> : <></>}
            </FormGroup>
          </FormControl>
        </div>
        <Divider />
      </Box>
    );
  };

  commonStyle = () => {
    return {
      "& .f16": {
        fontSize: "18px",
      },
      "& .staticText": {
        fontFamily: "Montserrat",
        position: "absolute",
        top: "50%",
        left: "10px",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        color: "black",
        marginTop: "16px"
      },
      "& .staticTextAr": {
        fontFamily: "Montserrat",
        position: "absolute",
        top: "50%",
        right: "10px",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        color: "black",
        marginTop: "16px"
      },
      "& .mb-0": {"marginBottom": 0},
      marginTop: "24px",
      width: "100%",
      "& .MuiFormControl-root": {
        marginBottom: "12px",
        "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
        "& .MuiInputBase-root": this.MuiInputBaseRoot,
        "&.last": {
          marginBottom: "0",
        },
        "@media (max-width:600px)": {
          marginBottom: "10px",
          "&.last": {
            marginBottom: "40px",
          },
        }
      },
    };
  };
  getsummarry = () => {
    
    return (
      <Box data-test-id="summary-box" sx={this.commonStyle()}>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="date-founded-input">
          {this.handleConditon(this.state.language === "en", configJSON.dateFoundedInputLabelText , "تاريخ تأسيس الشركة")} <span>*</span>
          </InputLabel>
          <InputBase
            name="date_founded"
            onChange={(ev) => this.handleOnInputChange("summarry", ev)}
            id="date-founded-input"
            data-test-id="date-founded-input"
            type="date"
            required
            value={this.state.data.summarry?.data.attributes.date_founded}
            style={this.getErrorStyle(this.state.errors.date_founded)} 
            
          />
          {this.state.errors.date_founded ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="startup-team-size-input">
            {this.handleConditon(this.state.language === "en", configJSON.employeesInputLabelText , "إجـمالي عدد الموظفين")} <span>*</span>
          </InputLabel>
          <InputBase
            data-test-id="startup-teamsize-input"
            name="number_of_employees"
            onChange={(ev) => this.handleOnInputChange("summarry", ev)}
            id="startup-team-size-input"
            value={this.state.data.summarry?.data.attributes.number_of_employees}
            style={this.getErrorStyle(this.state.errors.number_of_employees)}
          />
          {this.state.errors.number_of_employees ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="date-type-category-input">
            {this.handleConditon(this.state.language === "en", configJSON.businessTypeInputLabelText , "مجـال/صناعة الشركة")} <span>*</span>
          </InputLabel>
          <SelectMenu
            fieldName="summarry"
            name="sub_category_id"
            data-test-id="startup-cat-input"
            handleOnInputChange={this.handleOnInputChange}
            menuList={this.state.subCategoryList}
            selectedValue={this.state?.subCategoryList.find((value: any) => value.key === this.state.data?.summarry?.data.attributes.sub_category_id)?.title}
            style={this.getErrorStyle(this.state.errors.sub_category_id)}
          />
          {this.state.errors.sub_category_id ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        {conditionSort(this.state.otherSubCatId == this.state?.subCategoryList.find((value: any) => value.key === this.state.data?.summarry?.data.attributes.sub_category_id)?.key, 
            <FormControl fullWidth>
            <InputLabel shrink htmlFor="headline-input">
              {this.state.companyProfilePageText.otherCatText} <span>*</span>
            </InputLabel>
            <InputBase id="otherCatText-input" value={this.state.otherCatText}
              onChange={(event) => { this.setState({ otherCatText: event.target.value }) }}
              data-test-id="otherCatText-input"
              style={this.getErrorStyle(this.state.errors.otherCatText)}

            />
            {conditionSort(this.state.errors.otherCatText, <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>, <></>)}
          </FormControl>, null)}
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="country-input">
            {this.handleConditon(this.state.language === "en", configJSON.countryInputLabelText , "البلد")}<span>*</span> 
          </InputLabel>
          <SelectMenu
            fieldName="summarry"
            name="country"
            data-test-id="country"
            handleOnInputChange={this.handleOnInputChange}
            menuList={this.handleConditon(this.state.language === "en", this.state.countriesData , this.state.countriesDataAr)}
            selectedValue={this.state.data?.summarry?.data.attributes.country}
            style={this.getErrorStyle(this.state.errors.country)}
          />

          {this.state.errors.country ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}

        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="city-input">
            {this.handleConditon(this.state.language === "en", configJSON.cityInputLabelText , "المدينة")} <span>*</span>
          </InputLabel>
          <SelectMenu
            fieldName="summarry"
            name="city"
            data-test-id="city"
            handleOnInputChange={this.handleOnInputChange}
            menuList={this.handleConditon(this.state.language === "en", (this.state.cityItems.find((country: { country: string }) => country.country.toLowerCase() === this.state.data?.summarry.data.attributes.country?.toLowerCase())?.cities || []).map((city: string) => ({ value: city, title: city }))
            , (this.state.cityItemsAr.find((country: { country: string }) => country.country.toLowerCase() === this.state.data?.summarry.data.attributes.country?.toLowerCase())?.cities || []).map((city: string) => ({ value: city, title: city })))}
            disabled={!this.state.data?.summarry?.data.attributes.country}
            selectedValue={this.state.data?.summarry?.data.attributes.city}
            style={this.getErrorStyle(this.state.errors.city)}
          />
          {this.state.errors.city ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="headline-input">
            {this.handleConditon(this.state.language === "en", configJSON.headlineInputLabelText , "عنوان رئيسي:")} <span>*</span>
          </InputLabel>
          <InputBase
            name="headline"
            onChange={(ev) => this.handleOnInputChange("summarry", ev)}
            id="headline-input"
            data-test-id="headline-input"
            value={this.state.data.summarry?.data.attributes.headline}
            style={this.getErrorStyle(this.state.errors.headline)}
          />
          {this.state.errors.headline ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
            {this.handleConditon(this.state.language === "en",configJSON.descriptionInputLabelText , "الوصف")} <span>*</span>
          </InputLabel>
          <InputBase
            name="description"
            onChange={(ev) => this.handleOnInputChange("summarry", ev)}
            data-test-id="description"
            id="description-input"
            rows={3}
            multiline
            value={this.state.data.summarry?.data.attributes.description}
            style={{ border: this.handleConditon(this.state.errors.description , "1px solid red" , "") }}
          />
          {conditionSort(this.state.errors.description, <Box sx={this.errorField}>{conditionSort(this.state.data.summarry?.data.attributes.description?.length, conditionSort(this.state.language == 'en', configJSON.lenEr, configJSON.lenAr),this.state.companyProfilePageText.errorMessage)} </Box>, null)}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="asking-price-input">
            {this.handleConditon(this.state.language === "en", configJSON.askingPriceInputLabelText , "السعر المطلوب")} <span>*</span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{this.handleConditon(this.state.language === "en", configJSON.currency,configJSON.currency_ar)}</span>

          <InputBase
            name="asking_price"
            onChange={(ev) => this.handleNumChange("summarry", ev)}
            id="asking-price-input"
            data-test-id="asking-price-input"
            value={this.state.data.summarry?.data.attributes.asking_price}
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "40px",
              border: this.state.errors.asking_price ? "1px solid red" : "",
            }, {
              paddingLeft: "40px",
              border: this.state.errors.asking_price ? "1px solid red" : "",
            })}
          />
          {this.state.errors.asking_price ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth >
          <InputLabel shrink htmlFor="asking-price-reasoning-input">
            {this.handleConditon(this.state.language === "en", configJSON.askingPriceReasoningInputLabelText , "السبب للسعر المطلوب")} <span>*</span>
          </InputLabel>
          <InputBase
            name="asking_price_reasoning"
            data-test-id="asking-price-reason-input"
            onChange={(ev) => this.handleOnInputChange("summarry", ev)}
            id="asking-price-reasoning-input"
            multiline
            rows={3}
            value={this.state.data.summarry?.data.attributes.asking_price_reasoning}
            style={{ border: this.state.errors.asking_price_reasoning ? "1px solid red" : "" }}
          />
          {this.state.errors.asking_price_reasoning && (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          )}
        </FormControl>
      </Box>
    );
  };

  getCompanyOverview = () => {   
    return (
      <Box sx={this.commonStyle()}>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
            {this.handleConditon(this.state.language === "en", configJSON.businessTypeInputLabel , "نوع العمل ")} <span>*</span>
          </InputLabel>
          <MultiCheckBox
            options={this.handleConditon(this.state.language === "en", this.state.businessTypeCheckBoxes , this.state.businessTypeCheckBoxesAr)}
            selectedValue={this.state.data?.company_overview?.data.attributes.business_type}
            fieldName={"company_overview"}
            name="business_type"
            handleCheckboxChange={this.handleCheckboxChange}
            error={this.state.errors.business_type}
            inputName="businessTypeOther"
            data-test-id="business-input"
            otherValue={this.state.businessTypeOther}
            handleChange={this.handleChange}
            otherText={this.handleConditon(this.state.language === "en", configJSON.otherCheckboxLabel , "أخرى:")}
            handleClick={() => this.handleCheckboxOther('business_type')}
            otherCheckbox={this.state.otherCheckbox}
            otherName={'business_type'}
            errorMsg={this.state.companyProfilePageText.fieldError}
          />
          {conditionSort(this.state.errors.business_type && !this.state.otherCheckbox, <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>, <></>)}
        </FormControl>
        <div className="competitors-section" id="list-products">
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="competitors-input">
              {this.handleConditon(this.state.language === "en", configJSON.listOfProductsInputLabel , "قائمة المنتجات / الخدمات:")}
            </InputLabel>
            {this.state.data?.company_overview?.list_of_product_services.map(
              (comp: string, dataIndex: number) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly", alignItems: "baseline" }}
                    key={dataIndex}
                  >
                    <div>
                      <Typography style={{ color: "#000000" }}>
                        {dataIndex + 1}.
                      </Typography>
                    </div>
                    <div style={{ display: "flex", width: "96%" }}>
                      <InputBase
                        value={comp}
                        id={`products-input-${dataIndex}`}
                        data-test-id={`products-input-0`}
                        fullWidth
                        style={{ height: "40px" }}
                        onChange={(ev) =>
                          this.handleArrayInput(
                            "company_overview",
                            "list_of_product_services",
                            ev.target.value,
                            dataIndex
                          )
                        }
                      />
                    </div>
                  </div>
                );
              }
            )}

            <div >
              <IconButton
              className="mb-0"
              id="add-btn-product"
                onClick={() =>
                  this.addNewItem(
                    "company_overview",
                    "list_of_product_services"
                  )
                }
              >
                <AddCircleOutlineIcon style={{ fontSize: 30 }} />
              </IconButton>
            </div>
          </FormControl>
        </div>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
            {this.handleConditon(this.state.language === "en", configJSON.revenueModelInputLabel , configJSON.revenueModelInputLabel_ar)}  <span>*</span>
          </InputLabel>
          <InputBase
            name="revenue_model"
            value={this.state.data.company_overview.data.attributes.revenue_model}
            onChange={(ev) => this.handleOnInputChange("company_overview", ev)}
            id="description-input"
            data-test-id="techstack-input"
            multiline
            rows={3}
            style={{ border: this.state.errors.revenue_model ? "1px solid red" : "" }}
          />
          {this.state.errors.revenue_model ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
            {this.handleConditon(this.state.language === "en", configJSON.regionsMarketInputLabel , "حدد النطاق الجـغرافي لأعمال شركتكم")} <span>*</span>
          </InputLabel>
          <InputBase
            name="which_regions_business_operated"
            value={
              this.state.data.company_overview?.data.attributes.which_regions_business_operated
            }
            onChange={(ev) => this.handleOnInputChange("company_overview", ev)}
            id="description-input"
            multiline
            rows={4}
            style={{ border: this.state.errors.which_regions_business_operated ? "1px solid red" : "" }}
          />
          {this.state.errors.which_regions_business_operated ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
            {this.handleConditon(this.state.language === "en", configJSON.numberOfSuppliersInputLabel , "كم عدد الموردين الأساسيين لشركتك؟")} <span>*</span>
          </InputLabel>
          <InputBase
            name="number_of_suppliers"
            type="number"
            value={this.state.data.company_overview?.data.attributes.number_of_suppliers}
            onChange={(ev) => this.handleOnInputChange("company_overview", ev)}
            id="description-input"
            style={{ border: this.state.errors.number_of_suppliers ? "1px solid red" : "" }}
          />
          {this.state.errors.number_of_suppliers ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <div className="competitors-section" id="list-competetors">
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="competitors-input">
              {this.handleConditon(this.state.language === "en", configJSON.competitorsInputLabel , configJSON.competitorsInputLabel_ar)} <span>*</span>
            </InputLabel>
            {this.state.data?.company_overview?.data.attributes.competitors.map(
              (comp: string, strIndex: number) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly", alignItems: "baseline" }}
                    key={strIndex}
                  >
                    <div>
                      <Typography style={{ color: "#000000" }}>
                        {strIndex + 1}.
                      </Typography>
                    </div>
                    <div style={{ display: "flex", width: "96%" }}>
                      <InputBase
                        value={comp}
                        id={`comp-input-${strIndex}`}
                        fullWidth
                        style={{ border: this.state.errors.competitors ? "1px solid red" : "" }}
                        onChange={(ev) =>
                          this.handleArrayInput(
                            "company_overview",
                            "competitors",
                            ev.target.value,
                            strIndex
                          )
                        }
                      />
                    </div>
                  </div>
                );
              }
            )}
            {this.state.errors.competitors ? (
              <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
            ) : (
              <></>
            )}
            <div >
              <IconButton
                id="add-btn-comp"
                data-test-id="add-comp"
              className="mb-0"
                onClick={() =>
                  this.addNewItem("company_overview", "competitors")
                }
              >
                <AddCircleOutlineIcon style={{ fontSize: 30 }} />
              </IconButton>
            </div>
          </FormControl>
        </div>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
            {this.handleConditon(this.state.language === "en", configJSON.growthOpportunityInputLabel , "فرص التوسع والنمو")} <span>*</span>
          </InputLabel>
          <MultiCheckBox
            options={this.handleConditon(this.state.language === "en", this.state.growthApportunities , this.state.growthApportunitiesAr)}
            selectedValue={
              this.state.data?.company_overview?.data.attributes.growth_opportunity
            }
            fieldName={"company_overview"}
            data-test-id="growth-checkbox"
            name="growth_opportunity"
            handleCheckboxChange={this.handleCheckboxChange}
            error={this.state.errors.growth_opportunity}
            inputName="growthOpportunityOther"
            otherValue={this.state.growthOpportunityOther}
            handleChange={this.handleChange}
            otherText={this.handleConditon(this.state.language === "en", configJSON.otherCheckboxLabel , "أخرى:")}
            handleClick={() => this.handleCheckboxOther('growth_oppurtunity')}
            otherCheckbox={this.state.otherCheckbox1}
            otherName={'growth_opportunity'}
            errorMsg={this.state.companyProfilePageText.fieldError}
          />
          {conditionSort(this.state.errors.growth_opportunity && !this.state.otherCheckbox1, <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>, <></>)}
          
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
            {this.handleConditon(this.state.language === "en", configJSON.keyAssetsInputLabel , "أصول رئيسية ")} <span>*</span>
          </InputLabel>
          <MultiCheckBox
            options={this.handleConditon(this.state.language === "en", this.state.keyAssests , this.state.keyAssestsAr)}
            selectedValue={this.state.data?.company_overview?.data.attributes.key_assets}
            fieldName={"company_overview"}
            name="key_assets"
            data-test-id="key-checkbox"
            handleCheckboxChange={this.handleCheckboxChange}
            error={this.state.errors.key_assets}
            inputName="keyAssetsOther"
            otherValue={this.state.keyAssetsOther}
            handleChange={this.handleChange}
            otherText={this.handleConditon(this.state.language === "en", configJSON.otherCheckboxLabel , "أخرى:")}
            handleClick={() => this.handleCheckboxOther('key_assets')}
            otherCheckbox={this.state.otherCheckbox2}
            otherName={'key_assets'}
            errorMsg={this.state.companyProfilePageText.fieldError}
          />
          {conditionSort(this.state.errors.key_assets && !this.state.otherCheckbox2, <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>, <></>)}
        </FormControl>
      </Box>
    );
  };

  getAcquisitionDetails = () => {
    const style = () => {
      return {
        marginTop: "40px",
        width: "100%",
        "& .MuiFormControl-root": {
          marginBottom: "12px",
          "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
          "& .MuiInputBase-root": this.MuiInputBaseRoot,
          "@media (max-width:600px)": {
            marginBottom: "10px",
          },
          "&.last": {
            marginBottom: "0",
          },
        },
        "& .check-box-div": {
          display: "flex",
          flexWrap: "wrap",
        },
        "& .check-box-wrap": {
          flexBasis: "33.33%",
          maxWidth: "500px",
          "@media (max-width:600px)": {
            flexBasis: "100%",
          },
        },
      };
    };
    return (
      <Box sx={style()}>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
            {this.state.language === "en" ? configJSON.reasonForSellingLabelText : "سبب بيع الشركة"} <span>*</span>
          </InputLabel>
          <InputBase
            name="reason_for_selling"
            style={{ border: this.state.errors.reason_for_selling ? "1px solid red" : "" }}
            value={this.state.data.acquisition_detail?.data.attributes.reason_for_selling}
            onChange={(ev) =>
              this.handleOnInputChange("acquisition_detail", ev)
            }
            id="description-input"
            data-test-id="reasonforselling-input"
            multiline
            rows={3}
          />
          {this.state.errors.reason_for_selling ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>

        <FormControl fullWidth>
          <InputLabel shrink htmlFor="description-input">
          {this.state.language === "en" ? configJSON.financingSoucesLabelText : "مصدر التمويل "} <span>*</span>
          </InputLabel>

            <div
              style={{ display: "flex", justifyItems: "center", marginTop: "40px" }}
              className="check-box-div"
            >
              <div className="check-box-wrap">
                <FormControlLabel
                  data-test-id="fcl1"
                  control={
                    <Checkbox
                      name="self_funded"
                      data-test-id={`checkbox-self`}
                      checked={
                        this.state.data?.acquisition_detail.data.attributes?.self_funded
                          ? true
                          : false
                      }
                      onChange={(ev) =>
                        this.handleOnInputChange("acquisition_detail", {
                          target: {
                            name: "self_funded",
                            value: ev.target.checked,
                          },
                        })
                      }
                    />
                  }
                  label={<Typography>{this.state.language === "en" ? configJSON.selfFundedCheckboxLabel : "تمويل ذاتي "}</Typography>}
                />
              </div>
              <div className="check-box-wrap">
                <FormControlLabel
                data-test-id="fcl3"
                  control={
                    <Checkbox
                      name="corporate_funded"
                      data-test-id="corporate_funded"
                      checked={
                        this.state.data?.acquisition_detail.data.attributes?.corporate_funded
                          ? true
                          : false
                      }
                      onChange={(ev) =>
                        this.handleOnInputChange("acquisition_detail", {
                          target: {
                            name: "corporate_funded",
                            value: ev.target.checked,
                          },
                        })
                      }
                    />
                  }
                  label={<Typography>{this.state.language === "en" ?configJSON.corporateFundedCheckboxLabel : "تمويل من الشركات "}</Typography>}
                />
              </div>

              <div className="check-box-wrap">
                <FormControlLabel
                data-test-id="fcl4"
                  control={
                    <Checkbox
                      name="shareholders_equity" data-test-id="shareholders_equity"
                      checked={
                        this.state.data?.acquisition_detail.data.attributes?.shareholders_equity
                          ? true
                          : false
                      }
                      onChange={(ev) =>
                        this.handleOnInputChange("acquisition_detail", {
                          target: {
                            name: "shareholders_equity",
                            value: ev.target.checked,
                          },
                        })
                      }
                    />
                  }
                  label={<Typography>{this.state.language === "en" ? configJSON.shareholdersEquityCheckboxLabel : configJSON.shareholdersEquityCheckboxLabel_ar}</Typography>}
                />
              </div>

              <div className="check-box-wrap">
                <FormControlLabel
                  data-test-id="fcl5"
                  control={
                    <Checkbox
                      name="loans"
                      data-test-id="loans"
                      checked={
                        this.state.data?.acquisition_detail.data.attributes?.loans
                          ? true
                          : false
                      }
                      onChange={(ev) =>
                        this.handleOnInputChange("acquisition_detail", {
                          target: { name: "loans", value: ev.target.checked },
                        })
                      }
                    />
                  }
                  label={<Typography>{this.state.language === "en" ?configJSON.loansCheckboxLabel : "قروض"}</Typography>}
                />
              </div>

              <Grid justifyContent="center" className="check-box-wrap">
                <FormControlLabel style={{marginLeft: this.returnStyles()}} data-test-id="fcl2" control={<Checkbox data-test-id="checkbox-other-ac" value={this.state.otherCheckbox3} onChange={() => this.handleCheckboxOther('finance')} />} 
                 label={this.state.language === "en" ? configJSON.otherCheckboxLabel : "أخرى:"} />
                <input
                  name="other"
                  disabled={!this.state.otherCheckbox3}
                  data-test-id="input-value-other-ac"
                  onChange={(ev) => this.handleOnInputChange("acquisition_detail", ev)}
                  value={this.state.data?.acquisition_detail.data.attributes?.other}
                  style={{
                    borderBottom: "1px solid #000000",
                    borderRight: "none",
                    borderTop: "none",
                    borderLeft: "none",
                    outline: "none",
                    width: "100px",
                    borderColor: conditionSort(this.state.errors.financing_source && this.state.otherCheckbox3 && !this.state.data?.acquisition_detail.data.attributes?.other, 'red', '#000000')

                  }}
                  className="input"
                />
                {conditionSort(this.state.errors.financing_source && this.state.otherCheckbox3 && !this.state.data?.acquisition_detail.data.attributes?.other, <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>, <></>)}
              </Grid>
            </div>
            {conditionSort(this.state.errors.financing_source && !this.state.otherCheckbox3, <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>, <></>)}
          
        </FormControl>
      </Box>
    );
  };

  getfinancial = () => { 
    const tooltip = (title: JSX.Element) => {
      return (
        <Tooltip title={title} arrow placement="top"
          className="popper" style={{paddingTop: "0px", paddingBottom: "0px"}}
        >
          <IconButton>
            <InfoIcon style={{ color: "grey" }} />
          </IconButton>
        </Tooltip>
      )
    }
    return (
      <Box sx={this.commonStyle()}>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="startup-team-size-input">
            {this.handleConditon(this.state.language === "en",configJSON.ltmGrossRevenueInputLabel , "إجـمالي إيرادات آخر 12 شهر ")}<span>*</span><span>
              {tooltip(<Typography>{this.handleConditon(this.state.language === "en", configJSON.ltmGrossRevenueTooltip , "أدخل إجمالي مبلغ المال الذي حققته شركتك من المبيعات خلال الأشهر الاثني عشر الماضية قبل خصم أي نفقات.")}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{this.handleConditon(this.state.language === "en", configJSON.currency ,configJSON.currency_ar)}</span>

          <InputBase
          style={
            conditionSort(this.state.language == 'ar', {
            paddingRight: "40px",
            border: this.state.errors.ltm_gross_revenue ? "1px solid red" : "",
        }, {
            paddingLeft: "40px",
            border: this.state.errors.ltm_gross_revenue ? "1px solid red" : "",
        })}
            value={this.state.data.financial.data.attributes?.ltm_gross_revenue}
            name="ltm_gross_revenue" data-test-id="ltmgross-input"
            onChange={(ev) => this.handleNumChange("financial", ev)}
            id="startup-team-size-input"
          />
          {this.state.errors.ltm_gross_revenue ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="startup-team-size-input">
            {this.handleConditon(this.state.language === "en", configJSON.ltmNetProfitInputLabel , "صافي ربح آخر 12 شهر ")}<span>*</span><span>
              {tooltip(<Typography>{this.handleConditon(this.state.language === "en", configJSON.ltmNetProfitTooltip , "أدخل مبلغ المال المتبقي من إجمالي إيراداتك بعد طرح جميع التكاليف والنفقات خلال الأشهر الاثني عشر الماضية.")}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{this.handleConditon(this.state.language === "en", configJSON.currency ,configJSON.currency_ar)}</span>

          <InputBase
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "40px",
              border: this.state.errors.ltm_net_profit ? "1px solid red" : "",
            }, {
              paddingLeft: "40px",
              border: this.state.errors.ltm_net_profit ? "1px solid red" : "",
            })}
            value={this.state.data.financial.data.attributes?.ltm_net_profit}
            name="ltm_net_profit"
            data-test-id="ltmnet-input"
            onChange={(ev) => this.handleNumChange("financial", ev)}
            id="startup-team-size-input-2"
          />
          {this.state.errors.ltm_net_profit ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="startup-team-size-input">
            {this.handleConditon(this.state.language === "en", configJSON.annualRecurringRevenueInputLabel , "الإيرادات السنوية المتكررة")}<span>*</span><span>
              {tooltip(<Typography>{this.handleConditon(this.state.language === "en",configJSON.annualRecurringRevenueTooltip , "أدخل مبلغ المال الذي تتوقع شركتك أن تتلقاه بانتظام كل عام من أشياء مثل الاشتراكات أو الخدمات المستمرة.")}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{this.handleConditon(this.state.language === "en", configJSON.currency ,configJSON.currency_ar)}</span>
          <InputBase
          style={conditionSort(this.state.language == 'ar', {
            paddingRight: "40px",
            border: this.state.errors.annual_recurring_revenue ? "1px solid red" : "",
          }, {
            paddingLeft: "40px",
            border: this.state.errors.annual_recurring_revenue ? "1px solid red" : "",
          })}
            value={this.state.data.financial.data.attributes?.annual_recurring_revenue}
            name="annual_recurring_revenue"
            data-test-id="annual-input"
            onChange={(ev) => this.handleNumChange("financial", ev)}
            id="startup-team-size-input-3"
          />
          {this.state.errors.annual_recurring_revenue ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="startup-team-size-input">
            {this.handleConditon(this.state.language === "en", configJSON.annualGrowthRateInputLabel ,configJSON.annualGrowthRateInputLabel_ar )}<span>*</span><span>
              {tooltip(<Typography>{this.handleConditon(this.state.language === "en", configJSON.annualGrowthRateTooltip , "أدخل النسبة المئوية لارتفاع إيرادات شركتك مقارنة بالعام السابق. لا تقم بتضمين أي أرقام سلبية، حتى لو لم تنمو شركتك.")}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText") + ' f16'}>%</span>

          <InputBase
          style={conditionSort(this.state.language == 'ar', {
            paddingRight: "20px",
            border: this.state.errors.annual_growth_rate ? "1px solid red" : "",
          }, {
            paddingLeft: "20px",
            border: this.state.errors.annual_growth_rate ? "1px solid red" : "",
          })}
            value={this.state.data.financial.data.attributes?.annual_growth_rate}
            name="annual_growth_rate"
            data-test-id="annualgrowth-rate-input"
            onChange={(ev) => this.handleNumChange("financial", ev)}
            id="startup-team-size-input-4"
          />
          {this.state.errors.annual_growth_rate ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl fullWidth className="last">
              <InputLabel shrink htmlFor="startup-team-size-input">
                {this.handleConditon(this.state.language === "en", configJSON.revenueMultipleInputLabel ,configJSON.revenueMultipleInputLabel_ar)}<span>
              {tooltip(<Typography>{this.handleConditon(this.state.language === "en", configJSON.revenueMultipleTooltip , "3-10 أضعاف الإيرادات، وهي نسبة عالية بالنسبة للشركات الناشئة في مجال التكنولوجيا بسبب إمكانات النمو.")}</Typography>)}
            </span>
              </InputLabel>
              <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText") + ' f16'}>X</span>
              <InputBase
                type="number"
                inputProps={{ max: 999 }}
              style={conditionSort(this.state.language == 'ar', {
                paddingRight: "12px",
              }, {
                paddingLeft: "12px",
              })}
                value={this.state.data.financial.data.attributes?.revenue_multiple}
                name="revenue_multiple"
                data-test-id="lastmonthgrass-input"
                onChange={(ev) => this.handleNumChange("financial", ev)}
                id="revenue_multiple-input"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl fullWidth className="last">
              <InputLabel shrink htmlFor="price-earnings-ratio">
                {this.handleConditon(this.state.language === "en", configJSON.priceEarningsRatioInputLabel ,configJSON.priceEarningsRatioInputLabel_ar)}<span>
              {tooltip(<Typography>{this.handleConditon(this.state.language === "en",configJSON.priceEarningsRatioTooltip , "السعر / الأرباح")}</Typography>)}
            </span>
              </InputLabel>
            <span data-test-id="xxxx" className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText" + ' f16')}>X</span>
              <InputBase
                type="number"
                inputProps={{ max: 999 }}
                style={conditionSort(this.state.language == 'ar', {
                  paddingRight: "12px",
                }, {
                  paddingLeft: "12px",
                })}
                value={this.state.data.financial.data.attributes?.price_earnings_ratio}
                name="price_earnings_ratio"
                data-test-id="lastmonthnetprofit-input"
                onChange={(ev) => this.handleNumChange("financial", ev)}
                id="price-earnings-ratio"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  getCustomerAndVendoerMetrics = () => {
    return (
      <Box sx={this.commonStyle()}>
        <FormControl fullWidth className="last">
          <InputLabel shrink htmlFor="number-of-customer-input">
          {this.handleConditon(this.state.language === "en", configJSON.howManyCustomersInputLabel , configJSON.howManyCustomersInputLabel_ar)}{" "}
            <span>*</span>
          </InputLabel>
          <InputBase
            value={this.state.data.customer_metric.data.attributes?.number_of_customer}
            name="number_of_customer"
            onChange={(ev) => this.handleNumChange("customer_metric", ev)}
            id="number-of-customer-input"
            data-test-id="numberofusers"
            style={{ border: this.state.errors.number_of_customer ? "1px solid red" : "" }}
          />
          {this.state.errors.number_of_customer ? (
            <Box sx={this.errorField}>{this.state.companyProfilePageText.errorMessage}</Box>
          ) : (
            <></>
          )}
        </FormControl>
      </Box>
    );
  };

  renderListingModal = () => {
    return (
      <Container style={styles.container}>
        <Box display={"flex"} flexDirection={"column"} style={{...styles.containerBox, margin: 0, gap: "2rem", padding: "24px"}}>
        <Box alignItems={"center"} display={"flex"} justifyContent={"center"}>
        <Top_heading style={{ margin: "0px", fontSize: "22px" }}>
            {this.state.language==="ar"?configJSON.addNewListingText_ar:configJSON.addNewListingText}
            </Top_heading>
            <CancelOutlinedIcon data-test-id="closeListingModal" onClick={() => this.handleListingModal(false)} style={{ position: "absolute", right: "45px", fontSize: "2.2em" }} />
          </Box>
          {!this.state.isListingModalSuccess &&
            <>
              <Box sx={styles.box18} style={{gap: "36px"}}>
                <Box
                  sx={{
                    border: this.state.openStartUpBox
                      ? "4px solid #5412FC"
                      : "2px solid #8C8C8C",
                    ...(!this.state.openStartUpBox
                      ? styles.box19
                      : styles.box20),
                      position: "relative"
                  }}
                  style={{width: "150px", height: "150px"}}
                  className="box19"
                  data-test-id="startUpBoxList"
                  onClick={() => this.handleStartUpBox()}
                >
                  <div style={{position: "absolute", top: "10px", right: "10px"}}>{this.renderStartUpDotImageFn()}</div>
                  <div
                    style={{
                      width: "100%",
                      height: this.state.openStartUpBox ? "70%" : "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex"
                    }}
                  >
                    <TypographyCategory
                      style={{
                        color: this.state.openStartUpBox
                          ? "rgba(84, 18, 252, 0.80)"
                          : "",
                          fontSize: "22px"
                      }}
                      className="boxText"
                    >
                     {this.state.language === 'ar' ? configJSON.startUpText_ar:configJSON.startUpText}
                    </TypographyCategory>
                  </div>
                </Box>
                {
                  <Box
                    sx={{
                      border: this.state.openBuisnessBox
                        ? "4px solid #5412FC"
                        : "2px solid #8C8C8C",
                      ...(!this.state.openBuisnessBox
                        ? styles.box19
                        : styles.box20),
                        position: "relative"
                    }}
                    style={{width: "150px", height: "150px"}}
                    data-test-id="businessBoxList"
                    onClick={() => this.handleBuisnessBox()}
                  >
                    <div style={{position: "absolute", top: "10px", right: "10px"}}>{this.renderBuisnessDotImageFn()}</div>
                    <div
                      style={{
                        width: "100%",
                        height: this.state.openBuisnessBox ? "70%" : "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                      }}
                    >
                      <TypographyCategory
                        style={{
                          color: this.state.openBuisnessBox
                            ? "rgba(84, 18, 252, 0.80)"
                            : "",
                          fontSize: "22px"
                        }}
                        className="boxText"
                      >
                       {this.state.language === 'ar' ? configJSON.businessText_ar : configJSON.businessText}
                      </TypographyCategory>
                    </div>
                  </Box>
                }
              </Box>
              {(this.state.openStartUpBox || this.state.openBuisnessBox) && openBox(
                this.state.openStartUpBox,
                this.state.openBuisnessBox,
                this.state.boxTitle,
                this.state.boxContent
              )}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CustomizeButton
                  onClick={() => this.createNewSme()}
                  disabled={this.renderDisabledButton()}
                  data-test-id="nextBtnList"
                  style={{
                    width: "171px",
                    height: "48px",
                    background:
                      this.state.openBuisnessBox ||
                        this.state.openStartUpBox
                        ? "#10BFB8"
                        : "#8C8C8C"
                  }}
                  variant="contained"
                >
                  <ButtonTypography style={{...styles.buttonContent, fontSize: "18px"}}>{this.state.language === 'ar' ? configJSON.NextbuttonText_ar : configJSON.NextbuttonText}
                  </ButtonTypography>
                </CustomizeButton>
              </Box>
            </>}
          {this.state.isListingModalSuccess &&
            <>
              <Box style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}>
                <Box style={{
                  borderRadius: "20px",
                  border: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "16px 18px 32px 18px",
                  gap: "12px"
                }}>
                  <div style={{
                    "display": "flex",
                    "gap": "12px",
                    "alignItems": "center",
                    "height": "fit-content",
                  }}>
                    <CheckCircleOutlineOutlinedIcon style={{
                      "color": "#5412FC",
                      "fontSize": "2.5em",
                    }} />
                    <p style={{
                      "fontSize": "22px",
                      "fontWeight": 600,
                    }}>{ this.state.language === 'ar' ?configJSON.successText_ar :configJSON.successText}</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                    <p style={{ fontSize: "18px", textAlign: "center", margin: 0 }}>{ this.state.language === 'ar' ? configJSON.listingAddedText_ar :configJSON.listingAddedText}</p>
                    <p style={{ fontSize: "18px", textAlign: "center", margin: 0 }}>{this.state.language === 'ar' ? configJSON.youCanAccessText_ar :configJSON.youCanAccessText}</p>
                  </div>

                </Box>

              </Box>
            </>}
        </Box>
      </Container>
    )
  }

  renderSuccessModal = () => {
    return(<Box display={"flex"} flexDirection={"column"} style={{gap: "22px", padding: "24px"}}>
      <div style={{
        "height": "fit-content",
        "gap": "16px",
        "display": "flex",
        "alignItems": "center",
      }}>
        <CheckCircleOutlineOutlinedIcon style={{
          "fontSize": "3.5em",
          "color": "#5412FC",
        }} />
        <p style={{
          "fontSize": "22px",
          "fontWeight": 600,
          fontFamily: "Montserrat",
          marginTop: "10px",
          marginBottom: "10px"
        }}>{ this.state.language === 'ar' ?configJSON.successText_ar :configJSON.successText}</p>
            <CancelOutlinedIcon data-test-id="closeListingModalS" onClick={() => this.handleCloseModal()} style={{ position: "absolute", 
            right: conditionSort(this.state.language == 'ar', "unset", "45px"),
            cursor: "pointer",
            left: conditionSort(this.state.language == 'ar', "45px", "unset"), fontSize: "2.7em" }} />

      </div>
      
      <div style={{display: "flex", flexDirection:"column", gap: "18px"}}>
        <p style={{ fontSize: "18px", fontWeight: 500, fontFamily: "Montserrat", textAlign: conditionSort(this.state.language == 'en', "left", "right"), margin: "0" }}>{this.state.language === 'ar' ?configJSON.successConfirmTextLine1_ar:configJSON.successConfirmTextLine1}</p>
        <p style={{ fontSize: "18px", fontWeight: 500, fontFamily: "Montserrat", textAlign: conditionSort(this.state.language == 'en', "left", "right"), margin: "0" }}>{this.state.language === 'ar' ?configJSON.successConfirmTextLine2_ar:configJSON.successConfirmTextLine2}</p>
        <p style={{ fontSize: "18px", fontWeight: 500, fontFamily: "Montserrat", textAlign: conditionSort(this.state.language == 'en', "left", "right"), margin: "0" }}>{this.state.language === 'ar' ?configJSON.successConfirmTextLine4_ar:configJSON.successConfirmTextLine4} {this.state.hashId}</p>
      </div></Box>
    )
  }
  modalStyles = () => {
    return {
      margin: "auto",
      width: "100%",
      "& .MuiFormControl-root": {
        marginBottom: "20px",
        "@media (max-width:600px)": {
          marginBottom: "10px",
        },
        "& .MuiInputLabel-root": this.MuiInputLabelRootCss,
        "& .MuiInputBase-root": this.MuiInputBaseRoot,
        "&.last": {
          marginBottom: "0",
        },
      },
      "& .upload-documents": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#8C8C8C",
        fontSize: "20px",

        wordWrap: "break-word",
        marginTop: "30px",
        gap: "40px",
        border: "2px #8C8C8C dashed",
        borderRadius: "20px",
        padding: "20px",
        "&:hover": {
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
      },
      "& .document-file": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "20px",
        border: "1px #8C8C8C solid",
        background: "rgba(140, 140, 140, 0.25)",
        marginTop: "20px",
        padding: "20px",
        cursor: 'pointer',
        "&:hover": {
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
      },
      "& .titleInSideBox": {
        fontWeight: 600,
        fontSize: "30px",
        fontStyle: "normal",
        fontFamily: "Montserrat",
        "@media(max-width: 600px)": {
          fontSize: "18px !important"
        }
      },
      "& .imgStyles": {
        position: "relative",
        width: "24px",
        top: "20px",
        left: "150px",
        height: "24px",
        "@media(max-width: 600px)": {
          top: "10px !important",
          left: "90px !important",
          width: "14px !important",
          height: "14px !important",
        }
      },
      "& .responsiveBox":{
        gap:"30%",
        "@media(max-width: 600px)": {
          gap:"10%"
        }
      }
    }
  }
  renderDisabledButton = () => {
    if (this.state.openStartUpBox || this.state.openBuisnessBox) {
      return false;
    } else {
      return true;
    }
  };

  renderStartUpDotImageFn = () => {
    if (this.state.openStartUpBox) {
      return <DotImg src={dot} style={{position: "static", width: "16px"}} />;
    }
  };

  renderBuisnessDotImageFn = () => {
    if (this.state.openBuisnessBox) {
      return <DotImg src={dot} style={{position: "static", width: "16px"}} />;
    }
  };
  componentDidUpdate(prevProps:any) {
    if (prevProps.navigation.getParam("id") !== this.props.navigation.getParam("id")) {
      this.componentDidMount();
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        
        <Box
          sx={this.startUpDetailsStyle()}
          data-test-id="main-startup-details"
        >
          <div className="drop-down" id="dd" style={{ display: 'flex', alignItems: 'center', background:'#fff',zIndex:1001 }} aria-describedby={Boolean(this.state.anchorEl) ? 'simple-popover' : undefined} onClick={this.handlePopver}>
            <img src={businessLogo} style={{ backgroundColor: "rgba(84, 18, 252, 1)", borderRadius: "50%", margin: '10px 10px', width: "40px", height: "40px" }} />
            <Typography style={{ marginRight: '20px', width: "80%" }}>{this.state.data?.name}</Typography>
            <img src={selectMenuIcon} style={{ width: '30px', height: '20px' }} />
          </div>
          {Boolean(this.state.anchorEl) && (<div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
              }}
            />)}
          <Popover
            id="simple-popover"
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            onClose={() => { this.setState({ anchorEl: null }) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }}
            PaperProps={{
              style: {
                height:'201px',
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                width: '20%',
                padding: "20px",
              },
            }}
            style={{ marginTop: "40px", padding: '10px 20px', marginLeft:'30px' }}
          >
            <div  data-test-id="pop-new-heading" className="drop-down" style={{ justifyContent: "center", height: '100px' }} >
              {
                this.state.usersList.map((userData: any, index: number) => {
                  return (
                    <div onClick={() => this.redirectTo(userData.id, userData.attributes.category_id)}className="drop-down" id={`dynamic-${index}`} style={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #000", padding: '20px 0px' }}>
                      
                      <img src={conditionSort(userData.attributes.category_name == 'SME', businessLogo, rocketIcon) } 
                        style={{ backgroundColor: conditionSort(userData.attributes.category_name == 'SME', "rgba(84, 18, 252, 1)", "rgba(16, 191, 184, 1)") , borderRadius: "50%", width: "30px", height: "30px" }} />
                      <Typography style={{ marginLeft: '20px', fontSize: "20px" }}>{userData.attributes.name}</Typography>
                    </div>
                  )
                })
              }
              
              <div className="drop-down" id="static" style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }} 
              onClick={() => this.handleListingModal(true)}>
                <AddCircleOutlineIcon />
                <Typography style={{ marginLeft: '20px' }}>{this.state.language==="ar"?configJSON.addNewListingText_ar:configJSON.addNewListingText}</Typography>
              </div>
              <Typography style={{ marginLeft: '20px', fontSize:'12px', marginBottom: '12px' }} >{this.state.language==="ar"?configJSON.addingNewListingCompleteText_ar:configJSON.addingNewListingCompleteText}</Typography>
            </div>
          </Popover>
          
          <CardDetails data-test-id="card-details" data={this.state.data} language={this.state.language} pageText={this.state.companyProfilePageText} profilePercent={this.state.userData.profile_percent}/>
          {this.state.data?.id ?<>
          <AccordionComponent
            heading={this.handleConditon(this.state.language === "en", configJSON.privateInformationHeadingText , "معلومات خاصة") }
            subHeading={this.handleConditon(this.state.language === "en", configJSON.subHeadingLoremIpsumText , "سيتم مشاركة هذه المعلومات مع مشتري Nami المتميزين إذا تم الاتفاق على اتفاقية عدم إفشاء بينك وبين المشتري") }
            data-test-id="accordion-component-private"
            onSave={() => {
              this.handleSaveClick('private_information'); 
            }}
            onClear={() => this.clearInfo('private_information')}
            clearText={this.handleConditon(this.state.language === "en", configJSON.clearText , configJSON.clearText_ar)}
            saveText={this.handleConditon(this.state.language === "en", configJSON.saveText , configJSON.saveText_ar)}
            expanded = {this.state.expanded}
            handleAccordionClick={this.handleAccordionClick}
            keyVal="personalInfo"
          >
            {this.getPrivateInformation()}
          </AccordionComponent>
          <AccordionComponent
            heading={this.handleConditon(this.state.language === "en", configJSON.summaryHeadingText , configJSON.summaryHeadingText_ar) }
            subHeading={this.handleConditon(this.state.language === "en", configJSON.commonHeadingText , "ستكون هذه المعلومات متاحة لمستخدمي Nami المسجلين") }
            data-test-id="accordion-component-summary"
            onSave={() => {
              this.handleSaveClick('summarry'); 
            }}
            onClear={() => this.clearInfo('summarry')}
            clearText={this.handleConditon(this.state.language === "en", configJSON.clearText , configJSON.clearText_ar)}
            saveText={this.handleConditon(this.state.language === "en", configJSON.saveText , configJSON.saveText_ar)}
            expanded = {this.state.expanded}
            handleAccordionClick={this.handleAccordionClick}
            keyVal="summarybtn"
          >
            {this.getsummarry()}
          </AccordionComponent>
          <AccordionComponent
            data-test-id="accordion-component-company"
            heading={this.handleConditon(this.state.language === "en", configJSON.companyOverViewText , configJSON.companyOverViewText_ar) }
            subHeading={this.handleConditon(this.state.language === "en", configJSON.commonHeadingText , "ستكون هذه المعلومات متاحة لمستخدمي Nami المسجلين") }
            onSave={() => this.handleSaveClick('company_overview')}
            onClear={() => this.clearInfo('company_overview')}
            clearText={this.handleConditon(this.state.language === "en", configJSON.clearText , configJSON.clearText_ar)}
            saveText={this.handleConditon(this.state.language === "en", configJSON.saveText , configJSON.saveText_ar)}
            expanded = {this.state.expanded}
            handleAccordionClick={this.handleAccordionClick}
            keyVal="companybtn"
          >
            {this.getCompanyOverview()}
          </AccordionComponent>
          <AccordionComponent
            data-test-id="accordion-component-aquisition"
            heading={this.handleConditon(this.state.language === "en",  configJSON.acquisationDetailsHeading , configJSON.acquisationDetailsHeading_ar)}
            subHeading={this.handleConditon(this.state.language === "en", configJSON.commonHeadingText , "ستكون هذه المعلومات متاحة لمستخدمي Nami المسجلين") }
            onSave={() => this.handleSaveClick('acquisition_detail')}
            onClear={() => this.clearInfo('acquisition_detail')}
            clearText={this.handleConditon(this.state.language === "en", configJSON.clearText , configJSON.clearText_ar)}
            saveText={this.handleConditon(this.state.language === "en", configJSON.saveText , configJSON.saveText_ar)}
            expanded = {this.state.expanded}
            handleAccordionClick={this.handleAccordionClick}
            keyVal="acquisitonbtn"
          >
            {this.getAcquisitionDetails()}
          </AccordionComponent>
          <AccordionComponent
            data-test-id="accordion-component-financial"
            heading={this.handleConditon(this.state.language === "en", configJSON.financialHeadingText , configJSON.financialHeadingText_ar)}
            subHeading={this.handleConditon(this.state.language === "en", configJSON.commonHeadingText , "ستكون هذه المعلومات متاحة لمستخدمي Nami المسجلين")}
            onSave={() => this.handleSaveClick('financial')}
            onClear={() => this.clearInfo('financial')}
            clearText={this.handleConditon(this.state.language === "en", configJSON.clearText , configJSON.clearText_ar)}
            saveText={this.handleConditon(this.state.language === "en", configJSON.saveText , configJSON.saveText_ar)}
            expanded = {this.state.expanded}
            handleAccordionClick={this.handleAccordionClick}
            keyVal="financial"
          >            
            {this.getfinancial()}
          </AccordionComponent>
          <AccordionComponent
          data-test-id="accordion-component-customerMetrics"
          
            heading={this.handleConditon(this.state.language === "en", configJSON.customerAndVenderMetrics , configJSON.customerAndVenderMetrics_ar) }
            subHeading={this.handleConditon(this.state.language === "en", configJSON.commonHeadingText , "ستكون هذه المعلومات متاحة لمستخدمي Nami المسجلين") }
            onSave={() => this.handleSaveClick('customer_metric')}
            onClear={() => this.clearInfo('customer_metric')}
            clearText={this.handleConditon(this.state.language === "en", configJSON.clearText , configJSON.clearText_ar)}
            saveText={this.handleConditon(this.state.language === "en", configJSON.saveText , configJSON.saveText_ar)}
            expanded = {this.state.expanded}
            handleAccordionClick={this.handleAccordionClick}
            keyVal="metric"
          >
            {this.getCustomerAndVendoerMetrics()}
          </AccordionComponent>
          </> : <></>}
        </Box>
        
        <FooterBlock />
        <Modal
          open={this.state.openFileModal}
          data-test-id="modal"
        >
          <Box style={{
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            width: "80%",
            backgroundColor: "#fff",
            borderRadius: "12px",
            outline: 'none',
            overflowY: 'auto',
            padding: "5% 2%",
          }}
            sx={this.modalStyles()}
          >
            <Grid sm={12} item container>
              <Grid sm={6} item style={{ alignContent: "space-between", justifyContent: "space-between" }}>
                <Typography style={{ fontWeight: 700, fontSize: "24px" }}>
                  {conditionSort(this.state.language == 'en', configJSON.uploadDocumentText, configJSON.uploadDocumentText_ar)}
                </Typography>
              </Grid>
              <Grid sm={6} item style={{ justifyContent: "flex-end", textAlign: this.state.language ==="en" ? 'right' :"left" }}>
                <IconButton onClick={() => this.setState({ openFileModal: false })} data-test-id="close-icon">
                  <CancelOutlinedIcon style={{ color: "#000000" }} />
                </IconButton>
              </Grid>
            </Grid>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="title-input">
                {conditionSort(this.state.language == 'en', configJSON.titleText, configJSON.titleText_ar) }
              </InputLabel>
              <InputBase id="title-input"
                value={this.state.modalFileTitle}
                onChange={(e) => this.setState({ modalFileTitle: e.target.value })}
                data-test-id="modal-file-title"
              />
            </FormControl>
            <input type="file" id="p-input" hidden
              data-test-id="modal-file"
              onChange={(e) => {
                const file = e.target.files && e.target.files[0];
              if (file) {
                this.setState({ modalFile: file });
              }
              }}
            />
            <label htmlFor="p-input" className="upload-documents" >
              <img src={fileUpload} />
              {this.state.modalFile ? this.state.modalFile.name : conditionSort(this.state.language == 'en', "Upload File", "تحميل ملف")}
            </label>
            <div style={{ width: "100%" }}>
              <Button fullWidth id="documents-files-input" data-test-id="modal-file-upload" 
                className="document-file" onClick={() => this.handleDocumentAdd()}
              >
                <img src={documentUpload} />
              </Button>
            </div>
          </Box>
        </Modal>
        <Dialog
          open={this.state.openBoxModal}
          style={{borderRadius: "20px"}}
          onClose={() => this.handleListingModal(false)}
          maxWidth={"lg"}
          data-test-id="modal-add-list"
        > {this.state.successTextModal ? this.renderSuccessModal() : this.renderListingModal()}
          {}
         
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export enum CatalogueType {
  BUSINESS = "business",
  STARTUP = "start up",
}

const getTypeInfo = (type: CatalogueType) => {
  const typeImg =
    type.toLowerCase() === CatalogueType.BUSINESS ? businessLogo : rocketIcon;
  const typeColor = "rgba(84, 18, 252, 0.5)";
  const typeColorOpacity = "rgba(84, 18, 252, 0.5)";
  return { typeImg, typeColor, typeColorOpacity };
};


export const CardDetails = (data: any ) => {
  
  const { typeColorOpacity } = getTypeInfo(CatalogueType.STARTUP);
  const cardDetailsStyle = () => {
    return {
      border: "1px #262626 solid",
      borderRadius: "20px",
      marginBottom: "24px",
      "& .progress-bar-box": {
        height: "20px",
        background: typeColorOpacity,
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        overflow: "hidden",
        "& .progress-bar": {
          width: "50%",
          height: "100%",
          borderBottomRightRadius: "20px",
          borderTopRightRadius: "20px",
        },
      },
      "& .progress-complete": {
        color: "black",
        fontSize: "20px",
        wordWrap: "break-word",
        fontFamily: "Montserrat",
        fontWeight: 400,
        lineHeight: "24px",
        margin: "15px 22px 0",
        display: "flex",
        justifyContent: "flex-end",
      },
      "& .details-section": {
        margin: "0 42px 28px 60px",
        "& .heading-section": {
          display: "flex",
          alignItems: "flex-start",
          gap: "40px",
          marginBottom: "40px",
          "& img": {
            height: "64px",
            width: '64px',
          },
          "& .heading": {
            display: "grid",
            justifyItems: "start",
            gap: "4px",
            "& .sub-heading": {
              color: "black",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "24px",
              fontFamily: "Montserrat",
              wordWrap: "break-word",
            },
            "& .heading-text": {
              color: 'black',
              fontWeight: 700,
              fontSize: "30px",
              fontFamily: "Montserrat",
              wordWrap: "break-word",
              display: 'flex',
              alignItems: 'center'
            },
          },
        },
        "& .text-section": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: "24px",
          "& .text": {
            color: "black",
            fontSize: "20px",
            wordWrap: "break-word",
            fontFamily: "Montserrat",
            fontWeight: 400,
            lineHeight: "24px",
            maxWidth: "50%",
          },
        },
        "& .button-section": {
          display: "flex",
          justifyContent: "space-between",
          "& .complete-button": {
            background: "rgba(84, 18, 252, 1)",
            borderRadius: "12px",
            color: 'white',
            fontSize: "20px",
            border: "none",
            fontFamily: "Montserrat",
            fontWeight: 700,
            lineHeight: "24px",
            wordWrap: "break-word",
            padding: "12px 15px",
            cursor: "pointer",
            "&:hover": {
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
          "& .exploreLink": {
            textDecoration: "none !important",
            cursor: "pointer"
          },
          "& .explore-button": {
            border: "2px #8C8C8C solid",
            borderRadius: "12px",
            color: "#8C8C8C",
            lineHeight: "24px",
            fontSize: '20px',
            fontFamily: "Montserrat",
            fontWeight: 700,
            wordWrap: "break-word",
            padding: "12px 36px",
            background: "transparent",
          },
        },
      },
      "@media (max-width:600px)": {
        marginBottom: "15px",
        "& .progress-complete": {
          fontSize: '12px',
          margin: "9px 20px 0",
        },
        "& .details-section": {
          margin: "0 24px 50px 25px",
          "& .heading-section": {
            gap: "17px !important",
            marginBottom: "63px !important",
            "& img": {
              width: "54px !important",
              height: "54px !important",
            },
            "& .heading": {
              gap: "4px !important",
              "& .sub-heading": {
                fontSize: "12px !important",
              },
              "& .heading-text": {
                fontSize: "14px !important",
              },
            },
          },
          "& .text-section": {
            marginBottom: "18px !important",
            "& .text": {
              fontSize: '10px !important',
              lineHeight: "16px !important",
            },
          },
          "& .button-section": {
            "& .complete-button": {
              lineHeight: "13px !important",
              borderRadius: "5px !important",
              fontSize: "10px !important",
              padding: "7px !important",
            },
            "& .explore-button": {
              borderRadius: "5px !important",
              padding: "7px 19px !important",
              fontSize: "10px !important",
              lineHeight: "13px !important",
            },
          },
        },
      },
    };
  };

  const handleListingNav = () => {
    let existRecord = window.document.getElementById("anchorID") as HTMLAnchorElement;
    existRecord.href = "/Catalogue";
  
  };
  
  return (
      <Box sx={cardDetailsStyle()} data-test-id="card"> 
        <div className="progress-bar-box" id="progress">
          <div className="progress-bar" />
        </div>
        <div className="progress-complete">{Number(data.data.progress || 0)}{data.language === "en" ? data.pageText.progressCompleteLabel : "٪ مكتمل"}</div>
        <div className="details-section">
          <div className="heading-section">
            <img src={businessLogo} style={{ backgroundColor: "rgba(84, 18, 252, 1)", borderRadius: "50%" }}/>
            <div className="heading">
              <div className="sub-heading">{}{data.language === "en" ? data.pageText.yourListingLabel : "قائمتك"}</div>
              <div className="heading-text" data-test-id="heading-text">{conditionSort(data.data.name, data.data.name, conditionSort(data.language == 'ar', 'عمل', 'SME')) } 
              {Number(data.data.progress) == 100 && <span style={{ textTransform: "capitalize",
                marginLeft: "20px", marginRight: "20px", background: conditionSort(data.language == 'ar', configJSON.listingStatusAr[data.data.listing_status], configJSON[data.data.listing_status]), color:"white", borderRadius: "20px", padding: "5px 20px", fontSize: "18px"
                }}>{conditionSort( data.language == 'ar', configJSON.listingStatusArText[data.data.listing_status], configJSON.listingStatusEn[data.data.listing_status])}</span>}</div>
              
            </div>
          </div>
          <div className="text-section">
            <div className="text"> 
            {conditionSort(Number(data.profilePercent) != 100,data.language === "en" ? data.pageText.completeProfileText : "أكمل ملفك الشخصي للتحقق منه وتأمين مكانك في السوق", null)}
            </div>
            <div className="text">{data.language === "en" ? data.pageText.needHelpText : "هل تحتاج إلى مساعدة بهذا؟"}</div>
          </div>
          <div className="button-section" id="btn-sec">
          {conditionSort(Number(data.profilePercent) != 100, <Link to="/SellerProfile">
            <button className="complete-button">{data.language === "en" ? data.pageText.completeProfileButtonLabel : "أكمل ملفك الشخصي"}</button></Link>, <div></div>)}
            
            <NavTag id="anchorID" onClick={handleListingNav} className="exploreLink" data-test-id="catalogueNavTestId">
            <Box className="explore-button" data-test-id="catalogueNavTextTestId">{data.language === "en" ? data.pageText.exploreListingsButtonLabel : "استكشاف القوائم"}</Box>
          </NavTag>
          </div>
        </div>
      </Box>
  );
};

interface AccordionComponentProps {
  heading: string;
  subHeading: string;
  onSave?: any;
  onClear?: any;
  isHide?: boolean;
  clearText? : string;
  saveText?:string;
  expanded?:string;
  handleAccordionClick: (key: string) => void;
  keyVal: string
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
  heading,
  subHeading,
  children,
  onSave,
  onClear,
  isHide,
  clearText,
  saveText,
  expanded,
  handleAccordionClick,
  keyVal,
}) => {

  const headerRef = useRef<HTMLDivElement>(null);
  const handleSaveClick = () => {
  if (headerRef.current) {
    headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  onSave();
  };

  const AccordionComponentStyle = () => {
    return {
      border: "1px #262626 solid",
      borderRadius: "20px",
      marginBottom: "24px",
      overflow: "hidden",
      padding: "32px 40px",
      "& .MuiAccordion-root": {
        boxShadow: "none",
        borderRadius: "0 !important",
        "& .MuiAccordionSummary-root": {
          minHeight: "auto",
          padding: "0",
          "& .MuiIconButton-root": {

            margin: "0 !important",
            padding: "0 !important",
            "& .MuiIconButton-label": {
              width: "48px",
              height: "48px",
              "& img": {
              rotate: "180deg"
              },
              cursor: "pointer"
            },
          },
          "& .MuiAccordionSummary-content": {
            display: "grid",
            gap: "16px",
            margin: "0",
            "& .heading": {
              color: "#262626",
              fontSize: "30px",
              lineHeight: "24px",
              fontFamily: "Montserrat",
              fontWeight: 700,
              wordWrap: "break-word",
            },
            "& .sub-heading": {
              color: "#8C8C8C",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Montserrat",
              fontWeight: 400,
              wordWrap: "break-word",
            },
          },
        },
        "& .MuiCollapse-wrapperInner": {
          marginTop: "24px",
          "& .MuiDivider-root": {
            backgroundColor: "rgba(0, 0, 0, 1)",
          },
          "& .MuiAccordionDetails-root": {
            padding: "0",
          },
          "& .MuiAccordionActions-root": {
            display: "flex",
            padding: "0",
            gap: "200px",
            alignItems: "center",
            justifyContent: "center",
            margin: "40px 0 0px",
            "& .clear-button": {
              borderRadius: "12px",
              border: "2px #8C8C8C solid",
              color: "#8C8C8C",
              fontSize: "20px",
              fontFamily: "Montserrat",
              padding: "7px 73px",
              fontWeight: 700,
              lineHeight: "34px",
              wordWrap: "break-word",
              background: "transparent",
              cursor: "pointer",
              "&:hover": {
                boxShadow:
                  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              },
            },
            "& .save-button": {
              borderRadius: "12px",
              border: "none",
              color: "white",
              fontSize: "20px",
              fontFamily: "Montserrat",
              background: "rgba(84, 18, 252, 1)",
              fontWeight: 700,
              lineHeight: "34px",
              wordWrap: "break-word",
              padding: "7px 75px",
              cursor: "pointer",
              "&:hover": {
                boxShadow:
                  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              },
            },
          },
        },
      },
      "@media (max-width:759px)": {
        "& .MuiAccordion-root": {
          "& .MuiCollapse-wrapperInner": {
            "& .MuiAccordionActions-root": {
              gap: "100px !important",
            },
          },
        },
      },
      "@media (max-width:600px)": {
        marginBottom: "15px",
        padding: "48px 32px 34px",
        "& .MuiAccordion-root": {
          "& .MuiAccordionSummary-root": {
            "& .MuiIconButton-root": {
              "& .MuiIconButton-label": {
                height: "19px !important",
                width: "19px !important",
                "& img": {
                  width: "14px !important",
                  height: "7px !important",
                },
              },
            },
            "& .MuiAccordionSummary-content": {
              gap: "6px !important",
              "& .heading": {
                fontSize: "20px !important",
                lineHeight: "24px !important",
              },
              "& .sub-heading": {
                lineHeight: "15px !important",
                fontSize: "10px !important",
              },
            },
          },
          "& .MuiCollapse-wrapperInner": {
            marginTop: "15px !important",
            "& .MuiAccordionActions-root": {
              gap: "58px !important",
              margin: "40px 0 22px !important",
              "@media (max-width:600px)": {
                margin: "0px 0 22px !important",
              },
              "& .clear-button": {
                borderRadius: "5px !important",
                padding: "6px 24px !important",
                fontSize: "10px !important",
                lineHeight: "10px !important",
              },
              "& .save-button": {
                borderRadius: "5px !important",
                fontSize: "10px !important",
                lineHeight: "10px !important",
                padding: "8px 28px !important",
              },
            },
          },
        },
      },
    };
  };
  return (
    <>
      <Box sx={AccordionComponentStyle()} data-test-id="accordion">
        <Accordion expanded={expanded === keyVal}
        onChange={()=> handleAccordionClick(keyVal)}>
          <AccordionSummary
            expandIcon={<img src={ExpandIcon} />}
            aria-controls="panel1c-content"
            id="panel1c-header"
            ref={headerRef}
          >
            <div className="heading" id="head-1">{heading}</div>
            <div className="sub-heading">{subHeading}</div>
          </AccordionSummary>
          <Divider color="#000000" />
          <AccordionDetails>{children}</AccordionDetails>
          {
            !isHide && (<AccordionActions>
            <button
              onClick={() => onClear()}
              className="clear-button"
              id="clear-button-id"
              data-test-id="clear"
            >
              {clearText}
            </button>
            <button
              onClick={() => handleSaveClick()}
              className="save-button"
              id="save-button-id"
              data-test-id="save"
            >
             {saveText}
            </button>
          </AccordionActions>
          )}
          
        </Accordion>
      </Box>
    </>
  );
};

interface SelectMenuProps {
  selectedValue?: string;
  menuList: { value: string; title: string, arabic_title: string, key?: string, icon?: string }[];
  disabled?: boolean;
  fieldName?: string;
  handleOnInputChange: any;
  name: string;
  style?: any;
}

export const SelectMenu: React.FC<SelectMenuProps> = ({
  selectedValue,
  menuList,
  disabled,
  fieldName,
  handleOnInputChange,
  name,
  style,
}) => {
  const [close, setClose] = useState(false);

  const SelectMenuStyle = () => {
    return {
      border: "1px #8C8C8C solid",
      borderRadius: "12px",
      "@media (max-width:600px)": {
        marginTop: "10px",
      },
      marginTop: "30px",
      overflow: "hidden",
      "& .MuiAccordion-root": {
        boxShadow: "none",
        borderRadius: "0 !important",
        "& .MuiAccordionSummary-root": {
          minHeight: "auto",
          padding: "0",
          "& .MuiIconButton-root": {
            padding: "0 !important",
            margin: "0 !important",
            "& .MuiIconButton-label": {
              height: "28px",
              width: "28px",
              padding: "10px 20px",
            },
          },
          "& .MuiAccordionSummary-content": {
            "& .heading": {
              padding: "12px 24px",
              color: "black",
              fontWeight: 400,
              fontSize: "20px",
              fontFamily: "Montserrat",
              wordWrap: "break-word",
            },
          },
        },
        "& .MuiCollapse-wrapperInner": {
          margin: "0px 20px 20px",
          "& .MuiDivider-root": {
            backgroundColor: "#8C8C8C",
          },
          "& .MuiAccordionDetails-root": {
            padding: "8px 0 0",
            display: "block",
            "& .option": {
              padding: "8px",
              color: "black",
              lineHeight: "24px",
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              wordwrap: "break-word",
              "&:hover": {
                borderRadius: "20px",
                background: "rgb(140, 140, 140, 0.5)",
              },
            },
          },
        },
      },
      "& .MuiAccordion-root.Mui-disabled": {
        backgroundColor: "transparent",
      },
    };
  };

  const handleClose = () => {
    setClose(!close)
  }
  return (
    <>
      <Box sx={SelectMenuStyle()} data-test-id="accordion" style={style}>
        <Accordion disabled={disabled} expanded={close} onClick={handleClose}>
          <AccordionSummary
            expandIcon={<img src={selectMenuIcon} />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className="heading">{selectedValue}</div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            {menuList.map((menu) => (
              <div
                className="option"
                onClick={() =>
                  handleOnInputChange(fieldName, {
                    target: { name: name, value: menu?.key || menu?.title },
                  })
                }
              >{menu.icon ? <img style={{height: "24px", width:"24px", marginRight: "24px", marginLeft:"24px"}} src={menu?.icon} alt={menu.title}/>: <></>}
                {menu.title}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
// Customizable Area End
